import React, { useContext, useEffect, useState } from "react";
import {Card} from "react-bootstrap";


const FormDisclaimer: React.FC < { formName: string}> = (params) => {
    return (
        <div className="row" style={{ padding: 10 }}>
        <div className="col-12">
            <Card className="policyCard">
                <Card.Body className="shadow">
                    <>
                        <div>
                            <div
                                className="row"
                                style={{ paddingTop: 5, paddingBottom: 15 }}
                            >
                                <div className="col-12">
                                    <p>
                                        Please use the Download button below to
                                        download the {params.formName}.
                                        <br></br>
                                        <br></br>
                                        Once form has been downloaded and
                                        printed, complete the form with
                                        signature and date, and save to your
                                        device.<br></br>
                                        <br></br>
                                        Go to the Requests tab and select
                                        &apos;upload a document&apos; and attach
                                        your saved form.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                </Card.Body>
            </Card>
        </div>
    </div>
    );
};

export default FormDisclaimer;

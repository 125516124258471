import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "../shared/Common";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { CPContext } from "../../_helpers/CPContext";
import useUtilities from "../../_helpers/useUtilities";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { config } from "config";

type propType = {
    id: string;
    tab: string;
    faqTag: string;
};

interface fileDownloadRequest {
    id: number;
    policyDWKey: number;
}

const Forms: React.FC = () => {
    const utilities = useUtilities();
    const { get } = useFetch();
    const cpContext = useContext(CPContext);
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessingForms, setIsProcessingForms] = useState(false);
    const [formError, setFormError] = useState("");
    const [policyForms, setPolicyForms] = useState<PolicyForm[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const params = useParams<propType>();
    const navigate = useHistory();
    const { oktaAuth } = useOktaAuth();

    let _policyData = {} as Policy;
    let idParsed = 0;

    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
    }

    const getPolicyForms = async (_productType: string, _companyCode: number) => {
        try {
            if ((policyForms && policyForms.length == 0) || !policyForms) {
                const res = await get<PolicyForm[]>(
                    "/policy/forms?policyDWKey=" + idParsed + "&productType=" + _productType,
                    ""
                );
                setPolicyForms(res);
            }

            setIsProcessingForms(false);
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading this page, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(_companyCode) +
                        " for assistance."
                );
                setIsProcessingForms(false);
            }
        }
    };

    const getPolicyForm = async (_id: number, _name: string) => {
        try {
            const body: fileDownloadRequest = {
                id: _id,
                policyDWKey: idParsed
            };

            let formattedName = _name;
            if (formattedName && formattedName.length > 0) {
                formattedName = formattedName.replaceAll(" ", "");
            } else {
                formattedName = "file";
            }

            const requestOptionsBodyAuth = {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
                }
            };

            await fetch(
                config.resourceServer.apiUrl + "/policyform/download",
                requestOptionsBodyAuth
            )
                .then((response) => response.blob())
                .then((response) => {
                    if (response.type == "application/problem+json") {
                        setFormError("There was a problem loading the selected document.");
                    } else {
                        setFormError("");
                        const url = window.URL.createObjectURL(new Blob([response]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.target = "_blank";
                        link.setAttribute("download", formattedName + ".pdf"); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch((e: unknown) => {
                    if (utilities.isMaintenanceError(e)) {
                        navigate.push("/maint");
                    } else {
                        setFormError("There was a problem loading the selected document.");
                    }
                });
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const getPolicy = async () => {
        setIsProcessingForms(true);
        _policyData = await get<Policy>("/policy/" + idParsed, "");
        setPolicyData(_policyData);
    };

    useEffect(() => {
        getPolicy();

        if (policyData) {
            getPolicyForms(policyData?.productType, policyData?.companyCode);
        }
    });

    const requestsNavigate = (_uri: string) => {
        navigate.push(_uri);
    };

    if (
        policyData &&
        policyData.customerPortalAccessLevel == 0 &&
        !(cpContext.isAdmin || cpContext.isHO)
    ) {
        return (
            <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                We are unable to provide your policy information online at this time. Please call us
                at 800-231-0801.
            </div>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <>
                <h1 style={{ display: "none" }}>Policy Details Error Message</h1>
                <div
                    className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-warning"
                    aria-live="assertive"
                    role="alert"
                >
                    {errorMessage}
                </div>
            </>
        );
    } else {
        return (
            <section aria-labelledby="forms-section">
                <h2 style={{ display: "none" }} aria-labelledby="forms-section">
                    Form Section
                </h2>
                {isProcessingForms ? (
                    <Spinner imageWidth={20} aria-label="Loading" />
                ) : (
                    <>
                        <div tabIndex={0}>
                            <input
                                type="button"
                                style={{
                                    backgroundColor: "#003366",
                                    color: "#fff",
                                    border: "none",
                                    marginTop: 10,
                                    padding: 5,
                                    width: "250px"
                                }}
                                value="Back to Requests"
                                onClick={() =>
                                    requestsNavigate("/policy-detail/" + idParsed + "?tab=service")
                                }
                            />
                        </div>
                        <div className="row" style={{ paddingTop: 20 }} tabIndex={0}>
                            <h3 className="skip-to">Frequently Used Forms</h3>
                            {formError && formError.length > 0 ? (
                                <label className="text-danger">
                                    {formError}
                                    <br />
                                </label>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                {policyForms && policyForms.length > 0 ? (
                                    <Container>
                                        <Row xs={1} md={2} className="g-4">
                                            {policyForms.map((form, idx) => (
                                                <Col key={idx}>
                                                    <Card body>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 5
                                                            }}
                                                        >
                                                            <div
                                                                className="col-10"
                                                                tabIndex={0}
                                                                aria-label={form.formName}
                                                            >
                                                                <h5>
                                                                    <u>
                                                                        <b>{form.formName}</b>
                                                                    </u>
                                                                </h5>
                                                            </div>
                                                            <div className="col-2" tabIndex={0}>
                                                                <a
                                                                    href={"#"}
                                                                    onClick={() =>
                                                                        getPolicyForm(
                                                                            form.resourceVersionFileItemId,
                                                                            form.formName
                                                                        )
                                                                    }
                                                                    className="bi bi-download"
                                                                    aria-label="Download"
                                                                    title="Download Policy Form"
                                                                ></a>
                                                                &nbsp;
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 5
                                                            }}
                                                            tabIndex={0}
                                                        >
                                                            <div className="col-12">
                                                                {form.formDesc}
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Container>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <hr />
                        <div tabIndex={0}>
                            <input
                                type="button"
                                style={{
                                    backgroundColor: "#003366",
                                    color: "#fff",
                                    border: "none",
                                    marginTop: 10,
                                    padding: 5,
                                    width: "250px"
                                }}
                                value="Back to Requests"
                                onClick={() =>
                                    requestsNavigate("/policy-detail/" + idParsed + "?tab=service")
                                }
                            />
                        </div>
                    </>
                )}
            </section>
        );
    }
};

export default Forms;

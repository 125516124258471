/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js";
import jwt_decode from "jwt-decode";
import useFetch from "../../_helpers/useFetch";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../shared/Common";
import useUtilities from "../../_helpers/useUtilities";
import FormDisclaimer from "./FormDisclaimer";
import { config } from "config";

type propType = {
    policyDWKey: string;
    formNumber: string;
};

interface fileDownloadRequest {
    id: number;
    policyDWKey: number;
}

const FormDownload: React.FC = () => {
    const params = useParams<propType>();
    let idParsed = 0;
    let formNumberParsed = "";

    if (
        typeof params.policyDWKey != "undefined" &&
        params.policyDWKey &&
        params.policyDWKey.trim()
    ) {
        idParsed = +params.policyDWKey;
    }
    const navigate = useHistory();
    const search = navigate.location.search;
    const paramsFromURL = new URLSearchParams(search);
    formNumberParsed = paramsFromURL.get("formNumber") ?? "details";

    const cpContext = useContext(CPContext);
    const utilities = useUtilities();
    const { get } = useFetch();
    const { authState, oktaAuth } = useOktaAuth();
    const [errorMessage, setErrorMessage] = useState("");
    const [policyData, setPolicyData] = useState<Policy>();
    const [formData, setFormData] = useState<PolicyForm>();
    const [isProcessing, setIsProcessing] = useState(false);

    const onSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (formData?.resourceVersionFileItemId && formData?.formName) {
            await downloadPolicyForm(formData?.resourceVersionFileItemId, formData?.formName);
        }
    };

    const downloadPolicyForm = async (_id: number, _name: string) => {
        try {
            const body: fileDownloadRequest = {
                id: _id,
                policyDWKey: idParsed
            };

            let formattedName = _name;
            if (formattedName && formattedName.length > 0) {
                formattedName = formattedName.replaceAll(" ", "");
            } else {
                formattedName = "file";
            }

            const requestOptionsBodyAuth = {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
                }
            };

            await fetch(
                config.resourceServer.apiUrl + "/policyform/download",
                requestOptionsBodyAuth
            )
                .then((response) => response.blob())
                .then((response) => {
                    if (response.type == "application/problem+json") {
                        setErrorMessage("There was a problem loading the selected document.");
                    } else {
                        setErrorMessage("");
                        const url = window.URL.createObjectURL(new Blob([response]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.target = "_blank";
                        link.setAttribute("download", formattedName + ".pdf"); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch((error) => {
                    setErrorMessage("There was a problem loading the selected document.");
                });
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage("An unexpected error has occured.");
            }
        }
    };

    useEffect(() => {
        const getPolicy = async () => {
            try {
                setIsProcessing(true);
                const policyFromServer = await get<Policy>(
                    "/policy/full?policyDWKey=" + idParsed,
                    ""
                );
                setPolicyData(policyFromServer);
                if (policyFromServer) {
                    await getForm();
                }

                setIsProcessing(false);
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    setErrorMessage(
                        "There was a problem loading your policy information, please call us at " +
                            utilities.getSupportPhoneNumberByCompanyCode(
                                policyData?.companyCode ?? 0
                            ) +
                            " for assistance."
                    );
                    setIsProcessing(false);
                }
            }
        };

        const getForm = async () => {
            try {
                const formFromServer = await get<PolicyForm>(
                    "/policyform/getformdata?policyDWKey=" +
                        idParsed +
                        "&formNumber=" +
                        formNumberParsed,
                    ""
                );
                if (formFromServer) {
                    setFormData(formFromServer);
                } else {
                    setErrorMessage(
                        "There was a problem loading the selected form, please call us at " +
                            utilities.getSupportPhoneNumberByCompanyCode(
                                policyData?.companyCode ?? 0
                            ) +
                            " for assistance."
                    );
                    setIsProcessing(false);
                }
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    setErrorMessage(
                        "There was a problem loading the selected form, please call us at " +
                            utilities.getSupportPhoneNumberByCompanyCode(
                                policyData?.companyCode ?? 0
                            ) +
                            " for assistance."
                    );
                    setIsProcessing(false);
                }
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getPolicy();
        }
    }, [authState, cpContext, navigate]);

    if (policyData && formData && formData.formName && errorMessage.length <= 0) {
        return (
            <section aria-labelledby="form-download-section">
                <h2 style={{ display: "none" }} aria-labelledby="form-download-section">
                    Form Download Section
                </h2>
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-md-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-6">
                                <h1 className="custom-h1 skip-to" style={{ paddingTop: 10 }}>
                                    {formData.formName} Download
                                </h1>
                            </div>
                            <div className="col-6">
                                <div style={{ paddingTop: 10 }}>
                                    Policy # {policyData?.policyNumber} - {policyData?.product}
                                </div>
                            </div>
                        </div>
                        <FormDisclaimer formName={formData.formName} />
                        <div className="row pt-3 ps-2">
                            <div className="col-12">
                                <div className="row">
                                    <>
                                        <div className="col-4 col-xl-2 col-lg-2 col-md-3 col-sm-4">
                                            <input
                                                type="button"
                                                value="Download"
                                                className="btn btn-Primary"
                                                onClick={onSubmit}
                                                aria-label="Download button"
                                            ></input>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                type="button"
                                                className="btn btn-Primary"
                                                onClick={() =>
                                                    navigate.push(
                                                        "/policy-detail/" +
                                                            idParsed +
                                                            "?tab=service"
                                                    )
                                                }
                                                value="Cancel"
                                                aria-label="Cancel button"
                                            ></input>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <section aria-labelledby="form-download-section">
                <h1 style={{ display: "none" }}>Form Download Error Message</h1>
                <h2 style={{ display: "none" }} aria-labelledby="form-download-section">
                    Form Download Section
                </h2>
                <div
                    className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-danger"
                    aria-live="assertive"
                    role="alert"
                >
                    {errorMessage}
                </div>
            </section>
        );
    } else if (isProcessing) {
        return (
            <section aria-labelledby="form-download-section">
                <h1 style={{ display: "none" }}>Form Download Spinner</h1>
                <h2 style={{ display: "none" }} aria-labelledby="form-download-section">
                    Form Download Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    } else {
        return (
            <section aria-labelledby="form-download-section">
                <h1 style={{ display: "none" }}>Form Download Contact Customer Service</h1>
                <h2 style={{ display: "none" }} aria-labelledby="form-download-section">
                    Form Download Section
                </h2>
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                    Could not load selected form, please contact customer service for assistance.
                    <br></br>
                    <br></br>
                    <input
                        type="button"
                        value="Back"
                        className="btn btn-Primary"
                        onClick={() => navigate.push("/policy-detail/" + idParsed + "?tab=service")}
                        style={{ width: "90px" }}
                        aria-label="Back button"
                    ></input>
                </div>
            </section>
        );
    }
};

export default FormDownload;
/*eslint-enable*/

import React, { useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

const SettingAdd: React.FC = () => {
    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { post } = useFetch();
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const onSaveSetting = async (_name: string, _value: string) => {
        const body: NewSetting = {
            name: _name,
            value: _value
        };
        const result = await post<NewSetting, boolean>(
            "/settings/add/",
            "",
            body
        );
        return result;
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!name) {
            alert("Please enter a name");
            return;
        }
        if (!value) {
            alert("Please enter a value");
            return;
        }

        const result = await onSaveSetting(name, value);

        //TODO: [LOW] Need to build a refresh of the State data instead of hard refreshing the page
        if (result) {
            window.location.href = "/admin/settings";
        } else {
            setErrorMessage(
                "There was a problem adding the Setting, check to make sure the Name is unique."
            );
        }
    };

    if (cpContext.isAdmin) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-md-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2px",
                            borderBottomColor: "#33CCCC"
                        }}
                    >
                        <div className="col-md-8">
                            <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                Settings Admin - Add
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7" style={{ backgroundColor: "#eee" }}>
                            <form
                                className="form-control border-0"
                                onSubmit={onSubmit}
                                style={{ backgroundColor: "#eee" }}
                            >
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        {errorMessage && errorMessage.length > 0 ? (
                                            <>
                                                <span
                                                    style={{ color: "red" }}
                                                    aria-live="assertive"
                                                    role="alert"
                                                >
                                                    {errorMessage}
                                                </span>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Name:</label>
                                        <input
                                            className="form-control skip-to"
                                            type="text"
                                            name="Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            aria-label="Name"
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Value:</label>
                                        <textarea
                                            className="form-control"
                                            name="Value"
                                            rows={8}
                                            cols={78}
                                            onChange={(e) => setValue(e.target.value)}
                                            value={value}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-md-3">
                                        <input
                                            type="submit"
                                            value="Save"
                                            className="btn btn-Primary"
                                            aria-label="Save button"
                                        ></input>
                                    </div>
                                    <div className="col-md-3">
                                        <input
                                            type="button"
                                            className="btn btn-Primary"
                                            onClick={() => navigate.push("/admin/settings")}
                                            value="Cancel"
                                            aria-label="Cancel button"
                                        ></input>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default SettingAdd;

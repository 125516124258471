import React, { useEffect, useContext, useState, SetStateAction, Dispatch } from "react";
import { Spinner } from "../shared/Common";
import { CPContext } from "../../_helpers/CPContext";
import { useOktaAuth } from "@okta/okta-react";
import { Collapse } from "react-bootstrap";
import useFetch from "../../_helpers/useFetch";
import useUtilities from "../../_helpers/useUtilities";
import { useHistory } from "react-router";
import { config } from "config";

interface fileDownloadRequest {
    id: number;
    policyDWKey: number;
}

interface taxDocumentDownloadRequest {
    fileName: string;
    year: string;
    policyDWKey: number;
}

const PolicyDocuments: React.FC<{
    policyData: Policy;
    idParsed: number;
    setErrorMessage: Dispatch<SetStateAction<string>>;
}> = (params) => {
    useEffect(() => {
        const loadPolicyDocuments = async () => {
            await getDocs(params.policyData.companyCode);
            if (!cpContext.hideForNonDev) {
                await getOnlineTransactionHistory(params.policyData.companyCode);
            }
        };
        loadPolicyDocuments();
    }, []);

    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const utilities = useUtilities();
    const { get, post } = useFetch();
    const { authState, oktaAuth } = useOktaAuth();

    const [isProcessingDocs, setIsProcessingDocs] = useState(false);
    const [docError, setDocError] = useState("");
    const [toggleContracts, setToggleContracts] = useState(false);
    const [toggleStatements, setToggleStatements] = useState(false);
    const [toggleCorrespondence, setToggleCorrespondence] = useState(false);
    const [toggleTaxDocuments, setToggleTaxDocuments] = useState(false);
    const [toggleTransactions, setToggleTransactions] = useState(true);

    const [onlineTransactionHistory, setOnlineTransactionHistory] =
        useState<OnlineTransactionHistory[]>();
    const [policyContracts, setPolicyContracts] = useState<PolicyDocument[]>([]);
    const [policyStatements, setPolicyStatements] = useState<PolicyDocument[]>([]);
    const [policyCorrespondence, setPolicyCorrespondence] = useState<PolicyDocument[]>([]);
    const [policyTaxDocuments, setPolicyTaxDocuments] = useState<TaxDocument[]>([]);

    const toggleContractsFunc = React.useCallback(
        () => setToggleContracts(!toggleContracts),
        [toggleContracts]
    );
    const toggleStatementsFunc = React.useCallback(
        () => setToggleStatements(!toggleStatements),
        [toggleStatements]
    );
    const toggleCorrespondenceFunc = React.useCallback(
        () => setToggleCorrespondence(!toggleCorrespondence),
        [toggleCorrespondence]
    );
    const toggleTaxDocumentsFunc = React.useCallback(
        () => setToggleTaxDocuments(!toggleTaxDocuments),
        [toggleTaxDocuments]
    );
    const toggleTransactionsFunc = React.useCallback(
        () => setToggleTransactions(!toggleTransactions),
        [toggleTransactions]
    );

    const getPolicyDoc = async (_id: number) => {
        const body: fileDownloadRequest = {
            id: _id,
            policyDWKey: params.idParsed
        };
        const requestOptionsBodyAuth = {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
            }
        };
        await fetch(
            config.resourceServer.apiUrl + "/policy/downloadpolicyfile",
            requestOptionsBodyAuth
        )
            .then((response) => response.blob())
            .then((response) => {
                if (response.type == "application/problem+json") {
                    setDocError("Could not load the selected document.");
                } else {
                    setDocError("");
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute("download", "file.pdf"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch(() => {
                setDocError("Could not load the selected document.");
            });
    };

    const getTaxDoc = async (_fileName: string, _year: string) => {
        const body: taxDocumentDownloadRequest = {
            fileName: _fileName,
            year: _year,
            policyDWKey: params.idParsed
        };
        const requestOptionsBodyAuth = {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
            }
        };

        const fileName = _fileName.split("_");
        const formattedName = fileName[2] + "_" + fileName[3] + "_" + fileName[4];

        await fetch(
            config.resourceServer.apiUrl + "/customer/downloadtaxfile",
            requestOptionsBodyAuth
        )
            .then((response) => response.blob())
            .then((response) => {
                if (response.type == "application/problem+json") {
                    setDocError("Could not load the selected document.");
                } else {
                    setDocError("");
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute("download", formattedName);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch(() => {
                setDocError("Could not load the selected document.");
            });
    };

    const getOnlineTransactionDoc = async (_id: number) => {
        const body: fileDownloadRequest = {
            id: _id,
            policyDWKey: params.idParsed
        };

        const requestOptionsBodyAuth = {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
            }
        };
        await fetch(
            config.resourceServer.apiUrl + "/policy/downloadtransactionfile",
            requestOptionsBodyAuth
        )
            .then((response) => response.blob())
            .then((response) => {
                if (response.type == "application/problem+json") {
                    setDocError("Could not load the selected document.");
                } else {
                    setDocError("");
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute("download", "file.pdf"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch(() => {
                setDocError("Could not load the selected document.");
            });
    };

    const getDocs = async (_companyCode: number) => {
        try {
            if (!isProcessingDocs) {
                setIsProcessingDocs(true);

                const policyDocsFromServer = await get<PolicyDocument[]>(
                    "/policy/documents?policyDWKey=" + params.idParsed,

                    ""
                );

                let statementsTemp = policyStatements;
                let correspondenceTemp = policyCorrespondence;
                let contractsTemp = policyContracts;
                let taxDocumentsTemp = policyTaxDocuments;
                statementsTemp = policyDocsFromServer.filter(
                    (doc: PolicyDocument) =>
                        doc.documentType.toLowerCase().replaceAll(" ", "") === "annualstatements"
                );
                correspondenceTemp = policyDocsFromServer.filter(
                    (doc: PolicyDocument) =>
                        doc.documentType.toLowerCase().replaceAll(" ", "") === "correspondence"
                );
                contractsTemp = policyDocsFromServer.filter(
                    (doc: PolicyDocument) =>
                        doc.documentType.toLowerCase().replaceAll(" ", "") === "policycontracts"
                );
                taxDocumentsTemp = await get<TaxDocument[]>(
                    "/customer/taxdocuments?policyDWKey=" + params.idParsed,

                    ""
                );

                setPolicyStatements(statementsTemp);
                setPolicyContracts(contractsTemp);
                setPolicyCorrespondence(correspondenceTemp);
                setPolicyTaxDocuments(taxDocumentsTemp);

                setIsProcessingDocs(false);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                params.setErrorMessage(
                    "There was a problem loading this page, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(_companyCode) +
                        " for assistance."
                );
                setIsProcessingDocs(false);
            }
        }
    };

    const getOnlineTransactionHistory = async (_companyCode: number) => {
        if (
            (onlineTransactionHistory && onlineTransactionHistory.length == 0) ||
            !onlineTransactionHistory
        ) {
            try {
                if (!isProcessingDocs) {
                    const onlineTransactionHistory = await get<OnlineTransactionHistory[]>(
                        "/policy/onlinetransactionhistory?policyDWKey=" + params.idParsed,
                        ""
                    );
                    setOnlineTransactionHistory(onlineTransactionHistory);
                }
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    params.setErrorMessage(
                        "There was a problem loading this page, please call us at " +
                            utilities.getSupportPhoneNumberByCompanyCode(_companyCode) +
                            " for assistance."
                    );
                    setIsProcessingDocs(false);
                }
            }
        }
    };

    return (
        <section aria-labelledby="documents-section">
            <h2 style={{ display: "none" }} aria-labelledby="documents-section">
                Documents Section
            </h2>
            {isProcessingDocs ? (
                <Spinner imageWidth={20} aria-label="Loading" />
            ) : (
                <>
                    <div className="row" style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <div className="col-8" tabIndex={0}>
                            <h3>Policy Documents</h3>
                            {docError && docError.length > 0 ? (
                                <label className="text-danger">
                                    {docError}
                                    <br />
                                </label>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <div className="col-12">
                            <div className="row" style={{ paddingTop: 20, color: "#1C2F67" }}>
                                <h2 className="homeOfficeHeadingToggle" id="policyContractCollapse">
                                    <span
                                        onClick={toggleContractsFunc}
                                        style={{
                                            cursor: "pointer",
                                            color: "#1C2F67",
                                            fontWeight: "bold"
                                        }}
                                        tabIndex={0}
                                        role="alert"
                                    >
                                        Policy Contract&nbsp;&nbsp;
                                    </span>
                                    <span
                                        onClick={toggleContractsFunc}
                                        style={{
                                            cursor: "pointer",
                                            color: "#1C2F67",
                                            fontWeight: "bold"
                                        }}
                                        className={
                                            toggleContracts
                                                ? "bi bi-chevron-up"
                                                : "bi bi-chevron-down"
                                        }
                                        aria-hidden={true}
                                        tabIndex={0}
                                    >
                                        &nbsp;
                                    </span>
                                </h2>
                            </div>
                            <Collapse
                                in={toggleContracts}
                                role="region"
                                aria-labelledby="policyContractCollapse"
                            >
                                <div className="card policyCard">
                                    <div className="card-body shadow" style={{ minHeight: 160 }}>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 5 }}
                                        >
                                            <div
                                                className="col-8 fieldLabel"
                                                title="Name"
                                                tabIndex={0}
                                            >
                                                Contract Name
                                            </div>
                                            <div
                                                className="col-4 fieldLabel"
                                                title="Date"
                                                tabIndex={0}
                                            >
                                                Processed Date
                                            </div>
                                        </div>
                                        <>
                                            {policyContracts && policyContracts.length > 0 ? (
                                                <>
                                                    {policyContracts.map((f, idx) => (
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 5
                                                            }}
                                                            key={idx}
                                                        >
                                                            <div
                                                                className="col-8 fieldValue"
                                                                title="Name"
                                                                tabIndex={0}
                                                            >
                                                                <a
                                                                    href={"#"}
                                                                    onClick={() =>
                                                                        getPolicyDoc(f.statementKey)
                                                                    }
                                                                    className="bi bi-download"
                                                                ></a>
                                                                &nbsp;
                                                                {f.documentName}
                                                            </div>
                                                            <div
                                                                className="col-4 fieldValue"
                                                                title="Date"
                                                                tabIndex={0}
                                                            >
                                                                {utilities.parseAndFormatDate(
                                                                    f.processDate
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    <label tabIndex={0}>No Policy Contracts</label>
                                                </>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </Collapse>
                            <div className="row" style={{ paddingTop: 20, color: "#1C2F67" }}>
                                <h2
                                    className="homeOfficeHeadingToggle"
                                    id="annualStatementCollapse"
                                >
                                    <span
                                        onClick={toggleStatementsFunc}
                                        style={{
                                            cursor: "pointer",
                                            color: "#1C2F67",
                                            fontWeight: "bold"
                                        }}
                                        tabIndex={0}
                                    >
                                        Annual Statement&nbsp;&nbsp;
                                    </span>
                                    <span
                                        onClick={toggleStatementsFunc}
                                        style={{
                                            cursor: "pointer",
                                            color: "#1C2F67",
                                            fontWeight: "bold"
                                        }}
                                        className={
                                            toggleStatements
                                                ? "bi bi-chevron-up"
                                                : "bi bi-chevron-down"
                                        }
                                        aria-hidden={true}
                                        tabIndex={0}
                                    >
                                        &nbsp;
                                    </span>
                                </h2>
                            </div>
                            <Collapse
                                in={toggleStatements}
                                role="region"
                                aria-labelledby="annualStatementCollapse"
                            >
                                <div className="card policyCard">
                                    <div className="card-body shadow" style={{ minHeight: 160 }}>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 5 }}
                                        >
                                            <div
                                                className="col-8 fieldLabel"
                                                title="Name"
                                                tabIndex={0}
                                            >
                                                Statement Name
                                            </div>
                                            <div
                                                className="col-4 fieldLabel"
                                                title="Date"
                                                tabIndex={0}
                                            >
                                                Processed Date
                                            </div>
                                        </div>
                                        <>
                                            {policyStatements && policyStatements.length > 0 ? (
                                                <>
                                                    {policyStatements.map((f, idx) => (
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 5
                                                            }}
                                                            key={idx}
                                                        >
                                                            <div
                                                                className="col-8 fieldValue"
                                                                title="Name"
                                                                tabIndex={0}
                                                            >
                                                                <a
                                                                    href={"#"}
                                                                    onClick={() =>
                                                                        getPolicyDoc(f.statementKey)
                                                                    }
                                                                    className="bi bi-download"
                                                                ></a>
                                                                &nbsp;
                                                                {f.documentName}
                                                            </div>
                                                            <div
                                                                className="col-4 fieldValue"
                                                                title="Date"
                                                                tabIndex={0}
                                                            >
                                                                {utilities.parseAndFormatDate(
                                                                    f.processDate
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    <label tabIndex={0}>No Policy Statements</label>
                                                </>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </Collapse>
                            <div className="row" style={{ paddingTop: 20, color: "#1C2F67" }}>
                                <h2
                                    className="homeOfficeHeadingToggle"
                                    id="policyCorrespondenceCollapse"
                                >
                                    <span
                                        onClick={toggleCorrespondenceFunc}
                                        style={{
                                            cursor: "pointer",
                                            color: "#1C2F67",
                                            fontWeight: "bold"
                                        }}
                                        tabIndex={0}
                                    >
                                        Policy Correspondence&nbsp;&nbsp;
                                    </span>
                                    <span
                                        onClick={toggleCorrespondenceFunc}
                                        style={{
                                            cursor: "pointer",
                                            color: "#1C2F67",
                                            fontWeight: "bold"
                                        }}
                                        className={
                                            toggleCorrespondence
                                                ? "bi bi-chevron-up"
                                                : "bi bi-chevron-down"
                                        }
                                        aria-hidden={true}
                                        tabIndex={0}
                                    >
                                        &nbsp;
                                    </span>
                                </h2>
                            </div>
                            <Collapse
                                in={toggleCorrespondence}
                                role="region"
                                aria-labelledby="policyCorrespondenceCollapse"
                            >
                                <div className="card policyCard">
                                    <div className="card-body shadow" style={{ minHeight: 160 }}>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 5 }}
                                        >
                                            <div
                                                className="col-8 fieldLabel"
                                                title="Name"
                                                tabIndex={0}
                                            >
                                                Name
                                            </div>
                                            <div
                                                className="col-4 fieldLabel"
                                                title="Date"
                                                tabIndex={0}
                                            >
                                                Date
                                            </div>
                                        </div>
                                        <>
                                            {policyCorrespondence &&
                                            policyCorrespondence.length > 0 ? (
                                                <>
                                                    {policyCorrespondence.map((f, idx) => (
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 5
                                                            }}
                                                            key={idx}
                                                        >
                                                            <div
                                                                className="col-8 fieldValue"
                                                                title="Name"
                                                                tabIndex={0}
                                                            >
                                                                <a
                                                                    href={"#"}
                                                                    onClick={() =>
                                                                        getPolicyDoc(f.statementKey)
                                                                    }
                                                                    className="bi bi-download"
                                                                ></a>
                                                                &nbsp;
                                                                {f.documentName}
                                                            </div>
                                                            <div
                                                                className="col-4 fieldValue"
                                                                title="Date"
                                                                tabIndex={0}
                                                            >
                                                                {utilities.parseAndFormatDate(
                                                                    f.processDate
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    <label tabIndex={0}>
                                                        No Policy Correspondence
                                                    </label>
                                                </>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </Collapse>
                            {policyTaxDocuments && policyTaxDocuments.length > 0 ? (
                                <>
                                    <div
                                        className="row"
                                        style={{ paddingTop: 20, color: "#1C2F67" }}
                                    >
                                        <h2
                                            className="homeOfficeHeadingToggle"
                                            id="taxDocumentsCollapse"
                                        >
                                            <span
                                                onClick={toggleTaxDocumentsFunc}
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#1C2F67",
                                                    fontWeight: "bold"
                                                }}
                                                tabIndex={0}
                                            >
                                                Tax Documents&nbsp;&nbsp;
                                            </span>
                                            <span
                                                onClick={toggleTaxDocumentsFunc}
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#1C2F67",
                                                    fontWeight: "bold"
                                                }}
                                                className={
                                                    toggleTaxDocuments
                                                        ? "bi bi-chevron-up"
                                                        : "bi bi-chevron-down"
                                                }
                                                aria-hidden={true}
                                                tabIndex={0}
                                            >
                                                &nbsp;
                                            </span>
                                        </h2>
                                    </div>
                                    <Collapse
                                        in={toggleTaxDocuments}
                                        role="region"
                                        aria-labelledby="taxDocumentsCollapse"
                                    >
                                        <div className="card policyCard">
                                            <div
                                                className="card-body shadow"
                                                style={{ minHeight: 160 }}
                                            >
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <div
                                                        className="col-8 fieldLabel"
                                                        title="Tax Year"
                                                        tabIndex={0}
                                                    >
                                                        Tax Year
                                                    </div>
                                                    <div
                                                        className="col-4 fieldLabel"
                                                        title="Form Type"
                                                        tabIndex={0}
                                                    >
                                                        Form Type
                                                    </div>
                                                </div>
                                                <>
                                                    {policyTaxDocuments.map((f, idx) => (
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 5
                                                            }}
                                                            key={idx}
                                                        >
                                                            <div
                                                                className="col-8 fieldValue"
                                                                title="Tax Year"
                                                                tabIndex={0}
                                                            >
                                                                <a
                                                                    href={"#"}
                                                                    onClick={() =>
                                                                        getTaxDoc(
                                                                            f.fileName,
                                                                            f.year
                                                                        )
                                                                    }
                                                                    className="bi bi-download"
                                                                ></a>
                                                                &nbsp;
                                                                {f.year}
                                                            </div>
                                                            <div
                                                                className="col-4 fieldValue"
                                                                title="Form Type"
                                                                tabIndex={0}
                                                            >
                                                                {f.documentType}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            </div>
                                        </div>
                                    </Collapse>
                                </>
                            ) : (
                                <></>
                            )}
                            {(cpContext.isCustomer || cpContext.isHO || cpContext.isAdmin) &&
                            !cpContext.hideForNonDev ? (
                                <>
                                    <div
                                        className="row"
                                        style={{ paddingTop: 20, color: "#1C2F67" }}
                                    >
                                        <h2
                                            className="homeOfficeHeadingToggle"
                                            id="onlineTransactionCollapse"
                                        >
                                            <span
                                                onClick={toggleTransactionsFunc}
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#1C2F67",
                                                    fontWeight: "bold"
                                                }}
                                                tabIndex={0}
                                            >
                                                Online Transaction Documents&nbsp;&nbsp;
                                            </span>
                                            <span
                                                onClick={toggleTransactionsFunc}
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#1C2F67",
                                                    fontWeight: "bold"
                                                }}
                                                className={
                                                    toggleTransactions
                                                        ? "bi bi-chevron-up"
                                                        : "bi bi-chevron-down"
                                                }
                                                aria-hidden={true}
                                                tabIndex={0}
                                            >
                                                &nbsp;
                                            </span>
                                        </h2>
                                    </div>
                                    <Collapse
                                        in={toggleTransactions}
                                        role="region"
                                        aria-labelledby="onlineTransactionCollapse"
                                    >
                                        <div>
                                            <div className="card policyCard">
                                                <div
                                                    style={{
                                                        backgroundColor: "yellow",
                                                        paddingLeft: "5px"
                                                    }}
                                                >
                                                    Please be advised the documents displayed here
                                                    are only documents submitted or uploaded through
                                                    this portal.
                                                </div>
                                                <div
                                                    className="card-body shadow"
                                                    style={{ minHeight: 160 }}
                                                >
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 5, paddingBottom: 5 }}
                                                    >
                                                        <div
                                                            className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldLabel"
                                                            title="Type"
                                                            tabIndex={0}
                                                        >
                                                            Type
                                                        </div>
                                                        <div
                                                            className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldLabel"
                                                            title="Status"
                                                            tabIndex={0}
                                                        >
                                                            Status
                                                        </div>
                                                        <div
                                                            className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldLabel"
                                                            title="Complete Date"
                                                            tabIndex={0}
                                                        >
                                                            Complete Date
                                                        </div>
                                                        <div
                                                            className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldLabel"
                                                            title=""
                                                            tabIndex={0}
                                                        >
                                                            &nbsp;
                                                        </div>
                                                    </div>
                                                    <>
                                                        {onlineTransactionHistory &&
                                                        onlineTransactionHistory.length > 0 ? (
                                                            <>
                                                                {onlineTransactionHistory.map(
                                                                    (h, idx) => (
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                            key={idx}
                                                                        >
                                                                            <div
                                                                                className="col-3 fieldValue"
                                                                                title="Type"
                                                                                tabIndex={0}
                                                                            >
                                                                                {h.requestType}
                                                                            </div>
                                                                            <div
                                                                                className="col-3 fieldValue"
                                                                                title="Status"
                                                                                tabIndex={0}
                                                                            >
                                                                                {h.requestStatus}
                                                                            </div>
                                                                            <div
                                                                                className="col-3 fieldValue"
                                                                                title="Complete Date"
                                                                                tabIndex={0}
                                                                            >
                                                                                {utilities.convertToLocalDate(
                                                                                    h.receiptDate
                                                                                )}
                                                                            </div>
                                                                            <div
                                                                                className="col-3 fieldValue"
                                                                                title="Download"
                                                                                tabIndex={0}
                                                                            >
                                                                                <a
                                                                                    href={"#"}
                                                                                    onClick={() =>
                                                                                        getOnlineTransactionDoc(
                                                                                            h.requestId
                                                                                        )
                                                                                    }
                                                                                    className="bi bi-download"
                                                                                ></a>
                                                                                &nbsp;
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <label tabIndex={0}>
                                                                    No Online Transaction Documents
                                                                    Found
                                                                </label>
                                                            </>
                                                        )}
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </>
            )}
        </section>
    );
};

export default PolicyDocuments;

import React, { useState, useContext, useEffect } from "react";
import { Card, Button, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { Spinner } from "../shared/Common";
import { CPContext } from "../../_helpers/CPContext";

type propType = {
    id: string;
};

const Impersonate: React.FC = () => {
    const cpContext = useContext(CPContext);
    const params = useParams<propType>();
    let idParsed = "";
    if (
        typeof params.id != "undefined" &&
        params.id &&
        params.id.trim() &&
        params.id.trim() != "0"
    ) {
        idParsed = params.id;
    }

    const [policyOwners, setPolicyOwners] = useState<PolicyOwner[]>([]);
    const [searchText, setSearchText] = useState("");
    const navigate = useHistory();
    const { get, post } = useFetch();
    const { authState } = useOktaAuth();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(20);
    const searchInput = React.useRef<HTMLInputElement>(null);
    const [errorMessage, setErrorMessage] = useState("");

    const setImpersonate = async (_policyDWKey: number) => {
        const body: ImpersonateRequest = {
            policyDWKey: _policyDWKey.toString()
        };
        const result = await post<ImpersonateRequest, PolicyOwner>("/impersonate", "", body);

        if (result) {
            if (result.ownerName) {
                cpContext.changeImpersonateName(result.ownerName);
                cpContext.changeImpersonateEmail(result.ownerEmail);
                navigate.push("/policies");
            }
        } else {
            setErrorMessage("An unexpected error has occured, please try again.");
        }

        return result;
    };

    const handleKeywordKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key == "Enter") {
            onSearch("");
        }
    };

    const onSearch = (_searchText: string) => {
        if (_searchText && _searchText.length > 0) {
            fetchPolicyOwners(_searchText, 1, perPage);
        } else {
            fetchPolicyOwners(searchText, 1, perPage);
        }
    };

    const clearImpersonation = async () => {
        const result = await get<boolean>("/impersonate/clear", "");

        if (result) {
            cpContext.changeImpersonateName("");
        } else {
            //Display error
        }

        return result;
    };

    const nextPage = async () => {
        if (currentPage < pageCount) {
            await fetchPolicyOwners(searchText, currentPage + 1, perPage);
        }
    };

    const prevPage = async () => {
        if (currentPage > 1) {
            await fetchPolicyOwners(searchText, currentPage - 1, perPage);
        }
    };

    const handlePerPage = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        let valParsed = 20;
        if (typeof e.target.value != "undefined" && e.target.value && e.target.value.trim()) {
            valParsed = +e.target.value;
        }
        setPerPage(valParsed);
    };

    const fetchPolicyOwners = async (_searchText: string, _curPage: number, _perPage: number) => {
        try {
            setIsProcessing(true);

            let result: PolicyOwner[] = [];
            const body: ImpersonateSearch = {
                pageNumber: _curPage == 0 ? 1 : _curPage,
                pageSize: _perPage,
                searchText: _searchText
            };

            result = await post<ImpersonateSearch, PolicyOwner[]>("/impersonate/search/", "", body);

            if (result && result.length > 0) {
                let pageCount = 0;
                pageCount = result[0].totalRows > 20 ? Math.ceil(result[0].totalRows / perPage) : 1;
                setPageCount(pageCount);
                setCurrentPage(_curPage == 0 ? 1 : _curPage);
            } else {
                setCurrentPage(0);
            }
            setPolicyOwners(result);

            setIsProcessing(false);
        } catch (error) {
            setErrorMessage("An unexpected error has occured, please try again.");
            setIsProcessing(false);
        }
    };

    useEffect(() => {
        if (cpContext.isAdmin || cpContext.isHO) {
            if (null !== searchInput.current) {
                searchInput.current.focus();
                if (idParsed && idParsed.length > 1) {
                    setSearchText(idParsed);
                    onSearch(idParsed);
                }
            }
        }
        // eslint-disable-next-line
    }, [cpContext]);

    return (
        <div className="row" style={{ backgroundColor: "#eee" }}>
            <h1 style={{ display: "none" }}>Act as a Policy Holder</h1>
            <div className="col-md-1"></div>

            <div className="col-md-10">
                <div style={{ paddingTop: 30, paddingBottom: 0 }}>
                    <Card border="secondary">
                        <Card.Header className="whiteBackground">
                            <h1 className="custom-h1" style={{ marginBottom: 0 }}>
                                Act As Policy Holder
                            </h1>
                        </Card.Header>
                        <Card.Body>
                            <div className="row" style={{ backgroundColor: "#fff" }}>
                                <div className="col-md-12">
                                    {errorMessage && errorMessage.length > 0 ? (
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <span
                                                        className="text-danger"
                                                        aria-live="assertive"
                                                        role="alert"
                                                    >
                                                        {errorMessage}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span style={{ fontSize: "12px" }}>
                                                *For SSN search, use no dashes or spaces (i.e.,
                                                XXXXXXXXX)
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingTop: 5 }}>
                                        <div className="col-md-3">
                                            <div>
                                                <input
                                                    type="text"
                                                    ref={searchInput}
                                                    name="searchText"
                                                    title="Hit Enter key to search"
                                                    value={searchText}
                                                    placeholder="Search..."
                                                    className="form-control skip-to"
                                                    onChange={(e) =>
                                                        setSearchText(e.target.value.trim())
                                                    }
                                                    onKeyPress={handleKeywordKeyPress}
                                                    autoComplete="off"
                                                    aria-label="Search"
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div style={{ paddingTop: "8px" }}>
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => onSearch("")}
                                                >
                                                    Search
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div style={{ paddingTop: "20px" }}>
                                                {authState &&
                                                authState.isAuthenticated &&
                                                cpContext.impName &&
                                                cpContext.impName.length > 0 ? (
                                                    <a href="#" onClick={clearImpersonation}>
                                                        Stop Acting as {cpContext.impName}
                                                    </a>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10" style={{ float: "right" }}>
                                            Page {currentPage} out of {pageCount}&nbsp;
                                            {currentPage > 1 ? (
                                                <a href="#" onClick={prevPage}>
                                                    Prev
                                                </a>
                                            ) : (
                                                <></>
                                            )}
                                            &nbsp;
                                            {currentPage < pageCount ? (
                                                <a href="#" onClick={nextPage}>
                                                    Next
                                                </a>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div className="col-md-2" style={{ float: "right" }}>
                                            Per Page:{" "}
                                            <select
                                                name="PerPage"
                                                onChange={handlePerPage}
                                                defaultValue={perPage}
                                            >
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="250">250</option>
                                                <option value="500">500</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Table striped bordered hover responsive="lg">
                                                <thead
                                                    style={{
                                                        backgroundColor: "#003366",
                                                        color: "white",
                                                        height: "40px",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <tr>
                                                        <th aria-label="Person Icon"></th>
                                                        <th>Policy Owner</th>
                                                        <th>Email</th>
                                                        <th>Policy Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {policyOwners &&
                                                    policyOwners.length > 0 &&
                                                    !isProcessing ? (
                                                        <>
                                                            {policyOwners.map((po, idx) => (
                                                                <tr
                                                                    key={idx}
                                                                    onClick={() =>
                                                                        setImpersonate(
                                                                            po.policyDWKey
                                                                        )
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    role="link"
                                                                >
                                                                    <td tabIndex={0}>
                                                                        <span
                                                                            className="bi bi-person"
                                                                            style={{
                                                                                color: "black",
                                                                                cursor: "pointer"
                                                                            }}
                                                                        ></span>
                                                                    </td>
                                                                    <td
                                                                        tabIndex={0}
                                                                        style={{
                                                                            overflow: "hidden",
                                                                            whiteSpace: "nowrap",
                                                                            textOverflow: "ellipsis"
                                                                        }}
                                                                    >
                                                                        {po.ownerName}
                                                                    </td>
                                                                    <td
                                                                        tabIndex={0}
                                                                        style={{
                                                                            overflow: "hidden",
                                                                            whiteSpace: "nowrap",
                                                                            textOverflow: "ellipsis"
                                                                        }}
                                                                    >
                                                                        {po.ownerEmail}
                                                                    </td>
                                                                    <td
                                                                        tabIndex={0}
                                                                        style={{
                                                                            textAlign: "center"
                                                                        }}
                                                                    >
                                                                        {po.policyNumber}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {isProcessing ? (
                                                                <>
                                                                    <tr>
                                                                        <td colSpan={4}>
                                                                            <Spinner
                                                                                imageWidth={20}
                                                                                aria-label="Loading"
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className="col-md-1"></div>
        </div>
    );
};

export default Impersonate;

import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

type propType = {
    id: string;
};

const AdminFAQDetail: React.FC = () => {
    const { id } = useParams<propType>();
    let idParsed = 0;
    if (typeof id != "undefined" && id && id.trim()) {
        idParsed = +id;
    }

    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { get, post } = useFetch();
    const [question, setQuestion] = useState("");
    const [response, setResponse] = useState("");
    const [displaySequence, setDisplaySequence] = useState("");
    const [isActive, setIsActive] = useState<boolean>(false);
    const [faqData, setFAQData] = useState<FAQ>();
    const { authState } = useOktaAuth();
    const [errorMessage, setErrorMessage] = useState("");
    const [tagName, setTagName] = useState("");

    const onSaveFAQ = async (
        _id: number,
        _question: string,
        _response: string,
        _displaySequence: string,
        _isActive: boolean,
        _tagName: string
    ) => {
        const body: FAQ = {
            id: _id,
            question: _question,
            response: _response,
            displaySequence: _displaySequence,
            isActive: _isActive,
            products: [],
            tagName: _tagName
        };

        return await post<FAQ, boolean>("/faq/update/", "", body);
    };

    useEffect(() => {
        const getFAQ = async () => {
            const faqFromServer = await get<FAQ>("/faq/" + idParsed, "");
            setQuestion(faqFromServer.question);
            setResponse(faqFromServer.response);
            setDisplaySequence(faqFromServer.displaySequence);
            setIsActive(faqFromServer.isActive);
            setTagName(faqFromServer.tagName);

            setFAQData(faqFromServer);
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getFAQ();
        }
    }, [idParsed, authState, cpContext, navigate, get]);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            if (!question) {
                setErrorMessage("Please enter a question to continue.");
                return;
            }

            const result = await onSaveFAQ(
                idParsed,
                question,
                response,
                displaySequence,
                isActive,
                tagName
            );

            if (result) {
                navigate.push("/admin/faqs");
            } else {
                setErrorMessage("There was a problem saving, please try again.");
            }
        } catch {
            setErrorMessage("There was a problem, please try again.");
        }
    };

    if (typeof faqData != "undefined" && faqData && faqData.id && cpContext.isAdmin) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-md-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2px",
                            borderBottomColor: "#33CCCC"
                        }}
                    >
                        <div className="col-md-8">
                            <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                FAQ Admin - # {id}
                            </h1>
                        </div>
                        <div className="col-md-1" style={{ paddingTop: 15, textAlign: "right" }}>
                            <h5>
                                <Link
                                    to={"/admin/faqs"}
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        textDecoration: "none"
                                    }}
                                >
                                    List
                                </Link>
                            </h5>
                        </div>
                        <div
                            className="col-md-1"
                            style={{
                                paddingTop: 15,
                                textAlign: "left",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "4px",
                                borderBottomColor: "black"
                            }}
                        >
                            <span
                                style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    marginLeft: "10px"
                                }}
                            >
                                Details
                            </span>
                        </div>
                        <div className="col-md-2" style={{ paddingTop: 15, textAlign: "left" }}>
                            <h5>
                                <Link
                                    to={"/admin/faq-products/" + faqData.id}
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        textDecoration: "none"
                                    }}
                                >
                                    Products
                                </Link>
                            </h5>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-12">
                            <div>
                                {errorMessage && errorMessage.length > 0 ? (
                                    <>
                                        <label
                                            className="text-danger"
                                            aria-live="assertive"
                                            role="alert"
                                        >
                                            {errorMessage}
                                            <br />
                                            <br />
                                        </label>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7" style={{ backgroundColor: "#eee" }}>
                            <form
                                className="form-control border-0"
                                onSubmit={onSubmit}
                                style={{ backgroundColor: "#eee" }}
                            >
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Question:</label>
                                        <input
                                            className="form-control skip-to"
                                            type="text"
                                            name="Question"
                                            value={question}
                                            onChange={(e) => setQuestion(e.target.value)}
                                            aria-label="Question"
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Response:</label>
                                        <textarea
                                            className="form-control"
                                            name="Response"
                                            rows={8}
                                            cols={78}
                                            onChange={(e) => setResponse(e.target.value)}
                                            value={response}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Display Sequence:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="DisplaySequence"
                                            value={displaySequence}
                                            onChange={(e) => setDisplaySequence(e.target.value)}
                                            aria-label="Display Sequence"
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Tag:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="TagName"
                                            value={tagName}
                                            onChange={(e) => setTagName(e.target.value)}
                                            aria-label="Tag Name"
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div
                                        className="col-md-12 form-control form-control-check border-0"
                                        style={{ backgroundColor: "#eee" }}
                                    >
                                        <label style={{ display: "inline-block" }}>Active</label>
                                        <input
                                            type="checkbox"
                                            checked={isActive}
                                            value={isActive?.toString()}
                                            onChange={(e) => setIsActive(e.currentTarget.checked)}
                                            className="inactiveCheckbox"
                                            aria-label="Inactive checkbox"
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-md-3">
                                        <input
                                            type="submit"
                                            value="Save"
                                            className="btn btn-Primary"
                                            aria-label="Save button"
                                        ></input>
                                    </div>
                                    <div className="col-md-3">
                                        <input
                                            type="button"
                                            className="btn btn-Primary"
                                            onClick={() => navigate.push("/admin/faqs")}
                                            value="Cancel"
                                            aria-label="Cancel button"
                                        ></input>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default AdminFAQDetail;

import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

type propType = {
    id: string;
};

const AdminFAQProducts: React.FC = () => {
    const { id } = useParams<propType>();
    let idParsed = 0;
    if (typeof id != "undefined" && id && id.trim()) {
        idParsed = +id;
    }

    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { get, post } = useFetch();
    const [faqData, setFAQData] = useState<FAQ>();
    const [faqProducts, setFAQProducts] = useState<Product[]>([]);
    const [ids, setIds] = useState<Array<number>>([]);
    const { authState, oktaAuth } = useOktaAuth();
    const [errorMessage, setErrorMessage] = useState("");

    const onSaveFAQProducts = async (_id: number) => {
        const selectedProducts: Product[] = faqProducts.filter((product) =>
            ids.includes(product.id)
        );
        const productIdTmp = Array<number>();
        selectedProducts.forEach((p) => {
            productIdTmp.push(p.id);
        });

        const body: FAQProductList = {
            id: _id,
            productIds: productIdTmp
        };

        return await post<FAQProductList, boolean>("/faq/updateproducts/", "", body);
    };

    useEffect(() => {
        const getFAQ = async () => {
            const result = await get<FAQ>("/faq/" + idParsed, "");

            if (result && result.products) {
                const newIds = Array<number>();
                result.products.forEach((p) => {
                    newIds.push(p);
                });
                setIds(newIds);
            }

            setFAQData(result);
        };

        const getFAQProducts = async () => {
            const fromServer = await get<Product[]>("/faq/products", "");
            setFAQProducts(fromServer);
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getFAQ();
            getFAQProducts();
        }
    }, [idParsed, authState, oktaAuth, cpContext, navigate, get]);

    const selectProduct = (_checked: boolean, _selectedId: string) => {
        const selectedId = parseInt(_selectedId);

        if (_checked) {
            if (ids.includes(selectedId)) {
                // const newIds = ids.filter((id) => id !== selectedId)
                // setIds(newIds)
            } else {
                const newIds = [...ids];
                newIds.push(selectedId);
                setIds(newIds);
            }
        } else {
            if (ids.includes(selectedId)) {
                const newIds = ids.filter((id) => id !== selectedId);
                setIds(newIds);
            }
        }
    };

    const selectAllProducts = () => {
        setIds([]);
        const newIds = Array<number>();
        faqProducts.forEach((p) => {
            newIds.push(p.id);
        });
        setIds(newIds);
    };

    const clearProducts = () => {
        setIds([]);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            onSaveFAQProducts(idParsed);

            navigate.push("/admin/faqs");
        } catch {
            setErrorMessage("There was a problem, please try again.");
        }
    };

    if (faqData && faqProducts && faqProducts.length > 0 && cpContext.isAdmin) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-md-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2px",
                            borderBottomColor: "#33CCCC"
                        }}
                    >
                        <div className="col-md-8">
                            <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                FAQ Admin - # {id}
                            </h1>
                        </div>
                        <div className="col-md-1" style={{ paddingTop: 15, textAlign: "right" }}>
                            <h5>
                                <Link
                                    to={"/admin/faqs"}
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        textDecoration: "none"
                                    }}
                                >
                                    List
                                </Link>
                            </h5>
                        </div>
                        <div className="col-md-1" style={{ paddingTop: 15, textAlign: "right" }}>
                            <h5>
                                <Link
                                    to={"/admin/faq/" + faqData.id}
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        textDecoration: "none"
                                    }}
                                >
                                    Details
                                </Link>
                            </h5>
                        </div>
                        <div
                            className="col-md-1"
                            style={{
                                paddingTop: 15,
                                textAlign: "left",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "4px",
                                borderBottomColor: "black"
                            }}
                        >
                            <span
                                style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    marginLeft: "10px"
                                }}
                            >
                                Details
                            </span>
                        </div>
                        <div
                            className="col-md-1"
                            style={{
                                paddingTop: 15,
                                textAlign: "left",
                                color: "black",
                                fontWeight: "bold"
                            }}
                        ></div>
                    </div>
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-12">
                            <div>
                                {errorMessage && errorMessage.length > 0 ? (
                                    <>
                                        <label
                                            className="text-danger"
                                            aria-live="assertive"
                                            role="alert"
                                        >
                                            {errorMessage}
                                            <br />
                                            <br />
                                        </label>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7" style={{ backgroundColor: "#eee" }}>
                            <form
                                className="form-control border-0"
                                onSubmit={onSubmit}
                                style={{ backgroundColor: "#eee" }}
                            >
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>
                                            <b>Question:</b> {faqData.question}
                                        </label>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <p className="ms-2">
                                            Display this question for only these products
                                        </p>
                                        <p className="ms-4">
                                            NOTE: If no products are selected it will be shown for
                                            ALL products.
                                        </p>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-6" style={{ backgroundColor: "#eee" }}>
                                        <div className="ms-4">
                                            <a
                                                href="#"
                                                onClick={selectAllProducts}
                                                className="skip-to"
                                            >
                                                Select All
                                            </a>
                                            &nbsp;&nbsp;&nbsp;
                                            <a href="#" onClick={clearProducts}>
                                                Unselect All
                                            </a>
                                            <br></br>
                                            <br></br>
                                            {faqProducts.map((p, idx) => (
                                                <div
                                                    className="form-control-check"
                                                    style={{
                                                        backgroundColor: "#eee",
                                                        marginLeft: "10px"
                                                    }}
                                                    key={idx}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value={p.id}
                                                        checked={ids.includes(p.id) ? true : false}
                                                        onChange={(e) =>
                                                            selectProduct(
                                                                e.currentTarget.checked,
                                                                e.currentTarget.value
                                                            )
                                                        }
                                                        className="inactiveCheckbox"
                                                        aria-label="Inactive checkbox"
                                                    ></input>
                                                    <label style={{ display: "inline-block" }}>
                                                        {p.name}
                                                    </label>
                                                </div>
                                            ))}
                                            <br></br>
                                            <br></br>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-md-3">
                                        <input
                                            type="submit"
                                            value="Save"
                                            className="btn btn-Primary"
                                            aria-label="Save button"
                                        ></input>
                                    </div>
                                    <div className="col-md-3">
                                        <input
                                            type="button"
                                            className="btn btn-Primary"
                                            onClick={() => navigate.push("/admin/faqs")}
                                            value="Cancel"
                                            aria-label="Cancel button"
                                        ></input>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default AdminFAQProducts;

import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

type propType = {
    id: string;
};

const AdminMarketingMessageDetailStates: React.FC = () => {
    const { id } = useParams<propType>();
    let idParsed = 0;
    if (typeof id != "undefined" && id && id.trim()) {
        idParsed = +id;
    }

    const cpContext = useContext(CPContext);
    const { get, post } = useFetch();
    const [marketingMessage, setMarketingMessage] = useState<MarketingMessage>();
    const [marketingMessageStates, setMarketingMessageStates] = useState<State[]>([]);
    const [ids, setIds] = useState<Array<number>>([]);
    const navigate = useHistory();
    const { authState, oktaAuth } = useOktaAuth();

    const onSaveMMStates = async (_id: number) => {
        const selectedStates: State[] = marketingMessageStates.filter((state) =>
            ids.includes(state.id)
        );
        const stateIdTmp = Array<number>();
        selectedStates.forEach((s) => {
            stateIdTmp.push(s.id);
        });

        const body: MarketingMessageStateList = {
            id: _id,
            stateIds: stateIdTmp
        };
        const result = await post<MarketingMessageStateList, boolean>(
            "/marketingmessage/updatestates/",

            "",
            body
        );
        return result;
    };

    useEffect(() => {
        const fetchMM = async (_id: number) => {
            const result = await get<MarketingMessage>(
                "/marketingmessage/" + _id,

                ""
            );

            if (result && result.states) {
                const newIds = Array<number>();
                result.states.forEach((s) => {
                    newIds.push(s);
                });
                setIds(newIds);
            }

            return result;
        };

        const fetchMMStates = async () => {
            const result = await get<State[]>(
                "/marketingmessage/states",

                ""
            );

            return result;
        };

        const getMarketingMessage = async () => {
            const mmFromServer = await fetchMM(idParsed);
            setMarketingMessage(mmFromServer);
        };

        const getMarketingMessageStates = async () => {
            const mmStatesFromServer = await fetchMMStates();
            setMarketingMessageStates(mmStatesFromServer);
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getMarketingMessage();
            getMarketingMessageStates();
        }
    }, [idParsed, authState, oktaAuth, cpContext, navigate]);

    const selectState = (_checked: boolean, _selectedId: string) => {
        const selectedId = parseInt(_selectedId);

        if (_checked) {
            if (ids.includes(selectedId)) {
                // const newIds = ids.filter((id) => id !== selectedId)
                // setIds(newIds)
            } else {
                const newIds = [...ids];
                newIds.push(selectedId);
                setIds(newIds);
            }
        } else {
            if (ids.includes(selectedId)) {
                const newIds = ids.filter((id) => id !== selectedId);
                setIds(newIds);
            }
        }
    };

    const selectAllStates = () => {
        setIds([]);
        const newIds = Array<number>();
        marketingMessageStates.forEach((s) => {
            newIds.push(s.id);
        });
        setIds(newIds);
    };

    const clearStates = () => {
        setIds([]);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        onSaveMMStates(idParsed);

        navigate.push("/admin/marketing-messages");
    };

    if (
        marketingMessage &&
        marketingMessageStates &&
        marketingMessageStates.length > 0 &&
        cpContext.isAdmin
    ) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-md-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2px",
                            borderBottomColor: "#33CCCC"
                        }}
                    >
                        <div className="col-md-8">
                            <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                Marketing Message - # {id}
                            </h1>
                        </div>
                        <div
                            className="col-md-1"
                            style={{ paddingTop: 15, textAlign: "right", color: "#5D94CB" }}
                        >
                            <h5>
                                <Link
                                    to={"/admin/marketing-messages"}
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        textDecoration: "none"
                                    }}
                                >
                                    List
                                </Link>
                            </h5>
                        </div>
                        <div
                            className="col-md-1"
                            style={{
                                paddingTop: 15,
                                textAlign: "left",
                                color: "#5D94CB",
                                fontWeight: "bold"
                            }}
                        >
                            <h5>
                                <Link
                                    to={"/admin/marketing-message/" + marketingMessage.id}
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        textDecoration: "none"
                                    }}
                                >
                                    Details
                                </Link>
                            </h5>
                        </div>
                        <div
                            className="col-md-1"
                            style={{
                                paddingTop: 15,
                                textAlign: "left",
                                color: "black",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "4px",
                                borderBottomColor: "black"
                            }}
                        >
                            <span
                                style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    marginLeft: "10px"
                                }}
                            >
                                States
                            </span>
                        </div>
                        <div
                            className="col-md-1"
                            style={{
                                paddingTop: 15,
                                textAlign: "left",
                                color: "black",
                                fontWeight: "bold"
                            }}
                        ></div>
                    </div>
                    <div className="row">
                        <div className="col-md-7" style={{ backgroundColor: "#eee" }}>
                            <form
                                className="form-control border-0"
                                onSubmit={onSubmit}
                                style={{ backgroundColor: "#eee" }}
                            >
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <p className="ms-2">
                                            Display this message for only these States
                                        </p>
                                        <p className="ms-4">
                                            NOTE: If no States are selected it will be shown for ALL
                                            States.
                                        </p>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-6" style={{ backgroundColor: "#eee" }}>
                                        <div className="ms-4">
                                            <a href="#" onClick={selectAllStates}>
                                                Select All
                                            </a>
                                            &nbsp;&nbsp;&nbsp;
                                            <a href="#" onClick={clearStates}>
                                                Unselect All
                                            </a>
                                            <br></br>
                                            <br></br>
                                            {marketingMessageStates.map((s, idx) => (
                                                <div
                                                    className="form-control-check"
                                                    style={{
                                                        backgroundColor: "#eee",
                                                        marginLeft: "10px"
                                                    }}
                                                    key={idx}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value={s.id}
                                                        checked={ids.includes(s.id) ? true : false}
                                                        onChange={(e) =>
                                                            selectState(
                                                                e.currentTarget.checked,
                                                                e.currentTarget.value
                                                            )
                                                        }
                                                        className="inactiveCheckbox skip-to"
                                                        aria-label="Inactive checkbox"
                                                    ></input>
                                                    <label style={{ display: "inline-block" }}>
                                                        {s.name}
                                                    </label>
                                                </div>
                                            ))}
                                            <br></br>
                                            <br></br>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-md-3">
                                        <input
                                            type="submit"
                                            value="Save"
                                            className="btn btn-Primary"
                                            aria-label="Save button"
                                        ></input>
                                    </div>
                                    <div className="col-md-3">
                                        <input
                                            type="button"
                                            className="btn btn-Primary"
                                            onClick={() =>
                                                navigate.push("/admin/marketing-messages")
                                            }
                                            value="Cancel"
                                            aria-label="Cancel button"
                                        ></input>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default AdminMarketingMessageDetailStates;

export enum TransactionType {
    OneTimePayment = "OneTimePayment",
    Freelook = "Freelook",
    Surrender = "Surrender",
    Beneficiary = "Beneficiary",
    BankDraftForm = "MBPACK",
    BankDraftDateChange = "BankDraftDateChange",
    FormDownload = "FormDownload",
    Upload = "Upload"
}

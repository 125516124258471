import { useOktaAuth } from "@okta/okta-react";
import { http } from "./fetch.wrapper";

const useFetch = () => {
    const { oktaAuth } = useOktaAuth();

    async function get<T>(
        path: string,
        crsfToken: string,
        cpContext?: ICPHeaderContext,
        config?: RequestInit
    ): Promise<T> {
        const init = { method: "get", ...config };
        if (cpContext?.isMaint && cpContext?.isCustomer) {
            throw new Error(`Status: 503, Message: `);
        }
        return await http<T>(path, init, "get", crsfToken, oktaAuth.getAccessToken() || "");
    }

    async function post<T, U>(
        path: string,
        crsfToken: string,
        body: T,
        cpContext?: ICPHeaderContext,
        config?: RequestInit
    ): Promise<U> {
        const init = { method: "post", body: JSON.stringify(body), ...config };
        if (cpContext?.isMaint && cpContext?.isCustomer) {
            throw new Error(`Status: 503, Message: `);
        }
        return await http<U>(path, init, "post", crsfToken, oktaAuth.getAccessToken() || "");
    }

    async function put<T>(
        path: string,
        crsfToken: string,
        body?: T,
        cpContext?: ICPHeaderContext,
        config?: RequestInit
    ): Promise<T> {
        const init = { method: "put", body: JSON.stringify(body), ...config };
        if (cpContext?.isMaint && cpContext?.isCustomer) {
            throw new Error(`Status: 503, Message: `);
        }
        return await http<T>(path, init, "put", crsfToken, oktaAuth.getAccessToken() || "");
    }

    return {
        get,
        post,
        put
    };
};

export default useFetch;

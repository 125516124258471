export enum FinancalActivityType {
    TenThirtyFiveIAdd = "1035IADD",
    Addtl = "ADDTL",
    Apl = "APL",
    Cancel = "CANCEL",
    ChargeAdjust = "CHRGADJUST",
    Coi = "COI",
    CvBonus = "CVBONUS",
    DeathClaim = "DTHCLM",
    Fee = "Fee",
    FullSurr = "FULLSURR",
    IntAutoSurr = "INTAUTOSURR",
    PremiumBonus = "PREMBONUS",
    Premium = "PREMIUM",
    PremiumInit = "PREMIUMINIT",
    ReinPayment = "REINPYMT",
    Unknown = "Unknown"
}

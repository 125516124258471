import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { PolicyType } from "enums/PolicyType";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../shared/Common";
import useUtilities from "_helpers/useUtilities";
import useFetch from "_helpers/useFetch";

const PolicyPaymentDetails: React.FC<{ showDraftText: boolean; policyData?: Policy }> = (props) => {
    const params = useParams<propType>();
    const utilities = useUtilities();
    const { get } = useFetch();
    let idParsed = 0;
    let _policyData = {} as Policy;
    const navigate = useHistory();

    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
    }

    const { authState, oktaAuth } = useOktaAuth();
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessingDetails, setIsProcessingDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const cpContext = useContext(CPContext);

    const getPolicy = async () => {
        try {
            if (props.policyData && props.policyData.summary) {
                //do nothing, we have data already
                setPolicyData(props.policyData);
                setIsProcessingDetails(false);
            } else {
                setIsProcessingDetails(true);

                _policyData = await get<Policy>("/policy/" + idParsed, "");
                if (_policyData) {
                    //Determine if this policy can be shown the one-time payment link
                    _policyData.onlinePaymentAllowed = await utilities.validatePolicyForPayment(
                        idParsed
                    );
                }
                setPolicyData(_policyData);
                setIsProcessingDetails(false);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading your policy information, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                );
                setIsProcessingDetails(false);
            }
        }
    };

    useEffect(() => {
        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            //Policy Data is needed for just about everything so that will be the catalyst to load
            getPolicy();
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext]);

    return (
        <>
            {isProcessingDetails && (
                <div className="row">
                    <div className="col-7 col-sm-6 col-md-5 col-lg-3">
                        <p className="mb-2">Premium Due Date:</p>
                        <p className="mb-2">{props.showDraftText ? "Draft" : "Premium"} Amount:</p>
                        <p className="mb-2">Billing Mode:</p>
                        <p className="mb-2">
                            Current {props.showDraftText ? "Draft" : "Billed"} Date:
                        </p>
                        <p className="mb-2">Billing Method:</p>
                        <p className="mb-2">Paid to Date:</p>
                    </div>
                    <div className="col-5 col-sm-6 col-md-5 col-lg-4 loadingPolicyDataOverlay">
                        <Spinner imageWidth={20} aria-label="Loading" />
                        <p className="mb-2">&nbsp;</p>
                        <p className="mb-2">&nbsp;</p>
                        <p className="mb-2">&nbsp;</p>
                        <p className="mb-2">&nbsp;</p>
                        <p className="mb-2">&nbsp;</p>
                    </div>
                </div>
            )}
            {!isProcessingDetails && (
                <>
                    <div className="row">
                        <div className="col-7 col-sm-6 col-md-5 col-lg-3">
                            <p className="mb-2">Premium Due Date:</p>
                        </div>
                        <div className="col-5 col-sm-6 col-md-7 col-lg-9">
                            <p className="mb-2">
                                {utilities.parseAndFormatDate(
                                    policyData?.summary?.currentDraftDate ?? ""
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7 col-sm-6 col-md-5 col-lg-3">
                            <p className="mb-2">
                                {props.showDraftText ? "Draft" : "Premium"} Amount:
                            </p>
                        </div>
                        <div className="col-5 col-sm-6 col-md-7 col-lg-9">
                            <p className="mb-2">
                                {!policyData?.summary?.premiumAmount ? (
                                    <>&nbsp;</>
                                ) : (
                                    <>
                                        {policyData?.summary?.premiumAmount.toLocaleString(
                                            "en-US",
                                            {
                                                style: "currency",
                                                currency: "USD",
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }
                                        )}
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7 col-sm-6 col-md-5 col-lg-3">
                            <p className="mb-2">Billing Mode:</p>
                        </div>
                        <div className="col-5 col-sm-6 col-md-7 col-lg-9">
                            <p className="mb-2">
                                {policyData?.summary?.billingMode ?? <>%nbsp;</>}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7 col-sm-6 col-md-5 col-lg-3">
                            <p className="mb-2">
                                Current {props.showDraftText ? "Draft" : "Billed"} Date:
                            </p>
                        </div>
                        <div className="col-5 col-sm-6 col-md-7 col-lg-9">
                            <p className="mb-2">
                                {utilities.parseAndFormatDate(
                                    policyData?.summary?.currentDraftDate ?? ""
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7 col-sm-6 col-md-5 col-lg-3">
                            <p className="mb-2">Billing Method:</p>
                        </div>
                        <div className="col-5 col-sm-6 col-md-7 col-lg-9">
                            <p className="mb-2">
                                {policyData?.summary?.billingMethod ?? <>%nbsp;</>}
                            </p>
                        </div>
                    </div>
                    {(policyData?.policyType ?? "") != PolicyType.IUL &&
                        (policyData?.policyType ?? "") != PolicyType.Universal && (
                            <div className="row">
                                <div className="col-7 col-sm-6 col-md-5 col-lg-3">
                                    <p className="mb-2">Paid to Date:</p>
                                </div>
                                <div className="col-5 col-sm-6 col-md-7 col-lg-9">
                                    <p className="mb-2">
                                        {utilities.parseAndFormatDate(
                                            policyData?.summary?.currentDraftDate ?? ""
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}
                </>
            )}
        </>
    );
};

export default PolicyPaymentDetails;

import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import useFetch from "../_helpers/useFetch";
import { CPContext } from "../_helpers/CPContext";
import { Spinner } from "../_components/shared/Common";
import useUtilities from "../_helpers/useUtilities";

type RegisterUser = {
    firstName: string;
    lastName: string;
};

const Home: React.FC = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { post } = useFetch();
    const { isMaintenanceError } = useUtilities();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [createUserCheck, setCreateUserCheck] = useState<boolean>(false);

    useEffect(() => {
        const createUserFromOkta = async () => {
            if (!createUserCheck && !isProcessing && cpContext.userInfo) {
                try {
                    const body: RegisterUser = {
                        firstName: cpContext.userInfo.given_name,
                        lastName: cpContext.userInfo.family_name
                    };
                    console.log(
                        "createUserFromOkta:" +
                            cpContext.userInfo.given_name +
                            "; " +
                            cpContext.userInfo.family_name
                    );
                    setIsProcessing(true);
                    await post<RegisterUser, boolean>(
                        "/registration/registeramericouser",
                        "",
                        body
                    );
                    setIsProcessing(false);
                    setCreateUserCheck(true);
                } catch (e: unknown) {
                    if (isMaintenanceError(e)) {
                        navigate.push("/maint");
                    } else {
                        setIsProcessing(false);
                    }
                }
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            if (cpContext.isCustomer) {
                navigate.push("/landingpage");
            } else {
                createUserFromOkta();
            }
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext]);

    if (!isProcessing) {
        return (
            <section
                className="d-flex flex-column min-vh-100 justify-content-center align-items-center"
                aria-labelledby="welcome-region"
            >
                <h1 id="welcome-region" style={{ display: "none" }}>
                    Americo Customer Portal Home Card
                </h1>
                <Card body className="home-card skip-to">
                    <h1 style={{ fontSize: "1rem", marginBottom: 0 }}>
                        Welcome to the Americo Customer Portal!
                    </h1>
                </Card>
            </section>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default Home;

import PolicyPaymentDetails from "_components/policy/PolicyPaymentDetails";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import Header from "_components/shared/Header";
import useUtilities from "_helpers/useUtilities";
import useFetch from "_helpers/useFetch";
import useLogger from "_helpers/useLogger";
import { Row, Col } from "react-bootstrap";

const OneTimePayment: React.FC = () => {
    const params = useParams<propType>();
    const utilities = useUtilities();
    const logger = useLogger();
    const { get } = useFetch();
    let idParsed = 0;
    let _policyData = {} as Policy;
    const navigate = useHistory();

    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
    }

    const { authState, oktaAuth } = useOktaAuth();
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessingDetails, setIsProcessingDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showBankAccountText, setShowBankAccountText] = useState(false);
    const cpContext = useContext(CPContext);

    const getPolicy = async () => {
        try {
            if (!isProcessingDetails) {
                setIsProcessingDetails(true);
                if (policyData && policyData.summary) {
                    //do nothing, we have data already
                } else {
                    _policyData = await get<Policy>("/policy/" + idParsed, "");
                    if (_policyData) {
                        //Determine if this policy can be shown the one-time payment link
                        _policyData.onlinePaymentAllowed = await utilities.validatePolicyForPayment(
                            idParsed
                        );
                    }
                    setPolicyData(_policyData);
                }

                setIsProcessingDetails(false);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading your policy information, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                );
                setIsProcessingDetails(false);
            }
        }
    };

    useEffect(() => {
        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            //Policy Data is needed for just about everything so that will be the catalyst to load
            getPolicy();
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext]);

    return (
        <div className="row" style={{ backgroundColor: "#eee" }}>
            <Header title="Payments" description="One-Time Payment" policyData={policyData} />
            <div className="row pt-4">
                {policyData && policyData.summary && (
                    <div>
                        <PolicyPaymentDetails showDraftText={true} />
                    </div>
                )}
            </div>
            <div className="row py-4">
                <div>
                    <h5 className="provide-payment-info">Provide Payment Information</h5>
                    <p className="py-4">How would you like to pay?</p>
                    <Row>
                        <Col md={4}>
                            <span>
                                <input
                                    type="radio"
                                    id="bank-account"
                                    name="payment-options"
                                    value="Bank Account"
                                    aria-label="Bank account radio button"
                                    onClick={() => setShowBankAccountText(true)}
                                ></input>
                                <label className="px-2" htmlFor="bank-account">
                                    Bank Account
                                </label>
                            </span>
                        </Col>
                        <Col md={4}>
                            <span>
                                <input
                                    type="radio"
                                    id="debit-credit-card"
                                    name="payment-options"
                                    value="Debit/Credit Card"
                                    aria-label="Debit or Credit radio button"
                                    onClick={() =>
                                        logger
                                            .loggedRequestNavigate(
                                                `/payment`,
                                                "OneTimePayment",
                                                "",
                                                "",
                                                false,
                                                policyData!,
                                                idParsed
                                            )
                                            .then((uri) => navigate.push(uri))
                                    }
                                ></input>
                                <label className="px-2" htmlFor="debit-credit-card">
                                    Debit/Credit Card
                                </label>
                            </span>
                        </Col>
                    </Row>
                </div>
            </div>
            {showBankAccountText && (
                <div className="row py-3">
                    <div className="col-11 offset-md-1">
                        <p className="py-4 px-3 bank-account-payment">
                            To request an automatic payment from your Bank account, please contact
                            our Customer Service Department at 1-800-231-0801.
                        </p>
                    </div>
                </div>
            )}
            <div className="row py-5">
                <div>
                    <input
                        type="button"
                        className="btn btn-Primary"
                        aria-label="Cancel button"
                        value="Cancel"
                        onClick={() => {
                            logger.loggedRequestNavigate(
                                `/policy-detail/${idParsed}?tab=payments`,
                                "ManageMyPayments",
                                "",
                                "",
                                false,
                                policyData!
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default OneTimePayment;

import { useOktaAuth } from "@okta/okta-react";
import { Spinner } from "_components/shared/Common";
import useUtilities from "_helpers/useUtilities";
import { config } from "config";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useHistory } from "react-router";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const options = {
    cMapUrl: "/cmaps/",
    standardFontDataUrl: "/standard_fonts/"
};
const ElectronicAuthorizationForm: React.FC<{
    scrolledToBottom: Dispatch<SetStateAction<boolean>>;
    setPDFLoaded: Dispatch<SetStateAction<boolean>>;
    setErrorMessage: Dispatch<SetStateAction<string>>;
}> = (props) => {
    const utilities = useUtilities();
    const { oktaAuth } = useOktaAuth();
    const [eAuthPDFBlob, setEAuthPDFBlob] = useState<string>();
    const navigate = useHistory();
    const [numPages, setNumPages] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    let lastScrollTop = 0;

    useEffect(() => {
        getEAuthPreview();
    }, []);

    const getEAuthPreview = async () => {
        setLoading(true);
        try {
            const body: fileDownloadRequest = {
                id: 0,
                policyDWKey: 0,
                ignorePreviousDisclosure: true
            };
            const requestOptionsBodyAuth = {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
                }
            };

            await fetch(
                config.resourceServer.apiUrl + "/policy/eauthpreviews",
                requestOptionsBodyAuth
            )
                .then((response) => response.blob())
                .then((response) => {
                    if (response.type == "application/problem+json") {
                        props.setErrorMessage(
                            "Could not load the Electronic Authorization forms, please try again."
                        );
                    } else {
                        props.setErrorMessage("");
                        const pdfBlob = window.URL.createObjectURL(response);
                        setEAuthPDFBlob(pdfBlob);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    props.setErrorMessage(
                        "Could not load the Electronic Authorization forms, please try again."
                    );
                });
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const handlePDFLoad = (pdf: any) => {
        props.setPDFLoaded(true);
        setNumPages(pdf.numPages);
        setPDFFocus();
    };

    const detectScrollToBottom = (event: any) => {
        const element = event.currentTarget;
        const scrollTop = Math.ceil(element.scrollTop);
        if (scrollTop < lastScrollTop) {
            // upscroll
            return;
        }
        lastScrollTop = scrollTop <= 0 ? 0 : element.scrollTop;
        if (scrollTop + element.offsetHeight >= element.scrollHeight) {
            props.scrolledToBottom(true);
        }
    };

    const setPDFFocus = () => {
        const viewerElement = document.querySelector<HTMLElement>(".react-pdf__Document");
        if (!!viewerElement) {
            viewerElement.tabIndex = 0;
        }
    };

    if (loading) {
        return (
            <div aria-labelledby="eauth-section">
                <h1 style={{ display: "none" }}>
                    Electronic Signature and Delivery Authorization Spinner
                </h1>
                <h2 style={{ display: "none" }} aria-labelledby="eauth-section">
                    Electronic Auth Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </div>
        );
    } else {
        return (
            <div>
                {eAuthPDFBlob && eAuthPDFBlob?.length > 0 ? (
                    <>
                        <Document
                            file={eAuthPDFBlob}
                            onLoadSuccess={handlePDFLoad}
                            options={options}
                            onScroll={detectScrollToBottom}
                            id="ElectronicAuthorizationPreview"
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    scale="1.5"
                                />
                            ))}
                        </Document>
                    </>
                ) : (
                    <></>
                )}
            </div>
        );
    }
};

export default ElectronicAuthorizationForm;

import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

const Settings: React.FC = () => {
    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { get } = useFetch();
    const [settings, setSettings] = useState<Setting[]>([]);
    const { authState, oktaAuth } = useOktaAuth();
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const getSettings = async () => {
            try {
                const settingsServer = await get<Setting[]>(
                    "/settings",
                    ""
                );
                setSettings(settingsServer);
            } catch (error) {
                setErrorMessage("An unexpected error has occured, please try again.");
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getSettings();
        }
    }, [authState, oktaAuth, cpContext, navigate]);

    if (cpContext.isAdmin) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-md-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: 2,
                            borderStyle: "solid",
                            borderColor: "#33CCCC"
                        }}
                    >
                        <h1 className="custom-h1 skip-to" style={{ paddingTop: 10 }}>
                            Settings Admin
                        </h1>
                    </div>
                    <div className="row" style={{ paddingTop: 10 }}>
                        <div className="col-md-12">
                            <div
                                className="form-control form-control-check border-0"
                                style={{ backgroundColor: "#eee" }}
                            >
                                <Link to={"/admin/settingadd"} style={{ float: "right" }}>
                                    <button className="btn btn-Primary">Add</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Table striped bordered hover responsive="md">
                                <thead
                                    style={{
                                        backgroundColor: "#003366",
                                        color: "white",
                                        height: "40px",
                                        textAlign: "center"
                                    }}
                                >
                                    <tr>
                                        <th style={{ width: "5%" }}></th>
                                        <th style={{ width: "70%" }}>Name</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {settings && settings.length > 0 ? (
                                        <>
                                            {settings.map((setting, idx) => (
                                                <tr key={idx}>
                                                    <td>
                                                        <Link
                                                            to={
                                                                "/admin/settingdetail/" + setting.id
                                                            }
                                                        >
                                                            <span
                                                                className="bi bi-pencil"
                                                                style={{ color: "black" }}
                                                            ></span>
                                                        </Link>
                                                    </td>
                                                    <td
                                                        tabIndex={0}
                                                        style={{
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                    >
                                                        {setting.name}
                                                    </td>
                                                    <td
                                                        tabIndex={0}
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        {setting.value}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <td colSpan={3}>
                                                    <>No results found.</>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <div
                className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-danger"
                aria-live="assertive"
                role="alert"
            >
                {errorMessage}
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default Settings;

/*eslint-disable*/
import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import useFetch from "../../_helpers/useFetch";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../shared/Common";
import useLogger from "../../_helpers/useLogger";
import useUtilities from "../../_helpers/useUtilities";
import { config } from "config";

type propType = {
    id: string;
};

const BeneficiaryChange: React.FC = () => {
    const params = useParams<propType>();
    let formNumberParsed = "";
    let idParsed = 0;
    const navigate = useHistory();
    const search = navigate.location.search;
    const paramsFromURL = new URLSearchParams(search);

    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
    }
    //Load values of parameters or runtime variables
    formNumberParsed = paramsFromURL.get("formNumber") ?? "details";

    let autocomplete: google.maps.places.Autocomplete;

    const cpContext = useContext(CPContext);
    const utilities = useUtilities();
    const logger = useLogger();
    const { post, get } = useFetch();
    const { authState, oktaAuth } = useOktaAuth();
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessingPolicy, setIsProcessingPolicy] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [validationErrorMessage, setValidationErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
    const [isNextProcessing, setIsNextProcessing] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);
    const [beneficiary, setBeneficiary] = useState<Beneficiary>();
    const [stepNumber, setStepNumber] = useState(1);
    const [esignChecked, setEsignChecked] = useState<boolean>(false);
    const [equalShare, setEqualShare] = useState<boolean>(false);
    const [isPrimary, setIsPrimary] = useState<boolean>(true);
    const [requestId, setRequestId] = useState<number>(0);
    const [lastRequestId, setLastRequestId] = useState<number>(0);
    const [bcPDFBlob, setBCPDFBlob] = useState<string>();
    const [iFrameLoaded, setIFrameLoaded] = useState(false);
    const [showPrimaryHelp, setShowPrimaryHelp] = useState(false);
    const [showContingentHelp, setShowContingentHelp] = useState(false);
    const [showBeneHelp, setShowBeneHelp] = useState(false);
    const [showUnder18Help, setShowUnder18Help] = useState(false);
    const [primaryShareTotal, setPrimaryShareTotal] = useState(0);

    const [beneficiaryNameError, setBeneficiaryNameError] = useState(false);
    const [relationError, setRelationError] = useState(false);
    const [relationOtherError, setRelationOtherError] = useState(false);
    const [sharePercentageError, setSharePercentageError] = useState(false);
    const [equalizeShareError, setEqualizeShareError] = useState(false);
    const [dateOfBirthError, setDateOfBirthError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [zipError, setZipError] = useState(false);
    const [taxIdNumError, setTaxIdNumError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [suggestedAddresses, setSuggestedAddresses] = useState("");
    const [isAutoCompleteLoaded, setIsAutoCompleteLoaded] = useState(false);
    const [primaryEqualizeShare, setPrimaryEqualizeShare] = useState(false);
    const [contingentEqualizeShare, setContingentEqualizeShare] = useState(false);

    const [beneId, setBeneId] = useState(0);
    const [beneName, setBeneName] = useState("");
    const [beneRelation, setBeneRelation] = useState("");
    const [beneRelationOther, setBeneRelationOther] = useState("");
    const [beneSharePercentage, setBeneSharePercentage] = useState(0);
    const [beneEqualizeShare, setBeneEqualizeShare] = useState(false);
    const [beneDOB, setBeneDOB] = useState("");
    const [beneAddress, setBeneAddress] = useState("");
    const [beneCity, setBeneCity] = useState("");
    const [beneState, setBeneState] = useState("");
    const [beneZip, setBeneZip] = useState("");
    const [beneCountry, setBeneCountry] = useState("");
    const [beneTaxId, setBeneTaxId] = useState("");
    const [benePhone, setBenePhone] = useState("");
    const [beneEmail, setBeneEmail] = useState("");
    const [beneIsPrimary, setBeneIsPrimary] = useState(false);
    const [beneUnder18, setBeneUnder18] = useState(false);
    const nameInput = React.useRef<HTMLInputElement>(null);
    const [lastActionAddMore, setLastActionAddMore] = useState(false);

    const errorStyle = {
        color: "#dc3545",
        borderColor: "#dc3545"
    };

    const relationErrorStyle = {
        color: "#dc3545",
        borderColor: "#dc3545",
        marginTop: "10px",
        marginBottom: "10px"
    };

    const validGridShareStyle = {
        color: "black",
        borderColor: "black",
        width: "80px"
    };

    const invalidGridShareStyle = {
        color: "#dc3545",
        borderColor: "#dc3545",
        width: "80px"
    };

    const beneficiaryTemplate: Beneficiary = {
        id: 0,
        name: "",
        relation: "--Select Relation--",
        relationOther: "",
        sharePercentage: 0,
        equalizeShare: false,
        dateOfBirth: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        taxIdNum: "",
        phone: "",
        email: "",
        isPrimary: false,
        under18: false
    };

    const clearErrorFlagsAndText = () => {
        setBeneficiaryNameError(false);
        setRelationError(false);
        setRelationOtherError(false);
        setSharePercentageError(false);
        setEqualizeShareError(false);
        setDateOfBirthError(false);
        setDateOfBirthError(false);
        setAddressError(false);
        setCityError(false);
        setStateError(false);
        setZipError(false);
        setTaxIdNumError(false);
        setPhoneError(false);
        setEmailError(false);

        setValidationErrorMessage("");
        setErrorMessage("");
        setInfoMessage("");
        setShowUnder18Help(false);
    };

    const clearFields = () => {
        setBeneId(0);
        setBeneName("");
        setBeneRelation("--Select Relation--");
        setBeneRelationOther("");
        setBeneSharePercentage(0);
        setBeneEqualizeShare(isPrimary ? primaryEqualizeShare : contingentEqualizeShare);
        setBeneDOB("");
        setBeneAddress("");
        setBeneCity("");
        setBeneState("");
        setBeneZip("");
        setBeneCountry("");
        setBeneTaxId("");
        setBenePhone("");
        setBeneEmail("");
        setBeneIsPrimary(isPrimary);
        setBeneUnder18(false);
    };

    const fetchPolicy = async (_id: number) => {
        if (!isProcessingPolicy) {
            try {
                if (policyData && policyData.summary) {
                } else {
                    setIsProcessingPolicy(true);
                    const result = await get<Policy>("/policy/full?policyDWKey=" + _id, "");
                    setIsProcessingPolicy(false);
                    return result;
                }
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    setErrorMessage("An unexpected error has occured.");
                    setIsProcessingPolicy(false);
                }
            }
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        clearErrorFlagsAndText();
        setIsSubmitProcessing(true);
        const result = await processBeneficiaryChange();

        if (result) {
            setStepNumber(0);
            setRequestSubmitted(true);
            setValidationErrorMessage("");
        } else {
            setStepNumber(3);
            setValidationErrorMessage(
                "There was a problem submitting your request, please try again."
            );
        }
        setIsSubmitProcessing(false);
    };

    const addBeneficiarySave = async (_addMore: boolean) => {
        //validate fields
        let isValid = true;
        let validationError = "";
        clearErrorFlagsAndText();
        console.log("addBeneficiarySave add; beneficiaries.length = " + beneficiaries.length);

        if (!beneDOB) {
            isValid = false;
            validationError = "You must enter a date of birth or trust date to continue.";
            setDateOfBirthError(true);
        }
        if (!beneSharePercentage && !beneEqualizeShare) {
            isValid = false;
            validationError =
                "You must enter a share percentage or click Equal Shares to continue.";
            setSharePercentageError(true);
        }
        if (
            !beneRelation ||
            beneRelation == "--Select Relation--" ||
            (beneRelation === "Other" && !beneRelationOther)
        ) {
            isValid = false;
            validationError = "You must enter a relationship to continue.";
            setRelationError(true);
        }
        if (!beneName) {
            isValid = false;
            validationError = "You must enter a name to continue.";
            setBeneficiaryNameError(true);
        }

        if (isValid) {
            setValidationErrorMessage(validationError);

            const newBene = {
                id: beneId,
                name: beneName,
                relation: beneRelation,
                relationOther: beneRelationOther,
                sharePercentage: beneSharePercentage,
                equalizeShare: beneEqualizeShare,
                dateOfBirth: beneDOB,
                address: beneAddress,
                city: beneCity,
                state: beneState,
                zip: beneZip,
                country: beneCountry,
                taxIdNum: beneTaxId,
                phone: benePhone,
                email: beneEmail,
                isPrimary: isPrimary,
                under18: beneUnder18
            };

            const filtered = beneficiaries.filter((x) => x.id != beneId);
            filtered.push(newBene);

            if (isPrimary) {
                if (beneEqualizeShare) {
                    await setEqualFlag(isPrimary);
                } else {
                    await clearEqualFlag(isPrimary);
                }

                setPrimaryEqualizeShare(beneEqualizeShare);
            } else {
                if (beneEqualizeShare) {
                    await setEqualFlag(isPrimary);
                } else {
                    await clearEqualFlag(isPrimary);
                }

                setContingentEqualizeShare(beneEqualizeShare);
            }

            const equalized = await determineEqualShare(filtered);
            setBeneficiaries(equalized);

            setLastActionAddMore(_addMore);

            if (_addMore && equalized?.length < 18) {
                setInfoMessage("Beneficiary successfully saved.");

                clearFields();
                let count = equalized?.length;
                setBeneId(count + 1);

                //No idea why but it would not clear selectedIndex simply by setting the defaultValue
                var relationDDL = document.getElementById("Relation") as HTMLSelectElement;
                relationDDL.selectedIndex = 0;

                //focus on name field
                if (null !== nameInput.current) {
                    nameInput.current.focus();
                }
            } else {
                setStepNumber(1);
            }
        } else {
            setValidationErrorMessage(validationError);
            console.log("addBeneficiarySave invalid");
        }
    };

    const determineEqualShare = async (_beneList: Beneficiary[]) => {
        let anyPrimaryEqual = false;
        let anyContingentEqual = false;
        let primaryCount = 0;
        let contingentCount = 0;
        let primaryShare = 0;
        let contingentShare = 0;
        let primaryTotal = 0;
        let contingentTotal = 0;
        let primaryRemainder = false;
        let contingentRemainder = false;

        primaryCount = _beneList.filter((f) => f.isPrimary == true).length;
        contingentCount = _beneList.filter((f) => f.isPrimary == false).length;

        primaryShare = utilities.roundDown(100 / primaryCount, 2);
        primaryRemainder = 100 % primaryCount > 0;
        contingentShare = utilities.roundDown(100 / contingentCount, 2);
        contingentRemainder = 100 % contingentCount > 0;

        for (let i = 0; i < _beneList.length; i++) {
            if (_beneList[i].isPrimary && _beneList[i].equalizeShare) {
                anyPrimaryEqual = true;
            }
            if (!_beneList[i].isPrimary && _beneList[i].equalizeShare) {
                anyContingentEqual = true;
            }
        }

        setPrimaryEqualizeShare(anyPrimaryEqual);
        setContingentEqualizeShare(anyContingentEqual);

        for (let i = 0; i < _beneList.length; i++) {
            if (_beneList[i].isPrimary && anyPrimaryEqual) {
                _beneList[i].sharePercentage = primaryShare;
                primaryTotal = primaryTotal + primaryShare;
            }
            if (!_beneList[i].isPrimary && anyContingentEqual) {
                _beneList[i].sharePercentage = contingentShare;
                contingentTotal = contingentTotal + contingentShare;
            }
        }

        if (primaryRemainder && primaryTotal < 100 && anyPrimaryEqual) {
            const diff = +(100 - primaryTotal).toFixed(2);
            _beneList.every((b) => {
                if (b.isPrimary) {
                    b.sharePercentage = primaryShare + diff;
                    b.sharePercentage = +precisionRoundMod(b.sharePercentage, 2);
                    return false;
                } else {
                    return true;
                }
            });
            //console.log(' determineEqualShare primaryTotal diff=' + diff)
        }

        if (primaryRemainder && primaryTotal > 100 && anyPrimaryEqual) {
            const diff = +(primaryTotal - 100).toFixed(2);
            _beneList.every((b) => {
                if (b.isPrimary) {
                    b.sharePercentage = primaryShare - diff;
                    b.sharePercentage = +precisionRoundMod(b.sharePercentage, 2);
                    return false;
                } else {
                    return true;
                }
            });
            //console.log('determineEqualShare primaryTotal diff=' + diff)
        }

        if (contingentRemainder && contingentTotal < 100 && anyContingentEqual) {
            const diff = +(100 - contingentTotal).toFixed(2);
            _beneList.every((b) => {
                if (!b.isPrimary) {
                    b.sharePercentage = contingentShare + diff;
                    b.sharePercentage = +precisionRoundMod(b.sharePercentage, 2);
                    return false;
                } else {
                    return true;
                }
            });
            //console.log('determineEqualShare contingentTotal diff=' + diff)
        }

        if (contingentRemainder && contingentTotal > 100 && anyContingentEqual) {
            const diff = +(contingentTotal - 100).toFixed(2);
            _beneList.every((b) => {
                if (!b.isPrimary) {
                    b.sharePercentage = contingentShare - diff;
                    b.sharePercentage = +precisionRoundMod(b.sharePercentage, 2);
                    return false;
                } else {
                    return true;
                }
            });
            //console.log('determineEqualShare contingentTotal diff=' + diff)
        }

        //console.log(' determineEqualShare anyPrimaryEqual=' + anyPrimaryEqual + '; anyContingentEqual=' + anyContingentEqual + '; _beneList.length=' + _beneList.length + '; primaryShare=' + primaryShare + '; contingentShare=' + contingentShare)

        return _beneList;
    };

    const clearEqualFlag = async (_isPrimary: boolean) => {
        const copy = [...beneficiaries];

        //if user cleared equalize flag, we need to clear it globally
        if (_isPrimary) {
            setPrimaryEqualizeShare(false);
        } else {
            setContingentEqualizeShare(false);
        }

        for (let i = 0; i < copy.length; i++) {
            if (copy[i].isPrimary == _isPrimary) {
                copy[i].equalizeShare = false;
            }
        }

        setBeneficiaries(copy);
    };

    const setEqualFlag = async (_isPrimary: boolean) => {
        const copy = [...beneficiaries];

        //if user cleared equalize flag, we need to clear it globally
        if (_isPrimary) {
            setPrimaryEqualizeShare(true);
        } else {
            setContingentEqualizeShare(true);
        }

        for (let i = 0; i < copy.length; i++) {
            if (copy[i].isPrimary == _isPrimary) {
                copy[i].equalizeShare = true;
            }
        }

        setBeneficiaries(copy);
    };

    const removePrimaryBeneficiary = async (_id: number) => {
        clearErrorFlagsAndText();
        let anyContingent = false;
        let primaryCount = 0;
        if (beneficiaries) {
            const contingentBeneficiaries = beneficiaries.filter((b) => b.isPrimary == false);
            primaryCount = beneficiaries.filter((b) => b.isPrimary == true)?.length;
            if (contingentBeneficiaries && contingentBeneficiaries.length > 0) {
                anyContingent = true;
            }
        }
        //Display error if the user is trying to remove their only primary beneficiary and they have one or more contingents defined
        if (anyContingent && primaryCount == 1) {
            setValidationErrorMessage(
                "A primary beneficiary is required.  Please edit this section before proceeding by clicking on the pencil icon."
            );
        } else {
            const filtered = beneficiaries.filter((x) => x.id != _id);
            const equalized = await determineEqualShare(filtered);
            setBeneficiaries(equalized);
        }
    };

    const editPrimaryBeneficiary = async (_id: number) => {
        clearErrorFlagsAndText();
        //setBeneficiary(beneficiaryTemplate)
        const single = beneficiaries.filter((x) => x.id == _id)[0];

        if (single) {
            // setBeneficiary(single)
            // setBeneficiary({
            //     ...single,
            //     equalizeShare: primaryEqualizeShare
            // })
            setBeneId(single.id);
            setBeneName(single.name);
            setBeneRelation(single.relation);
            setBeneRelationOther(single.relationOther);
            setBeneSharePercentage(single.sharePercentage);
            setBeneEqualizeShare(primaryEqualizeShare);
            setBeneDOB(single.dateOfBirth);
            setBeneAddress(single.address);
            setBeneCity(single.city);
            setBeneState(single.state);
            setBeneZip(single.zip);
            setBeneCountry(single.country);
            setBeneTaxId(single.taxIdNum);
            setBenePhone(single.phone);
            if (cpContext.isHO) {
                setBeneEmail("");
            } else {
                setBeneEmail(single.email);
            }
            setBeneUnder18(false);

            setIsPrimary(true);
            setStepNumber(9);
        }
    };

    const removeContingentBeneficiary = async (_id: number) => {
        clearErrorFlagsAndText();
        const filtered = beneficiaries.filter((x) => x.id != _id);
        const equalized = await determineEqualShare(filtered);
        setBeneficiaries(equalized);
    };

    const editContingentBeneficiary = async (_id: number) => {
        clearErrorFlagsAndText();
        //setBeneficiary(beneficiaryTemplate)
        const single = beneficiaries.filter((x) => x.id == _id)[0];
        if (single) {
            //setBeneficiary(single)
            setBeneId(single.id);
            setBeneName(single.name);
            setBeneRelation(single.relation);
            setBeneRelationOther(single.relationOther);
            setBeneSharePercentage(single.sharePercentage);
            setBeneEqualizeShare(contingentEqualizeShare);
            setBeneDOB(single.dateOfBirth);
            setBeneAddress(single.address);
            setBeneCity(single.city);
            setBeneState(single.state);
            setBeneZip(single.zip);
            setBeneCountry(single.country);
            setBeneTaxId(single.taxIdNum);
            setBenePhone(single.phone);
            setBeneEmail(single.email);
            setBeneUnder18(false);

            setIsPrimary(false);
            setStepNumber(9);
        }
    };

    const precisionRoundMod = (_num: number, _precision: number) => {
        const factor = Math.pow(10, _precision);
        const n = _precision < 0 ? _num : 0.01 / factor + _num;
        return Math.round(n * factor) / factor;
    };

    const onNext = async (e: React.FormEvent<HTMLInputElement>) => {
        clearErrorFlagsAndText();
        let validationError = "";
        let formattedDOB = "01/01/1900";
        const minimumDOB = utilities.parseDate("1900-01-01");
        let primaryShare = 0;
        let contingentShare = 0;
        const anyContingent = beneficiaries.filter((b) => b.isPrimary == false)?.length > 0;
        setIsNextProcessing(true);

        if (stepNumber === 1) {
            if (Number.isNaN(primaryShare)) {
                primaryShare = 0;
            }
            if (Number.isNaN(contingentShare)) {
                contingentShare = 0;
            }

            if (beneficiaries && beneficiaries.length > 0) {
                primaryShare = beneficiaries
                    .filter((f) => f.isPrimary == true)
                    .map((b) => b.sharePercentage)
                    .reduce((sum, current) => +precisionRoundMod(sum, 2) + +current, 0);
                contingentShare = beneficiaries
                    .filter((f) => f.isPrimary == false)
                    .map((b) => b.sharePercentage)
                    .reduce((sum, current) => +precisionRoundMod(sum, 2) + +current, 0);
            }

            //Validate percentage
            if ((contingentShare < 100 || contingentShare > 100) && anyContingent) {
                validationError =
                    "Total percentage for all contingent beneficiaries must equal 100%";
            }
            if (primaryShare < 100 || primaryShare > 100) {
                console.log("onNext primaryShare=" + primaryShare);
                validationError = "Total percentage for all primary beneficiaries must equal 100%";
            }

            if (!beneficiaries || beneficiaries.length <= 0) {
                validationError = "You must enter one or more primary beneficiaries to continue.";
            }

            await logger.logDetailedActivity(
                "BeneficiaryChange",
                "add beneficiaries",
                cpContext,
                cpContext.userInfo?.email,
                cpContext.sessionId,
                "",
                idParsed
            );
        }
        if (stepNumber === 2) {
            await logger.logDetailedActivity(
                "BeneficiaryChange",
                "submit esign",
                cpContext,
                cpContext.userInfo?.email,
                cpContext.sessionId,
                "",
                idParsed
            );
            if (!esignChecked) {
                validationError = "Please ensure electronic signature box is checked to continue.";
            }

            if (validationError.length <= 0) {
                setLastRequestId(requestId);
                const _requestId = await generateBeneficiaryChangePreview();
                if (_requestId && _requestId <= 0) {
                    //preview generation failed
                    validationError = "Could not load Beneficiary Change forms, please try again.";
                } else if (_requestId) {
                    setRequestId(_requestId);
                    const previewResult = await getBeneficiaryChangePreview(_requestId);
                    if (!previewResult) {
                        validationError =
                            "Could not load Beneficiary Change forms, please try again.";
                    }
                }
            }
        }
        if (stepNumber === 3) {
            await logger.logDetailedActivity(
                "BeneficiaryChange",
                "review forms",
                cpContext,
                cpContext.userInfo?.email,
                cpContext.sessionId,
                "",
                idParsed
            );
            //No validation, review step
        }

        if (validationError && validationError.length > 0) {
            setValidationErrorMessage(validationError);
            setIsNextProcessing(false);
            return;
        } else {
            setValidationErrorMessage(validationError);
            setIsNextProcessing(false);
            setStepNumber(stepNumber + 1);
        }

        document.body.scrollTop = 0; //safari
        document.documentElement.scrollTop = 0; //chrome, firefox, ie
    };

    const onBack = async (e: React.FormEvent<HTMLInputElement>) => {
        clearErrorFlagsAndText();
        setStepNumber(stepNumber - 1);

        document.body.scrollTop = 0; //safari
        document.documentElement.scrollTop = 0; //chrome, firefox, ie
    };

    const onAddCancel = async (e: React.FormEvent<HTMLInputElement>) => {
        clearErrorFlagsAndText();
        setStepNumber(1);

        document.body.scrollTop = 0; //safari
        document.documentElement.scrollTop = 0; //chrome, firefox, ie
    };

    const onCancel = async (e: React.FormEvent<HTMLInputElement>) => {
        clearErrorFlagsAndText();
        e.preventDefault();
        navigate.push("/policy-detail/" + idParsed + "?tab=service");

        document.body.scrollTop = 0; //safari
        document.documentElement.scrollTop = 0; //chrome, firefox, ie
    };

    const onAddClick = async (_isPrimary: boolean) => {
        clearErrorFlagsAndText();
        let anyPrimary = false;
        let primaryShare = 0;
        let possibleShare = 0;
        let count = 0;

        if (beneficiaries) {
            primaryShare = beneficiaries
                .filter((f) => f.isPrimary == true)
                .map((b) => b.sharePercentage)
                .reduce((sum, current) => +precisionRoundMod(sum, 2) + +current, 0);
            const primaryBeneficiaries = beneficiaries.filter((b) => b.isPrimary == true);
            if (primaryBeneficiaries && primaryBeneficiaries.length > 0) {
                anyPrimary = true;
            }

            count = beneficiaries.length;

            document.body.scrollTop = 0; //safari
            document.documentElement.scrollTop = 0; //chrome, firefox, ie
        }

        if (!_isPrimary && !anyPrimary) {
            setValidationErrorMessage(
                "Contingent Beneficiary(ies) cannot be named until Primary Beneficiary section is completed."
            );
        } else if (!_isPrimary && primaryShare < 100) {
            setValidationErrorMessage(
                "Total percentage for all primary beneficiaries must equal 100%"
            );
        } else if (count >= 18) {
            //nothing
        } else {
            clearFields();

            setBeneId(count + 1);
            setIsPrimary(_isPrimary);
            setBeneEqualizeShare(_isPrimary ? primaryEqualizeShare : contingentEqualizeShare);
            setStepNumber(9);
            //focus on name field
            if (null !== nameInput.current) {
                nameInput.current.focus();
            }
        }
    };

    const loggedNavigate = async (_uri: string, _linkName: string) => {
        clearErrorFlagsAndText();
        await logger.logDetailedActivity(
            "BeneficiaryChange",
            _linkName,
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );

        navigate.push(_uri);
    };

    const handleBeneficiaryChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let tempBene = beneficiaryTemplate;
        if (!beneficiary) {
            const newId = beneficiaries.length + 1;
            tempBene.id = newId;
            setBeneficiary(tempBene);
        } else {
            tempBene = beneficiary;
        }

        setBeneficiary({
            ...tempBene,
            [e.target.name]: e.target.value
        });
    };

    const beneficiaryEqualizeClick = async (_checked: boolean, _isPrimary: boolean) => {
        setBeneEqualizeShare(_checked);
        if (_isPrimary) {
            setPrimaryEqualizeShare(_checked);
        } else {
            setContingentEqualizeShare(_checked);
        }
    };

    const handleRelation = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        let cleanup = "";
        if (e.target.value != "--Select Relation--") {
            setValidationErrorMessage("");
            setRelationError(false);
            cleanup = e.target.value;
        }

        let tempBene = beneficiaryTemplate;

        if (!beneficiary) {
            return false;
        } else {
            tempBene = beneficiary;
        }

        setBeneficiary({
            ...tempBene,
            relation: cleanup
        });
    };

    const handleCountry = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        let tempBene = beneficiaryTemplate;

        if (!beneficiary) {
            return false;
        } else {
            tempBene = beneficiary;
        }

        setBeneficiary({
            ...tempBene,
            country: e.target.value
        });
    };

    const handleShare = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let tempBene = beneficiaryTemplate;
        let cleanup = e.target.value;

        //Replace everything except numbers and decimal
        cleanup = cleanup.replace(/[^0-9.]/g, "");

        if (parseFloat(cleanup) > 100) {
            cleanup = "100";
        }

        // if (!regex.test('/^((\d{0,1}[0-9](\.\d{0,1}[0-9])?)|(100))$/g')) {
        //     setValidationErrorMessage('Share percentage must be between .01 and 100.')
        // }

        // if (!beneficiary) {
        //     return false
        // } else {
        //     tempBene = beneficiary
        // }

        // setBeneficiary({
        //     ...tempBene,
        //     sharePercentage: parseFloat(parseFloat(cleanup).toFixed(2))
        // })

        setBeneSharePercentage(parseFloat(parseFloat(cleanup).toFixed(2)));
    };

    const handleBeneName = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let cleanup = e.target.value;

        //Replace everything except numbers and decimal
        cleanup = cleanup.replace(/\d/g, "");

        setBeneName(cleanup);
    };

    const handleGridShare = async (e: React.ChangeEvent<HTMLInputElement>, _id: number) => {
        let tempBene = beneficiaryTemplate;
        let cleanup = e.target.value;
        console.log("handleGridShare=" + e.target.name + " = " + e.target.value + "; " + _id);

        clearErrorFlagsAndText();
        const single = beneficiaries.filter((x) => x.id == _id)[0];
        const bIndex = beneficiaries.findIndex((x) => x.id == _id);

        cleanup = cleanup.replace(/[^0-9.]/g, "");

        if (parseFloat(cleanup) > 100) {
            cleanup = "100";
        }

        if (!single) {
            console.log("handleGridShare not found");
            return false;
        } else {
            console.log("handleGridShare updating bene: " + cleanup);
            //beneficiaries.filter(x => x.id == _id)[0].sharePercentage = parseFloat(parseFloat(cleanup).toFixed(2))
            single.sharePercentage = parseFloat(parseFloat(cleanup).toFixed(2));

            //add beneficiary to list of beneficiaries
            const copy = [...beneficiaries];
            copy[bIndex] = single;
            // filtered.push(single)
            const equalized = await determineEqualShare(copy);
            setBeneficiaries(equalized);
        }
    };

    const handleDOB = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let tempBene = beneficiaryTemplate;
        let cleanup = e.target.value;
        let _under18 = false;

        if (utilities.isValidDate(cleanup, "yyyy-MM-dd")) {
            let parsedDate = utilities.parseDateCustom(cleanup, "yyyy-MM-dd");
            if (Date.now() - parsedDate.getTime() >= 18 * 365 * 24 * 60 * 60 * 1000) {
                _under18 = false;
            } else {
                _under18 = true;
            }
        }

        setShowUnder18Help(_under18);

        setBeneDOB(e.target.value);
    };

    const handleStreetChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let tempBene = beneficiaryTemplate;
        if (!beneficiary) {
            return false;
        } else {
            tempBene = beneficiary;
        }

        setBeneficiary({
            ...tempBene,
            [e.target.name]: e.target.value
        });
    };

    const handleIFrameLoad = () => setIFrameLoaded(true);

    const processBeneficiaryChange = async () => {
        try {
            const body: PolicyBeneficiaryChange = {
                policyDWKey: idParsed,
                beneficiaries: beneficiaries,
                electronicSignatureAgreement: true,
                electronicSignatureName: "",
                notifyEmailAddress: cpContext.userInfo?.email,
                declineElectronicNotification: false,
                requestId: requestId
            };
            const result = await post<PolicyBeneficiaryChange, boolean>(
                "/policy/policybeneficiarychange/",
                "",
                body
            );
            return result;
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const generateBeneficiaryChangePreview = async () => {
        try {
            const body: PolicyBeneficiaryChange = {
                policyDWKey: idParsed,
                beneficiaries: beneficiaries,
                electronicSignatureAgreement: true,
                electronicSignatureName: "",
                notifyEmailAddress: cpContext.userInfo?.email,
                declineElectronicNotification: false,
                requestId: requestId
            };
            const result = await post<PolicyBeneficiaryChange, number>(
                "/policy/generatebeneficiarychangepreview/",
                "",
                body
            );
            return result;
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const getBeneficiaryChangePreview = async (_id: number): Promise<boolean> => {
        let result = false;
        try {
            const body: PolicyBeneficiaryChange = {
                policyDWKey: idParsed,
                beneficiaries: beneficiaries,
                electronicSignatureAgreement: true,
                electronicSignatureName: "",
                notifyEmailAddress: cpContext.userInfo?.email,
                declineElectronicNotification: false,
                requestId: _id
            };

            const requestOptionsBodyAuth = {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
                }
            };
            await fetch(
                config.resourceServer.apiUrl + "/policy/beneficiarychangepreview",
                requestOptionsBodyAuth
            )
                .then((response) => response.blob())
                .then((response) => {
                    if (response.type == "application/problem+json" || response.size == 0) {
                        result = false;
                    } else {
                        setValidationErrorMessage("");
                        const pdfBlob = window.URL.createObjectURL(response);
                        setBCPDFBlob(pdfBlob);
                        result = true;
                    }
                })
                .catch((e: unknown) => {
                    if (utilities.isMaintenanceError(e)) {
                        navigate.push("/maint");
                    } else {
                        result = false;
                    }
                });
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }

        return result;
    };

    const initAutocomplete = async () => {
        let address1Field = document.querySelector("#address") as HTMLInputElement;

        console.log("initAutocomplete");
        autocomplete = new google.maps.places.Autocomplete(address1Field, {
            // componentRestrictions: { country: ["us", "ca", "mx"] },
            fields: ["address_components", "geometry"],
            types: ["address"]
        });

        autocomplete.unbindAll();
        autocomplete.addListener("place_changed", fillInAddress);
    };

    const fillInAddress = async () => {
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();
        let _address1 = "";
        let _city = "";
        let _state = "";
        let _postcode = "";
        let _country = "";

        for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    _address1 = `${component.long_name} ${_address1}`;
                    break;
                }

                case "route": {
                    _address1 += component.short_name;
                    break;
                }

                case "postal_code": {
                    _postcode = `${component.long_name}${_postcode}`;
                    break;
                }

                case "postal_code_suffix": {
                    _postcode = `${_postcode}-${component.long_name}`;
                    break;
                }

                case "locality":
                    _city = component.long_name;
                    break;

                case "administrative_area_level_1": {
                    _state = component.short_name;
                    break;
                }

                case "country":
                    _country = component.short_name;
                    break;
            }
        }

        console.log(
            "fillInAddress beneficiary id=" +
                beneficiary?.id +
                "; address=" +
                _address1 +
                "; city=" +
                _city +
                "; state=" +
                _state +
                "; zip=" +
                _postcode +
                "; country=" +
                _country
        );
        setBeneAddress(_address1);
        setBeneCity(_city);
        setBeneState(_state);
        setBeneZip(_postcode);
        setBeneCountry(_country);
    };

    const contingentBeneficiaryEqualizeClick = async (_checked: boolean) => {
        setContingentEqualizeShare(contingentEqualizeShare ? false : true);

        if (contingentEqualizeShare) {
            clearEqualFlag(false);
        } else {
            setEqualFlag(false);
            const copy = [...beneficiaries];
            const equalized = await determineEqualShare(copy);
            setBeneficiaries(equalized);
        }
    };

    const primaryBeneficiaryEqualizeClick = async (_checked: boolean) => {
        setPrimaryEqualizeShare(primaryEqualizeShare ? false : true);

        if (primaryEqualizeShare) {
            clearEqualFlag(true);
        } else {
            setEqualFlag(true);
            const copy = [...beneficiaries];
            const equalized = await determineEqualShare(copy);
            setBeneficiaries(equalized);
        }
    };

    const getCurrentShareTotal = (_isPrimary: boolean) => {
        let total = beneficiaries
            .filter((f) => f.isPrimary == _isPrimary)
            .map((b) => b.sharePercentage)
            .reduce((sum, current) => +precisionRoundMod(sum, 2) + +current, 0);
        if (Number.isNaN(total)) {
            total = 0;
        } else {
            total = +total.toFixed(2);
        }
        return total;
    };

    const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key == "Enter") {
            addBeneficiarySave(lastActionAddMore);
        }
    };

    useEffect(() => {
        const getPolicy = async () => {
            try {
                setIsProcessing(true);
                const policyFromServer = await fetchPolicy(idParsed);
                setPolicyData(policyFromServer);

                setIsProcessing(false);
            } catch (e: unknown) {
                setErrorMessage(
                    "There was a problem loading your policy information, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                );
                setIsProcessing(false);
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            if (cpContext.isCustomer) {
                getPolicy();
            } else {
                navigate.push("/formdownload/" + idParsed + "?formNumber=" + formNumberParsed);
            }
        }
    }, [authState, cpContext, navigate]); // Update if authState changes

    if (policyData && policyData.summary && cpContext.isCustomer) {
        return (
            <section aria-labelledby="beneficiary-change-section">
                <h2 style={{ display: "none" }} aria-labelledby="beneficiary-change-section">
                    Beneficiary Change Section
                </h2>
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-md-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-6">
                                <h1 className="custom-h1 skip-to" style={{ paddingTop: 10 }}>
                                    Beneficiary Change
                                </h1>
                            </div>
                            <div className="col-6" style={{ textAlign: "right" }}>
                                <div style={{ paddingTop: 10 }}>
                                    {policyData?.policyNumber} - {policyData.product}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <div>
                                    {validationErrorMessage && validationErrorMessage.length > 0 ? (
                                        <>
                                            <label
                                                className="text-danger"
                                                aria-live="assertive"
                                                role="alert"
                                            >
                                                {validationErrorMessage}
                                                <br />
                                                <br />
                                            </label>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {infoMessage && infoMessage.length > 0 ? (
                                        <>
                                            <label style={{ color: "green" }}>
                                                {infoMessage}
                                                <br />
                                                <br />
                                            </label>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {stepNumber === 1 ? (
                                    <>
                                        <div>
                                            Subject to the provisions of the Policy and the rights
                                            of any Assignee of Record with the Company, it is
                                            requested that the Beneficiary be changed as follows:
                                        </div>
                                        <div>&nbsp;</div>
                                        <div>
                                            Click the help icon to learn more about establishing
                                            beneficiaries{" "}
                                            <a href="#" onClick={() => setShowBeneHelp(true)}>
                                                <i
                                                    className="bi bi-question-circle-fill"
                                                    style={{
                                                        fontSize: "20px",
                                                        fontWeight: "700",
                                                        color: "#003366"
                                                    }}
                                                ></i>
                                            </a>
                                            <div
                                                className="bigHelpBox"
                                                style={
                                                    showBeneHelp
                                                        ? { display: "block" }
                                                        : { display: "none" }
                                                }
                                            >
                                                <div className="row">
                                                    <div style={{ textAlign: "center" }}>
                                                        <h5>Beneficiary Change Request Help</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div
                                                        className="col"
                                                        style={{ padding: "10px" }}
                                                    >
                                                        <div className="bigHelpBoxContent fieldValue">
                                                            Most policies allow the beneficiary to
                                                            be changed at any time. Changes do not
                                                            take effect until submitted and approved
                                                            by us.<br></br>
                                                            It is important to keep your beneficiary
                                                            designations up to date as your life
                                                            changes (marriage, children, divorce,
                                                            etc.).<br></br>
                                                            Please read these instructions
                                                            carefully. If you have any questions,
                                                            call 800.231.0801 or email us at
                                                            claims.operations@americo.com.
                                                            <br></br>
                                                            <br></br>
                                                            WHO MAY NAME OR CHANGE THE BENEFICIARY
                                                            ON A POLICY? <br></br>
                                                            Only the owner of the insurance policy
                                                            or annuity.
                                                            <br></br>
                                                            <br></br>
                                                            SPECIAL REQUIREMENTS<br></br>
                                                            There are certain situations where you
                                                            cannot change the beneficiary online. In
                                                            these cases, you must download and
                                                            complete a beneficiary form. Here are
                                                            these situations:<br></br>• An
                                                            irrevocable beneficiary has previously
                                                            been named (as the signature of the
                                                            irrevocable beneficiary is required).
                                                            <br></br>• The owner of the policy is a
                                                            Trust, the signature(s) and title(s) of
                                                            the trustee(s) are required.<br></br>•
                                                            The owner of the policy is a
                                                            corporation, partnership or business as
                                                            two company officer signatures and
                                                            titles are required (President, Vice
                                                            President, Secretary, etc.).<br></br>•
                                                            The owner of the policy is a sole
                                                            proprietorship as the sole proprietor
                                                            must sign.<br></br>• The policy has a
                                                            joint owner as both owners must sign.
                                                            <br></br>• You have a Qualified 403(b)
                                                            Annuity as your spouse must also sign.
                                                            <br></br>
                                                            <br></br>
                                                            WHO MAY BE NAMED AS A BENEFICIARY?{" "}
                                                            <br></br>
                                                            The beneficiary may be one person, more
                                                            than one person, a Trust, a corporation,
                                                            or any other entity which will be able
                                                            to obtain legal receipt of the proceeds
                                                            of the policy or contract. If this is a
                                                            Qualified Plan, beneficiary changes may
                                                            be restricted by IRS regulations.
                                                            <br></br>
                                                            <br></br>
                                                            CAN I NAME A MINOR CHILD AS A
                                                            BENEFICIARY? <br></br>
                                                            If the policy owner wishes a minor child
                                                            to receive life insurance proceeds, the
                                                            children themselves can be named.
                                                            However, because benefits are payable to
                                                            minors in only certain situations, it is
                                                            recommended that a Trust be established
                                                            to their benefit. To name a Trust as
                                                            beneficiary for minor children, we
                                                            require the name, date, and address of
                                                            the Trust.
                                                            <br></br>
                                                            <br></br>
                                                            HOW ARE MULTIPLE BENEFICIARIES
                                                            DESIGNATED?<br></br>
                                                            You can name more than one beneficiary.
                                                            You can select for all to share equally
                                                            or designate percentages. It is required
                                                            that all percentages total to 100%.
                                                            <br></br>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "10px"
                                                    }}
                                                >
                                                    <input
                                                        type="button"
                                                        value="OK"
                                                        className="btn btn-Primary"
                                                        onClick={() => setShowBeneHelp(false)}
                                                        style={{ width: "90px" }}
                                                        aria-label="OK button"
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-danger">
                                                Maximum 18 beneficiaries and/or contingents
                                                supported online. If you have questions, please
                                                contact Customer Service at 800-231-0801.
                                            </span>
                                        </div>
                                        <div>&nbsp;</div>
                                        <Card className="policyCard">
                                            <Card.Body className="shadow">
                                                <div>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 5, paddingBottom: 15 }}
                                                    >
                                                        <div
                                                            className="col-8"
                                                            style={{
                                                                fontSize: "20px",
                                                                fontWeight: "700",
                                                                color: "#003366"
                                                            }}
                                                        >
                                                            <span title="The Primary beneficiary is the party who will receive the proceeds of the policy when the insured passes away. The owner of the policy may indicate, by percentage, how the proceeds are to be divided among the parties.">
                                                                Primary
                                                            </span>
                                                            &nbsp;
                                                            <a
                                                                href="#"
                                                                onClick={() =>
                                                                    setShowPrimaryHelp(true)
                                                                }
                                                            >
                                                                <i className="bi bi-question-circle-fill"></i>
                                                            </a>
                                                            <div
                                                                className="smallHelpBox fieldValue"
                                                                style={
                                                                    showPrimaryHelp
                                                                        ? { display: "block" }
                                                                        : { display: "none" }
                                                                }
                                                            >
                                                                The Primary beneficiary is the party
                                                                who will receive the proceeds of the
                                                                policy when the insured passes away.
                                                                The owner of the policy may
                                                                indicate, by percentage, how the
                                                                proceeds are to be divided among the
                                                                parties.
                                                                <br></br>
                                                                <div
                                                                    style={{
                                                                        textAlign: "center",
                                                                        paddingTop: "10px"
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="button"
                                                                        value="OK"
                                                                        className="btn btn-Primary"
                                                                        onClick={() =>
                                                                            setShowPrimaryHelp(
                                                                                false
                                                                            )
                                                                        }
                                                                        style={{ width: "90px" }}
                                                                        aria-label="OK button"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-3"
                                                            style={{
                                                                color: "#003366",
                                                                textAlign: "center"
                                                            }}
                                                        >
                                                            {beneficiaries.filter(
                                                                (f) => f.isPrimary == true
                                                            ).length > 0 ? (
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            primaryEqualizeShare
                                                                        }
                                                                        value={primaryEqualizeShare.toString()}
                                                                        onChange={(e) =>
                                                                            primaryBeneficiaryEqualizeClick(
                                                                                primaryEqualizeShare
                                                                                    ? false
                                                                                    : true
                                                                            )
                                                                        }
                                                                        className="EqualPrimaryShareCheck"
                                                                        name="EqualizePrimaryShare"
                                                                        aria-label="Equalize Primary Share checkbox"
                                                                    ></input>
                                                                    &nbsp;Create Equal Shares
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                        <div
                                                            className="col-1"
                                                            style={{
                                                                fontSize: "20px",
                                                                fontWeight: "700",
                                                                color: "#003366",
                                                                textAlign: "center"
                                                            }}
                                                        >
                                                            <i
                                                                className="bi bi-plus-square-fill"
                                                                style={
                                                                    beneficiaries.length >= 18
                                                                        ? { cursor: "not-allowed" }
                                                                        : { cursor: "pointer" }
                                                                }
                                                                onClick={() => onAddClick(true)}
                                                                title="Add Primary Beneficiary"
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <b>Name</b>
                                                        </div>
                                                        <div className="col-3">
                                                            <b>Relationship</b>
                                                        </div>
                                                        <div className="col-3">
                                                            <b>Date of Birth</b>
                                                        </div>
                                                        <div className="col-2">
                                                            <b>Percentage</b>
                                                        </div>
                                                        <div className="col-1">&nbsp;</div>
                                                    </div>
                                                    <>
                                                        {beneficiaries &&
                                                        beneficiaries.length > 0 ? (
                                                            <>
                                                                {beneficiaries
                                                                    .filter(
                                                                        (f) => f.isPrimary == true
                                                                    )
                                                                    .sort((s) => s.id)
                                                                    .map((b, idx) => (
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                            key={idx}
                                                                        >
                                                                            <div
                                                                                className="col-3 fieldValue"
                                                                                title="Name"
                                                                            >
                                                                                {b.name}
                                                                            </div>
                                                                            <div
                                                                                className="col-3 fieldValue"
                                                                                title="Relation"
                                                                            >
                                                                                {b.relation}
                                                                            </div>
                                                                            <div
                                                                                className="col-3 fieldValue"
                                                                                title="Date of Birth"
                                                                            >
                                                                                {utilities.parseAndFormatDateCustom(
                                                                                    b.dateOfBirth,
                                                                                    "yyyy-MM-dd"
                                                                                )}
                                                                            </div>
                                                                            <div
                                                                                className="col-2 fieldValue"
                                                                                title="Share"
                                                                            >
                                                                                {/* {b.sharePercentage} */}
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control gridSharePercentage"
                                                                                    title="Share"
                                                                                    onChange={(e) =>
                                                                                        handleGridShare(
                                                                                            e,
                                                                                            b.id
                                                                                        )
                                                                                    }
                                                                                    name="sharePercentage"
                                                                                    value={
                                                                                        b.sharePercentage
                                                                                    }
                                                                                    style={
                                                                                        b.sharePercentage ==
                                                                                            0 ||
                                                                                        !b.sharePercentage
                                                                                            ? invalidGridShareStyle
                                                                                            : validGridShareStyle
                                                                                    }
                                                                                    disabled={
                                                                                        b?.equalizeShare &&
                                                                                        ((b?.isPrimary &&
                                                                                            primaryEqualizeShare) ||
                                                                                            (!b?.isPrimary &&
                                                                                                contingentEqualizeShare))
                                                                                    }
                                                                                    step={0.01}
                                                                                    aria-label="Grid Share Percentage"
                                                                                ></input>
                                                                            </div>
                                                                            <div
                                                                                className="col-1 fieldValue"
                                                                                title="Delete, Edit buttons"
                                                                            >
                                                                                <a
                                                                                    href={"#"}
                                                                                    onClick={() =>
                                                                                        removePrimaryBeneficiary(
                                                                                            b.id
                                                                                        )
                                                                                    }
                                                                                    className="bi bi-trash"
                                                                                ></a>
                                                                                &nbsp;
                                                                                <a
                                                                                    href={"#"}
                                                                                    onClick={() =>
                                                                                        editPrimaryBeneficiary(
                                                                                            b.id
                                                                                        )
                                                                                    }
                                                                                    className="bi bi-pencil"
                                                                                ></a>
                                                                                &nbsp;
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                <div
                                                                    className="row"
                                                                    style={{
                                                                        paddingTop: 5,
                                                                        paddingBottom: 5
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="col-3 fieldValue"
                                                                        title=""
                                                                    >
                                                                        &nbsp;
                                                                    </div>
                                                                    <div
                                                                        className="col-3 fieldValue"
                                                                        title=""
                                                                    >
                                                                        &nbsp;
                                                                    </div>
                                                                    <div
                                                                        className="col-3 fieldValue"
                                                                        title="Total"
                                                                    >
                                                                        Total:
                                                                    </div>
                                                                    <div
                                                                        className="col-2 fieldValue"
                                                                        title=""
                                                                    >
                                                                        {getCurrentShareTotal(
                                                                            true
                                                                        ) < 100 ||
                                                                        getCurrentShareTotal(true) >
                                                                            100 ? (
                                                                            <>
                                                                                <span className="text-danger">
                                                                                    {getCurrentShareTotal(
                                                                                        true
                                                                                    )}
                                                                                    %
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span
                                                                                    style={{
                                                                                        color: "green"
                                                                                    }}
                                                                                >
                                                                                    {getCurrentShareTotal(
                                                                                        true
                                                                                    )}
                                                                                    %
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className="col-1 fieldValue"
                                                                        title=""
                                                                    >
                                                                        &nbsp;
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>No beneficiaries.</>
                                                        )}
                                                    </>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <br></br>
                                        <Card className="policyCard">
                                            <Card.Body className="shadow">
                                                <div>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 5, paddingBottom: 15 }}
                                                    >
                                                        <div
                                                            className="col-8"
                                                            style={{
                                                                fontSize: "20px",
                                                                fontWeight: "700",
                                                                color: "#003366"
                                                            }}
                                                        >
                                                            <span title="The Contingent beneficiary will receive the proceeds if the primary beneficiary(ies) should pass away before the person whose Life is insured. Unless otherwise provided, the contingent beneficiary will only receive proceeds from the policy if all the designated primary beneficiaries have predeceased the insured.">
                                                                Contingent
                                                            </span>
                                                            &nbsp;
                                                            <a
                                                                href="#"
                                                                onClick={() =>
                                                                    setShowContingentHelp(true)
                                                                }
                                                            >
                                                                <i className="bi bi-question-circle-fill"></i>
                                                            </a>
                                                            <div
                                                                className="smallHelpBox fieldValue"
                                                                style={
                                                                    showContingentHelp
                                                                        ? { display: "block" }
                                                                        : { display: "none" }
                                                                }
                                                            >
                                                                The Contingent beneficiary will
                                                                receive the proceeds if the primary
                                                                beneficiary(ies) should pass away
                                                                before the person whose Life is
                                                                insured. Unless otherwise provided,
                                                                the contingent beneficiary will only
                                                                receive proceeds from the policy if
                                                                all the designated primary
                                                                beneficiaries have predeceased the
                                                                insured.
                                                                <br></br>
                                                                <div
                                                                    style={{
                                                                        textAlign: "center",
                                                                        paddingTop: "10px"
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="button"
                                                                        value="OK"
                                                                        className="btn btn-Primary"
                                                                        onClick={() =>
                                                                            setShowContingentHelp(
                                                                                false
                                                                            )
                                                                        }
                                                                        style={{ width: "90px" }}
                                                                        aria-label="OK button"
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-3"
                                                            style={{
                                                                color: "#003366",
                                                                textAlign: "center"
                                                            }}
                                                        >
                                                            {beneficiaries.filter(
                                                                (f) => f.isPrimary == false
                                                            ).length > 0 ? (
                                                                <>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            contingentEqualizeShare
                                                                        }
                                                                        value={contingentEqualizeShare.toString()}
                                                                        onChange={(e) =>
                                                                            contingentBeneficiaryEqualizeClick(
                                                                                contingentEqualizeShare
                                                                                    ? false
                                                                                    : true
                                                                            )
                                                                        }
                                                                        className="EqualContingentShareCheck"
                                                                        name="EqualizeContingentShare"
                                                                        aria-label="Equalize Contingent Share checkbox"
                                                                    ></input>
                                                                    &nbsp;Create Equal Shares
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                        <div
                                                            className="col-1"
                                                            style={{
                                                                fontSize: "20px",
                                                                fontWeight: "700",
                                                                color: "#003366",
                                                                textAlign: "center"
                                                            }}
                                                        >
                                                            <i
                                                                className="bi bi-plus-square-fill"
                                                                style={
                                                                    beneficiaries.length >= 18
                                                                        ? { cursor: "not-allowed" }
                                                                        : { cursor: "pointer" }
                                                                }
                                                                onClick={() => onAddClick(false)}
                                                                title="Add Secondary Beneficiary"
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <b>Name</b>
                                                        </div>
                                                        <div className="col-3">
                                                            <b>Relationship</b>
                                                        </div>
                                                        <div className="col-3">
                                                            <b>Date of Birth</b>
                                                        </div>
                                                        <div className="col-2">
                                                            <b>Percentage</b>
                                                        </div>
                                                        <div className="col-1">&nbsp;</div>
                                                    </div>
                                                    <>
                                                        {beneficiaries &&
                                                        beneficiaries.filter(
                                                            (f) => f.isPrimary == false
                                                        ).length > 0 ? (
                                                            <>
                                                                {beneficiaries
                                                                    .filter(
                                                                        (f) => f.isPrimary == false
                                                                    )
                                                                    .map((b, idx) => (
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                            key={idx}
                                                                        >
                                                                            <div
                                                                                className="col-3 fieldValue"
                                                                                title="Name"
                                                                            >
                                                                                {b.name}
                                                                            </div>
                                                                            <div
                                                                                className="col-3 fieldValue"
                                                                                title="Relation"
                                                                            >
                                                                                {b.relation}
                                                                            </div>
                                                                            <div
                                                                                className="col-3 fieldValue"
                                                                                title="Date of Birth"
                                                                            >
                                                                                {utilities.parseAndFormatDateCustom(
                                                                                    b.dateOfBirth,
                                                                                    "yyyy-MM-dd"
                                                                                )}
                                                                            </div>
                                                                            <div
                                                                                className="col-2 fieldValue"
                                                                                title="Share"
                                                                            >
                                                                                {/* {b.sharePercentage} */}
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control gridSharePercentage"
                                                                                    title="Share"
                                                                                    onChange={(e) =>
                                                                                        handleGridShare(
                                                                                            e,
                                                                                            b.id
                                                                                        )
                                                                                    }
                                                                                    name="sharePercentage"
                                                                                    value={
                                                                                        b.sharePercentage
                                                                                    }
                                                                                    style={
                                                                                        b.sharePercentage ==
                                                                                            0 ||
                                                                                        !b.sharePercentage
                                                                                            ? invalidGridShareStyle
                                                                                            : validGridShareStyle
                                                                                    }
                                                                                    disabled={
                                                                                        b?.equalizeShare ||
                                                                                        contingentEqualizeShare
                                                                                    }
                                                                                    step={0.01}
                                                                                    aria-label="Grid Share Percentage"
                                                                                ></input>
                                                                            </div>
                                                                            <div
                                                                                className="col-1 fieldValue"
                                                                                title="Delete, Edit buttons"
                                                                            >
                                                                                <a
                                                                                    href={"#"}
                                                                                    onClick={() =>
                                                                                        removeContingentBeneficiary(
                                                                                            b.id
                                                                                        )
                                                                                    }
                                                                                    className="bi bi-trash"
                                                                                ></a>
                                                                                &nbsp;
                                                                                <a
                                                                                    href={"#"}
                                                                                    onClick={() =>
                                                                                        editContingentBeneficiary(
                                                                                            b.id
                                                                                        )
                                                                                    }
                                                                                    className="bi bi-pencil"
                                                                                ></a>
                                                                                &nbsp;
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                <div
                                                                    className="row"
                                                                    style={{
                                                                        paddingTop: 5,
                                                                        paddingBottom: 5
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="col-3 fieldValue"
                                                                        title=""
                                                                    >
                                                                        &nbsp;
                                                                    </div>
                                                                    <div
                                                                        className="col-3 fieldValue"
                                                                        title=""
                                                                    >
                                                                        &nbsp;
                                                                    </div>
                                                                    <div
                                                                        className="col-3 fieldValue"
                                                                        title="Total"
                                                                    >
                                                                        Total:
                                                                    </div>
                                                                    <div
                                                                        className="col-2 fieldValue"
                                                                        title="Current Share Total"
                                                                    >
                                                                        {(getCurrentShareTotal(
                                                                            false
                                                                        ) < 100 ||
                                                                            getCurrentShareTotal(
                                                                                false
                                                                            ) > 100) &&
                                                                        beneficiaries.filter(
                                                                            (f) =>
                                                                                f.isPrimary == false
                                                                        ).length > 0 ? (
                                                                            <>
                                                                                <span className="text-danger">
                                                                                    {getCurrentShareTotal(
                                                                                        false
                                                                                    )}
                                                                                    %
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span
                                                                                    style={{
                                                                                        color: "green"
                                                                                    }}
                                                                                >
                                                                                    {getCurrentShareTotal(
                                                                                        false
                                                                                    )}
                                                                                    %
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className="col-1 fieldValue"
                                                                        title=""
                                                                    >
                                                                        &nbsp;
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>No beneficiaries.</>
                                                        )}
                                                    </>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 10, paddingBottom: 0 }}
                                        >
                                            <div className="col-12">
                                                <div className="row">
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "100px"
                                                        }}
                                                    >
                                                        {isNextProcessing ? (
                                                            <Spinner
                                                                imageWidth={20}
                                                                aria-label="Loading"
                                                            />
                                                        ) : (
                                                            <input
                                                                type="button"
                                                                value="Next"
                                                                className="btn btn-Primary"
                                                                onClick={onNext}
                                                                style={{ width: "90px" }}
                                                                aria-label="Next button"
                                                            ></input>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "100px"
                                                        }}
                                                    >
                                                        <input
                                                            type="button"
                                                            className="btn btn-Primary"
                                                            onClick={() =>
                                                                loggedNavigate(
                                                                    "/policy-detail/" +
                                                                        idParsed +
                                                                        "?tab=service",
                                                                    "Cancel"
                                                                )
                                                            }
                                                            value="Cancel"
                                                            style={{ width: "90px" }}
                                                            aria-label="Cancel button"
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}

                                {stepNumber === 2 ? (
                                    <>
                                        <div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-12">
                                                    <b>Electronic Signature:</b>
                                                </div>
                                            </div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-12" style={{ paddingLeft: 30 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={esignChecked}
                                                        value={esignChecked?.toString()}
                                                        onChange={(e) =>
                                                            setEsignChecked(e.currentTarget.checked)
                                                        }
                                                        className="EsignCheck"
                                                        aria-label="E-Sign checkbox"
                                                    ></input>
                                                    &nbsp; By checking this box you agree and affirm
                                                    with full knowledge and consent that all
                                                    electronic signatures that you provide or adopt
                                                    are the legal equivalent of your handwritten
                                                    signature and your signature on this document is
                                                    as valid as if you signed the document in
                                                    writing. This acknowledgement may be used in
                                                    conjunction with the use of electronic
                                                    signatures on all forms regarding any and all
                                                    future documentation with a signature
                                                    requirement, should you elect to have signed
                                                    electronically. You have the right to withdraw
                                                    your consent at any time and receive a paper
                                                    copy at no charge by reaching out to Americo at
                                                    800.231.0801.
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-12" style={{ paddingLeft: 30 }}>
                                                <p>
                                                    Click Next to review forms and to submit your
                                                    final request
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 10, paddingBottom: 0 }}
                                        >
                                            <div className="col-12">
                                                <div className="row">
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "100px"
                                                        }}
                                                    >
                                                        <input
                                                            type="button"
                                                            className="btn btn-Primary"
                                                            onClick={onBack}
                                                            value="Previous"
                                                            style={{ width: "90px" }}
                                                            aria-label="Back button"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "100px"
                                                        }}
                                                    >
                                                        {isNextProcessing ? (
                                                            <Spinner
                                                                imageWidth={20}
                                                                aria-label="Loading"
                                                            />
                                                        ) : (
                                                            <input
                                                                type="button"
                                                                value="Next"
                                                                className="btn btn-Primary"
                                                                onClick={onNext}
                                                                style={{ width: "90px" }}
                                                                aria-label="Next button"
                                                            ></input>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "100px"
                                                        }}
                                                    >
                                                        <input
                                                            type="button"
                                                            className="btn btn-Primary"
                                                            onClick={() =>
                                                                loggedNavigate(
                                                                    "/policy-detail/" +
                                                                        idParsed +
                                                                        "?tab=service",
                                                                    "Cancel"
                                                                )
                                                            }
                                                            value="Cancel"
                                                            style={{ width: "90px" }}
                                                            aria-label="Cancel button"
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}

                                {stepNumber === 3 ? (
                                    <div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-12">
                                                <b>Review and Submit:</b>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-12" style={{ paddingLeft: 30 }}>
                                                <p>
                                                    Please review the form and click
                                                    &apos;Submit&apos; to submit your request. If
                                                    you would like to make changes, please hit the
                                                    &apos;previous&apos; button.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div
                                                className="col-12"
                                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                            >
                                                {requestId > 0 &&
                                                bcPDFBlob &&
                                                bcPDFBlob.length > 0 ? (
                                                    <>
                                                        <iframe
                                                            src={bcPDFBlob}
                                                            onLoad={handleIFrameLoad}
                                                            title={"BCPreview"}
                                                            id={"BCPreview"}
                                                            style={{
                                                                width: "100%",
                                                                height: "550px"
                                                            }}
                                                        ></iframe>
                                                    </>
                                                ) : (
                                                    <>
                                                        Count not load preview, please click OK to
                                                        try again.{requestId}:{lastRequestId}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-12" style={{ paddingLeft: 30 }}>
                                                <p>Click submit to finalize your request.</p>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 10, paddingBottom: 0 }}
                                        >
                                            <div className="col-12">
                                                <div className="row">
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "100px"
                                                        }}
                                                    >
                                                        <input
                                                            type="button"
                                                            className="btn btn-Primary"
                                                            onClick={onBack}
                                                            value="Previous"
                                                            style={{ width: "90px" }}
                                                            aria-label="Previous button"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "100px"
                                                        }}
                                                    >
                                                        <input
                                                            type="button"
                                                            value="Submit"
                                                            className="btn btn-Primary"
                                                            onClick={onSubmit}
                                                            style={{ width: "90px" }}
                                                            aria-label="Submit button"
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {requestSubmitted ? (
                                    <>
                                        <Card className="policyCard">
                                            <Card.Body className="shadow">
                                                <div>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 5, paddingBottom: 15 }}
                                                    >
                                                        <div className="col-9">
                                                            <b>Your request has been submitted</b>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 5, paddingBottom: 15 }}
                                                    >
                                                        <div
                                                            className="col-9"
                                                            style={{ paddingLeft: 30 }}
                                                        >
                                                            <p>
                                                                You will receive an email at the
                                                                email you used to log in confirming
                                                                receipt of your request.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 10, paddingBottom: 0 }}
                                        >
                                            <div className="col-12">
                                                <div className="row">
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "100px"
                                                        }}
                                                    >
                                                        <input
                                                            type="button"
                                                            value="OK"
                                                            className="btn btn-Primary"
                                                            onClick={() =>
                                                                navigate.push(
                                                                    "/policy-detail/" +
                                                                        idParsed +
                                                                        "?tab=service"
                                                                )
                                                            }
                                                            style={{ width: "90px" }}
                                                            aria-label="OK button"
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}

                                {stepNumber === 9 ? (
                                    <>
                                        <div className="row" style={{ paddingTop: 5 }}>
                                            <b>
                                                Add {isPrimary ? <>Primary</> : <>Secondary</>}{" "}
                                                Beneficiary
                                            </b>
                                        </div>
                                        <div>&nbsp;</div>
                                        <div className="row" style={{ paddingTop: 5 }}>
                                            <div className="col-9">
                                                <>
                                                    <div>
                                                        <label>
                                                            Legal Name / Trust Name / Company Name
                                                        </label>
                                                        <span className="text-danger">
                                                            &nbsp;&nbsp;*
                                                        </span>
                                                        &nbsp;&nbsp;
                                                        <a
                                                            href="#"
                                                            onClick={() => setShowPrimaryHelp(true)}
                                                        >
                                                            <i className="bi bi-question-circle-fill"></i>
                                                        </a>
                                                        <div
                                                            className="smallHelpBox fieldValue"
                                                            style={
                                                                showPrimaryHelp
                                                                    ? { display: "block" }
                                                                    : { display: "none" }
                                                            }
                                                        >
                                                            HOW DO I NAME A TRUST AS MY BENEFICIARY?
                                                            <br></br>
                                                            Provide the name, date, and address of
                                                            the Trust where indicated on the
                                                            Beneficiary Change form. Trust documents
                                                            will be required in order to process a
                                                            claim on this policy. If you would like
                                                            us to keep a copy of the Trust documents
                                                            on file, please send a copy to us.
                                                            Certificate of Trust, if applicable, can
                                                            be accepted in lieu of Trust documents.
                                                            If the Trust named is a Testamentary
                                                            Trust, please indicate this on the form
                                                            and do not include a Trust date.
                                                            <br></br>
                                                            <div
                                                                style={{
                                                                    textAlign: "center",
                                                                    paddingTop: "10px"
                                                                }}
                                                            >
                                                                <input
                                                                    type="button"
                                                                    value="OK"
                                                                    className="btn btn-Primary"
                                                                    onClick={() =>
                                                                        setShowPrimaryHelp(false)
                                                                    }
                                                                    style={{ width: "90px" }}
                                                                    aria-label="OK button"
                                                                ></input>
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            name="name"
                                                            ref={nameInput}
                                                            onChange={handleBeneName}
                                                            value={beneName}
                                                            style={
                                                                beneficiaryNameError
                                                                    ? errorStyle
                                                                    : {}
                                                            }
                                                            aria-label="Beneficiary Name"
                                                        ></input>
                                                    </div>
                                                    <div>
                                                        <label>Relationship to the Insured</label>
                                                        <span className="text-danger">
                                                            &nbsp;&nbsp;*
                                                        </span>
                                                        <select
                                                            id="Relation"
                                                            name="Relation"
                                                            className="form-select"
                                                            onChange={(e) =>
                                                                setBeneRelation(e.target.value)
                                                            }
                                                            defaultValue={beneRelation}
                                                            style={
                                                                relationError
                                                                    ? relationErrorStyle
                                                                    : {
                                                                          marginTop: "10px",
                                                                          marginBottom: "10px"
                                                                      }
                                                            }
                                                        >
                                                            <option value="--Select Relation--">
                                                                --Select Relation--
                                                            </option>
                                                            <option value="Child">Child</option>
                                                            <option value="Spouse">Spouse</option>
                                                            <option value="Sibling">Sibling</option>
                                                            <option value="Parent">Parent</option>
                                                            <option value="Domestic Partner">
                                                                Domestic Partner
                                                            </option>
                                                            <option value="Fiance">Fiance</option>
                                                            <option value="Grandchild">
                                                                Grandchild
                                                            </option>
                                                            <option value="Common Law Spouse">
                                                                Common Law Spouse
                                                            </option>
                                                            <option value="Friend">Friend</option>
                                                            <option value="Niece">Niece</option>
                                                            <option value="Nephew">Nephew</option>
                                                            <option value="Cousin">Cousin</option>
                                                            <option value="Estate">Estate</option>
                                                            <option value="Ex-spouse">
                                                                Ex-spouse
                                                            </option>
                                                            <option value="Stepchild">
                                                                Stepchild
                                                            </option>
                                                            <option value="Aunt">Aunt</option>
                                                            <option value="Uncle">Uncle</option>
                                                            <option value="Grandparent">
                                                                Grandparent
                                                            </option>
                                                            <option value="Trust">Trust</option>
                                                            <option value="Funeral Home">
                                                                Funeral Home
                                                            </option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>
                                                    {beneRelation === "Other" ? (
                                                        <>
                                                            <div style={{ marginLeft: "20px" }}>
                                                                <label>Please Specify</label>
                                                                <span className="text-danger">
                                                                    &nbsp;&nbsp;*
                                                                </span>
                                                                <input
                                                                    type="text"
                                                                    name="relationOther"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    onChange={(e) =>
                                                                        setBeneRelationOther(
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    value={beneRelationOther}
                                                                    style={
                                                                        relationOtherError
                                                                            ? errorStyle
                                                                            : {}
                                                                    }
                                                                    maxLength={20}
                                                                    aria-label="Beneficiary Relationship Other"
                                                                ></input>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <label>Share of 100%</label>
                                                                <span className="text-danger">
                                                                    &nbsp;&nbsp;*
                                                                </span>
                                                                &nbsp;
                                                                <span>
                                                                    (Current:{" "}
                                                                    {getCurrentShareTotal(
                                                                        isPrimary
                                                                    )}
                                                                    /100%)
                                                                </span>
                                                            </div>
                                                            <div className="col">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        beneEqualizeShare ||
                                                                        (isPrimary &&
                                                                            primaryEqualizeShare) ||
                                                                        (!isPrimary &&
                                                                            contingentEqualizeShare)
                                                                    }
                                                                    value={beneEqualizeShare.toString()}
                                                                    onChange={(e) =>
                                                                        beneficiaryEqualizeClick(
                                                                            beneEqualizeShare
                                                                                ? false
                                                                                : true,
                                                                            isPrimary
                                                                        )
                                                                    }
                                                                    className="EqualShareCheck"
                                                                    name="equalizeShare"
                                                                    aria-label="Equalize Share checkbox"
                                                                ></input>
                                                                &nbsp;Create Equal Shares
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder=""
                                                            name="sharePercentage"
                                                            onChange={handleShare}
                                                            value={beneSharePercentage}
                                                            style={
                                                                sharePercentageError
                                                                    ? errorStyle
                                                                    : {}
                                                            }
                                                            disabled={
                                                                beneEqualizeShare ||
                                                                (isPrimary &&
                                                                    primaryEqualizeShare) ||
                                                                (!isPrimary &&
                                                                    contingentEqualizeShare)
                                                            }
                                                            step={0.01}
                                                            aria-label="Share percentage"
                                                        ></input>
                                                    </div>
                                                    <div>
                                                        <label>Date of Birth / Trust Date</label>
                                                        <span className="text-danger">
                                                            &nbsp;&nbsp;*
                                                        </span>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="mm/dd/yyyy"
                                                            name="dateOfBirth"
                                                            onChange={handleDOB}
                                                            value={beneDOB}
                                                            style={
                                                                dateOfBirthError ? errorStyle : {}
                                                            }
                                                            onKeyPress={handleEnterKeyPress}
                                                            aria-label="Date of Birth/Trust Date"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        className="smallHelpBox fieldValue"
                                                        style={
                                                            showUnder18Help
                                                                ? { display: "block" }
                                                                : { display: "none" }
                                                        }
                                                    >
                                                        Please be aware that for a minor to receive
                                                        proceeds, special State-specific regulations
                                                        will result in additional claim requirements
                                                        and may delay claim payment.
                                                        <br></br>
                                                        <div
                                                            style={{
                                                                textAlign: "center",
                                                                paddingTop: "10px"
                                                            }}
                                                        >
                                                            <input
                                                                type="button"
                                                                value="OK"
                                                                className="btn btn-Primary"
                                                                onClick={() =>
                                                                    setShowUnder18Help(false)
                                                                }
                                                                style={{ width: "90px" }}
                                                                aria-label="OK button"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label>Street Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            name="address"
                                                            id="address"
                                                            onChange={(e) =>
                                                                setBeneAddress(e.target.value)
                                                            }
                                                            onFocus={initAutocomplete}
                                                            autoComplete="off"
                                                            value={beneAddress}
                                                            style={addressError ? errorStyle : {}}
                                                            aria-label="Street Address"
                                                        ></input>
                                                    </div>
                                                    <div>
                                                        <label>City</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            name="city"
                                                            id="city"
                                                            onChange={(e) =>
                                                                setBeneCity(e.target.value)
                                                            }
                                                            value={beneCity}
                                                            style={cityError ? errorStyle : {}}
                                                            aria-label="City"
                                                        ></input>
                                                    </div>
                                                    <div>
                                                        <label>State / Province / Region</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            name="state"
                                                            id="state"
                                                            onChange={(e) =>
                                                                setBeneState(e.target.value)
                                                            }
                                                            value={beneState}
                                                            style={stateError ? errorStyle : {}}
                                                            aria-label="State, Province, Region"
                                                        ></input>
                                                    </div>
                                                    <div>
                                                        <label>Postal Code</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            name="zip"
                                                            id="zip"
                                                            onChange={(e) =>
                                                                setBeneZip(e.target.value)
                                                            }
                                                            value={beneZip}
                                                            style={zipError ? errorStyle : {}}
                                                            aria-label="Zip code"
                                                        ></input>
                                                    </div>
                                                    <div>
                                                        <label>Country</label>
                                                        <select
                                                            name="country"
                                                            id="country"
                                                            className="form-select"
                                                            onChange={(e) =>
                                                                setBeneCountry(e.target.value)
                                                            }
                                                            value={beneCountry}
                                                            style={{
                                                                marginTop: "10px",
                                                                marginBottom: "10px"
                                                            }}
                                                        >
                                                            <option value="US">
                                                                United States
                                                            </option>
                                                            <option value="AF">Afghanistan</option>
                                                            <option value="AX">
                                                                Aland Islands
                                                            </option>
                                                            <option value="AL">Albania</option>
                                                            <option value="DZ">Algeria</option>
                                                            <option value="AS">
                                                                American Samoa
                                                            </option>
                                                            <option value="AD">Andorra</option>
                                                            <option value="AO">Angola</option>
                                                            <option value="AI">Anguilla</option>
                                                            <option value="AQ">Antarctica</option>
                                                            <option value="AG">
                                                                Antigua and Barbuda
                                                            </option>
                                                            <option value="AR">Argentina</option>
                                                            <option value="AM">Armenia</option>
                                                            <option value="AW">Aruba</option>
                                                            <option value="AU">Australia</option>
                                                            <option value="AT">Austria</option>
                                                            <option value="AZ">Azerbaijan</option>
                                                            <option value="BS">Bahamas</option>
                                                            <option value="BH">Bahrain</option>
                                                            <option value="BD">Bangladesh</option>
                                                            <option value="BB">Barbados</option>
                                                            <option value="BY">Belarus</option>
                                                            <option value="BE">Belgium</option>
                                                            <option value="BZ">Belize</option>
                                                            <option value="BJ">Benin</option>
                                                            <option value="BM">Bermuda</option>
                                                            <option value="BT">Bhutan</option>
                                                            <option value="BO">Bolivia</option>
                                                            <option value="BQ">
                                                                Bonaire, Sint Eustatius and Saba
                                                            </option>
                                                            <option value="BA">
                                                                Bosnia and Herzegovina
                                                            </option>
                                                            <option value="BW">Botswana</option>
                                                            <option value="BV">
                                                                Bouvet Island
                                                            </option>
                                                            <option value="BR">Brazil</option>
                                                            <option value="IO">
                                                                British Indian Ocean Territory
                                                            </option>
                                                            <option value="BN">
                                                                Brunei Darussalam
                                                            </option>
                                                            <option value="BG">Bulgaria</option>
                                                            <option value="BF">Burkina Faso</option>
                                                            <option value="BI">Burundi</option>
                                                            <option value="KH">Cambodia</option>
                                                            <option value="CM">Cameroon</option>
                                                            <option value="CA">Canada</option>
                                                            <option value="CV">Cape Verde</option>
                                                            <option value="KY">
                                                                Cayman Islands
                                                            </option>
                                                            <option value="CF">
                                                                Central African Republic
                                                            </option>
                                                            <option value="TD">Chad</option>
                                                            <option value="CL">Chile</option>
                                                            <option value="CN">China</option>
                                                            <option value="CX">
                                                                Christmas Island
                                                            </option>
                                                            <option value="CC">
                                                                Cocos (Keeling) Islands
                                                            </option>
                                                            <option value="CO">Colombia</option>
                                                            <option value="KM">Comoros</option>
                                                            <option value="CG">Congo</option>
                                                            <option value="CD">
                                                                Congo, Democratic Republic of the
                                                                Congo
                                                            </option>
                                                            <option value="CK">Cook Islands</option>
                                                            <option value="CR">Costa Rica</option>
                                                            <option value="CI">
                                                                Cote D'Ivoire
                                                            </option>
                                                            <option value="HR">Croatia</option>
                                                            <option value="CU">Cuba</option>
                                                            <option value="CW">Curacao</option>
                                                            <option value="CY">Cyprus</option>
                                                            <option value="CZ">
                                                                Czech Republic
                                                            </option>
                                                            <option value="DK">Denmark</option>
                                                            <option value="DJ">Djibouti</option>
                                                            <option value="DM">Dominica</option>
                                                            <option value="DO">
                                                                Dominican Republic
                                                            </option>
                                                            <option value="EC">Ecuador</option>
                                                            <option value="EG">Egypt</option>
                                                            <option value="SV">El Salvador</option>
                                                            <option value="GQ">
                                                                Equatorial Guinea
                                                            </option>
                                                            <option value="ER">Eritrea</option>
                                                            <option value="EE">Estonia</option>
                                                            <option value="ET">Ethiopia</option>
                                                            <option value="FK">
                                                                Falkland Islands (Malvinas)
                                                            </option>
                                                            <option value="FO">
                                                                Faroe Islands
                                                            </option>
                                                            <option value="FJ">Fiji</option>
                                                            <option value="FI">Finland</option>
                                                            <option value="FR">France</option>
                                                            <option value="GF">
                                                                French Guiana
                                                            </option>
                                                            <option value="PF">
                                                                French Polynesia
                                                            </option>
                                                            <option value="TF">
                                                                French Southern Territories
                                                            </option>
                                                            <option value="GA">Gabon</option>
                                                            <option value="GM">Gambia</option>
                                                            <option value="GE">Georgia</option>
                                                            <option value="DE">Germany</option>
                                                            <option value="GH">Ghana</option>
                                                            <option value="GI">Gibraltar</option>
                                                            <option value="GR">Greece</option>
                                                            <option value="GL">Greenland</option>
                                                            <option value="GD">Grenada</option>
                                                            <option value="GP">Guadeloupe</option>
                                                            <option value="GU">Guam</option>
                                                            <option value="GT">Guatemala</option>
                                                            <option value="GG">Guernsey</option>
                                                            <option value="GN">Guinea</option>
                                                            <option value="GW">
                                                                Guinea-Bissau
                                                            </option>
                                                            <option value="GY">Guyana</option>
                                                            <option value="HT">Haiti</option>
                                                            <option value="HM">
                                                                Heard Island and Mcdonald Islands
                                                            </option>
                                                            <option value="VA">
                                                                Holy See (Vatican City State)
                                                            </option>
                                                            <option value="HN">Honduras</option>
                                                            <option value="HK">Hong Kong</option>
                                                            <option value="HU">Hungary</option>
                                                            <option value="IS">Iceland</option>
                                                            <option value="IN">India</option>
                                                            <option value="ID">Indonesia</option>
                                                            <option value="IR">
                                                                Iran, Islamic Republic of
                                                            </option>
                                                            <option value="IQ">Iraq</option>
                                                            <option value="IE">Ireland</option>
                                                            <option value="IM">Isle of Man</option>
                                                            <option value="IL">Israel</option>
                                                            <option value="IT">Italy</option>
                                                            <option value="JM">Jamaica</option>
                                                            <option value="JP">Japan</option>
                                                            <option value="JE">Jersey</option>
                                                            <option value="JO">Jordan</option>
                                                            <option value="KZ">Kazakhstan</option>
                                                            <option value="KE">Kenya</option>
                                                            <option value="KI">Kiribati</option>
                                                            <option value="KP">
                                                                Korea, Democratic People's Republic
                                                                of
                                                            </option>
                                                            <option value="KR">
                                                                Korea, Republic of
                                                            </option>
                                                            <option value="XK">Kosovo</option>
                                                            <option value="KW">Kuwait</option>
                                                            <option value="KG">Kyrgyzstan</option>
                                                            <option value="LA">
                                                                Lao People's Democratic Republic
                                                            </option>
                                                            <option value="LV">Latvia</option>
                                                            <option value="LB">Lebanon</option>
                                                            <option value="LS">Lesotho</option>
                                                            <option value="LR">Liberia</option>
                                                            <option value="LY">
                                                                Libyan Arab Jamahiriya
                                                            </option>
                                                            <option value="LI">
                                                                Liechtenstein
                                                            </option>
                                                            <option value="LT">Lithuania</option>
                                                            <option value="LU">Luxembourg</option>
                                                            <option value="MO">Macao</option>
                                                            <option value="MK">
                                                                Macedonia, the Former Yugoslav
                                                                Republic of
                                                            </option>
                                                            <option value="MG">Madagascar</option>
                                                            <option value="MW">Malawi</option>
                                                            <option value="MY">Malaysia</option>
                                                            <option value="MV">Maldives</option>
                                                            <option value="ML">Mali</option>
                                                            <option value="MT">Malta</option>
                                                            <option value="MH">
                                                                Marshall Islands
                                                            </option>
                                                            <option value="MQ">Martinique</option>
                                                            <option value="MR">Mauritania</option>
                                                            <option value="MU">Mauritius</option>
                                                            <option value="YT">Mayotte</option>
                                                            <option value="MX">Mexico</option>
                                                            <option value="FM">
                                                                Micronesia, Federated States of
                                                            </option>
                                                            <option value="MD">
                                                                Moldova, Republic of
                                                            </option>
                                                            <option value="MC">Monaco</option>
                                                            <option value="MN">Mongolia</option>
                                                            <option value="ME">Montenegro</option>
                                                            <option value="MS">Montserrat</option>
                                                            <option value="MA">Morocco</option>
                                                            <option value="MZ">Mozambique</option>
                                                            <option value="MM">Myanmar</option>
                                                            <option value="NA">Namibia</option>
                                                            <option value="NR">Nauru</option>
                                                            <option value="NP">Nepal</option>
                                                            <option value="NL">Netherlands</option>
                                                            <option value="AN">
                                                                Netherlands Antilles
                                                            </option>
                                                            <option value="NC">
                                                                New Caledonia
                                                            </option>
                                                            <option value="NZ">New Zealand</option>
                                                            <option value="NI">Nicaragua</option>
                                                            <option value="NE">Niger</option>
                                                            <option value="NG">Nigeria</option>
                                                            <option value="NU">Niue</option>
                                                            <option value="NF">
                                                                Norfolk Island
                                                            </option>
                                                            <option value="MP">
                                                                Northern Mariana Islands
                                                            </option>
                                                            <option value="NO">Norway</option>
                                                            <option value="OM">Oman</option>
                                                            <option value="PK">Pakistan</option>
                                                            <option value="PW">Palau</option>
                                                            <option value="PS">
                                                                Palestinian Territory, Occupied
                                                            </option>
                                                            <option value="PA">Panama</option>
                                                            <option value="PG">
                                                                Papua New Guinea
                                                            </option>
                                                            <option value="PY">Paraguay</option>
                                                            <option value="PE">Peru</option>
                                                            <option value="PH">Philippines</option>
                                                            <option value="PN">Pitcairn</option>
                                                            <option value="PL">Poland</option>
                                                            <option value="PT">Portugal</option>
                                                            <option value="PR">Puerto Rico</option>
                                                            <option value="QA">Qatar</option>
                                                            <option value="RE">Reunion</option>
                                                            <option value="RO">Romania</option>
                                                            <option value="RU">
                                                                Russian Federation
                                                            </option>
                                                            <option value="RW">Rwanda</option>
                                                            <option value="BL">
                                                                Saint Barthelemy
                                                            </option>
                                                            <option value="SH">Saint Helena</option>
                                                            <option value="KN">
                                                                Saint Kitts and Nevis
                                                            </option>
                                                            <option value="LC">Saint Lucia</option>
                                                            <option value="MF">Saint Martin</option>
                                                            <option value="PM">
                                                                Saint Pierre and Miquelon
                                                            </option>
                                                            <option value="VC">
                                                                Saint Vincent and the Grenadines
                                                            </option>
                                                            <option value="WS">Samoa</option>
                                                            <option value="SM">San Marino</option>
                                                            <option value="ST">
                                                                Sao Tome and Principe
                                                            </option>
                                                            <option value="SA">Saudi Arabia</option>
                                                            <option value="SN">Senegal</option>
                                                            <option value="RS">Serbia</option>
                                                            <option value="CS">
                                                                Serbia and Montenegro
                                                            </option>
                                                            <option value="SC">Seychelles</option>
                                                            <option value="SL">Sierra Leone</option>
                                                            <option value="SG">Singapore</option>
                                                            <option value="SX">Sint Maarten</option>
                                                            <option value="SK">Slovakia</option>
                                                            <option value="SI">Slovenia</option>
                                                            <option value="SB">
                                                                Solomon Islands
                                                            </option>
                                                            <option value="SO">Somalia</option>
                                                            <option value="ZA">South Africa</option>
                                                            <option value="GS">
                                                                South Georgia and the South Sandwich
                                                                Islands
                                                            </option>
                                                            <option value="SS">South Sudan</option>
                                                            <option value="ES">Spain</option>
                                                            <option value="LK">Sri Lanka</option>
                                                            <option value="SD">Sudan</option>
                                                            <option value="SR">Suriname</option>
                                                            <option value="SJ">
                                                                Svalbard and Jan Mayen
                                                            </option>
                                                            <option value="SZ">Swaziland</option>
                                                            <option value="SE">Sweden</option>
                                                            <option value="CH">Switzerland</option>
                                                            <option value="SY">
                                                                Syrian Arab Republic
                                                            </option>
                                                            <option value="TW">
                                                                Taiwan, Province of China
                                                            </option>
                                                            <option value="TJ">Tajikistan</option>
                                                            <option value="TZ">
                                                                Tanzania, United Republic of
                                                            </option>
                                                            <option value="TH">Thailand</option>
                                                            <option value="TL">Timor-Leste</option>
                                                            <option value="TG">Togo</option>
                                                            <option value="TK">Tokelau</option>
                                                            <option value="TO">Tonga</option>
                                                            <option value="TT">
                                                                Trinidad and Tobago
                                                            </option>
                                                            <option value="TN">Tunisia</option>
                                                            <option value="TR">Turkey</option>
                                                            <option value="TM">Turkmenistan</option>
                                                            <option value="TC">
                                                                Turks and Caicos Islands
                                                            </option>
                                                            <option value="TV">Tuvalu</option>
                                                            <option value="UG">Uganda</option>
                                                            <option value="UA">Ukraine</option>
                                                            <option value="AE">
                                                                United Arab Emirates
                                                            </option>
                                                            <option value="GB">
                                                                United Kingdom
                                                            </option>
                                                            <option value="UM">
                                                                United States Minor Outlying Islands
                                                            </option>
                                                            <option value="UY">Uruguay</option>
                                                            <option value="UZ">Uzbekistan</option>
                                                            <option value="VU">Vanuatu</option>
                                                            <option value="VE">Venezuela</option>
                                                            <option value="VN">Viet Nam</option>
                                                            <option value="VG">
                                                                Virgin Islands, British
                                                            </option>
                                                            <option value="VI">
                                                                Virgin Islands, U.s.
                                                            </option>
                                                            <option value="WF">
                                                                Wallis and Futuna
                                                            </option>
                                                            <option value="EH">
                                                                Western Sahara
                                                            </option>
                                                            <option value="YE">Yemen</option>
                                                            <option value="ZM">Zambia</option>
                                                            <option value="ZW">Zimbabwe</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label>Social Security Number / TIN</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            name="taxIdNum"
                                                            onChange={(e) =>
                                                                setBeneTaxId(e.target.value)
                                                            }
                                                            value={beneTaxId}
                                                            aria-label="Beneficiary Tax ID Number"
                                                        ></input>
                                                    </div>
                                                    <div>
                                                        <label>Phone Number</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            name="phone"
                                                            onChange={(e) =>
                                                                setBenePhone(e.target.value)
                                                            }
                                                            value={benePhone}
                                                            aria-label="Beneficiary Phone Number"
                                                        ></input>
                                                    </div>
                                                    <div>
                                                        <label>Email Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            name="email"
                                                            onChange={(e) =>
                                                                setBeneEmail(e.target.value)
                                                            }
                                                            value={beneEmail}
                                                            style={emailError ? errorStyle : {}}
                                                            onKeyPress={handleEnterKeyPress}
                                                            aria-label="Beneficiary Email"
                                                        ></input>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 10, paddingBottom: 0 }}
                                        >
                                            <div className="col-12">
                                                <div className="row">
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "100px"
                                                        }}
                                                    >
                                                        <input
                                                            type="button"
                                                            value="Save"
                                                            className="btn btn-Primary"
                                                            onClick={() =>
                                                                addBeneficiarySave(false)
                                                            }
                                                            style={{ width: "90px" }}
                                                            aria-label="Save button"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "195px"
                                                        }}
                                                    >
                                                        <input
                                                            type="button"
                                                            value="Save and Add Another"
                                                            className="btn btn-Primary"
                                                            onClick={() => addBeneficiarySave(true)}
                                                            aria-label="Save and add another beneficiary"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            float: "left",
                                                            width: "100px"
                                                        }}
                                                    >
                                                        <input
                                                            type="button"
                                                            className="btn btn-Primary"
                                                            onClick={onAddCancel}
                                                            value="Cancel"
                                                            style={{ width: "90px" }}
                                                            aria-label="Cancel button"
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <section aria-labelledby="beneficiary-change-section">
                <h1 style={{ display: "none" }}>Beneficiary Change Error</h1>
                <h2 style={{ display: "none" }} aria-labelledby="beneficiary-change-section">
                    Beneficiary Change Section
                </h2>
                <div
                    className="d-flex flex-column min-vh-100 justify-content-center align-items-center"
                    style={{ color: "red" }}
                >
                    <div style={{ backgroundColor: "#E8E7E7" }}>
                        <div className="row">
                            <div className="col-12 text-danger" aria-live="assertive" role="alert">
                                {errorMessage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="button"
                                    value="OK"
                                    className="btn btn-Primary"
                                    onClick={() => setErrorMessage("")}
                                    aria-label="OK button"
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else if (isProcessing) {
        return (
            <section aria-labelledby="beneficiary-change-section">
                <h1 style={{ display: "none" }}>Beneficiary Change Spinner</h1>
                <h2 style={{ display: "none" }} aria-labelledby="beneficiary-change-section">
                    Beneficiary Change Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    } else if (!cpContext.isCustomer) {
        return (
            <section aria-labelledby="beneficiary-change-section">
                <h1 style={{ display: "none" }}>Beneficiary Change Cannot Continue</h1>
                <h2 style={{ display: "none" }} aria-labelledby="beneficiary-change-section">
                    Beneficiary Change Section
                </h2>
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                    Must be a customer to continue.
                </div>
            </section>
        );
    } else {
        return (
            <section aria-labelledby="beneficiary-change-section">
                <h2 style={{ display: "none" }} aria-labelledby="beneficiary-change-section">
                    Beneficiary Change Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    }
};

export default BeneficiaryChange;
/*eslint-enable*/

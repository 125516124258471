import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { config } from "../../config";
import { CPContext } from "../../_helpers/CPContext";
import PolicyPaymentDetails from "_components/policy/PolicyPaymentDetails";
import FormDisclaimer from "./FormDisclaimer";
import Header from "_components/shared/Header";
import useFetch from "_helpers/useFetch";
import useUtilities from "_helpers/useUtilities";

const ChangeBankRoutingAccount: React.FC = () => {
    const params = useParams<propType>();
    let idParsed = 0;
    let _policyData = {} as Policy;
    const navigate = useHistory();

    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
        console.log(params.id);
    }

    const { authState, oktaAuth } = useOktaAuth();
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessingDetails, setIsProcessingDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { get } = useFetch();
    const utilities = useUtilities();
    const cpContext = useContext(CPContext);
    const [formData, setFormData] = useState<PolicyForm>();

    const getPolicy = async () => {
        try {
            if (!isProcessingDetails) {
                setIsProcessingDetails(true);
                if (policyData && policyData.summary) {
                    //do nothing, we have data already
                } else {
                    _policyData = await get<Policy>("/policy/" + idParsed, "");
                    if (_policyData) {
                        //Determine if this policy can be shown the one-time payment link
                        _policyData.onlinePaymentAllowed = await utilities.validatePolicyForPayment(
                            idParsed
                        );
                    }
                    setPolicyData(_policyData);
                }

                setIsProcessingDetails(false);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading your policy information, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                );
                setIsProcessingDetails(false);
            }
        }
    };

    useEffect(() => {
        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            //Policy Data is needed for just about everything so that will be the catalyst to load
            getPolicy();
            getForm();
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext]);

    //Move to utilities later....
    const downloadPolicyForm = async (_id: number, _name: string) => {
        try {
            const body: fileDownloadRequest = {
                id: _id,
                policyDWKey: idParsed,
                ignorePreviousDisclosure: false
            };

            let formattedName = _name;
            if (formattedName && formattedName.length > 0) {
                formattedName = formattedName.replaceAll(" ", "");
            } else {
                formattedName = "file";
            }

            const requestOptionsBodyAuth = {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
                }
            };

            await fetch(
                config.resourceServer.apiUrl + "/policyform/download",
                requestOptionsBodyAuth
            )
                .then((response) => response.blob())
                .then((response) => {
                    if (response.type == "application/problem+json") {
                        setErrorMessage("There was a problem loading the selected document.");
                    } else {
                        setErrorMessage("");
                        const url = window.URL.createObjectURL(new Blob([response]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.target = "_blank";
                        link.setAttribute("download", formattedName + ".pdf"); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch((error) => {
                    setErrorMessage("There was a problem loading the selected document.");
                });
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage("An unexpected error has occured.");
            }
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (formData?.resourceVersionFileItemId && formData?.formName) {
            await downloadPolicyForm(formData?.resourceVersionFileItemId, formData?.formName);
        }
    };

    const getForm = async () => {
        try {
            const formFromServer = await get<PolicyForm>(
                "/policyform/getformdata?policyDWKey=" + idParsed + "&formNumber=" + "AF55019",
                ""
            );
            if (formFromServer) {
                setFormData(formFromServer);
            } else {
                setErrorMessage(
                    "There was a problem loading the selected form, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                );
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading the selected form, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                );
            }
        }
    };

    return (
        <div className="row" style={{ backgroundColor: "#eee" }}>
            <div className="col-12">
                <div className="row">
                    <Header title="Payments" description="Bank Routing/Account Change" policyData={policyData} />
                </div>
            </div>
            <div className="row pt-4">
                {policyData && policyData.summary && (
                    <div className="col-11">
                        <PolicyPaymentDetails showDraftText={true} />
                    </div>
                )}
            </div>
            <div className="col-12 manage-my-payments-border">
                <h1 className="custom-h1 skip-to" style={{ paddingTop: 10 }}>
                    Bank Draft Authorization Form Download
                </h1>
            </div>
            <FormDisclaimer formName="Bank Draft Authorization Form" />
            <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                <div className="col-12">
                    <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                        <>
                            <div className="col-4 col-xl-2 col-lg-2 col-md-3 col-sm-4">
                                <input
                                    type="button"
                                    value="Download"
                                    className="btn btn-Primary"
                                    onClick={onSubmit}
                                    aria-label="Download button"
                                ></input>
                            </div>
                            <div className="col-4">
                                <input
                                    type="button"
                                    className="btn btn-Primary"
                                    onClick={() =>
                                        navigate.push(
                                            "/policy-detail/" + idParsed + "?tab=payments"
                                        )
                                    }
                                    value="Cancel"
                                    aria-label="Cancel button"
                                ></input>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ChangeBankRoutingAccount;

import React from "react";
import "../../styles/policyPayments.css";
import useUtilities from "_helpers/useUtilities";

const PolicyFinancialActivity: React.FC<{
    policyData: Policy;
    activityTypes: string[];
    showLastSixMonthsOrDefault?: boolean;
}> = (params) => {
    const utilities = useUtilities();
    let desiredFinancialActivityTypesToDisplayList: PolicyFinancialActivity[] = [];

    if (
        params.policyData &&
        params.policyData.financial &&
        params.policyData.financial.financialActivity
    ) {
        if (params.activityTypes.length > 0) {
            desiredFinancialActivityTypesToDisplayList =
                params.policyData.financial.financialActivity.filter((x) =>
                    params.activityTypes.includes(x.activityType)
                );
        } else {
            desiredFinancialActivityTypesToDisplayList =
                params.policyData.financial.financialActivity;
        }

        // If "showLastSixMonths" is true, but policy billing mode isn't "monthly", default to show 13 months of payment data per acitivy type(s) requested.
        const limitToSixMonths =
            !!params.showLastSixMonthsOrDefault &&
            params.showLastSixMonthsOrDefault === true &&
            params.policyData.summary &&
            (params.policyData.summary.billingMode ?? "").toLocaleLowerCase().includes("month");

        const targetDate: Date = new Date();

        targetDate.setMonth(targetDate.getMonth() - (limitToSixMonths ? 6 : 13));

        desiredFinancialActivityTypesToDisplayList =
            desiredFinancialActivityTypesToDisplayList.filter(
                (x) => utilities.parseDate(x.effectiveDate) > targetDate
            );
    }

    function GetFinancialActivityData() {
        return (
            <>
                {desiredFinancialActivityTypesToDisplayList.length > 0 ? (
                    <>
                        {desiredFinancialActivityTypesToDisplayList.map((f, idx) => (
                            <div
                                className="row"
                                style={{
                                    paddingTop: 5,
                                    paddingBottom: 5
                                }}
                                key={idx}
                            >
                                <div
                                    className="col-4 fieldValue px-0"
                                    title="Date the payment was applied."
                                    tabIndex={0}
                                >
                                    {utilities.parseAndFormatDate(f.effectiveDate)}
                                </div>
                                <div
                                    className="col-5 col-sm-4 fieldValue px-0"
                                    title="Type of payment received (premium,loan)."
                                    tabIndex={0}
                                >
                                    {f.activityType}
                                </div>
                                <div
                                    className="col-3 col-sm-4 fieldValue text-end px-0"
                                    title="Amount of payment applied."
                                    tabIndex={0}
                                >
                                    {f.activityAmount < 0 ? (
                                        <span
                                            style={{
                                                color: "black"
                                            }}
                                        >
                                            {utilities.formatCurrency(f.activityAmount)}
                                        </span>
                                    ) : (
                                        <>{utilities.formatCurrency(f.activityAmount)}</>
                                    )}
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        <label tabIndex={0}>No Activity</label>
                    </>
                )}
            </>
        );
    }

    return (
        <>
            <div className="card-body px-1 px-sm-3 py-0 financialActivityCardBody">
                <div
                    className="row"
                    style={{
                        paddingTop: 5,
                        paddingBottom: 5
                    }}
                >
                    <div
                        className="col-4 fieldLabel px-0"
                        title="Date the payment was applied."
                        tabIndex={0}
                    >
                        Effective Date
                    </div>
                    <div
                        className="col-4 fieldLabel px-0"
                        title="Type of payment received (premium,loan)."
                        tabIndex={0}
                    >
                        Type
                    </div>
                    <div
                        className="col-4 fieldLabel text-end px-0"
                        title="Amount of payment applied."
                        tabIndex={0}
                    >
                        Amount
                    </div>
                </div>
                {GetFinancialActivityData()}
            </div>
        </>
    );
};

export default PolicyFinancialActivity;

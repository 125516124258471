import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import useFetch from "../../_helpers/useFetch";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../shared/Common";
import useLogger from "../../_helpers/useLogger";
import useUtilities from "../../_helpers/useUtilities";

type propType = {
    id: string;
};

const BankDraftDateChange: React.FC = () => {
    const params = useParams<propType>();
    let idParsed = 0;
    const navigate = useHistory();

    if ( params.id && params.id.trim()) {
        idParsed = +params.id;
    }

    const cpContext = useContext(CPContext);
    const { authState, oktaAuth } = useOktaAuth();
    const logger = useLogger();
    const utilities = useUtilities();
    const { get, post } = useFetch();
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessingPolicy, setIsProcessingPolicy] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [validationErrorMessage, setValidationErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const [stepNumber, setStepNumber] = useState(1);
    const [isOneTimeChecked, setIsOneTimeChecked] = useState<boolean>();
    const [newDraftDate, setNewDraftDate] = useState("");
    const [newDraftDateAdjusted, setNewDraftDateAdjusted] = useState<Date>(new Date());
    const [currentDraftDate, setCurrentDraftDate] = useState<Date>(new Date());
    const [nextDraftDate, setNextDraftDate] = useState<Date>(new Date());
    const [paidToDate, setPaidToDate] = useState<Date>(new Date());
    const [isNextProcessing, setIsNextProcessing] = useState(false);
    const [newDraftDateError, setNewDraftDateError] = useState(false);
    const [esignChecked, setEsignChecked] = useState<boolean>(false);
    const [draftChangeChecked, setDraftChangeChecked] = useState<boolean>(false);
    const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
    const [minNewDraftDate, setMinNewDraftDate] = useState<Date>(new Date());
    const [maxNewDraftDate, setMaxNewDraftDate] = useState<Date>(new Date());
    const [electronicNotifyDecline, setElectronicNotifyDecline] = useState(false);
    const [notifyEmailAddress, setNotifyEmailAddress] = useState("");

    const errorStyle = {
        color: "#dc3545",
        borderColor: "#dc3545"
    };

    const clearErrorFlagsAndText = () => {
        setNewDraftDateError(false);

        setValidationErrorMessage("");
        setErrorMessage("");
        setInfoMessage("");
    };

    const onNext = async () => {
        clearErrorFlagsAndText();
        let validationError = "";
        let minimumNewDraftDate = new Date();
        minimumNewDraftDate = new Date();
        // if (policyData?.summary.premiumDate) {
        //     minimumNewDraftDate = utilities.parseDate(policyData?.summary.premiumDate)
        // } else {
        //     minimumNewDraftDate.setDate(minimumNewDraftDate.getDate() + 5)
        // }
        minimumNewDraftDate.setDate(currentDraftDate.getDate() + 5);
        minimumNewDraftDate.setDate(currentDraftDate.getDate() + 5);
        setIsNextProcessing(true);

        if (stepNumber === 1) {
            //Validate
            let newDraftDateParsed = new Date();

            console.log("onNext newDraftDate=" + newDraftDate);
            if (newDraftDate && newDraftDate.length > 0) {
                newDraftDateParsed = utilities.parseDateCustom(newDraftDate, "yyyy-MM-dd");
            }

            if (newDraftDateParsed > maxNewDraftDate && !isOneTimeChecked) {
                validationError =
                    "Selected date is outside the 10 days from your original due date, please choose a different date or contact customer service at 800-231-0801.";
            }

            if (newDraftDateParsed > maxNewDraftDate && isOneTimeChecked) {
                validationError =
                    "One time draft must be before your next draft date, please choose a different date or contact customer service at 800-231-0801.";
            }

            // if (newDraftDateParsed <= minNewDraftDate && !isOneTimeChecked) {
            //     validationError = 'We require 5 business day advance notice to make changes.  Your draft for the current month may already be in process, therefore any changes made today will not go into affect until the following month. Please contact Customer Service at 800-231-0801 if you would like the change to go in affect immediately.'
            // }

            // if (newDraftDateParsed <= minNewDraftDate && isOneTimeChecked) {
            //     validationError = 'We require 5 business day advance notice to make changes.  Your draft for the current month may already be in process, therefore any changes made today will not go into affect until the following month. Please contact Customer Service at 800-231-0801 if you would like the change to go in affect immediately.'
            // }

            if (
                (newDraftDateParsed.getDate() == 29 ||
                    newDraftDateParsed.getDate() == 30 ||
                    newDraftDateParsed.getDate() == 31) &&
                !isOneTimeChecked
            ) {
                validationError =
                    "Selected date falls on unavailable draft days due to Americo policy, please choose a different date or contact customer service at 800-231-0801.";
            }

            if (!newDraftDate || newDraftDate.length <= 0) {
                validationError = "Invalid date. Please enter in a valid date.";
            } else {
                const newDraftDateAdjustedTemp = utilities.parseDateCustom(
                    newDraftDate,
                    "yyyy-MM-dd"
                );
                //This is for when the upcoming draft date is within 5 days, we need to adjust to the next billing mode (monthly,quarterly,semi-annual,annual)
                if (policyData?.summary.billingMode == "Monthly") {
                    newDraftDateAdjustedTemp.setDate(newDraftDateAdjustedTemp.getDate() + 30);
                } else if (policyData?.summary.billingMode == "Quarterly") {
                    newDraftDateAdjustedTemp.setDate(newDraftDateAdjustedTemp.getDate() + 90);
                } else if (policyData?.summary.billingMode == "Semi-Annual (twice a year)") {
                    newDraftDateAdjustedTemp.setDate(newDraftDateAdjustedTemp.getDate() + 182);
                } else if (policyData?.summary.billingMode == "Annual") {
                    newDraftDateAdjustedTemp.setDate(newDraftDateAdjustedTemp.getDate() + 365);
                }
                setNewDraftDateAdjusted(newDraftDateAdjustedTemp);
            }

            await logger.logDetailedActivity(
                "BankDraftDateChange",
                "add new date",
                cpContext,
                cpContext.userInfo?.email,
                cpContext.sessionId,
                "",
                idParsed
            );
        }
        if (stepNumber === 2) {
            await logger.logDetailedActivity(
                "BankDraftDateChange",
                "submit esign",
                cpContext,
                cpContext.userInfo?.email,
                cpContext.sessionId,
                "",
                idParsed
            );
            if (!esignChecked) {
                validationError = "Please ensure electronic signature box is checked to continue.";
            }
        }

        if (validationError && validationError.length > 0) {
            setValidationErrorMessage(validationError);
            setNewDraftDateError(true);
            setIsNextProcessing(false);
            return;
        } else {
            setValidationErrorMessage(validationError);
            setIsNextProcessing(false);
            setStepNumber(stepNumber + 1);
        }
    };

    const onBack = async () => {
        clearErrorFlagsAndText();
        setStepNumber(stepNumber - 1);
    };

    const processBankDraftChange = async () => {
        try {
            const body: PolicyBankDraftChange = {
                policyDWKey: idParsed,
                electronicSignatureAgreement: true,
                electronicSignatureName: "",
                notifyEmailAddress: notifyEmailAddress,
                declineElectronicNotification: electronicNotifyDecline,
                requestId: 0,
                currenDraftDate: currentDraftDate.toDateString(),
                isOneTime: isOneTimeChecked ?? false,
                newDraftDate: newDraftDate
            };

            return await post<PolicyBankDraftChange, boolean>(
                "/policy/bankdraftdatechange/",
                "",
                body
            );
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        clearErrorFlagsAndText();
        let validationError = "";
        setIsSubmitProcessing(true);

        if (!cpContext.isCustomer && !notifyEmailAddress && !electronicNotifyDecline) {
            validationError =
                "Please ensure either an email is entered or the decline box is checked.";
        }

        if (cpContext.isCustomer && (!esignChecked || !draftChangeChecked)) {
            validationError =
                "Please ensure you agree to draft change, eSignature and have checked the box for each.";
        }

        if (validationError && validationError.length > 0) {
            setValidationErrorMessage(validationError);
            setIsSubmitProcessing(false);
            return;
        } else {
            const result = await processBankDraftChange();

            if (result) {
                setStepNumber(0);
                setRequestSubmitted(true);
                setValidationErrorMessage("");
            } else {
                setStepNumber(2);
                setValidationErrorMessage(
                    "There was a problem submitting your request, please try again."
                );
            }
        }

        setIsSubmitProcessing(false);
    };

    const handleOneTimeCheck = async (
        _isOneTime: boolean,
        _nextDraftDate: Date,
        _currentDraftDate: Date
    ) => {
        setIsOneTimeChecked(_isOneTime);

        console.log(
            "handleOneTimeCheck _nextDraftDate=" +
                _nextDraftDate +
                "; _currentDraftDate=" +
                _currentDraftDate
        );

        if (_isOneTime) {
            const tempMinDate = new Date();
            const tempMaxDate = new Date();

            //Rule: one time payment can be up to 28 days from the current draft date
            tempMinDate.setDate(tempMinDate.getDate() + 1);
            tempMaxDate.setDate(tempMaxDate.getDate() + 28);

            //Rule: one time payment cannot be after current draft date
            if (tempMaxDate >= _nextDraftDate) {
                tempMaxDate.setDate(new Date(_nextDraftDate.getTime()).getDate());
            }

            setMinNewDraftDate(tempMinDate);
            setMaxNewDraftDate(tempMaxDate);
        } else {
            const tempMinDate = new Date();
            console.log("handleOneTimeCheck currentDraftDate " + _currentDraftDate.toISOString());
            const tempMaxDate = new Date(_currentDraftDate.getTime());

            //Rule: require 5 days from today for bank draft change
            tempMinDate.setDate(tempMinDate.getDate() + 5);

            //Rule: No more than 10 days from the currentDraftDate
            tempMaxDate.setDate(tempMaxDate.getDate() + 10);

            setMinNewDraftDate(tempMinDate);
            setMaxNewDraftDate(tempMaxDate);
        }

        console.log("handleOneTimeCheck minNewDraftDate " + minNewDraftDate.toISOString());
        console.log(
            "handleOneTimeCheck maxNewDraftDate " + maxNewDraftDate.toISOString().slice(0, 10)
        );
    };

    const fetchPolicy = async (_id: number) => {
        if (!isProcessingPolicy) {
            try {
                if (policyData && policyData.summary) {
                    //nothing
                } else {
                    setIsProcessingPolicy(true);
                    const result = await get<Policy>("/policy/full?policyDWKey=" + _id, "");

                    setIsProcessingPolicy(false);
                    return result;
                }
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    setErrorMessage("An unexpected error has occured.");
                    setIsProcessingPolicy(false);
                }
            }
        }
    };

    const LogggedNavigate = async (_uri: string, _linkName: string) => {
        clearErrorFlagsAndText();
        await logger.logDetailedActivity(
            "BankDraftDateChange",
            _linkName,
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );

        navigate.push(_uri);
    };

    useEffect(() => {
        const getPolicy = async () => {
            try {
                setIsProcessing(true);
                const policyFromServer = await fetchPolicy(idParsed);
                setPolicyData(policyFromServer);

                if (policyFromServer) {
                    setCurrentDraftDate(
                        utilities.parseDate(policyFromServer.summary.currentDraftDate)
                    );
                    setNextDraftDate(utilities.parseDate(policyFromServer.summary.nextDraftDate));
                    setPaidToDate(utilities.parseDate(policyFromServer.summary.currentDraftDate));

                    handleOneTimeCheck(
                        false,
                        utilities.parseDate(policyFromServer.summary.nextDraftDate),
                        utilities.parseDate(policyFromServer.summary.currentDraftDate)
                    );

                    if (cpContext.isHO) {
                        setNotifyEmailAddress("");
                    } else if (!cpContext.isCustomer) {
                        if (cpContext.impEmail?.length > 0) {
                            setNotifyEmailAddress(cpContext.impEmail);
                        } else {
                            setNotifyEmailAddress(policyFromServer?.client.ownerEmail ?? "");
                        }
                    } else {
                        setNotifyEmailAddress(cpContext.userInfo?.email);
                    }
                }

                setIsProcessing(false);
            } catch {
                setErrorMessage(
                    "There was a problem loading your policy information, please call us at 800-231-0801 for assistance."
                );
                setIsProcessing(false);
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getPolicy();
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext, navigate]); // Update if authState changes

    if (policyData && policyData.summary) {
        return (
            <section aria-labelledby="bank-draft-date-change-section">
                <h2 aria-labelledby="bank-draft-date-change-section" style={{ display: "none" }}>
                    Bank Draft Date Change Section
                </h2>
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-md-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-6">
                                <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                    Bank Draft Date Change
                                </h1>
                            </div>
                            <div className="col-6">
                                <div style={{ paddingTop: 10 }}>
                                    {policyData?.policyNumber} - {policyData.product}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <div>
                                    {validationErrorMessage && validationErrorMessage.length > 0 ? (
                                        <>
                                            <label
                                                className="text-danger"
                                                aria-live="assertive"
                                                role="alert"
                                            >
                                                {validationErrorMessage}
                                                <br />
                                                <br />
                                            </label>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {infoMessage && infoMessage.length > 0 ? (
                                        <>
                                            <label style={{ color: "green" }}>
                                                {infoMessage}
                                                <br />
                                                <br />
                                            </label>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                        {stepNumber === 1 ? (
                            <>
                                <div className="row" style={{ paddingTop: 5 }}>
                                    <div
                                        className="col-3"
                                        style={{
                                            fontFamily: "PT Sans Bold",
                                            fontSize: "20px",
                                            paddingBottom: 15
                                        }}
                                    >
                                        <b></b>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 5 }}>
                                    <div className="col-3">Premium Due Date:&nbsp;</div>
                                    <div className="col-3">{paidToDate.toLocaleDateString()}</div>
                                </div>
                                <div className="row" style={{ paddingTop: 5 }}>
                                    <div className="col-3">Draft Amount:&nbsp;</div>
                                    <div className="col-3">
                                        {utilities.formatCurrency(policyData.summary.premiumAmount)}
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 5 }}>
                                    <div
                                        className="col-3"
                                        title="Approved frequency of premium payments i.e.: monthly, quarterly, annually etc."
                                    >
                                        Billing Mode:&nbsp;
                                    </div>
                                    <div
                                        className="col-3"
                                        title="Approved frequency of premium payments i.e.: monthly, quarterly, annually etc."
                                    >
                                        {policyData.summary.billingMode}
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 5 }}>
                                    <div className="col-3">Current Draft Date:&nbsp;</div>
                                    <div className="col-3">
                                        {currentDraftDate.toLocaleDateString()}
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 5 }}>
                                    <div className="col-3">New Draft Date:&nbsp;</div>
                                    <div className="col-3">
                                        <input
                                            type="date"
                                            className="form-control skip-to"
                                            placeholder="mm/dd/yyyy"
                                            value={newDraftDate}
                                            onChange={(e) => setNewDraftDate(e.target.value)}
                                            min={
                                                minNewDraftDate
                                                    ? minNewDraftDate.toISOString().slice(0, 10)
                                                    : ""
                                            }
                                            max={
                                                maxNewDraftDate
                                                    ? maxNewDraftDate.toISOString().slice(0, 10)
                                                    : ""
                                            }
                                            style={newDraftDateError ? errorStyle : {}}
                                            aria-label="New Draft Date"
                                            aria-live={newDraftDateError ? "assertive" : "off"}
                                            role={newDraftDateError ? "alert" : ""}
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingBottom: 15 }}>
                                    <div className="col-3">&nbsp;</div>
                                    <div className="col-4">
                                        <span>
                                            If the draft date you selected falls on a Holiday or
                                            weekend, your draft will be processed on the next
                                            business day.
                                        </span>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingBottom: 15 }}>
                                    <div className="col-3">&nbsp;</div>
                                    <div className="col-4">
                                        {policyData.daysUntilNextDraft < 5 ? (
                                            <>
                                                <span style={{ color: "red" }}>
                                                    We require 5 business day advance notice to make
                                                    changes. Your draft for the current month may
                                                    already be in process, therefore any changes
                                                    made today will not go into effect until the
                                                    following month. Please contact Customer Service
                                                    at 800-231-0801 if you would like the change to
                                                    go in effect immediately.
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <input
                                                    type="checkbox"
                                                    checked={isOneTimeChecked}
                                                    value={isOneTimeChecked?.toString()}
                                                    onChange={() =>
                                                        handleOneTimeCheck(
                                                            true,
                                                            nextDraftDate,
                                                            currentDraftDate
                                                        )
                                                    }
                                                    className="OneTimeCheck"
                                                    aria-label="One Time Payment Checkbox"
                                                ></input>
                                                &nbsp; One-time Draft&nbsp;&nbsp;&nbsp;
                                                <input
                                                    type="checkbox"
                                                    checked={!isOneTimeChecked}
                                                    value={isOneTimeChecked?.toString()}
                                                    onChange={() =>
                                                        handleOneTimeCheck(
                                                            false,
                                                            nextDraftDate,
                                                            currentDraftDate
                                                        )
                                                    }
                                                    className="OneTimeCheck"
                                                    aria-label="Permanent Change Checkbox"
                                                ></input>
                                                &nbsp; Permanent Change
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="row" style={{ paddingBottom: 15 }}>
                                    <div className="col-3">&nbsp;</div>
                                    <div className="col-4">
                                        {isOneTimeChecked ? (
                                            <>
                                                <p>
                                                    Your policy will draft one time on the date
                                                    specified. Future payments will resume on the
                                                    regular scheduled date.
                                                </p>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {policyData.hasActiveFIBA ? (
                                            <>
                                                <p>
                                                    <b>Note:</b> This date change will also be
                                                    applied to your related Annuity.
                                                </p>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <div style={{ display: "none" }}>
                                    <div className="row" style={{ paddingTop: 5 }}>
                                        <div className="col-3">Debug</div>
                                        <div className="col-3">
                                            currentDraftDate (Debug):&nbsp;
                                            {currentDraftDate.toLocaleDateString()}
                                            <br></br>
                                            currentDraftDate getDate (Debug):&nbsp;
                                            {currentDraftDate.getDate()}
                                            <br></br>
                                            Date getDate (Debug):&nbsp;{new Date().getDate()}
                                            <br></br>
                                            minNewDraftDate (Debug):&nbsp;
                                            {minNewDraftDate.toLocaleDateString()}
                                            <br></br>
                                            daysUntilNextDraft (Debug): &nbsp;
                                            {policyData.daysUntilNextDraft}
                                            <br></br>
                                            minNewDraftDate.getDate - currentDraftDate.getDate
                                            (Debug):&nbsp;
                                            {minNewDraftDate.getDate() - currentDraftDate.getDate()}
                                            <br></br>
                                            minNewDraftDate.getDate - currentDraftDate.getDate
                                            (Debug):&nbsp;
                                            {minNewDraftDate.getDate() -
                                                currentDraftDate.getDate() <
                                                5}
                                            <br></br>
                                            currentDraftDate.getDate - minNewDraftDate.getDate
                                            (Debug):&nbsp;
                                            {currentDraftDate.getDate() - minNewDraftDate.getDate()}
                                            <br></br>
                                            newDraftDateAdjusted (Debug):&nbsp;
                                            {newDraftDateAdjusted.toLocaleDateString()}
                                            <br></br>
                                            NextRiskPurchaseDate (Debug):&nbsp;
                                            {policyData.summary.nextRiskPurchaseDate}
                                            <br></br>
                                            NextDraftDate (Debug):&nbsp;
                                            {policyData.summary.nextDraftDate}
                                            <br></br>
                                            PremiumDate (Debug):&nbsp;
                                            {policyData.summary.premiumDate}
                                            <br></br>
                                            PolicyAsOfDate (Debug):&nbsp;
                                            {policyData.summary.policyAsOfDate}
                                            <br></br>
                                            PolicyType (Debug):&nbsp;{policyData.policyType}
                                            <br></br>
                                            minNewDraftDate (Debug):&nbsp;
                                            {minNewDraftDate.toLocaleDateString()}
                                            <br></br>
                                            maxNewDraftDate (Debug):&nbsp;
                                            {maxNewDraftDate?.toLocaleDateString()}
                                            <br></br>
                                            notifyEmailAddress (Debug):&nbsp;{notifyEmailAddress}
                                            <br></br>
                                            cpContext.impEmail (Debug):&nbsp;{cpContext.impEmail}
                                            <br></br>
                                            notifyEmailAddress (Debug):&nbsp;
                                            {policyData?.client.ownerEmail}
                                            <br></br>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {isNextProcessing ? (
                                                    <Spinner imageWidth={20} aria-label="Loading" />
                                                ) : (
                                                    <input
                                                        type="button"
                                                        value="Next"
                                                        className="btn btn-Primary"
                                                        onClick={onNext}
                                                        style={{ width: "90px" }}
                                                        aria-label="Next button"
                                                    ></input>
                                                )}
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    type="button"
                                                    className="btn btn-Primary"
                                                    onClick={() =>
                                                        LogggedNavigate(
                                                            "/policy-detail/" +
                                                                idParsed +
                                                                "?tab=service",
                                                            "Cancel"
                                                        )
                                                    }
                                                    value="Cancel"
                                                    style={{ width: "90px" }}
                                                    aria-label="Cancel button"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {stepNumber === 2 ? (
                            <div>
                                {!cpContext.isCustomer ? (
                                    <>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9">
                                                <b>Email confirmation/Electronic Signature:</b>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <label>Notify Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    onChange={(e) =>
                                                        setNotifyEmailAddress(e.target.value)
                                                    }
                                                    value={notifyEmailAddress}
                                                    autoComplete="off"
                                                    aria-label="Notify Email"
                                                ></input>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <input
                                                    type="checkbox"
                                                    checked={electronicNotifyDecline}
                                                    value={electronicNotifyDecline?.toString()}
                                                    onChange={(e) =>
                                                        setElectronicNotifyDecline(
                                                            e.currentTarget.checked
                                                        )
                                                    }
                                                    className="EsignCheck"
                                                    aria-label="E-Sign checkbox"
                                                ></input>
                                                &nbsp;By checking this box, I decline to receive
                                                confirmation via email of this change.
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9">
                                                <b>Review and Submit:</b>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                {!isOneTimeChecked ? (
                                                    <>
                                                        {policyData.daysUntilNextDraft < 5 ? (
                                                            <span style={{ fontWeight: "bold" }}>
                                                                Your new draft date will be the{" "}
                                                                {utilities
                                                                    .parseDateCustom(
                                                                        newDraftDate,
                                                                        "yyyy-MM-dd"
                                                                    )
                                                                    .getDate()}{" "}
                                                                of each month. Your next draft will
                                                                be processed on{" "}
                                                                <span style={{ color: "red" }}>
                                                                    {newDraftDateAdjusted.toLocaleDateString()}
                                                                </span>
                                                                .
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                Your new draft date will be the{" "}
                                                                {utilities
                                                                    .parseDateCustom(
                                                                        newDraftDate,
                                                                        "yyyy-MM-dd"
                                                                    )
                                                                    .getDate()}{" "}
                                                                of each month. Your next draft will
                                                                be processed on{" "}
                                                                {utilities.parseAndFormatDateCustom(
                                                                    newDraftDate,
                                                                    "yyyy-MM-dd"
                                                                )}
                                                                .
                                                            </span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span>
                                                            Your policy will draft one time on{" "}
                                                            {utilities.parseAndFormatDateCustom(
                                                                newDraftDate,
                                                                "yyyy-MM-dd"
                                                            )}
                                                            . Future payments will resume on the
                                                            regular scheduled date of{" "}
                                                            {utilities.parseAndFormatDateCustom(
                                                                nextDraftDate.toLocaleDateString(),
                                                                "MM/dd/yyyy"
                                                            )}
                                                            .
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <p>
                                                    To confirm this change, please review and agree
                                                    to the below Draft Day Change Authorization and
                                                    Electronic Signature Authorization statements:
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={draftChangeChecked}
                                                        value={draftChangeChecked?.toString()}
                                                        onChange={(e) =>
                                                            setDraftChangeChecked(
                                                                e.currentTarget.checked
                                                            )
                                                        }
                                                        className="DraftChangeChecked"
                                                        aria-label="Draft Change Checkbox"
                                                    ></input>
                                                    &nbsp; I authorize Americo and their banking
                                                    institution to pay or charge my payment method
                                                    as indicated on this request. This authorization
                                                    will remain in effect until revoked by Americo
                                                    or me. I further understand that Americo
                                                    requires a 5-business day advance notice to
                                                    setup, change, or discontinue my bank draft
                                                    information and should any draft not be honored
                                                    for the reason of “insufficient funds”, a second
                                                    attempt to draft may occur. I authorize Americo
                                                    Life, Inc., to verify the validity of the
                                                    financial institution information provided with
                                                    any third-party including, but not limited to,
                                                    any consumer reporting agency for purposes of
                                                    confirming accurate pre-draft information.
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={esignChecked}
                                                        value={esignChecked?.toString()}
                                                        onChange={(e) =>
                                                            setEsignChecked(e.currentTarget.checked)
                                                        }
                                                        className="EsignCheck"
                                                        aria-label="E-Sign Checkbox"
                                                    ></input>
                                                    &nbsp; By checking this box you agree and affirm
                                                    with full knowledge and consent that all
                                                    electronic signatures that you provide or adopt
                                                    are the legal equivalent of your handwritten
                                                    signature and your signature on this document is
                                                    as valid as if you signed the document in
                                                    writing. This acknowledgement may be used in
                                                    conjunction with the use of electronic
                                                    signatures on all forms regarding any and all
                                                    future documentation with a signature
                                                    requirement, should you elect to have signed
                                                    electronically. You have the right to withdraw
                                                    your consent at any time and receive a paper
                                                    copy at no charge by reaching out to Americo at
                                                    800.231.0801.
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col">
                                                <input
                                                    type="button"
                                                    className="btn btn-Primary"
                                                    onClick={onBack}
                                                    value="Previous"
                                                    style={{ width: "90px" }}
                                                    aria-label="Back button"
                                                ></input>
                                            </div>
                                            <div className="col">
                                                {isSubmitProcessing ? (
                                                    <Spinner imageWidth={20} aria-label="Loading" />
                                                ) : (
                                                    <>
                                                        <input
                                                            type="button"
                                                            value="Submit"
                                                            className="btn btn-Primary"
                                                            onClick={onSubmit}
                                                            style={{ width: "90px" }}
                                                        ></input>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    type="button"
                                                    className="btn btn-Primary"
                                                    onClick={() =>
                                                        LogggedNavigate(
                                                            "/policy-detail/" +
                                                                idParsed +
                                                                "?tab=service",
                                                            "Cancel"
                                                        )
                                                    }
                                                    value="Cancel"
                                                    style={{ width: "90px" }}
                                                    aria-label="Cancel button"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {requestSubmitted ? (
                            <>
                                <Card className="policyCard">
                                    <Card.Body className="shadow">
                                        <div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-9">
                                                    <b>Your request has been submitted</b>
                                                </div>
                                            </div>
                                            {cpContext.isCustomer ? (
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <p>
                                                            You will receive an email at the email
                                                            you used to log in confirming receipt of
                                                            your request.
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col">
                                                <input
                                                    type="button"
                                                    value="OK"
                                                    className="btn btn-Primary"
                                                    onClick={() =>
                                                        navigate.push(
                                                            "/policy-detail/" +
                                                                idParsed +
                                                                "?tab=service"
                                                        )
                                                    }
                                                    style={{ width: "90px" }}
                                                    aria-label="OK button"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </section>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <section aria-labelledby="bank-draft-date-change-section">
                <h1 style={{ display: "none" }}>
                    We encountered an error message with your Bank Draft Date Change request
                </h1>
                <h2 aria-labelledby="bank-draft-date-change-section">
                    Bank Draft Date Change Section
                </h2>
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-danger">
                    <div style={{ backgroundColor: "#E8E7E7" }}>
                        <div className="row">
                            <div className="col-12 text-danger" aria-live="assertive" role="alert">
                                {errorMessage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="button"
                                    value="OK"
                                    className="btn btn-Primary"
                                    onClick={() => setErrorMessage("")}
                                    aria-label="OK button"
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else if (isProcessing) {
        return (
            <section aria-labelledby="bank-draft-date-change-section">
                <h1 style={{ display: "none" }}>Bank Draft Date Change Spinner</h1>
                <h2 aria-labelledby="bank-draft-date-change-section">
                    Bank Draft Date Change Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    } else {
        return (
            <section aria-labelledby="bank-draft-date-change-section">
                <h1 style={{ display: "none" }}>Bank Draft Date Change Spinner</h1>
                <h2 aria-labelledby="bank-draft-date-change-section">
                    Bank Draft Date Change Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    }
};

export default BankDraftDateChange;

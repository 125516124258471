/* eslint-disable */

//Test/Staging/Prod (these values will be replaced by Octopus, comment out for manual or local deploy)
const API_URI = process.env.REACT_APP_API_URI as string;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;
const RECAPTCHAV2_KEY = process.env.REACT_APP_RECAPTCHAV2_KEY as string;
const RECAPTCHAV3_KEY = process.env.REACT_APP_RECAPTCHAV3_KEY as string;
const ISSUER = process.env.REACT_APP_ISSUER as string;
const ADYEN_ENV = process.env.REACT_APP_ADYEN_ENV as string;
const ADYEN_KEY = process.env.REACT_APP_ADYEN_KEY as string;

let OKTA_TESTING_DISABLEHTTPSCHECK = false;
const BASENAME = "";
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

/* eslint-enable */
const config = {
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ["openid", "profile", "email"],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK
    },
    adyen: {
        environment: ADYEN_ENV,
        clientKey: ADYEN_KEY
    },
    resourceServer: {
        apiUrl: API_URI
    },
    app: {
        basename: BASENAME,
        recaptchav2Key: RECAPTCHAV2_KEY,
        recaptchav3Key: RECAPTCHAV3_KEY,
        hideForNonDev: false
    }
};

export { config };

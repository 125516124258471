import React, { useState, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import useFetch from "../../_helpers/useFetch";
import { CPContext } from "../../_helpers/CPContext";
import useUtilities from "../../_helpers/useUtilities";
import { useHistory } from "react-router-dom";

interface SiteStatusResult {
    lastUpdated: string;
    dbHasRecords: boolean;
}

const Footer: React.FC = () => {
    const cpContext = useContext(CPContext);
    const { authState, oktaAuth } = useOktaAuth();
    const [siteStatus, setSiteStatus] = useState("");
    const navigate = useHistory();
    const { get } = useFetch();
    const { isMaintenanceError } = useUtilities();
    // eslint-disable-next-line
    const renderSiteStatus = (props: any) => <Tooltip {...props}>{siteStatus}</Tooltip>;

    const fetchSiteStatus = async () => {
        try {
            const result = await get<SiteStatusResult>(
                "/home/sitestatus",

                ""
            );
            if (result && result.lastUpdated) {
                if (cpContext.isAdmin || cpContext.isHO) {
                    setSiteStatus(
                        "Last updated: " +
                            result.lastUpdated +
                            " Policies Loaded: " +
                            result.dbHasRecords
                    );
                } else {
                    setSiteStatus("Last updated: " + result.lastUpdated);
                }
            } else {
                setSiteStatus("Last updated: NA");
            }
        } catch (e: unknown) {
            if (isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setSiteStatus("Last updated: NA");
            }
        }
    };

    const getCurrentYear = () => {
        const currentDate = new Date();
        return currentDate.getFullYear();
    };

    return (
        <footer role="contentinfo">
            <nav className="subFooter">
                <ul className="container" style={{ listStyleType: "none" }}>
                    <li className="likeBtn">
                        <a
                            href="https://www.facebook.com/AmericoLife"
                            className="info"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/assets/images/facebook.jpg" alt="like us on Facebook"></img>
                        </a>
                    </li>
                    <li className="copyright">
                        <p>
                            © 2018-{getCurrentYear()} Americo&nbsp;{" "}
                            <a href="https://www.americo.com/privacy-policy/">Privacy Policy</a>{" "}
                            <span className="divider" aria-hidden={true}>
                                |
                            </span>{" "}
                            <a href="https://www.americo.com/website-linking-agreement/">
                                Website Linking Agreement
                            </a>{" "}
                            <span className="divider" aria-hidden={true}>
                                |
                            </span>{" "}
                            <a href="https://www.americo.com/legal/">Legal</a>{" "}
                            <span className="divider" aria-hidden={true}>
                                |
                            </span>{" "}
                            <a href="https://www.americo.com/sitemap/">Sitemap</a>
                            {authState && authState.isAuthenticated ? (
                                <>
                                    &nbsp;
                                    <span className="divider" aria-hidden={true}>
                                        |
                                    </span>
                                    <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={renderSiteStatus}
                                        onEnter={fetchSiteStatus}
                                    >
                                        <span className="siteStatus" style={{ cursor: "pointer" }}>
                                            Site Status
                                        </span>
                                    </OverlayTrigger>
                                </>
                            ) : (
                                <></>
                            )}
                        </p>
                    </li>
                    <li>
                        <p>
                            Americo is the brand name for insurance products issued by the
                            subsidiary insurance companies controlled by Americo Life, Inc. Products
                            are underwritten by Americo Financial Life and Annuity Insurance Company
                            (AFL) or Great Southern Life Insurance Company (GSL), Kansas City, MO,
                            and may vary in accordance with state laws. Not all policies and
                            benefits are available in every state. AFL is authorized to conduct life
                            insurance business in the District of Columbia and all states except NY,
                            and health insurance business in the District of Columbia and all states
                            except CT, ME, and NY. GSL is authorized to conduct health insurance
                            business in the District of Columbia and all states except NJ, NY, and
                            VT.
                        </p>
                    </li>
                    <li>
                        <p>
                            Americo Financial Life and Annuity Insurance Co. (formerly The College
                            Life Insurance Company of America) • Great Southern Life Insurance Co. •
                            United Fidelity Life Insurance Co. • National Farmers Union Life
                            Insurance Co. • Investors Life Insurance Company of North America •
                            Companies Administered by the Americo Group of Companies: Protective
                            Life Ins. Co. (formerly Ohio Life Ins.) • Berkley Life and Health Ins.
                            Co. (formerly Investors Guaranty Life) • First Health Life & Health
                            Insurance Co. (formerly Loyalty Life Ins. Co.) • Renaissance Life &
                            Health Insurance Company of America (formerly Central National Life of
                            Omaha) • Pavonia Life Insurance Co. of NY (formerly First Central
                            National Life of New York) • Wilcac Life Ins Co. (formerly Conseco Life
                            Ins. Co./ Massachusetts General Life Ins. Co.) • Life Insurance Company
                            of North America • Athene Annuity and Life Company (formerly American
                            Investors Life) • The Ohio State Life Insurance Co. • Upstream Life
                            Insurance Co. (formerly Financial Assurance Life Insurance Co.)
                        </p>
                    </li>
                </ul>
            </nav>
        </footer>
    );
};

export default Footer;

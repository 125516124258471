import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import PolicyPaymentDetails from "_components/policy/PolicyPaymentDetails";
import Header from "_components/shared/Header";
import { PaymentsPages } from "../../enums/PaymentsPages";
import useUtilities from "_helpers/useUtilities";
import useFetch from "_helpers/useFetch";
import useLogger from "_helpers/useLogger";

const ChangeBankDraftAmount: React.FC = () => {
    const params = useParams<propType>();
    const utilities = useUtilities();
    const logger = useLogger();
    const { get } = useFetch();
    let idParsed = 0;
    let _policyData = {} as Policy;
    const navigate = useHistory();

    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
    }

    const { authState, oktaAuth } = useOktaAuth();
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessingDetails, setIsProcessingDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const cpContext = useContext(CPContext);

    const getPolicy = async () => {
        try {
            if (!isProcessingDetails) {
                setIsProcessingDetails(true);
                if (policyData && policyData.summary) {
                    //do nothing, we have data already
                } else {
                    _policyData = await get<Policy>("/policy/" + idParsed, "");
                    if (_policyData) {
                        //Determine if this policy can be shown the one-time payment link
                        _policyData.onlinePaymentAllowed = await utilities.validatePolicyForPayment(
                            idParsed
                        );
                    }
                    setPolicyData(_policyData);
                }

                setIsProcessingDetails(false);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading your policy information, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                );
                setIsProcessingDetails(false);
            }
        }
    };

    useEffect(() => {
        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            //Policy Data is needed for just about everything so that will be the catalyst to load
            getPolicy();
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext]);

    return (
        <div className="row" style={{ backgroundColor: "#eee" }}>
            <Header
                title="Payments"
                description="Change Bank Draft Amount"
                policyData={policyData}
            />
            <div className="pt-4 pb-2 d-inline">
                <PolicyPaymentDetails showDraftText={true} />
            </div>
            <Card className="mt-2 policyCard">
                <Card.Body>
                    <div className="row mt-3">
                        <p>
                            If you wish to make a change to your bank draft amount, please contact
                            our Customer Service Department at 1-800-366-6100.
                        </p>
                    </div>
                </Card.Body>
            </Card>
            <div className="row mt-2">
                <div className="col-4 col-xl-2 col-lg-2 col-md-3 col-sm-4 px-0">
                    <input
                        type="button"
                        className="btn btn-Primary"
                        onClick={() => {
                            logger.loggedRequestNavigate(
                                `/policy-detail/${idParsed}?tab=payments`,
                                PaymentsPages.ChangeBankDraftAmount,
                                "",
                                "",
                                false,
                                policyData!
                            );
                        }}
                        value="Cancel"
                        aria-label="Cancel button"
                    />
                </div>
            </div>
        </div>
    );
};

export default ChangeBankDraftAmount;

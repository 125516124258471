import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const Maintenance: React.FC = () => {
    const navigate = useHistory();
    const timeoutCount = 60000;

    useEffect(() => {
        const intervalId = setInterval(() => {
            console.log("timeout hit");
            navigate.push("/landingpage");
        }, timeoutCount);

        return () => {
            console.log("clear because return");
            clearInterval(intervalId);
        };
    });

    return (
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
            <Card body className="home-card">
                <div>
                    <h3>Site is unavailable. Please check back later.</h3>
                </div>
            </Card>
        </div>
    );
};

export default Maintenance;

import React, { SetStateAction, Dispatch } from "react";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { PolicyStatus } from "enums/PolicyStatus";
import "../../styles/policyPayments.css";
import ManageMyPayments from "./ManageMyPayments";
import PolicyFinancialActivity from "./PolicyFinancialActivity";
import { FinancalActivityType } from "enums/FinancialActivityType";
import PolicyPaymentDetails from "./PolicyPaymentDetails";
import useUtilities from "_helpers/useUtilities";

const PolicyPayments: React.FC<{
    policyData: Policy;
    idParsed: number;
    setErrorMessage: Dispatch<SetStateAction<string>>;
}> = (params) => {
    const urlParams = useParams<propType>();
    const utilities = useUtilities();

    let idParsed = 0;
    if (urlParams.id && urlParams.id.trim()) {
        idParsed = +urlParams.id;
    }

    const faqNavigate = (_uri: string) => {
        window.location.replace(_uri);
    };

    const getLapsedElement = () => {
        let result = <></>;
        const path = "/policy-detail/" + idParsed + "?tab=faqs&faqTag=grace";
        if (params.policyData?.summary.status === PolicyStatus.LapsePending) {
            result = (
                <span>
                    Urgent - The policy is currently in Grace. To learn more about Grace, please{" "}
                    <a href="#" onClick={() => faqNavigate(path)}>
                        click here
                    </a>{" "}
                    or call Customer Service at{" "}
                    {utilities.getSupportPhoneNumberByCompanyCode(params.policyData?.companyCode)}.
                </span>
            );
        } else if (params.policyData?.summary.status === PolicyStatus.Lapse) {
            result = (
                <span>
                    Attention! - Grace Period has Expired - Policy has Lapsed. Please call Customer
                    Service at{" "}
                    {utilities.getSupportPhoneNumberByCompanyCode(params.policyData?.companyCode)}.
                </span>
            );
        }

        return result;
    };

    return (
        <section aria-labelledby="payments-section">
            <div className="row" style={{ backgroundColor: "#eee" }}>
                {params.policyData.summary.status === PolicyStatus.LapsePending ? (
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-12">
                            <Card className="grace-warning">
                                <Card.Body className="shadow">
                                    <div className="row">
                                        <div className="col-12 text-danger">
                                            {getLapsedElement()}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {params.policyData && params.policyData.summary && (
                <div className="col-11 offset-md-1">
                    <PolicyPaymentDetails showDraftText={false} policyData={params.policyData} />
                </div>
            )}
            <div className="my-5">
                <ManageMyPayments policyData={params.policyData} id={idParsed} />
            </div>
            <div className="row my-5">
                <div className="col-12 col-md-11 col-lg-9 col-xxl-7 offset-md-1">
                    <h2>Payment History</h2>
                    <PolicyFinancialActivity
                        policyData={params.policyData}
                        activityTypes={[
                            FinancalActivityType.Premium,
                            FinancalActivityType.PremiumInit
                        ]}
                        showLastSixMonthsOrDefault={true}
                    />
                </div>
            </div>
        </section>
    );
};

export default PolicyPayments;

import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { Spinner } from "../shared/Common";
import { CPContext } from "../../_helpers/CPContext";
import useUtilities from "../../_helpers/useUtilities";

const PolicyList: React.FC = () => {
    const cpContext = useContext(CPContext);
    const { isMaintenanceError, parseAndFormatDate } = useUtilities();
    const { get } = useFetch();
    const [policies, setPolicies] = useState<PolicySimple[]>([]);
    const { authState, oktaAuth } = useOktaAuth();
    const navigate = useHistory();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigatePolicy = async (_policyDWKey: number) => {
        let _url = "/policy-detail/" + _policyDWKey;
        if (cpContext.isHO || cpContext.isAdmin) {
            _url = "/policy-detail/" + _policyDWKey + "?tab=homeoffice";
        }
        navigate.push(_url);
    };

    useEffect(() => {
        const getPolicies = async () => {
            if (!isProcessing) {
                try {
                    setIsProcessing(true);
                    const policiesFromServer = await get<PolicySimple[]>("/policy", "");
                    if (policiesFromServer.length == 1) {
                        const policy: PolicySimple = policiesFromServer[0];
                        if (cpContext.isHO || cpContext.isAdmin) {
                            navigate.push(
                                "/policy-detail/" + policy.policyDWKey + "?tab=homeoffice"
                            );
                        } else {
                            navigate.push("/policy-detail/" + policy.policyDWKey);
                        }
                    }
                    setPolicies(policiesFromServer);
                    setIsProcessing(false);
                } catch (e: unknown) {
                    if (isMaintenanceError(e)) {
                        navigate.push("/maint");
                    } else {
                        setIsProcessing(false);
                        setErrorMessage("An unexpected error has occured, please try again.");
                    }
                }
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getPolicies();
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext, navigate]);

    if (!isProcessing) {
        if (policies && policies.length > 0) {
            return (
                <section aria-labelledby="policy-list-section">
                    <h1 style={{ display: "none" }}>List of client policies</h1>
                    <h2 style={{ display: "none" }} aria-labelledby="policy-list-section">
                        Policy List Section
                    </h2>
                    <div className="row" style={{ backgroundColor: "#eee" }}>
                        <div className="col-2"></div>
                        <div className="col-8">
                            <div className="row" style={{ paddingTop: 50, paddingBottom: 20 }}>
                                <h4>Which policy would you like to view?</h4>
                            </div>
                            {policies.length > 0 ? (
                                <div>
                                    {policies.map((policy, idx) => (
                                        <div key={idx} style={{ paddingBottom: "15px" }}>
                                            <Card>
                                                <Card.Body className="shadow">
                                                    <div
                                                        className="row"
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            padding: 10
                                                        }}
                                                    >
                                                        <div className="col-12 col-md-4">
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div className="col">
                                                                    <button
                                                                        className="btnRounded"
                                                                        onClick={() =>
                                                                            navigatePolicy(
                                                                                policy.policyDWKey
                                                                            )
                                                                        }
                                                                    >
                                                                        SELECT
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-8">
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div className="col-5">
                                                                    Policy #:
                                                                </div>
                                                                <div className="col-7">
                                                                    {policy.policyNumber}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div className="col-5">
                                                                    Issue Date:
                                                                </div>
                                                                <div className="col-7">
                                                                    {parseAndFormatDate(
                                                                        policy.issueDate
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div className="col-5">
                                                                    Insured:
                                                                </div>
                                                                <div className="col-7">
                                                                    {policy.insuredName}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div className="col-5">Status:</div>
                                                                <div className="col-7">
                                                                    {policy.policyStatus}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            padding: 10
                                                        }}
                                                    >
                                                        <div></div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="col-2"></div>
                    </div>
                </section>
            );
        } else {
            return (
                <section aria-labelledby="policy-list-section">
                    <h1 style={{ display: "none" }}>
                        We are unable to provide your policy information
                    </h1>
                    <h2 style={{ display: "none" }} aria-labelledby="policy-list-section">
                        Policy List Section
                    </h2>
                    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                        Currently we are unable to provide your policy information, please call us
                        at 800-231-0801 for assistance.
                    </div>
                </section>
            );
        }
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <section aria-labelledby="policy-list-section">
                <h1 style={{ display: "none" }}>
                    We encountered an error when accessing your policy information
                </h1>
                <h2 style={{ display: "none" }} aria-labelledby="policy-list-section">
                    Policy List Section
                </h2>
                <div
                    className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-warning"
                    aria-live="assertive"
                    role="alert"
                >
                    {errorMessage}
                </div>
            </section>
        );
    } else {
        return (
            <section aria-labelledby="policy-list-section">
                <h1 style={{ display: "none" }}>Policy List Spinner</h1>
                <h2 style={{ display: "none" }} aria-labelledby="policy-list-section">
                    Policy List Section
                </h2>
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                    <Spinner imageWidth={20} aria-label="Loading" />
                </div>
            </section>
        );
    }
};

export default PolicyList;

/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js";
import jwt_decode from "jwt-decode";
import useFetch from "../../_helpers/useFetch";
import useLogger from "../../_helpers/useLogger";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../shared/Common";
import useUtilities from "../../_helpers/useUtilities";
import { config } from "config";

const ElectronicAuth: React.FC = () => {
    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const utilities = useUtilities();
    const logger = useLogger();
    const { get, post } = useFetch();
    const search = navigate.location.search;
    const paramsFromURL = new URLSearchParams(search);
    const { authState, oktaAuth } = useOktaAuth();
    const [errorMessage, setErrorMessage] = useState("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [electronicDisclosure, setElectronicDisclosure] = useState<boolean>(false);
    const [agreeChecked, setAgreeChecked] = useState(false);
    const [eAuthStep, setEAuthStep] = useState(1);
    const [showEAuth, setShowEAuth] = useState(false);
    const [policyDWKey, setPolicyDWKey] = useState(0);
    const [policyData, setPolicyData] = useState<Policy>();
    const [iFrameLoaded, setIFrameLoaded] = useState(false);
    const [isSubmitProcessing, setIsSubmitProcessing] = useState<boolean>(false);
    const [requestId, setRequestId] = useState<number>(0);
    const [lastRequestId, setLastRequestId] = useState<number>(0);
    const [eAuthPDFBlob, setEAuthPDFBlob] = useState<string>();

    const params = useParams<propType>();
    let idParsed = 0;
    let requestParsed = "";
    let formNumberParsed = "";
    //Load values of parameters or runtime variables
    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
    }
    requestParsed = paramsFromURL.get("request") ?? "freelook";
    formNumberParsed = paramsFromURL.get("formNumber") ?? "";

    const fetchPolicy = async (_id: number) => {
        try {
            const result = await get<Policy>("/policy/full?policyDWKey=" + _id, "");
            return result;
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const onOK = async (e: React.FormEvent<HTMLInputElement>) => {
        await logger.logDetailedActivity(
            "ElectronicAuth",
            "OK",
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId
        );
        if (agreeChecked) {
            if (requestParsed && requestParsed.length > 0) {
                if (requestParsed === "freelook") {
                    if (formNumberParsed && formNumberParsed.length > 0) {
                        navigate.push("/freelook/" + idParsed + "?formNumber=" + formNumberParsed);
                    } else {
                        navigate.push("/freelook/" + idParsed);
                    }
                } else if (requestParsed === "surrender") {
                    if (formNumberParsed && formNumberParsed.length > 0) {
                        navigate.push("/surrender/" + idParsed + "?formNumber=" + formNumberParsed);
                    } else {
                        navigate.push("/surrender/" + idParsed);
                    }
                } else if (requestParsed.includes("/payments/")) {
                    navigate.push(requestParsed + idParsed);
                } else {
                    navigate.push("/policy-detail/" + idParsed + "?tab=service");
                }
            } else {
                navigate.push("/policies/");
            }
        } else {
            if (requestParsed && requestParsed.length > 0) {
                navigate.push("/policy-detail/" + idParsed + "?tab=service");
            } else {
                navigate.push("/policies/");
            }
        }
    };

    const LogggedNavigate = async (_uri: string, _linkName: string) => {
        await logger.logDetailedActivity(
            "ElectronicAuth",
            _linkName,
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId
        );

        navigate.push(_uri);
    };

    const processEAuth = async () => {
        try {
            const body: PolicyElectronicAuthorization = {
                policyDWKey: idParsed,
                electronicSignatureAgreement: true,
                electronicSignatureName: "",
                requestId: 0
            };
            const result = await post<PolicyElectronicAuthorization, boolean>(
                "/policy/completeelectronicauthorization/",
                "",
                body
            );
            return result;
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const showEAuthClick = async (e: React.FormEvent<HTMLInputElement>) => {
        try {
            await logger.logDetailedActivity(
                "ElectronicAuth",
                "ShowPreview",
                cpContext,
                cpContext.userInfo?.email,
                cpContext.sessionId,
                "",
                idParsed
            );
            await getEAuthPreview();
            setShowEAuth(true);
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const getEAuthPreview = async () => {
        try {
            const body: fileDownloadRequest = {
                id: requestId,
                policyDWKey: idParsed,
                ignorePreviousDisclosure: false
            };

            const requestOptionsBodyAuth = {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
                }
            };
            await fetch(
                config.resourceServer.apiUrl + "/policy/eauthpreviews",
                requestOptionsBodyAuth
            )
                .then((response) => response.blob())
                .then((response) => {
                    if (response.type == "application/problem+json") {
                        setErrorMessage(
                            "Could not load the Electronic Authorization forms, please try again."
                        );
                    } else {
                        setErrorMessage("");
                        //TODO: Test if the response is a valid blob/PDF
                        const pdfBlob = window.URL.createObjectURL(response);
                        setEAuthPDFBlob(pdfBlob);
                    }
                })
                .catch((error) => {
                    setErrorMessage(
                        "Could not load the Electronic Authorization forms, please try again."
                    );
                });
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const onSubmitEAuth = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsSubmitProcessing(true);
        await logger.logDetailedActivity(
            "ElectronicAuth",
            "EAuthSubmit",
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );

        if (agreeChecked) {
            const result = await processEAuth();

            if (result) {
                setEAuthStep(eAuthStep + 2);
            } else {
                setErrorMessage(
                    "There was a problem saving your Electronic Disclosure Authorization selection. Please try again."
                );
            }
        } else {
            setEAuthStep(eAuthStep + 1);
        }
        setIsSubmitProcessing(false);
    };

    useEffect(() => {
        const getPolicy = async () => {
            try {
                setIsProcessing(true);
                const policyFromServer = await fetchPolicy(idParsed);
                if (policyFromServer) {
                    setPolicyData(policyFromServer);
                    setElectronicDisclosure(policyFromServer.electronicDisclosure);
                    setIsProcessing(false);
                }
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    setErrorMessage(
                        "There was a problem loading your policy information, please call us at " +
                            utilities.getSupportPhoneNumberByCompanyCode(
                                policyData?.companyCode ?? 0
                            ) +
                            " for assistance."
                    );
                    setIsProcessing(false);
                }
            }
        };

        if (authState && authState.isAuthenticated) {
            getPolicy();
        }
    }, [authState, cpContext, navigate]); // Update if authState changes

    const handleIFrameLoad = () => setIFrameLoaded(true);

    if (!isProcessing && errorMessage.length <= 0) {
        if (!electronicDisclosure) {
            if (eAuthStep == 1) {
                return (
                    <section aria-labelledby="eauth-section">
                        <h2 style={{ display: "none" }} aria-labelledby="eauth-section">
                            Electronic Auth Section
                        </h2>
                        <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                            <div className="col-md-12">
                                <form
                                    className="add-form"
                                    onSubmit={onSubmitEAuth}
                                    style={{ backgroundColor: "#eee" }}
                                >
                                    <div
                                        className="row"
                                        style={{
                                            paddingTop: 10,
                                            paddingBottom: 0,
                                            borderTop: 0,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            borderBottom: 2,
                                            borderStyle: "solid",
                                            borderColor: "#33CCCC"
                                        }}
                                    >
                                        <div className="col-8">
                                            <h1
                                                className="custom-h1 skip-to"
                                                style={{ paddingTop: 10 }}
                                            >
                                                Electronic Signature and Delivery Authorization
                                            </h1>
                                        </div>
                                    </div>
                                    <div className="row" style={{ padding: 10 }}>
                                        <div className="col-9">
                                            <Card className="policyCard">
                                                <Card.Body className="shadow">
                                                    <>
                                                        <div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 15
                                                                }}
                                                            >
                                                                <div className="col-9">
                                                                    <p>
                                                                        {!showEAuth ? (
                                                                            <>
                                                                                Please review and
                                                                                agree to the
                                                                                Electronic Signature
                                                                                and Delivery
                                                                                Authorization
                                                                                consumer disclosure
                                                                                (click OK to view):
                                                                                <br></br>
                                                                                <input
                                                                                    type="button"
                                                                                    value="OK"
                                                                                    className="btnRounded btn-block"
                                                                                    onClick={
                                                                                        showEAuthClick
                                                                                    }
                                                                                    aria-label="OK button"
                                                                                ></input>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                Please review and
                                                                                agree to the
                                                                                Electronic Signature
                                                                                and Delivery
                                                                                Authorization
                                                                                consumer disclosure:
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 15
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 30 }}
                                                                >
                                                                    {showEAuth &&
                                                                    eAuthPDFBlob &&
                                                                    eAuthPDFBlob?.length > 0 ? (
                                                                        <>
                                                                            <iframe
                                                                                src={eAuthPDFBlob}
                                                                                width={650}
                                                                                height={550}
                                                                                onLoad={
                                                                                    handleIFrameLoad
                                                                                }
                                                                                title={
                                                                                    "ElectronicAuthorizationPreview"
                                                                                }
                                                                                id={
                                                                                    "ElectronicAuthorizationPreview"
                                                                                }
                                                                            ></iframe>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {showEAuth ? (
                                                                <>
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingBottom: 15,
                                                                            paddingTop: 15
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="col-1"
                                                                            style={{
                                                                                paddingLeft: 30
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={
                                                                                    agreeChecked
                                                                                }
                                                                                value="true"
                                                                                onChange={(e) =>
                                                                                    setAgreeChecked(
                                                                                        true
                                                                                    )
                                                                                }
                                                                                className="AgreeCheck"
                                                                                aria-label="Agree"
                                                                            ></input>
                                                                        </div>
                                                                        <div
                                                                            className="col-9"
                                                                            style={{
                                                                                paddingLeft: 30
                                                                            }}
                                                                        >
                                                                            I agree to use
                                                                            electronic records and
                                                                            signature&apos;s
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingBottom: 15
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="col-1"
                                                                            style={{
                                                                                paddingLeft: 30
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={
                                                                                    !agreeChecked
                                                                                }
                                                                                value="false"
                                                                                onChange={(e) =>
                                                                                    setAgreeChecked(
                                                                                        false
                                                                                    )
                                                                                }
                                                                                className="NotAgreeCheck"
                                                                                aria-label="Not Agree"
                                                                            ></input>
                                                                        </div>
                                                                        <div
                                                                            className="col-9"
                                                                            style={{
                                                                                paddingLeft: 30
                                                                            }}
                                                                        >
                                                                            I do not agree to use
                                                                            electronic records and
                                                                            signature&apos;s
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div
                                        className="row"
                                        style={{ paddingTop: 10, paddingBottom: 0 }}
                                    >
                                        <div className="col-md-6">
                                            <div
                                                className="row"
                                                style={{ paddingTop: 10, paddingBottom: 0 }}
                                            >
                                                <div className="col-3">
                                                    {showEAuth && iFrameLoaded ? (
                                                        <>
                                                            {isSubmitProcessing ? (
                                                                <Spinner
                                                                    imageWidth={20}
                                                                    aria-label="Loading"
                                                                />
                                                            ) : (
                                                                <input
                                                                    type="submit"
                                                                    value="Complete"
                                                                    className="btnRounded btn-block"
                                                                    aria-label="Complete"
                                                                ></input>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                );
            } else if (eAuthStep == 2) {
                return (
                    <>
                        <section aria-labelledby="eauth-section">
                            <h1 style={{ display: "none" }}>
                                Electronic Signature and Delivery Authorization
                            </h1>
                            <h2 style={{ display: "none" }} aria-labelledby="eauth-section">
                                Electronic Auth Section
                            </h2>
                            <div
                                className="row"
                                style={{ backgroundColor: "#eee", padding: "20px" }}
                            >
                                <div className="col-md-12">
                                    <div
                                        className="row"
                                        style={{
                                            paddingTop: 10,
                                            paddingBottom: 0,
                                            borderTop: 0,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            borderBottom: 2,
                                            borderStyle: "solid",
                                            borderColor: "#33CCCC"
                                        }}
                                    >
                                        <div className="col-8">
                                            <h4 style={{ paddingTop: 10 }}>
                                                Electronic Signature and Delivery Authorization
                                            </h4>
                                        </div>
                                        <div className="col-4">
                                            <div style={{ paddingTop: 10 }}>
                                                Policy # {policyData?.policyNumber} -{" "}
                                                {policyData?.product}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ padding: 10 }}>
                                        <div className="col-9">
                                            <Card className="policyCard">
                                                <Card.Body className="shadow">
                                                    <>
                                                        <form
                                                            className="add-form"
                                                            onSubmit={onSubmitEAuth}
                                                            style={{ backgroundColor: "#FFFFFF" }}
                                                        >
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingBottom: 15,
                                                                    paddingTop: 15
                                                                }}
                                                            >
                                                                {requestParsed &&
                                                                requestParsed.length > 0 ? (
                                                                    <>
                                                                        In order to make any change
                                                                        to your Policy
                                                                        electronically through the
                                                                        Portal, we must have your
                                                                        consent to use electronic
                                                                        records and signatures. In
                                                                        the meantime, you can
                                                                        continue by hitting
                                                                        &apos;OK&apos;.
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        In order to make any change
                                                                        to your Policy
                                                                        electronically through the
                                                                        Portal, we must have your
                                                                        consent to use electronic
                                                                        records and signatures. In
                                                                        the meantime, you can
                                                                        continue to view your Policy
                                                                        Details by hitting
                                                                        &apos;OK&apos;.
                                                                    </>
                                                                )}
                                                            </div>
                                                        </form>
                                                    </>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div
                                        className="row"
                                        style={{ paddingTop: 10, paddingBottom: 0 }}
                                    >
                                        <div className="col-md-6">
                                            <div
                                                className="row"
                                                style={{ paddingTop: 10, paddingBottom: 0 }}
                                            >
                                                <div className="col-6">
                                                    <input
                                                        type="button"
                                                        value="Back"
                                                        className="btn btn-Primary"
                                                        onClick={() => setEAuthStep(eAuthStep - 1)}
                                                        style={{ width: "90px" }}
                                                        aria-label="Back button"
                                                    ></input>
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        type="button"
                                                        value="OK"
                                                        className="btn btn-Primary"
                                                        onClick={onOK}
                                                        aria-label="OK button"
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                );
            } else if (eAuthStep == 3) {
                return (
                    <>
                        <section aria-labelledby="eauth-section">
                            <h1 style={{ display: "none" }}>
                                Electronic Signature and Delivery Authorization
                            </h1>
                            <h2 style={{ display: "none" }} aria-labelledby="eauth-section">
                                Electronic Auth Section
                            </h2>
                            <div
                                className="row"
                                style={{ backgroundColor: "#eee", padding: "20px" }}
                            >
                                <div className="col-md-12">
                                    <div
                                        className="row"
                                        style={{
                                            paddingTop: 10,
                                            paddingBottom: 0,
                                            borderTop: 0,
                                            borderLeft: 0,
                                            borderRight: 0,
                                            borderBottom: 2,
                                            borderStyle: "solid",
                                            borderColor: "#33CCCC"
                                        }}
                                    >
                                        <div className="col-8">
                                            <h4 style={{ paddingTop: 10 }}>
                                                Electronic Signature and Delivery Authorization
                                            </h4>
                                        </div>
                                        <div className="col-4">
                                            <div style={{ paddingTop: 10 }}>
                                                Policy # {policyData?.policyNumber} -{" "}
                                                {policyData?.product}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ padding: 10 }}>
                                        <div className="col-9">
                                            <Card className="policyCard">
                                                <Card.Body className="shadow">
                                                    <>
                                                        <form
                                                            className="add-form"
                                                            onSubmit={onSubmitEAuth}
                                                            style={{ backgroundColor: "#FFFFFF" }}
                                                        >
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingBottom: 15,
                                                                    paddingTop: 15
                                                                }}
                                                            >
                                                                Thank you for signing. Your
                                                                signature has been applied. A
                                                                confirmation email will be sent to
                                                                your email address with a link to
                                                                your completed Electronic
                                                                Authorization Form.
                                                            </div>
                                                        </form>
                                                    </>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div
                                        className="row"
                                        style={{ paddingTop: 10, paddingBottom: 0 }}
                                    >
                                        <div className="col-md-6">
                                            <div
                                                className="row"
                                                style={{ paddingTop: 10, paddingBottom: 0 }}
                                            >
                                                <div className="col-6">
                                                    <input
                                                        type="button"
                                                        value="OK"
                                                        className="btn btn-Primary"
                                                        onClick={onOK}
                                                        aria-label="OK button"
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                );
            } else {
                return (
                    <section aria-labelledby="eauth-section">
                        <h1 style={{ display: "none" }}>
                            Electronic Signature and Delivery Authorization
                        </h1>
                        <h2 style={{ display: "none" }} aria-labelledby="eauth-section">
                            Electronic Auth Section
                        </h2>
                        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                            &nbsp;
                        </div>
                    </section>
                );
            }
        } else {
            return (
                <>
                    <section aria-labelledby="eauth-section">
                        <h1 style={{ display: "none" }}>
                            Electronic Signature and Delivery Authorization
                        </h1>
                        <h2 style={{ display: "none" }} aria-labelledby="eauth-section">
                            Electronic Auth Section
                        </h2>
                        <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                            <div className="col-md-12">
                                <div
                                    className="row"
                                    style={{
                                        paddingTop: 10,
                                        paddingBottom: 0,
                                        borderTop: 0,
                                        borderLeft: 0,
                                        borderRight: 0,
                                        borderBottom: 2,
                                        borderStyle: "solid",
                                        borderColor: "#33CCCC"
                                    }}
                                >
                                    <div className="col-8">
                                        <h4 style={{ paddingTop: 10 }}>
                                            Electronic Signature and Delivery Authorization
                                        </h4>
                                    </div>
                                    <div className="col-4">
                                        <div style={{ paddingTop: 10 }}>
                                            Policy # {policyData?.policyNumber} -{" "}
                                            {policyData?.product}
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: 10 }}>
                                    <div className="col-9">
                                        <Card className="policyCard">
                                            <Card.Body className="shadow">
                                                <>
                                                    <form
                                                        className="add-form"
                                                        onSubmit={onSubmitEAuth}
                                                        style={{ backgroundColor: "#FFFFFF" }}
                                                    >
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingBottom: 15,
                                                                paddingTop: 15
                                                            }}
                                                        >
                                                            {requestParsed &&
                                                            requestParsed.length > 0 ? (
                                                                <>
                                                                    We have your completed
                                                                    Electronic Authorization Form on
                                                                    file, if you wish to decline
                                                                    Electronic Authorization please
                                                                    contact us. Click OK to view
                                                                    your policy.
                                                                </>
                                                            ) : (
                                                                <>
                                                                    We have your completed
                                                                    Electronic Authorization Form on
                                                                    file, if you wish to decline
                                                                    Electronic Authorization please
                                                                    contact us. Click OK to
                                                                    continue.
                                                                </>
                                                            )}
                                                        </div>
                                                    </form>
                                                </>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-md-6">
                                        <div
                                            className="row"
                                            style={{ paddingTop: 10, paddingBottom: 0 }}
                                        >
                                            <div className="col-6">
                                                <input
                                                    type="button"
                                                    value="OK"
                                                    className="btn btn-Primary"
                                                    onClick={onOK}
                                                    aria-label="OK button"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );
        }
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <>
                <section aria-labelledby="eauth-section">
                    <h1 style={{ display: "none" }}>
                        Electronic Signature and Delivery Authorization Error Message
                    </h1>
                    <h2 style={{ display: "none" }} aria-labelledby="eauth-section">
                        Electronic Auth Section
                    </h2>
                    <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                        <div className="col-md-12">
                            <div
                                className="row"
                                style={{
                                    paddingTop: 10,
                                    paddingBottom: 0,
                                    borderTop: 0,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    borderBottom: 2,
                                    borderStyle: "solid",
                                    borderColor: "#33CCCC"
                                }}
                            >
                                <div className="col-8">
                                    <h4 style={{ paddingTop: 10 }}>
                                        Electronic Signature and Delivery Authorization
                                    </h4>
                                </div>
                                <div className="col-4">
                                    <div style={{ paddingTop: 10 }}>
                                        Policy # {policyData?.policyNumber} - {policyData?.product}
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ padding: 10 }}>
                                <div className="col-9">
                                    <Card className="policyCard">
                                        <Card.Body className="shadow">
                                            <>
                                                <form
                                                    className="add-form"
                                                    onSubmit={onSubmitEAuth}
                                                    style={{ backgroundColor: "#FFFFFF" }}
                                                >
                                                    <div
                                                        className="row"
                                                        style={{
                                                            paddingBottom: 15,
                                                            paddingTop: 15
                                                        }}
                                                    >
                                                        <div
                                                            className="col-12 text-danger"
                                                            aria-live="assertive"
                                                            role="alert"
                                                        >
                                                            {errorMessage}
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                <div className="col-md-6">
                                    <div
                                        className="row"
                                        style={{ paddingTop: 10, paddingBottom: 0 }}
                                    >
                                        <div className="col-6">
                                            <input
                                                type="button"
                                                value="OK"
                                                className="btn btn-Primary"
                                                onClick={() => setErrorMessage("")}
                                                aria-label="OK button"
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    } else {
        return (
            <section aria-labelledby="eauth-section">
                <h1 style={{ display: "none" }}>
                    Electronic Signature and Delivery Authorization Spinner
                </h1>
                <h2 style={{ display: "none" }} aria-labelledby="eauth-section">
                    Electronic Auth Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    }
};

export default ElectronicAuth;

import React, { useContext, useEffect, useState } from "react";
import useFetch from "../../_helpers/useFetch";
import useUtilities from "_helpers/useUtilities";
import { CPContext } from "../../_helpers/CPContext";
import { Button, Modal } from "react-bootstrap";

const Messages: React.FC = () => {
    const cpContext = useContext(CPContext);
    const { parseAndFormatDate } = useUtilities();
    const { get, put } = useFetch();
    const [messages, setMessages] = useState<UserMessage[]>();
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState<UserMessage>();
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    useEffect(() => {
        getCustomerMessages();
    }, []);

    const getCustomerMessages = async () => {
        const result = await get<CustomerMessagesResonse>(
            "/customer/message",

            ""
        );
        setMessages(result.messages);
    };

    const markAsViewedAndOpen = async (message: UserMessage) => {
        setModalMessage(message);
        handleShowModal();

        if (message.viewed == null) {
            const result = await put<UserMessage>(
                "/customer/message-viewed/" + message.id,

                ""
            );

            if (messages) {
                cpContext.changeMessageCount(cpContext.messageCount - 1);
                setMessages([result, ...messages.filter((x) => x.id != result.id)]);
            }
        }
    };

    return (
        <div className="row my-5" style={{ padding: 10 }}>
            <div className="col-12">
                <div className="card policyCard">
                    <div className="card-body shadow" style={{ minHeight: 160 }}>
                        <div className="row" style={{ paddingTop: 5, paddingBottom: 5 }}>
                            <div
                                className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fieldLabel"
                                title="Message Title"
                                tabIndex={0}
                            >
                                Message
                            </div>
                            <div
                                className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldLabel"
                                title="Received Date"
                                tabIndex={0}
                            >
                                Received Date
                            </div>
                            <div
                                className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldLabel"
                                title="Viewed Date"
                                tabIndex={0}
                            >
                                Viewed Date
                            </div>
                        </div>
                        <>
                            {messages && messages.length > 0 ? (
                                <>
                                    {messages.map((h, idx) => (
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 5 }}
                                            key={idx}
                                        >
                                            <div
                                                className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fieldValue"
                                                title="Message Title"
                                                tabIndex={0}
                                            >
                                                {h.viewed != null ? (
                                                    <span
                                                        role="button"
                                                        onClick={() => markAsViewedAndOpen(h)}
                                                    >
                                                        {h.title}
                                                    </span>
                                                ) : (
                                                    <span
                                                        className="fw-bold"
                                                        role="button"
                                                        onClick={() => markAsViewedAndOpen(h)}
                                                    >
                                                        {h.title}
                                                    </span>
                                                )}
                                            </div>
                                            <div
                                                className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldValue"
                                                title="Create Date"
                                                tabIndex={0}
                                            >
                                                {parseAndFormatDate(h.created)}
                                            </div>
                                            <div
                                                className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldValue"
                                                title="Viewed Date"
                                                tabIndex={0}
                                            >
                                                {parseAndFormatDate(h.viewed)}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <label tabIndex={0}>No Messages</label>
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalMessage?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage?.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Messages;

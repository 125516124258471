import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Spinner } from "../shared/Common";

export const Logout: React.FC = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const navigate = useHistory();
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        let ignore = false;
        const triggerLogout = async () => {
            console.log("triggerLogout isProcessing=" + isProcessing + "; " + authState);

            if (authState) {
                setIsProcessing(true);
                const basename = window.location.origin + navigate.createHref({ pathname: "/" });
                try {
                    console.log("triggerLogout oktaAuth.signOut");
                    await oktaAuth.signOut({ postLogoutRedirectUri: basename });
                } catch (err) {
                    console.log("triggerLogout=" + err);
                }
                setIsProcessing(false);
            }
        };

        if (!ignore) triggerLogout();
        return () => {
            ignore = true;
        };
        // eslint-disable-next-line
    }, []);

    return <Spinner imageWidth={20} aria-label="Loading" />;
};

export default Logout;

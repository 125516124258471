/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import useFetch from "../../_helpers/useFetch";
import useLogger from "../../_helpers/useLogger";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../shared/Common";
import useUtilities from "../../_helpers/useUtilities";

type propType = {
    id: string;
    formNumber: string;
};

const Surrender: React.FC = () => {
    const params = useParams<propType>();
    let idParsed = 0;
    let formNumberParsed = "";
    let policyNumber = "";

    const navigate = useHistory();
    const search = navigate.location.search;
    const paramsFromURL = new URLSearchParams(search);

    const cpContext = useContext(CPContext);
    const { authState, oktaAuth } = useOktaAuth();
    const { isMaintenanceError } = useUtilities();
    const logger = useLogger();
    const { get } = useFetch();
    const [stepNumber, setStepNumber] = useState(1);
    const [surrenderOption, setSurrenderOption] = useState("");
    const [surrenderNet, setSurrenderNet] = useState<boolean>(true);
    const [fundsDistribution, setFundsDistribution] = useState("");
    const [directFundsDistribution, setDirectFundsDistribution] = useState("");
    const [withholdTax, setWithholdTax] = useState<boolean>(true);
    const [surrenderChecked, setSurrenderChecked] = useState<boolean>(false);
    const [liabilityChecked, setLiabilityChecked] = useState<boolean>(false);
    const [noInterestChecked, setNoInterestChecked] = useState<boolean>(false);
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [backupWithholdingCheck, setBackupWithholdingCheck] = useState<boolean>(false);
    const [surrenderAmountRequested, setSurrenderAmountRequested] = useState("");
    const [collectOnlyReason, setCollectOnlyReason] = useState<boolean>(false);
    const [surrenderReason, setSurrenderReason] = useState("");
    const [surrenderReasonOther, setSurrenderReasonOther] = useState("");

    const [policyData, setPolicyData] = useState<Policy>();

    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorText, setErrorText] = useState("");
    const reasonOtherMin = 5;
    const reasonOtherMax = 250;

    const errorStyle = {
        color: "#dc3545",
        borderColor: "#dc3545"
    };

    //Load values of parameters or runtime variables
    if ( params.id && params.id.trim()) {
        idParsed = +params.id;
    }
    formNumberParsed = paramsFromURL.get("formNumber") ?? "details";

    const handleSurrenderOptionRadioClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSurrenderOption(e.target.value);
    };

    const handleSurrenderNetRadioClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSurrenderNet(e.target.value === "true");
    };

    const handleWithholdTaxRadioClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setWithholdTax(e.target.value === "true");
    };

    const handleFundsDistributionRadioClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setFundsDistribution(e.target.value);
    };

    const handleDirectFundsDistributionRadioClick = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDirectFundsDistribution(e.target.value);
    };

    const handleSurrenderReason = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSurrenderReason(e.target.value);
        setSurrenderReasonOther("");
        setErrorText("");
    };

    const handleSurrenderReasonOther = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setErrorText("");
        setSurrenderReasonOther(e.target.value.substring(0, reasonOtherMax));
    };

    const validateRoutingNumber = (num: string) => {
        if (num.length != 9) {
            return false;
        }

        let n = 0;
        for (let i = 0; i < num.length; i += 3) {
            n +=
                parseInt(num.charAt(i), 10) * 3 +
                parseInt(num.charAt(i + 1), 10) * 7 +
                parseInt(num.charAt(i + 2), 10);
        }

        // If the resulting sum is an even multiple of ten (but not zero),
        // the aba routing number is good.
        if (n != 0 && n % 10 == 0) {
            return true;
        } else {
            return false;
        }
    };

    const getEmailAddress = (): string => {
        if (cpContext.isHO) {
            return "";
        } else {
            return cpContext.userInfo?.email;
        }
    };

    const onNext = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        let validationError = "";
        setErrorText(validationError);

        if (stepNumber === 1) {
            if (surrenderReason === "") {
                validationError = "Please select a cancellation reason to continue.";
            } else if (
                (surrenderReasonOther === "" || surrenderReasonOther.length < reasonOtherMin) &&
                surrenderReason === "Other"
            ) {
                validationError =
                    "Please enter your cancellation reason in the textbox to continue.";
            }

            if (
                collectOnlyReason &&
                ((validationError && validationError.length <= 0) || !validationError)
            ) {
                //Redirect to download page
                const logObject = {
                    Reason: surrenderReason,
                    ReasonOther: surrenderReasonOther,
                    PolicyNumber: policyNumber
                };
                await logger.logDetailedActivity(
                    "Surrender",
                    "submit reason",
                    cpContext,

                    getEmailAddress(),
                    cpContext.sessionId,
                    JSON.stringify(logObject),
                    idParsed
                );

                navigate.push("/formdownload/" + idParsed + "?formNumber=" + formNumberParsed);
            }
        }
        if (stepNumber === 2) {
            if (!surrenderOption) {
                validationError = "Please select a surrender amount option to continue.";
            } else if (surrenderOption === "partial" && !surrenderAmountRequested) {
                validationError = "Please enter a partial surrender amount to continue.";
            }
        }
        if (stepNumber === 3) {
            if (!surrenderChecked || !liabilityChecked || !noInterestChecked) {
                validationError = "Please check the boxes below to continue.";
            }
        }

        //handle anywhere in the Surrender process where step < 9 and reason is "Other" but no additional reason provided
        if (
            stepNumber < 9 &&
            surrenderReason === "Other" &&
            surrenderReasonOther.length < reasonOtherMin
        ) {
            validationError =
                'Please enter your cancellation reason in the "Other" textbox to continue.';
        }

        if (validationError && validationError.length > 0) {
            setErrorText(validationError);
            return;
        } else {
            setStepNumber(stepNumber + 1);
        }
    };

    const onBack = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        setStepNumber(stepNumber - 1);
    };

    const onSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        setStepNumber(0);
        setRequestSubmitted(true);
    };

    const renderPolicyDocumentsLinkPath = () => {
        return "<a href='/policy-detail/" + idParsed + "?tab=docs' target='_blank'>here</a>";
    };

    const renderSurrenderEmpathy = (reason: string) => {
        switch (reason) {
            case "NotAfford":
                return "Americo understands the importance of financial stability. Ensuring your premium fits your financial scope is important to Americo. You may have the option of reducing your death benefit to reduce your premium payment. Please reach us at 1-800-231-0801 to speak with one of our representatives today.";
            case "NotAnticipated":
                return "Understanding your policy is extremely important. Americo wants to ensure you have the best possible coverage for you and your loved ones. You may call us at 1-800-231-0801 to learn detailed information regarding your current coverage and any options you may have to better accommodate your needs.";
            case "NoPolicyPages":
                return (
                    "Receiving and reviewing your contract after purchasing coverage is an expectation for all of our valued customers. We have made viewing your policy easy and accessible on your client portal. You may view, download, and even print your policy right from " +
                    renderPolicyDocumentsLinkPath() +
                    ". If you would like us to send a copy of your policy, please call 1-800-231-0801."
                );
            case "MedicareGov":
                return "We understand the importance of meeting expected benefit requirements and continuing your coverage. You may want to explore other options beyond canceling your policy such as a change in ownership or beneficiary change. Please call us at 1-800-231-0801 for more information.";
            case "EndTerm":
                return "We appreciate the opportunity to serve as your life insurance company throughout the term of your policy. You may have the ability to convert your policy to a whole life (if applicable). Please call us at 1-800-231-0801 to discuss if this is an option.";
            default:
                return "";
        }
    };

    const LogggedNavigate = async (_uri: string, _linkName: string) => {
        await logger.logDetailedActivity(
            "Freelook",
            _linkName,
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId
        );

        navigate.push(_uri);
    };

    useEffect(() => {
        const getPolicy = async () => {
            try {
                setIsProcessing(true);
                const policyFromServer = await get<Policy>(
                    "/policy/full?policyDWKey=" + idParsed,

                    ""
                );
                setPolicyData(policyFromServer);

                //Until /surrender wizard is live we will force it to only collect reason and then redirect
                setCollectOnlyReason(true);

                if (policyFromServer) {
                    policyNumber = policyFromServer.policyNumber;

                }

                setIsProcessing(false);
            } catch (e: unknown) {
                if (isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    setErrorMessage(
                        "There was a problem loading your policy information, please call us at 800-231-0801 for assistance."
                    );
                    setIsProcessing(false);
                }
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getPolicy();
        }
    }, [authState, oktaAuth, cpContext]);

    if (policyData && policyData.summary) {
        return (
            <section aria-labelledby="surrender-section">
                <h2 style={{ display: "none" }} aria-labelledby="surrender-section">
                    Surrender Section
                </h2>
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-md-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-6">
                                <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                    Policy Surrender
                                </h1>
                            </div>
                            <div className="col-6">
                                <div style={{ paddingTop: 10 }}>
                                    {policyData?.policyNumber} - {policyData.product}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-9">
                                <Card className="policyCard">
                                    <Card.Body className="shadow">
                                        <div>
                                            {errorText && errorText.length > 0 ? (
                                                <>
                                                    <label className="text-danger">
                                                        {errorText}
                                                        <br />
                                                        <br />
                                                    </label>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        {stepNumber === 1 ? (
                                            <>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <div className="col-9">
                                                        {collectOnlyReason ||
                                                        policyData.summary.daysSinceIssue >= 45 ? (
                                                            <></>
                                                        ) : (
                                                            <>
                                                                This policy may be entitled to a
                                                                full refund.
                                                            </>
                                                        )}
                                                        {policyData.isDraftDay ? (
                                                            <span className="text-danger">
                                                                Cancellations within 2 business days
                                                                of draft date may not suspend the
                                                                scheduled draft. If draft occurs,
                                                                refund will be processed within 14
                                                                business days.
                                                            </span>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 5 }}>
                                                    <div
                                                        className="col-9"
                                                        style={{
                                                            fontFamily: "PT Sans Bold",
                                                            fontSize: "20px",
                                                            paddingBottom: 15
                                                        }}
                                                    >
                                                        <b>
                                                            Why do you want to cancel this policy?
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingBottom: 15 }}>
                                                    <div className="col-9">
                                                        <select
                                                            name="SurrenderReason"
                                                            style={{ width: "400px" }}
                                                            onChange={handleSurrenderReason}
                                                            defaultValue={surrenderReason}
                                                            className="skip-to"
                                                        >
                                                            <option value="">
                                                                --Select a reason--
                                                            </option>
                                                            <option value="NotAfford">
                                                                I can no longer afford my insurance
                                                                premium.
                                                            </option>
                                                            <option value="NotAnticipated">
                                                                This policy is not what I
                                                                anticipated.
                                                            </option>
                                                            <option value="NoPolicyPages">
                                                                I have not received my policy pages.
                                                            </option>
                                                            <option value="MedicareGov">
                                                                I cannot keep the coverage due to
                                                                Medicare/government guidelines.
                                                            </option>
                                                            <option value="Other">
                                                                Other (please describe)
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 5 }}>
                                                    <div className="col-9">
                                                        {surrenderReason &&
                                                        surrenderReason.length > 0 ? (
                                                            <>
                                                                {surrenderReason !== "Other" ? (
                                                                    <>
                                                                        <span
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: renderSurrenderEmpathy(
                                                                                    surrenderReason
                                                                                )
                                                                            }}
                                                                        ></span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {/* <input type="text" name="SurrenderReasonOther" className='form-control' placeholder='' onChange={(e) => setSurrenderReasonOther(e.target.value)} value={surrenderReasonOther}></input> */}
                                                                        <span
                                                                            style={
                                                                                surrenderReasonOther.length ===
                                                                                    reasonOtherMax ||
                                                                                surrenderReasonOther.length <
                                                                                    reasonOtherMin
                                                                                    ? errorStyle
                                                                                    : {
                                                                                          color: "green"
                                                                                      }
                                                                            }
                                                                        >
                                                                            {
                                                                                surrenderReasonOther.length
                                                                            }{" "}
                                                                            / {reasonOtherMax}
                                                                        </span>
                                                                        <span
                                                                            style={{ color: "red" }}
                                                                        >
                                                                            &nbsp;*Required
                                                                        </span>
                                                                        <br></br>
                                                                        <textarea
                                                                            style={{
                                                                                width: "400px",
                                                                                height: "160px"
                                                                            }}
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={
                                                                                handleSurrenderReasonOther
                                                                            }
                                                                            maxLength={
                                                                                reasonOtherMax
                                                                            }
                                                                            minLength={5}
                                                                            aria-required={true}
                                                                        >
                                                                            {surrenderReasonOther.substring(
                                                                                0,
                                                                                reasonOtherMax
                                                                            )}
                                                                        </textarea>
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 2 ? (
                                            <>
                                                <div>
                                                    <div className="row" style={{ paddingTop: 5 }}>
                                                        <div
                                                            className="col-9"
                                                            style={{
                                                                fontFamily: "PT Sans Bold",
                                                                fontSize: "20px",
                                                                paddingBottom: 15
                                                            }}
                                                        >
                                                            <b>
                                                                How much would you like to
                                                                surrender?
                                                            </b>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ paddingTop: 15 }}>
                                                        <div
                                                            className="col-9"
                                                            style={{ paddingLeft: 30 }}
                                                        >
                                                            <input
                                                                type="radio"
                                                                value="penaltyfreepartial"
                                                                name="SurrenderOption"
                                                                checked={
                                                                    surrenderOption ===
                                                                    "penaltyfreepartial"
                                                                }
                                                                onChange={
                                                                    handleSurrenderOptionRadioClick
                                                                }
                                                                aria-label="Penalty Free Partial Surrender Option"
                                                            />{" "}
                                                            10% Penalty Free Partial Surrender
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ paddingTop: 15 }}>
                                                        <div
                                                            className="col-9"
                                                            style={{ paddingLeft: 30 }}
                                                        >
                                                            <input
                                                                type="radio"
                                                                value="partial"
                                                                name="SurrenderOption"
                                                                checked={
                                                                    surrenderOption === "partial"
                                                                }
                                                                onChange={
                                                                    handleSurrenderOptionRadioClick
                                                                }
                                                                aria-label="Partial Surrender Option"
                                                            />{" "}
                                                            Partial Surrender
                                                        </div>
                                                    </div>
                                                    {surrenderOption === "partial" ? (
                                                        <>
                                                            <div
                                                                className="row"
                                                                style={{ paddingTop: 1 }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 40 }}
                                                                >
                                                                    (specify amount requested)&nbsp;
                                                                    <input
                                                                        type="text"
                                                                        name="SurrenderOptionAmount"
                                                                        value={
                                                                            surrenderAmountRequested
                                                                        }
                                                                        onChange={(e) =>
                                                                            setSurrenderAmountRequested(
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        aria-label="Surrender Option Amount"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <div className="row" style={{ paddingTop: 15 }}>
                                                        <div
                                                            className="col-9"
                                                            style={{ paddingLeft: 30 }}
                                                        >
                                                            <input
                                                                type="radio"
                                                                value="fullcash"
                                                                name="SurrenderOption"
                                                                checked={
                                                                    surrenderOption === "fullcash"
                                                                }
                                                                onChange={
                                                                    handleSurrenderOptionRadioClick
                                                                }
                                                                aria-label="Full Cash Surrender Option"
                                                            />{" "}
                                                            Full Cash
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ paddingTop: 15 }}>
                                                        <div className="col-9">
                                                            Please note that we cannot initiate
                                                            transfers/rollovers from this site. If
                                                            you wish to do a transfer or rollover,
                                                            please complete this{" "}
                                                            <a href="https://www.americo.com">
                                                                form
                                                            </a>
                                                            .
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 3 ? (
                                            <div onChange={handleSurrenderNetRadioClick}>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 20 }}
                                                >
                                                    <div className="col-9">
                                                        <b>
                                                            Should the $ you requested be processed
                                                            as Net or Gross?
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingBottom: 5 }}>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            value="true"
                                                            name="IsSurrenderNet"
                                                            checked={surrenderNet === true}
                                                            aria-label="Process Surrender as a Net Amount"
                                                        />{" "}
                                                        Process as a net amount
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingBottom: 5, paddingTop: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            value="false"
                                                            name="IsSurrenderNet"
                                                            checked={surrenderNet === false}
                                                            aria-label="Process Surrender as a Gross Amount"
                                                        />{" "}
                                                        Process as a gross amount
                                                    </div>
                                                </div>
                                                {/* <div className="row" style={{ paddingTop: 15 }}>
                                                <div className="col-9" style={{ paddingLeft: 40 }}>
                                                    If you have additional questions, please call 1-800-555-1212.
                                                </div>
                                            </div> */}
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 4 ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 20 }}
                                                >
                                                    <div className="col-9">
                                                        <b>
                                                            Please acknowledge the conditions of
                                                            your surrender:
                                                        </b>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingBottom: 15, paddingTop: 15 }}
                                                >
                                                    <div
                                                        className="col-1"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={surrenderChecked}
                                                            value={surrenderChecked?.toString()}
                                                            onChange={(e) =>
                                                                setSurrenderChecked(
                                                                    e.currentTarget.checked
                                                                )
                                                            }
                                                            className="SurrenderCheck"
                                                            aria-label="Surrender checkbox"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        In consideration of and in exchange for the
                                                        cash value of the above policy, the
                                                        undersigned hereby surrenders said policy
                                                        for cancellation. In accordance with the
                                                        terms of the policy, it is hereby agreed
                                                        that any indebtedness thereon to the Company
                                                        will be deducted from the cash value.
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingBottom: 15 }}>
                                                    <div
                                                        className="col-1"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={liabilityChecked}
                                                            value={liabilityChecked?.toString()}
                                                            onChange={(e) =>
                                                                setLiabilityChecked(
                                                                    e.currentTarget.checked
                                                                )
                                                            }
                                                            className="LiabilityCheck"
                                                            aria-label="Liability checkbox"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        It is understood and agreed that upon
                                                        execution and mailing of this request to the
                                                        Company, the entire liability of the Company
                                                        under this policy is hereby discharged and
                                                        terminated, except for payment of the net
                                                        cash surrender value.
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingBottom: 5 }}>
                                                    <div
                                                        className="col-1"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={noInterestChecked}
                                                            value={noInterestChecked?.toString()}
                                                            onChange={(e) =>
                                                                setNoInterestChecked(
                                                                    e.currentTarget.checked
                                                                )
                                                            }
                                                            className="LiabilityCheck"
                                                            aria-label="No Interest checkbox"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        It is expressly represented and warranted
                                                        that no other person, firm or corporation
                                                        has any interest in said policy except the
                                                        undersigned and that there are no tax liens
                                                        or proceedings in insolvency or bankruptcy
                                                        instituted or pending against the
                                                        undersigned owner.
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 5 ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 20 }}
                                                >
                                                    <div className="col-9">
                                                        <b>
                                                            How should these funds be distributed?
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingBottom: 5 }}>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            value="directdistribution"
                                                            name="FundsDistribution"
                                                            checked={
                                                                fundsDistribution ===
                                                                "directdistribution"
                                                            }
                                                            onChange={
                                                                handleFundsDistributionRadioClick
                                                            }
                                                            aria-label="Direct Distribution Funds Distribution"
                                                        />{" "}
                                                        Direct Distribution
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 15 }}>
                                                    <div
                                                        className="col-12"
                                                        style={{ paddingLeft: 50 }}
                                                    >
                                                        {fundsDistribution ===
                                                        "directdistribution" ? (
                                                            <>
                                                                <div
                                                                    onChange={
                                                                        handleDirectFundsDistributionRadioClick
                                                                    }
                                                                >
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingBottom: 5,
                                                                            fontSize: "14px"
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="col-2"
                                                                            style={{
                                                                                paddingLeft: 30
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                value="regularmail"
                                                                                name="DirectFundsDistribution"
                                                                                checked={
                                                                                    directFundsDistribution ===
                                                                                    "regularmail"
                                                                                }
                                                                                aria-label="Regular Mail Direct Funds Distribution"
                                                                            />{" "}
                                                                            Regular Mail
                                                                        </div>
                                                                        <div
                                                                            className="col-5"
                                                                            style={{
                                                                                paddingLeft: 30
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                value="eft"
                                                                                name="DirectFundsDistribution"
                                                                                checked={
                                                                                    directFundsDistribution ===
                                                                                    "eft"
                                                                                }
                                                                                aria-label="EFT Direct Funds Distribution"
                                                                            />{" "}
                                                                            EFT (Please provide
                                                                            voided check)
                                                                        </div>
                                                                        <div
                                                                            className="col-5"
                                                                            style={{
                                                                                paddingLeft: 30
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                value="overnight"
                                                                                name="DirectFundsDistribution"
                                                                                checked={
                                                                                    directFundsDistribution ===
                                                                                    "overnight"
                                                                                }
                                                                                aria-label="Overnight Direct Funds Distribution"
                                                                            />{" "}
                                                                            Overnight $20.00
                                                                            (physical address
                                                                            required)
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingBottom: 5, paddingTop: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            value="rollovertransferexchange"
                                                            name="FundsDistribution"
                                                            checked={
                                                                fundsDistribution ===
                                                                "rollovertransferexchange"
                                                            }
                                                            onChange={
                                                                handleFundsDistributionRadioClick
                                                            }
                                                            aria-label="Directed Rollover, Transfer or Exchange Funds Distribution"
                                                        />{" "}
                                                        Directed Rollover, Transfer or Exchange
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 15 }}>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 70 }}
                                                    >
                                                        {fundsDistribution ===
                                                        "rollovertransferexchange" ? (
                                                            <>
                                                                Rollover, transfer and exchange
                                                                transactions cannot be initiated
                                                                online due to the need to have
                                                                documents with a wet signature.
                                                                Please contact the receiving company
                                                                to obtain the requirement
                                                                documentation.
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* <div className="row" style={{ paddingTop: 15 }}>
                                                <div className="col-9" style={{ paddingLeft: 40 }}>
                                                    If you have additional questions, please call 1-800-555-1212.
                                                </div>
                                            </div> */}
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 6 ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 20 }}
                                                >
                                                    <div className="col-9">
                                                        <b>
                                                            Enter the names of the companies that
                                                            will receive the distribution:
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 5 }}>
                                                    <div
                                                        className="col-4"
                                                        style={{ paddingLeft: 70 }}
                                                    >
                                                        <div>
                                                            <label>Company Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder=""
                                                                aria-label="Company Name"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-4"
                                                        style={{ paddingLeft: 70 }}
                                                    >
                                                        <div>
                                                            <label>
                                                                Amount to distribute to this company
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder=""
                                                                aria-label="Amount to distribute to this company"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 15 }}>
                                                    <div
                                                        className="col-4"
                                                        style={{ paddingLeft: 40 }}
                                                    >
                                                        &nbsp;
                                                    </div>
                                                    <div
                                                        className="col-4"
                                                        style={{ paddingLeft: 40 }}
                                                    >
                                                        Total: $0.00
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 15 }}>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 40 }}
                                                    >
                                                        <input
                                                            type="button"
                                                            value="Add Another Company"
                                                            aria-label="Add Another Company button"
                                                        ></input>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 15 }}>
                                                    <div
                                                        className="col-4"
                                                        style={{ paddingLeft: 40 }}
                                                    >
                                                        Upload any supporting documents
                                                    </div>
                                                    <div
                                                        className="col-4"
                                                        style={{ paddingLeft: 40 }}
                                                    >
                                                        <input
                                                            type="file"
                                                            aria-label="Upload Supporting Documents"
                                                        ></input>
                                                    </div>
                                                </div>
                                                {/* <div className="row" style={{ paddingTop: 15 }}>
                                                <div className="col-9" style={{ paddingLeft: 40 }}>
                                                    If you have additional questions, please call 1-800-555-1212.
                                                </div>
                                            </div> */}
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 7 ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 20 }}
                                                >
                                                    <div className="col-9">
                                                        <b>
                                                            Please upload an image of a voided check
                                                        </b>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingBottom: 15, paddingTop: 15 }}
                                                >
                                                    <div
                                                        className="col-2"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="button"
                                                            value="Photo Library"
                                                            aria-label="Photo Library"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        className="col-2"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="button"
                                                            value="Take Photo"
                                                            aria-label="Take Photo"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        className="col-2"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="button"
                                                            value="Choose File"
                                                            aria-label="Choose File"
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 8 ? (
                                            <div onChange={handleWithholdTaxRadioClick}>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 20 }}
                                                >
                                                    <div className="col-9">
                                                        <b>
                                                            Would you like federal income tax
                                                            withheld?
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingBottom: 5 }}>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            value="false"
                                                            name="WithholdTax"
                                                            checked={withholdTax === false}
                                                            aria-label="Do not withhold federal income tax from the taxable portion of my distribution"
                                                        />{" "}
                                                        I elect NOT to have federal income tax
                                                        withheld from the taxable portion of my
                                                        distribution check.
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingBottom: 5 }}>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            value="true"
                                                            name="WithholdTax"
                                                            checked={withholdTax === true}
                                                            aria-label="Withhold federal income tax from the taxable portion of my distribution"
                                                        />{" "}
                                                        I elect to have federal income tax withheld
                                                        from the taxable portion of my distribution
                                                        check.
                                                    </div>
                                                </div>
                                                {withholdTax ? (
                                                    <>
                                                        <div
                                                            className="row"
                                                            style={{ paddingBottom: 5 }}
                                                        >
                                                            <div
                                                                className="col-9"
                                                                style={{ paddingLeft: 70 }}
                                                            >
                                                                (specify rate if greater than
                                                                10%)&nbsp;
                                                                <input
                                                                    type="text"
                                                                    value=""
                                                                    name="WithholdTaxAmount"
                                                                    aria-label="Tax Withhold Amount"
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <div className="row" style={{ paddingBottom: 5 }}>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <p>
                                                            Please note: If your payments of
                                                            estimated tax are inadequate and a
                                                            sufficient amount of tax is not withheld
                                                            from any distribution, penalties may be
                                                            imposed under the estimated tax payment
                                                            rules. If you elect withholding of
                                                            federal income tax and your state
                                                            requires withholding of state income
                                                            tax, both taxes will be withheld. If you
                                                            elect not to have federal income tax
                                                            withheld, state income tax, if required,
                                                            will not be withheld.
                                                        </p>
                                                        <p>
                                                            If you elect not to have withholding
                                                            apply to your payment, or if you do not
                                                            have enough federal income tax withheld,
                                                            you may be responsible for payment of
                                                            estimated tax. You may incur penalties
                                                            under the estimated rules if your
                                                            withholding and estimated tax payments
                                                            are not sufficient. Most distributions
                                                            (both periodic and non-periodic) from
                                                            qualified retirement plans and
                                                            nonqualified annuity contacts made to
                                                            you before you reach age 59½ may be
                                                            subject to an additional tax of 10%.
                                                        </p>
                                                        <p>
                                                            As a result of the Unemployment
                                                            Compensation Amendments of 1992 (P.L.
                                                            102-318), if this distribution is an
                                                            “eligible rollover distribution,” as
                                                            defined in Internal Revenue Code Section
                                                            402(f)(2)(A), we are required to
                                                            withhold 20% of your distribution unless
                                                            it is paid directly to another trustee.
                                                            If this distribution is not an eligible
                                                            rollover distribution, then we are
                                                            required to inform you of and give you
                                                            an opportunity to make a tax withholding
                                                            election. The new provisions apply to
                                                            distributions from qualified and
                                                            nonqualified life insurance policies and
                                                            annuities including, but not limited to,
                                                            deferred compensation plans, pension
                                                            plans and 403(b) distributions. Partial
                                                            surrenders of these contracts are to be
                                                            treated as distributions. Withholding
                                                            will only apply to the portion of your
                                                            distribution that is included in your
                                                            income subject to federal income tax.
                                                            Thus, there would be no withholding on
                                                            the return of your own nondeductible
                                                            contribution. If an election is not made
                                                            or if withholding is elected, the
                                                            Company is required to withhold as
                                                            follows:
                                                            <br></br>If your check is a nonperiodic
                                                            payment, the rate of withholding will be
                                                            either: (a) determined according to
                                                            computational procedures or tables
                                                            provided in the Treasury Regulations
                                                            accompanying Internal Revenue Code
                                                            Section 3405 if the distribution is
                                                            either a qualified total distribution or
                                                            a total distribution by reason of death
                                                            of the participant; or (b) 10% for any
                                                            other nonperiodic payment, unless a
                                                            higher rate is requested.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 9 ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div className="col-9">
                                                        <b>Taxpayer Identification Certification</b>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        Under penalties of perjury, I certify that:
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        1. I am a US citizen or other US person, and
                                                        that the number shown on this form is my
                                                        correct taxpayer identification number (or I
                                                        am waiting for a number to be issued to me.)
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        2. I am not subject to backup withholding
                                                        because:<br></br>
                                                        A. I am exempt from backup withholding, or
                                                        <br></br>
                                                        B. I have not been notified by the Internal
                                                        Revenue Service (IRS) that I am subject to
                                                        backup withholding as a result of a failure
                                                        to report all interest or dividends, or
                                                        <br></br>
                                                        C. the IRS has notified me that I am no
                                                        longer subject to backup withholding. Check
                                                        the box if you have been notified by the IRS
                                                        that you are currently subject to backup
                                                        withholding because you failed to report all
                                                        interest or dividends on your tax return.
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            name="BackupWithholdingCheck"
                                                            checked={backupWithholdingCheck}
                                                            aria-label="I am subject to backup withholding"
                                                        />{" "}
                                                        <b>
                                                            Check this box if you have been notified
                                                            by the IRS that you are currently
                                                            subject to backup withholding because
                                                            you failed to report all interest or
                                                            dividends on your tax return
                                                        </b>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        3. I am exempt from FATCA (Foreign Account
                                                        Tax Compliance Act) reporting.
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 10 ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div className="col-9">
                                                        <b>Review and Submit:</b>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <p>Request Summary:</p>
                                                        <p style={{ paddingLeft: 30 }}>
                                                            Amount to be surrendered: $0.00
                                                            Processed as a net amount
                                                        </p>
                                                        <p style={{ paddingLeft: 30 }}>
                                                            Rollover distribution to Infinitas
                                                            Wealth Management by Regular Mail
                                                        </p>
                                                        <p style={{ paddingLeft: 30 }}>
                                                            No federal income tax will be withheld
                                                            from the taxable portion of my
                                                            distribution check.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <p>
                                                            Click submit to finalize your request.
                                                        </p>
                                                        <p>
                                                            NOTE: Once your request is received...
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {requestSubmitted ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div className="col-9">
                                                        <b>Your request has been submitted</b>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <p>
                                                            You will receive an email at the email
                                                            you used to log in confirming receipt of
                                                            your request.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                            <div className="col">
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-4">
                                        <div className="row">
                                            {requestSubmitted ? (
                                                <>
                                                    <div className="col">
                                                        <input
                                                            type="button"
                                                            value="OK"
                                                            className="btn btn-Primary"
                                                            onClick={() =>
                                                                navigate.push(
                                                                    "/policy-detail/" +
                                                                        idParsed +
                                                                        "?tab=service"
                                                                )
                                                            }
                                                            style={{ width: "90px" }}
                                                            aria-label="OK button"
                                                        ></input>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {stepNumber > 1 ? (
                                                        <div className="col">
                                                            <input
                                                                type="button"
                                                                value="Previous"
                                                                className="btn btn-Primary"
                                                                onClick={onBack}
                                                                style={{ width: "90px" }}
                                                                aria-label="Previous button"
                                                            ></input>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {stepNumber < 9 ? (
                                                        <>
                                                            <div className="col">
                                                                <input
                                                                    type="button"
                                                                    value="Next"
                                                                    className="btn btn-Primary"
                                                                    onClick={onNext}
                                                                    style={{ width: "90px" }}
                                                                    aria-label="Next button"
                                                                ></input>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col">
                                                                <input
                                                                    type="button"
                                                                    value="Submit"
                                                                    className="btn btn-Primary"
                                                                    onClick={onSubmit}
                                                                    style={{ width: "90px" }}
                                                                    aria-label="Submit button"
                                                                ></input>
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="col">
                                                        <input
                                                            type="button"
                                                            className="btn btn-Primary"
                                                            onClick={() =>
                                                                navigate.push(
                                                                    "/policy-detail/" +
                                                                        idParsed +
                                                                        "?tab=service"
                                                                )
                                                            }
                                                            value="Cancel"
                                                            style={{ width: "90px" }}
                                                            aria-label="Cancel button"
                                                        ></input>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <section aria-labelledby="surrender-section">
                <h1 style={{ display: "none" }}>Surrender Policy Error Message</h1>
                <h2 style={{ display: "none" }} aria-labelledby="surrender-section">
                    Surrender Section
                </h2>
                <div
                    className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-danger"
                    aria-live="assertive"
                    role="alert"
                >
                    {errorMessage}
                </div>
            </section>
        );
    } else if (isProcessing) {
        return (
            <section aria-labelledby="surrender-section">
                <h1 style={{ display: "none" }}>Surrender Policy Spinner</h1>
                <h2 style={{ display: "none" }} aria-labelledby="surrender-section">
                    Surrender Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    } else {
        return (
            <section aria-labelledby="surrender-section">
                <h1 style={{ display: "none" }}>Surrender Policy Contact Customer Service</h1>
                <h2 style={{ display: "none" }} aria-labelledby="surrender-section">
                    Surrender Section
                </h2>
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                    There was a problem loading your policy information, please call us at
                    800-231-0801 for assistance.
                </div>
            </section>
        );
    }
};

export default Surrender;
/*eslint-enable*/

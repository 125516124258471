import React from "react";

const defaultState: ICPHeaderContext = {
    impName: "",
    impEmail: "",
    isCustomer: false,
    isAdmin: false,
    isHO: false,
    hideForNonDev: true,
    userInfo: {},
    sessionId: "",
    isMaint: false,
    messageCount: 0,
    featureFlags: {},
    customer: {
        showAccountSettings: false,
        messageCount: 0
    },
    changeImpersonateName: () => {
        /**/
    },
    changeImpersonateEmail: () => {
        /**/
    },
    /*eslint-disable*/
    changeIsCustomer: (_is: boolean) => {
        /**/
    },
    changeIsAdmin: (_is: boolean) => {
        /**/
    },
    changeIsHO: (_is: boolean) => {
        /**/
    },
    changeSessionId: () => {
        /**/
    },
    changeHideForNonDev: () => {
        /**/
    },
    changeIsMaint: () => {
        /**/
    },
    changeMessageCount: () => {
        /**/
    }
    /*eslint-enable*/
};

const CPContext = React.createContext<ICPHeaderContext>(defaultState);

export { CPContext };

import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

const AdminFAQList: React.FC = () => {
    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { get } = useFetch();
    const [faqs, setFAQs] = useState<FAQ[]>([]);
    const [inActiveFAQDisplay, setInActiveFAQDisplay] = useState<boolean>(false);
    const [faqSearch, setFAQSearch] = useState("");
    const { authState, oktaAuth } = useOktaAuth();
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const getFAQs = async () => {
            try {
                const faqsFromServer = await get<FAQ[]>(
                    "/faq/adminlist",

                    ""
                );
                let faqsTemp = faqsFromServer;
                if (faqsTemp) {
                    faqsTemp =
                        inActiveFAQDisplay === true
                            ? faqsTemp.filter((faq: FAQ) => faq.isActive === false)
                            : faqsTemp.filter((faq: FAQ) => faq.isActive === true);

                    //filter
                    faqsTemp =
                        faqSearch !== "" && faqSearch !== "undefined"
                            ? faqsTemp.filter(
                                  (faq: FAQ) =>
                                      faq.question.indexOf(faqSearch) !== -1 ||
                                      faq.response.indexOf(faqSearch) !== -1
                              )
                            : faqsTemp;
                }

                setFAQs(faqsTemp);
            } catch (error) {
                setErrorMessage("An unexpected error has occured, please try again.");
            }
        };

        // if (!authState || !authState.isAuthenticated) {
        //     navigate.push('/')
        // } else {
        //     const at: string = oktaAuth.getAccessToken() || ''
        //     setAccessToken(at)
        //     const decoded: any = jwt_decode(at)
        //     if (decoded) {
        //         setIsAdmin(decoded.isAdmin)
        //     }

        //     if (accessToken && isAdmin) {
        //         getFAQs()
        //     } else if (accessToken && !isAdmin) {
        //         navigate.push('/')
        //     }
        // }

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getFAQs();
        }
    }, [faqSearch, inActiveFAQDisplay, authState, oktaAuth, cpContext, navigate]);

    if (cpContext.isAdmin) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-md-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: 2,
                            borderStyle: "solid",
                            borderColor: "#33CCCC"
                        }}
                    >
                        <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                            FAQ Admin
                        </h1>
                    </div>
                    <div className="row" style={{ paddingTop: 50 }}>
                        <div className="col-md-3">
                            <div className="inner-addon right-addon">
                                <i className="biCustom bi-search"></i>
                                <input
                                    type="text"
                                    name="faqSearch"
                                    value={faqSearch}
                                    placeholder="Search..."
                                    className="form-control skip-to"
                                    onChange={(e) => setFAQSearch(e.target.value)}
                                    aria-label="Search FAQs"
                                ></input>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div
                                className="form-control form-control-check border-0"
                                style={{ backgroundColor: "#eee" }}
                            >
                                <label style={{ display: "inline-block" }}>Display Inactive?</label>
                                <input
                                    type="checkbox"
                                    name="inActiveFAQDisplay"
                                    value={inActiveFAQDisplay.toString()}
                                    onChange={(e) => setInActiveFAQDisplay(e.currentTarget.checked)}
                                    className="inactiveCheckbox"
                                    aria-label="Inactive checkbox"
                                ></input>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div
                                className="form-control form-control-check border-0"
                                style={{ backgroundColor: "#eee" }}
                            >
                                <Link to={"/admin/faqadd"} style={{ float: "right" }}>
                                    <button className="btn btn-Primary">Add</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Table striped bordered hover responsive="md">
                                <thead
                                    style={{
                                        backgroundColor: "#003366",
                                        color: "white",
                                        height: "40px",
                                        textAlign: "center"
                                    }}
                                >
                                    <tr>
                                        <th
                                            aria-label="Pencil Icon (Edit)"
                                            style={{ width: "5%" }}
                                        ></th>
                                        <th className="text-start" style={{ width: "70%" }}>
                                            Question
                                        </th>
                                        <th>Sequence</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {faqs && faqs.length > 0 ? (
                                        <>
                                            {faqs.map((faq, idx) => (
                                                <tr key={idx}>
                                                    <td tabIndex={faq.id}>
                                                        <Link to={"/admin/faq/" + faq.id}>
                                                            <span
                                                                className="bi bi-pencil"
                                                                style={{ color: "black" }}
                                                                title="Edit FAQ"
                                                            ></span>
                                                        </Link>
                                                    </td>
                                                    <td
                                                        tabIndex={0}
                                                        style={{
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                    >
                                                        {faq.question}
                                                    </td>
                                                    <td
                                                        tabIndex={0}
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        {faq.displaySequence}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <td colSpan={3}>
                                                    {faqSearch !== "" &&
                                                    faqSearch !== "undefined" ? (
                                                        <>No results found.</>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <div
                className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-danger"
                aria-live="assertive"
                role="alert"
            >
                {errorMessage}
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default AdminFAQList;

import React, { useEffect, useContext } from "react";
import { Card } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import useLogger from "../../_helpers/useLogger";
import { CPContext } from "../../_helpers/CPContext";

export const Login: React.FC = () => {
    const { oktaAuth } = useOktaAuth();
    const cpContext = useContext(CPContext);
    const logger = useLogger();

    useEffect(() => {
        const doLogin = async () => {
            await logger.logDetailedActivity(
                "Home",
                "loginredirect",
                cpContext,
                "",
                "anonymous",
                ""
            );
            oktaAuth.signInWithRedirect({ originalUri: "/" });
        };

        doLogin();
    }, [oktaAuth, cpContext]);

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
    }

    return (
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
            <Card
                body
                style={{
                    minWidth: "350px",
                    backgroundColor: "#EEEEEE",
                    borderStyle: "none",
                    boxShadow: "0 0 10px 0 rgba(100, 100, 100, 0.26)"
                }}
            >
                <form
                    className="add-form"
                    onSubmit={onSubmit}
                    style={{ backgroundColor: "#EEEEEE" }}
                >
                    <div>
                        <label>Please Wait...</label>
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default Login;

import React, { useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { CPContext } from "_helpers/CPContext";
import { BillingMethod } from "enums/BillingMethod";
import { PolicyType } from "enums/PolicyType";
import { useOktaAuth } from "@okta/okta-react";
import useLogger from "_helpers/useLogger";

const ManageMyPayments: React.FC<{ policyData: Policy; id: number }> = (parameters) => {
    const params = useParams<propType>();
    const cpContext = useContext(CPContext);
    const logger = useLogger();
    const navigate = useHistory();

    let idParsed = 0;

    if (params.id && params.id.trim()) {
        idParsed = parseInt(params.id);
    }

    return (
        <div className="row">
            <div className="col-12 col-md-8 col-lg-6 offset-md-1 px-4 pt-1 pb-3 manage-my-payments-section">
                <h1 className="custom-h1 pt-3">Manage My Payments</h1>
                <div className="d-flex flex-column align-items-start">
                    <button
                        className={`pt-2 text-start text-decoration-none d-inline-block manage-my-payments-link ${
                            parameters.policyData.summary.billingMethod ===
                                BillingMethod.BankDraft || !(cpContext.isHO || cpContext.isCustomer)
                                ? "disabled-link"
                                : ""
                        }`}
                        onClick={() => {
                            if (parameters.policyData.electronicDisclosure) {
                                logger.loggedRequestNavigate(
                                    `/payments/add-new-bank-draft`,
                                    "ManageMyPayments",
                                    "",
                                    "",
                                    false,
                                    parameters.policyData,
                                    idParsed
                                );
                            } else {
                                logger.loggedRequestNavigate(
                                    `/electronicauth/${idParsed}?request=/payments/add-new-bank-draft/`,
                                    "ManageMyPayments",
                                    "",
                                    "",
                                    false,
                                    parameters.policyData
                                );
                            }
                        }}
                    >
                        Add New Bank Draft
                    </button>
                    <button
                        className={`pt-2 text-start text-decoration-none d-inline-block manage-my-payments-link ${
                            parameters.policyData.summary.billingMethod ===
                                BillingMethod.BankDraft &&
                            (cpContext.isHO || cpContext.isCustomer)
                                ? ""
                                : "disabled-link"
                        }`}
                        onClick={() => {
                            if (parameters.policyData.electronicDisclosure) {
                                logger.loggedRequestNavigate(
                                    `/payments/change-bank-routing-account`,
                                    "ManageMyPayments",
                                    "",
                                    "",
                                    false,
                                    parameters.policyData,
                                    idParsed
                                );
                            } else {
                                logger.loggedRequestNavigate(
                                    `/electronicauth/${idParsed}?path=/payments/change-bank-routing-account/`,
                                    "ManageMyPayments",
                                    "",
                                    "",
                                    false,
                                    parameters.policyData
                                );
                            }
                        }}
                    >
                        Bank Account or Routing Number Change
                    </button>
                    <button
                        className={`pt-2 text-start text-decoration-none d-inline-block manage-my-payments-link ${
                            parameters.policyData.summary.billingMethod ===
                                BillingMethod.BankDraft &&
                            parameters.policyData.summary.billingMethod ===
                                BillingMethod.BankDraft &&
                            (cpContext.isHO || cpContext.isCustomer)
                                ? ""
                                : "disabled-link"
                        }`}
                        onClick={() => {
                            logger.loggedRequestNavigate(
                                `/formdownload`,
                                "ManageMyPayments",
                                "",
                                "AF55019",
                                false,
                                parameters.policyData,
                                idParsed
                            );
                        }}
                    >
                        Change Bank Draft Date
                    </button>
                    <button
                        className={`pt-2 text-start text-decoration-none d-inline-block manage-my-payments-link ${
                            parameters.policyData.summary.billingMethod ===
                                BillingMethod.BankDraft &&
                            (parameters.policyData.policyType == PolicyType.IUL ||
                                parameters.policyData.policyType == PolicyType.Universal ||
                                parameters.policyData.policyType == PolicyType.CashBackOption) &&
                            (cpContext.isHO || cpContext.isCustomer)
                                ? ""
                                : "disabled-link"
                        }`}
                        onClick={() => {
                            if (parameters.policyData.electronicDisclosure) {
                                logger.loggedRequestNavigate(
                                    `/payments/change-bank-draft-amount`,
                                    "ManageMyPayments",
                                    "",
                                    "",
                                    false,
                                    parameters.policyData,
                                    idParsed
                                );
                            } else {
                                logger.loggedRequestNavigate(
                                    `/electronicauth/${idParsed}?path=/payments/change-bank-draft-amount/`,
                                    "ManageMyPayments",
                                    "",
                                    "",
                                    false,
                                    parameters.policyData
                                );
                            }
                        }}
                    >
                        Change Bank Draft Amount
                    </button>
                    <button
                        className={`pt-2 text-start text-decoration-none d-inline-block manage-my-payments-link ${
                            cpContext.isHO || cpContext.isCustomer ? "" : "disabled-link"
                        }`}
                        onClick={() => {
                            if (parameters.policyData.electronicDisclosure) {
                                logger.loggedRequestNavigate(
                                    `/payments/one-time-payment`,
                                    "ManageMyPayments",
                                    "",
                                    "",
                                    false,
                                    parameters.policyData,
                                    idParsed
                                );
                            } else {
                                logger.loggedRequestNavigate(
                                    `/electronicauth/${idParsed}?path=/payments/one-time-payment/`,
                                    "ManageMyPayments",
                                    "",
                                    "",
                                    false,
                                    parameters.policyData
                                );
                            }
                        }}
                    >
                        One-Time Payment
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ManageMyPayments;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import useFetch from "../../_helpers/useFetch";
import { config } from "../../config";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../shared/Common";
import useLogger from "../../_helpers/useLogger";
import useUtilities from "../../_helpers/useUtilities";
import AdyenCheckout from "@adyen/adyen-web";
import { Card } from "react-bootstrap";
import "@adyen/adyen-web/dist/adyen.css";

type propType = {
    id: string;
};

type SessionRequest = {
    policyDWKey: number;
    issueState: string;
    paymentAmount: number;
    companyCode: number;
};

type SessionResponse = {
    provider: string;
    sessionIdentifier: string;
    sessionData: string;
    principal: number;
    fee: number;
    total: number;
    error: string;
};

type AdyenSession = {
    id: string;
    sessionData: string;
};

const RemotePayment: React.FC = () => {
    const params = useParams<propType>();
    let idParsed = 0;
    const navigate = useHistory();

    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
    }

    const cpContext = useContext(CPContext);
    const utilities = useUtilities();
    const logger = useLogger();
    const { get, post } = useFetch();
    const { authState, oktaAuth } = useOktaAuth();
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessingPolicy, setIsProcessingPolicy] = useState(false);
    const [isProcessingPaymentAPI, setIsProcessingPaymentAPI] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [adyenSession, setAdyenSession] = useState<AdyenSession>();
    const [paymentComponentMounted, setPaymentComponentMounted] = useState(false);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [paymentFee, setPaymentFee] = useState(0);
    const [paymentTotal, setPaymentTotal] = useState(0);
    const paymentContainer = useRef(null);
    const [stepNumber, setStepNumber] = useState(2);
    const [headerText, setHeaderText] = useState("One-Time Online Payment");
    const [agreeToTermsChecked, setAgreeToTermsChecked] = useState(false);
    const search = navigate.location.search;
    const paramsFromURL = new URLSearchParams(search);

    const redirectUri: string = paramsFromURL.get("payment") ?? "service";

    const configuration = {
        environment: config.adyen.environment,
        clientKey: config.adyen.clientKey,
        analytics: {
            enabled: true
        },
        session: {
            id: "",
            sessionData: ""
        },
        // eslint-disable-next-line
        onPaymentCompleted: (result: any, component: any) => {
            if (result && result.resultCode) {
                console.log("result.resultCode = " + result.resultCode);
                if (result.resultCode == "Authorised") {
                    setPaymentComplete(true);
                }
            }
            console.info(result, component);
        },
        // eslint-disable-next-line
        onError: (error: any, component: any) => {
            console.error(error.name, error.message, error.stack, component);
        },
        paymentMethodsConfiguration: {
            card: {
                hasHolderName: true,
                holderNameRequired: true,
                billingAddressRequired: true,
                billingAddressMode: "full",
                billingAddressAllowedCountries: ["US", "VI", "GU"],
                brands: ["mc", "visa", "discover"]
            }
        }
    };

    const setupAdyenSession = async (
        _issueState: string,
        _amount: number,
        _companyCode: number
    ): Promise<AdyenSession> => {
        const paymentSession: AdyenSession = {
            id: "",
            sessionData: ""
        };

        if (!isProcessingPaymentAPI) {
            try {
                if (adyenSession && adyenSession.id && adyenSession.id.length > 0) {
                    return adyenSession;
                } else {
                    setIsProcessingPaymentAPI(true);
                    const body: SessionRequest = {
                        companyCode: _companyCode,
                        issueState: _issueState,
                        paymentAmount: _amount,
                        policyDWKey: idParsed
                    };

                    const result = await post<SessionRequest, SessionResponse>(
                        "/payment/newsession",
                        "",
                        body
                    );

                    if (result && result.error && result.error.length > 0) {
                        //setErrorMessage(result.error)
                        setErrorMessage(
                            "Online payments are currently unavailable at this time. Contact customer service at 1-800-231-0801."
                        );
                    } else if (result && result.provider == "adyen") {
                        paymentSession.id = result.sessionIdentifier;
                        paymentSession.sessionData = result.sessionData;
                        setAdyenSession(paymentSession);
                        setPaymentAmount(result.principal);
                        setPaymentFee(result.fee);
                        setPaymentTotal(result.total);
                    } else {
                        setErrorMessage(
                            "Online payments are currently unavailable at this time. Contact customer service at 1-800-231-0801."
                        );
                        console.log("setupAdyenSession " + JSON.stringify(result));
                    }
                    setIsProcessingPaymentAPI(false);
                }
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    //setErrorMessage("An unexpected error has occured.")
                    setErrorMessage(
                        "Online payments are currently unavailable at this time. Contact customer service at 1-800-231-0801."
                    );
                    setIsProcessingPaymentAPI(false);
                }
            }
        }

        return paymentSession;
    };

    const fetchPolicy = async (_id: number) => {
        if (!isProcessingPolicy) {
            try {
                if (policyData && policyData.summary) {
                    //nothing
                } else {
                    setIsProcessingPolicy(true);
                    const result = await get<Policy>("/policy/full?policyDWKey=" + _id, "");

                    setIsProcessingPolicy(false);
                    return result;
                }
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    setErrorMessage("An unexpected error has occured.");
                    setIsProcessingPolicy(false);
                }
            }
        }
    };

    useEffect(() => {
        const getPolicy = async () => {
            try {
                setIsProcessing(true);
                const policyFromServer = await fetchPolicy(idParsed);
                setPolicyData(policyFromServer);

                if (policyFromServer) {
                    const tempSession = await setupAdyenSession(
                        policyFromServer.issueStateAbbr ?? "NA",
                        policyFromServer.summary.premiumAmount ?? 0,
                        policyFromServer.companyCode ?? 0
                    );
                    setAdyenSession(tempSession);
                }

                setIsProcessing(false);
            } catch {
                setErrorMessage(
                    "There was a problem loading your policy information, please call us at 800-231-0801 for assistance."
                );
                setIsProcessing(false);
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getPolicy();
        }

        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext, navigate]); // Update if authState changes

    const LoggedNavigate = async (_uri: string, _linkName: string) => {
        await logger.logDetailedActivity(
            "OneTimePayment",
            _linkName,
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );

        navigate.push(_uri);
    };

    const createCheckout = async (_sessionId: string, _sessionData: string) => {
        configuration.session.id = _sessionId;
        configuration.session.sessionData = _sessionData;
        const checkout = await AdyenCheckout(configuration);

        if (paymentContainer.current && !paymentComponentMounted) {
            checkout.create("dropin").mount(paymentContainer.current);
            setPaymentComponentMounted(true);
        } else {
            setPaymentComponentMounted(false);
        }
    };

    const onClickMoveToPayment = async () => {
        if (!paymentComponentMounted && policyData) {
            if (!agreeToTermsChecked) {
                setErrorMessage(
                    "You must agree to terms and conditions in order to proceed to payment."
                );
            } else if (agreeToTermsChecked) {
                setStepNumber(3);
                setHeaderText("One-Time Online Payment");
                setErrorMessage("");
                await logger.logDetailedActivity(
                    "One Time Payment",
                    "proceed to process payment",
                    cpContext,

                    cpContext.userInfo?.email,
                    cpContext.sessionId,
                    "Accepted Terms and Conditions, proceeding to process payment",
                    idParsed
                );
            }

            if (adyenSession && adyenSession.id && adyenSession.id.length > 0) {
                await createCheckout(adyenSession.id, adyenSession.sessionData);
            } else {
                //Not sure we need to display this anymore
                //setErrorMessage("Adyen Payment failed to load, please call us at 800-231-0801 for assistance.")
                setErrorMessage(
                    "Online payments are currently unavailable at this time. Contact customer service at 1-800-231-0801."
                );
            }
        }
    };

    const onClickMoveToPrevStep = async () => {
        const currentStep = stepNumber;
        setStepNumber(currentStep - 1);
        if (currentStep - 1 === 2) {
            setHeaderText("Payment Authorization");
        } else {
            setHeaderText("One-Time Online Payment");
        }
    };

    if (policyData && policyData.summary && adyenSession && adyenSession.id) {
        return (
            <section aria-labelledby="one-time-payment-section">
                <h2 style={{ display: "none" }} aria-labelledby="one-time-payment-section">
                    One-Time Payment Section
                </h2>
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-md-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-6">
                                <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                    {headerText}
                                </h1>
                            </div>
                            <div className="col-6">
                                <div style={{ paddingTop: 10 }}>
                                    {policyData?.policyNumber} - {policyData.product}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <div>
                                    {errorMessage && errorMessage.length > 0 ? (
                                        <>
                                            <label
                                                className="text-danger"
                                                aria-live="assertive"
                                                role="alert"
                                            >
                                                {errorMessage}
                                                <br />
                                                <br />
                                            </label>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>

                        {stepNumber === 2 && (
                            <div style={{ margin: -10 }}>
                                <Card>
                                    <Card.Body>
                                        <div className="skip-to">
                                            <p>
                                                <b>
                                                    Please acknowledge the conditions of your
                                                    payment:
                                                </b>
                                            </p>
                                            <p>
                                                This website is for current and prospective
                                                customers of Americo Financial Life and Annuity
                                                Insurance Company, Great Southern Life Insurance
                                                Company, Investors Life Insurance Company of North
                                                America, National Farmers Union Life Insurance
                                                Company, and United Fidelity Life Insurance Company,
                                                each individually a “Life Company” and together the
                                                “Life Companies.”
                                            </p>
                                            <div style={{ color: "#ff0000", fontStyle: "italic" }}>
                                                <p>
                                                    Attention: If your policy is currently set up
                                                    for automatic bank draft, using a credit/debit
                                                    card for a one-time payment will not override
                                                    the bank draft, resulting in duplicate payments.
                                                    To avoid duplicate payments, please contact us
                                                    immediately at 1-800-231-0801 to address this
                                                    matter.
                                                </p>
                                            </div>
                                            <p>
                                                By checking the box, I agree to be bound by the
                                                terms and conditions of the applicable insurance
                                                product and warrant the following:
                                            </p>
                                            <div
                                                className="row"
                                                style={{ paddingBottom: 15, paddingTop: 15 }}
                                            >
                                                <div
                                                    className="col-1"
                                                    style={{ paddingLeft: 30 }}
                                                ></div>
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    I authorize the applicable Life Company to
                                                    initiate a one-time charge to the credit card
                                                    identified on this form for the amount shown.
                                                </div>
                                            </div>

                                            <div
                                                className="row"
                                                style={{ paddingBottom: 15, paddingTop: 15 }}
                                            >
                                                <div
                                                    className="col-1"
                                                    style={{ paddingLeft: 30 }}
                                                ></div>
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    I understand the policy effective date may
                                                    differ from this transaction date, and that such
                                                    policy effective date is subject to the
                                                    applicable policy.
                                                </div>
                                            </div>

                                            <div className="row" style={{ paddingTop: 15 }}>
                                                <div
                                                    className="col-1"
                                                    style={{ paddingLeft: 30 }}
                                                ></div>
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    I certify that I am an authorized user of this
                                                    credit or debit card. I will not dispute the
                                                    payment with my credit card company as long as
                                                    the transactions correspond to the terms
                                                    indicated on this form.
                                                </div>
                                            </div>

                                            <div
                                                className="row"
                                                style={{ paddingBottom: 15, paddingTop: 15 }}
                                            >
                                                <div
                                                    className="col-1"
                                                    style={{ paddingLeft: 30 }}
                                                ></div>
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    I may be charged a returned payment fee
                                                    (“Non-sufficient Funds Fee” or “NSF”) if my
                                                    financial institution is unable to process the
                                                    transaction due to insufficient funds or account
                                                    closure. This fee will not exceed 5% or the
                                                    maximum rate permitted by law. The Life Company
                                                    may charge my credit or debit card a second time
                                                    if it is notified of a returned payment.
                                                </div>
                                            </div>

                                            <div
                                                className="row"
                                                style={{ paddingBottom: 15, paddingTop: 15 }}
                                            >
                                                <div
                                                    className="col-1"
                                                    style={{ paddingLeft: 30 }}
                                                ></div>
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    I consent to the use and disclosure of my
                                                    personal or card information as detailed in the
                                                    Life Companies Privacy Policy.
                                                </div>
                                            </div>

                                            <div
                                                className="row"
                                                style={{ paddingBottom: 15, paddingTop: 15 }}
                                            >
                                                <div
                                                    className="col-1"
                                                    style={{ paddingLeft: 30 }}
                                                ></div>
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    Any dispute regarding this transaction will be
                                                    governed by the State of Issue for the Policy,
                                                    in the courts located solely in Kansas City,
                                                    Missouri, excluding any conflict of law rules.
                                                </div>
                                            </div>

                                            <div className="row" style={{ paddingBottom: 30 }}>
                                                <div className="col-1" style={{ paddingLeft: 112 }}>
                                                    <input
                                                        type="checkbox"
                                                        onChange={() =>
                                                            setAgreeToTermsChecked(
                                                                !agreeToTermsChecked
                                                            )
                                                        }
                                                        aria-label="Agree to Terms checkbox"
                                                    ></input>
                                                </div>
                                                <div className="col-9">
                                                    <b>
                                                        I acknowledge the payment conditions above.
                                                    </b>
                                                </div>
                                            </div>

                                            <p>
                                                For more information regarding the applicable Life
                                                Company&apos;s cancellation policy, your free look
                                                period or general policy questions, please consult
                                                your policy or contact a representative at{" "}
                                                <a href="mailto:customer.service@americo.com">
                                                    customer.service@americo.com
                                                </a>{" "}
                                                or at <a href="#">800-231-0801</a>.
                                            </p>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <div className="row" style={{ marginTop: 15 }}>
                                    <input
                                        type="button"
                                        value="Previous"
                                        className="btn btn-Primary"
                                        onClick={() =>
                                            logger
                                                .loggedRequestNavigate(
                                                    `/payments`,
                                                    "ManageMyPayments",
                                                    "",
                                                    "",
                                                    false,
                                                    policyData,
                                                    idParsed
                                                )
                                                .then((uri) => navigate.push(uri))
                                        }
                                        style={{ width: "90px", marginRight: 5 }}
                                        aria-label="Previous button"
                                    ></input>
                                    <input
                                        type="button"
                                        value="Next"
                                        className="btn btn-Primary"
                                        onClick={() => onClickMoveToPayment()}
                                        style={{ width: "90px", marginRight: 10 }}
                                        aria-label="Next button"
                                    ></input>
                                    <input
                                        type="button"
                                        className="btn btn-Primary"
                                        onClick={() =>
                                            LoggedNavigate(
                                                `/policy-detail/${idParsed}?tab=${redirectUri}`,
                                                "Cancel"
                                            )
                                        }
                                        value="Cancel"
                                        style={{ width: "90px" }}
                                        aria-label="Cancel button"
                                    ></input>
                                </div>
                            </div>
                        )}

                        {stepNumber === 3 ? (
                            <div className="row">
                                {!paymentComplete ? (
                                    <div
                                        className="col"
                                        style={{
                                            fontFamily: "PT Sans Bold",
                                            fontSize: "20px",
                                            paddingBottom: 15
                                        }}
                                    >
                                        <Card>
                                            <Card.Body>
                                                <div className="skip-to">
                                                    Premium Amount:{" "}
                                                    {utilities.formatCurrency(paymentAmount)}
                                                    <br></br>
                                                    Payment Fee:{" "}
                                                    {utilities.formatCurrency(paymentFee)}
                                                    <br></br>
                                                    Payment Total:{" "}
                                                    {utilities.formatCurrency(paymentTotal)}
                                                    <br></br>
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#ff0000",
                                                        fontStyle: "italic"
                                                    }}
                                                >
                                                    <p>
                                                        Attention: If your policy is currently set
                                                        up for automatic bank draft, using a
                                                        credit/debit card for a one-time payment
                                                        will not override the bank draft, resulting
                                                        in duplicate payments. To avoid duplicate
                                                        payments, please contact us immediately at
                                                        1-800-231-0801 to address this matter.
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className="col-12">
                                    <div className="payment-container">
                                        <div ref={paymentContainer} className="payment"></div>
                                    </div>
                                </div>
                                <div className="col-12" style={{ marginTop: 5 }}>
                                    {!paymentComplete ? (
                                        <div style={{ minWidth: "100%", textAlign: "center" }}>
                                            <a
                                                href="#"
                                                onClick={() =>
                                                    LoggedNavigate(
                                                        `/policy-detail/${idParsed}?tab=${redirectUri}`,
                                                        "Cancel"
                                                    )
                                                }
                                                style={{
                                                    textDecoration: "none",
                                                    color: "#0d6efd !important"
                                                }}
                                            >
                                                Cancel and return to Policy Details
                                            </a>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {paymentComplete ? (
                            <>
                                <div>
                                    <div
                                        className="row"
                                        style={{ paddingTop: 5, paddingBottom: 15 }}
                                    >
                                        <div className="col-9" style={{ paddingLeft: 30 }}>
                                            <p>
                                                {/* We are processing your payment, it will be visible in 24-48 hours on your policy detail page. */}
                                                Your transaction has been processed and will be
                                                available in transaction history tomorrow.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col">
                                                <input
                                                    type="button"
                                                    value="OK"
                                                    className="btn btn-Primary"
                                                    onClick={() =>
                                                        LoggedNavigate(
                                                            "/policy-detail/" +
                                                                idParsed +
                                                                "?tab=details",
                                                            "OK"
                                                        )
                                                    }
                                                    style={{ width: "90px" }}
                                                    aria-label="OK button"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </section>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <section aria-labelledby="one-time-payment-section">
                <h1 style={{ display: "none" }}>One-Time Payment Error Message</h1>
                <h2 style={{ display: "none" }} aria-labelledby="one-time-payment-section">
                    One-Time Payment Section
                </h2>
                <div
                    className="d-flex flex-column min-vh-100 justify-content-center align-items-center"
                    style={{ color: "red" }}
                >
                    <div style={{ backgroundColor: "#E8E7E7" }}>
                        <div className="row">
                            <div className="col-12 text-danger" aria-live="assertive" role="alert">
                                {errorMessage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="button"
                                    value="OK"
                                    className="btn btn-Primary"
                                    onClick={() =>
                                        LoggedNavigate(
                                            "/policy-detail/" + idParsed + "?tab=details",
                                            "OK"
                                        )
                                    }
                                    aria-label="OK button"
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else if (isProcessing) {
        return (
            <section aria-labelledby="one-time-payment-section">
                <h1 style={{ display: "none" }}>One-Time Payment Spinner</h1>
                <h2 style={{ display: "none" }} aria-labelledby="one-time-payment-section">
                    One-Time Payment Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    } else {
        return (
            <section aria-labelledby="one-time-payment-section">
                <h1 style={{ display: "none" }}>One-Time Payment Spinner</h1>
                <h2 style={{ display: "none" }} aria-labelledby="one-time-payment-section">
                    One-Time Payment Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    }
};

export default RemotePayment;

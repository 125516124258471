import React from "react";
import { useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

type propType = {
    id: string;
};

const ImpersonateRedirectByPolicy: React.FC = () => {
    const params = useParams<propType>();
    let idParsed = "";
    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = params.id;
    }

    const cpContext = useContext(CPContext);
    const { authState, oktaAuth } = useOktaAuth();
    const navigate = useHistory();
    const { post } = useFetch();

    const setImpersonate = async () => {
        let searchResult: PolicyOwner[] = [];
        const searchBody: ImpersonateSearch = {
            pageNumber: 1,
            pageSize: 1,
            searchText: idParsed.toString()
        };

        searchResult = await post<ImpersonateSearch, PolicyOwner[]>(
            "/impersonate/search/",
            "",
            searchBody
        );

        if (searchResult && searchResult.length > 0) {
            const body: ImpersonateRequest = {
                policyDWKey: searchResult[0].policyDWKey.toString()
            };
            const result = await post<ImpersonateRequest, PolicyOwner>(
                "/impersonate",
                "",
                body
            );

            if (result) {
                if (result.ownerName) {
                    window.location.href = "/policies";
                }
            } else {
                navigate.push("/");
            }
        } else {
            navigate.push("/");
        }
    };

    useEffect(() => {
        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            if (cpContext.isAdmin || cpContext.isHO) {
                setImpersonate();
            }
        }

        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext]);

    return <Spinner imageWidth={20} aria-label="Loading" />;
};

export default ImpersonateRedirectByPolicy;

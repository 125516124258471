import React, { useState, useContext } from "react";
import { CPContext } from "../../_helpers/CPContext";
import { useHistory } from "react-router-dom";
import useUtilities from "../../_helpers/useUtilities";
import useLogger from "../../_helpers/useLogger";
import { Card } from "react-bootstrap";
import { Spinner } from "../shared/Common";
import ElectronicAuthorizationForm from "./ElectronicAuthorizationForm";

const DocumentDeliveryAuthorization: React.FC = () => {
    const utilities = useUtilities();
    const logger = useLogger();
    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const [errorMessage, setErrorMessage] = useState("");
    const [pdfLoaded, setPDFLoaded] = useState(false);
    const [agreeChecked, setAgreeChecked] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [scrolledToBottom, setScrolledToBottom] = useState(false);

    const cancel = () => navigate.push("/document-delivery");
    const submit = async () => {
        if (!agreeChecked) {
            setErrorMessage(
                "To continue with your request, you must acknowledge and accept the Electronic Signature and Delivery Authorization."
            );
            return;
        }
        logger.logDetailedActivity(
            "DocumentDelivery",
            "eDelivery",
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId,
            "Electronic Authorization Submit",
            0
        );
        setLoading(true);
        const response = await utilities.createEDeliveryFlagWorkObject(true).then((res) => {
            return res;
        });
        if (response) {
            setLoading(false);
            navigate.push({ pathname: "/document-delivery", state: { fromEAuth: true } });
        }
    };

    if (loading) {
        return (
            <section aria-labelledby="eauth-section">
                <h1 style={{ display: "none" }}>
                    Electronic Signature and Delivery Authorization Spinner
                </h1>
                <h2 style={{ display: "none" }} aria-labelledby="eauth-section">
                    Electronic Auth Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    } else {
        return (
            <div className="row my-5" style={{ padding: 10 }}>
                <div className="col-12">
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-12">
                            <Card className="policyCard">
                                <Card.Body className="shadow">
                                    <>
                                        <div>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-center">
                                                    <p>
                                                        Please review and agree to the Electronic
                                                        Signature and Delivery Authorization
                                                        consumer disclosure(s):
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-center">
                                                    <p>
                                                        *NOTE: Your selection will apply to all
                                                        policies
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="row justify-content-center">
                                                <div className="col-12 d-flex justify-content-center">
                                                    <ElectronicAuthorizationForm
                                                        setPDFLoaded={setPDFLoaded}
                                                        scrolledToBottom={setScrolledToBottom}
                                                        setErrorMessage={setErrorMessage}
                                                    />
                                                </div>
                                            </div>
                                            {pdfLoaded ? (
                                                <>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {errorMessage && (
                                                                    <div className="text-danger mt-3">
                                                                        {errorMessage}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="row mt-3">
                                                            <div
                                                                className="col-1"
                                                                style={{ paddingLeft: 30 }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={agreeChecked}
                                                                    value="true"
                                                                    onChange={(e) => {
                                                                        setAgreeChecked(true);
                                                                        logger.logDetailedActivity(
                                                                            "DocumentDelivery",
                                                                            "Electronic Authorization Checkbox",
                                                                            cpContext,
                                                                            cpContext.userInfo
                                                                                ?.email,
                                                                            cpContext.sessionId,
                                                                            new Date().toUTCString(),
                                                                            0
                                                                        );
                                                                    }}
                                                                    className="AgreeCheck"
                                                                    aria-label="Agree"
                                                                ></input>
                                                            </div>
                                                            <div
                                                                className="col-11"
                                                                style={{ paddingLeft: 30 }}
                                                            >
                                                                I acknowledge and accept the
                                                                Electronic Signature and Delivery
                                                                Authorization
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4">
                                                        <div className="col-12 d-flex">
                                                            <div className="mx-auto">
                                                                <input
                                                                    type="button"
                                                                    className="btn btn-Primary me-2"
                                                                    onClick={submit}
                                                                    disabled={!scrolledToBottom}
                                                                    value="Submit"
                                                                    aria-label="Submit button"
                                                                ></input>
                                                                <input
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    value="Cancel"
                                                                    onClick={() => {
                                                                        cancel();
                                                                        logger.logDetailedActivity(
                                                                            "DocumentDelivery",
                                                                            "Cancel",
                                                                            cpContext,
                                                                            cpContext.userInfo
                                                                                ?.email,
                                                                            cpContext.sessionId,
                                                                            "Cancel Document Delivery Authorization",
                                                                            0
                                                                        );
                                                                    }}
                                                                    aria-label="Cancel button"
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default DocumentDeliveryAuthorization;

import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import useFetch from "../../_helpers/useFetch";
import { CPContext } from "../../_helpers/CPContext";
import useLogger from "../../_helpers/useLogger";
import useUtilities from "../../_helpers/useUtilities";
import Header from "_components/shared/Header";
import { config } from "config";

const BankDraftReview: React.FC = () => {
    const params = useParams<propType>();
    const utilities = useUtilities();
    const logger = useLogger();
    const { get } = useFetch();
    let idParsed = 0;
    let _policyData = {} as Policy;
    const navigate = useHistory();
    let requestId = 0;

    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
    }

    if (typeof params.request != "undefined" && params.request && params.request.trim()) {
        requestId = +params.request;
    }

    const { authState, oktaAuth } = useOktaAuth();
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessingDetails, setIsProcessingDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const cpContext = useContext(CPContext);
    const [iFrameLoaded, setIFrameLoaded] = useState(false);
    const [bankDraftAuthPDFBlob, setBankDraftAuthPDFBlob] = useState<string>();
    const { isMaintenanceError } = useUtilities();

    const getPolicy = async () => {
        try {
            if (!isProcessingDetails) {
                setIsProcessingDetails(true);
                if (policyData && policyData.summary) {
                    //do nothing, we have data already
                } else {
                    _policyData = await get<Policy>("/policy/" + idParsed, "");
                    if (_policyData) {
                        //Determine if this policy can be shown the one-time payment link
                        _policyData.onlinePaymentAllowed = await utilities.validatePolicyForPayment(
                            idParsed
                        );
                    }
                    setPolicyData(_policyData);
                }

                setIsProcessingDetails(false);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading your policy information, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                );
                setIsProcessingDetails(false);
            }
        }
    };

    const getBankDraftAuthPreview = async (_id: number): Promise<boolean> => {
        let result = false;
        try {
            const body: object = {
                policyDWKey: idParsed,
                requestId: _id
            };

            const requestOptionsBodyAuth = {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
                }
            };

            await fetch(
                config.resourceServer.apiUrl + "/policy/bank-draft-authorization-preview",
                requestOptionsBodyAuth
            )
                .then((response) => response.blob())
                .then((response) => {
                    if (response.type == "application/problem+json" || response.size == 0) {
                        result = false;
                    } else {
                        //setErrorText("");
                        const pdfBlob = window.URL.createObjectURL(response);
                        setBankDraftAuthPDFBlob(pdfBlob);
                        result = true;
                    }
                })
                .catch((e: unknown) => {
                    if (isMaintenanceError(e)) {
                        navigate.push("/maint");
                    } else {
                        result = false;
                    }
                });
        } catch (e: unknown) {
            if (isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }

        return result;
    };

    const onSubmit = () => {
        alert("submitting...");
    };

    const onPrevious = () => {
        logger
            .loggedRequestNavigate(
                `/payments/add-new-bank-draft/${idParsed}`,
                "BankDraftReview",
                "",
                "",
                false,
                policyData
            )
            .then((uri) => navigate.push(uri));
    };

    useEffect(() => {
        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            //Policy Data is needed for just about everything so that will be the catalyst to load
            getPolicy();
            getBankDraftAuthPreview(requestId);
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext]);

    return (
        <div className="row" style={{ backgroundColor: "#eee" }}>
            <Header
                title="Payment Authorization"
                description="Bank Draft Review"
                policyData={policyData}
            />
            <div className="row pt-5 pb-3">
                <p className="bank-review-header">Your bank draft request has been received.</p>
            </div>
            <div className="row pt-1 pb-3">
                <p>
                    Please review the form and click &apos;Submit&apos; to submit your request. If
                    you would like to make changes, please hit the &apos;previous&apos; button.
                </p>
            </div>
            <div className="row pt-1 pb-3">
                <div className="col-12">
                    {requestId > 0 && bankDraftAuthPDFBlob && bankDraftAuthPDFBlob.length > 0 && (
                        <>
                            <iframe
                                src={bankDraftAuthPDFBlob}
                                onLoad={() => setIFrameLoaded(true)}
                                title={"BankDraftAuthorizationPreview"}
                                id={"BankDraftAuthorizationPreview"}
                                style={{
                                    width: "100%",
                                    height: "550px"
                                }}
                            ></iframe>
                        </>
                    )}
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-1">
                    <input
                        type="button"
                        className="btn btn-Primary float-end"
                        value="Previous"
                        onClick={onPrevious}
                        aria-label="Previous button"
                    ></input>
                </div>
                <div className="col-1">
                    <input
                        type="submit"
                        className="btn btn-Primary"
                        value="Submit"
                        aria-label="Submit button"
                        onClick={onSubmit}
                    ></input>
                </div>
            </div>
        </div>
    );
};

export default BankDraftReview;

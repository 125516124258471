import React from "react";

const Header: React.FC<{ title: string; description?: string; policyData?: Policy }> = (params) => {
    return (
        <div className="col-12 pt-4 pb-2 manage-my-payments-border d-inline">
            <div className="row col-2 d-inline">
                <h1 className="custom-h1 skip-to d-inline" title="Payments">
                    {params.title}
                </h1>
                <h6 className="d-inline" title={params.description}>
                    {params.description}
                </h6>
                {params.policyData && params.policyData.summary && (
                    <h6 className="d-inline policy-details" title="Policy Information">
                        {params.policyData.policyNumber} - {params.policyData.product}
                    </h6>
                )}
            </div>
        </div>
    );
};

export default Header;

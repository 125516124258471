import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import useFetch from "../../_helpers/useFetch";
import { config } from "../../config";
import { parse, isValid, format } from "date-fns";
import { enUS } from "date-fns/locale";
import ReCaptchaV2 from "react-google-recaptcha";
import { load } from "recaptcha-v3";
import useUtilities from "../../_helpers/useUtilities";

const Register: React.FC = () => {
    const { oktaAuth } = useOktaAuth();
    const navigate = useHistory();
    const { isMaintenanceError } = useUtilities();
    const { post } = useFetch();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [policyNumber, setPolicyNumber] = useState("");
    const [lastPremium, setLastPremium] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [emailAddressConfirm, setEmailAddressConfirm] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [stepNumber, setStepNumber] = useState(1);
    const [registrationError, setRegistrationError] = useState("");
    const [activationLink, setActivationLink] = useState("");
    const [activationMessage, setActivationMessage] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    // eslint-disable-next-line
    const reCaptchaRefStep1: any = React.createRef();
    const [requireReCaptchaV2, setRequireReCaptchaV2] = useState(false);
    const [showLoginButton, setShowLoginButton] = useState(false);

    const onBack = () => {
        //Show first step of Registration
        setStepNumber(1);
        document.body.scrollTop = 0; //safari
        document.documentElement.scrollTop = 0; //chrome, firefox, ie
    };

    //TODO: [Low] change to use parseDateCustom in utilities.tsx
    const parseDate = (dateString: string) => {
        const parsed: Date = parse(dateString, "yyyy-MM-dd", new Date(), { locale: enUS });
        if (isValid(parsed)) {
            const result = format(parsed, "MM/dd/yyyy");
            return result;
        }
        return "NA";
    };

    const handleLastPremium = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let cleanup = e.target.value;
        cleanup = cleanup.replace("$", "");
        cleanup = cleanup.replace(/[^\d.-]/g, "");
        setLastPremium(cleanup);
    };

    const handleMobilePhoneKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === "Backspace") {
            let cleanup = e.currentTarget.value;
            cleanup = formatPhoneNumber(cleanup, true);
            setMobilePhone(cleanup);
        }
    };

    const handleMobilePhone = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let cleanup = e.target.value;
        cleanup = formatPhoneNumber(cleanup, false);
        setMobilePhone(cleanup);
    };

    const formatPhoneNumber = (input: string, backspace: boolean) => {
        let cleanup = input.replace(/\D/g, "");
        if (backspace && cleanup.length <= 6) {
            cleanup = cleanup.substring(0, cleanup.length - 1);
        }
        if (cleanup.length === 0) {
            cleanup = "";
        } else if (cleanup.length <= 3) {
            cleanup = cleanup.replace(/^(\d{0,3})/, "($1)");
        } else if (cleanup.length <= 6) {
            cleanup = cleanup.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
        } else if (cleanup.length <= 10) {
            cleanup = cleanup.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
        } else {
            cleanup = cleanup.substring(0, 10);
            cleanup = cleanup.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
        }
        return cleanup;
    };

    const handleCaptchaStep1 = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (requireReCaptchaV2) {
            const recaptchaValue = reCaptchaRefStep1.current.getValue();
            await onNext(recaptchaValue);
        } else {
            const recaptcha = await load(config.app.recaptchav3Key);
            const token = await recaptcha.execute("Verify");
            await onNext(token);
        }
    };

    const handleCaptchaStep2 = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        //Execute recaptchav3
        const recaptcha = await load(config.app.recaptchav3Key);
        const token = await recaptcha.execute("Register");
        await onSubmit(token);
    };

    const onValidateCustomer = async (
        _firstName: string,
        _lastName: string,
        _dateOfBirth: string,
        _policyNumber: string,
        _lastPremiumPaid: string,
        _recaptchaToken: string
    ) => {
        try {
            setIsProcessing(true);
            const body: CustomerValidationRequest = {
                firstName: _firstName.trim(),
                lastName: _lastName.trim(),
                dateOfBirth: _dateOfBirth,
                policyNumber: _policyNumber,
                lastPremiumPaid: _lastPremiumPaid,
                recaptchaToken: _recaptchaToken,
                recaptchaVersion: requireReCaptchaV2 ? 2 : 3
            };
            const result = await post<CustomerValidationRequest, CustomerValidationResponse>(
                "/registration/validatecustomer/",
                "",
                body
            );
            setIsProcessing(false);
            return result;
        } catch (e: unknown) {
            if (isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setRegistrationError("An unexpected error has occured.");
                setIsProcessing(false);
            }
        }
    };

    const onRegisterCustomer = async (
        _email: string,
        _password: string,
        _firstName: string,
        _lastName: string,
        _mobilePhone: string,
        _dateOfBirth: string,
        _policyNumber: string,
        _lastPremiumPaid: string,
        _recaptchaToken: string
    ) => {
        try {
            setIsProcessing(true);
            const body: CustomerRegistrationRequest = {
                email: _email.trim(),
                password: _password,
                firstName: _firstName.trim(),
                lastName: _lastName.trim(),
                mobilePhone: _mobilePhone,
                dateOfBirth: _dateOfBirth,
                policyNumber: _policyNumber,
                lastPremiumPaid: _lastPremiumPaid,
                recaptchaToken: _recaptchaToken,
                recaptchaVersion: 3
            };
            const result = await post<CustomerRegistrationRequest, CustomerRegistrationResponse>(
                "/registration/registercustomer/",
                "",
                body
            );
            setIsProcessing(false);
            return result;
        } catch (e: unknown) {
            if (isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setRegistrationError("An unexpected error has occured.");
                setIsProcessing(false);
            }
        }
    };

    const onNext = async (_token: string) => {
        let validationError = "";
        setRegistrationError(validationError);
        let formattedDOB = "01/01/1900";
        const minimumDOB = parseDate("1900-01-01");

        //Confirm submitted details
        if (!firstName) {
            validationError = "Please enter your First Name";
            document.getElementById("firstName")?.focus();
        }
        if (!lastName && validationError == "") {
            validationError = "Please enter your Last Name";
            document.getElementById("lastName")?.focus();
        }
        if (!dateOfBirth && validationError == "") {
            validationError = "Please enter your Date of Birth";
        } else if (validationError == "") {
            formattedDOB = parseDate(dateOfBirth);
            if (formattedDOB == "NA") {
                validationError = "Please enter your Date of Birth in the format MM/DD/YYYY";
            } else {
                if (formattedDOB < minimumDOB) {
                    validationError = "Please enter a valid Date of Birth in the format MM/DD/YYYY";
                }
            }
        }

        if (!policyNumber && !lastPremium && validationError == "") {
            validationError = "Please enter your Policy Number or Last Premium Amount";
            document.getElementById("policyNumber")?.focus();
        }

        if (validationError && validationError.length > 0) {
            setRegistrationError(validationError);
            return;
        } else if (!_token) {
            validationError =
                "There was a problem validating your request, please try again or contact customer service.";
            setRegistrationError(validationError);
            return;
        } else {
            //Find record via API
            const res = await onValidateCustomer(
                firstName,
                lastName,
                formattedDOB,
                policyNumber,
                lastPremium,
                _token
            );

            //Show second step of Registration
            if (res && res.result) {
                setStepNumber(2);
            } else if (res) {
                if (res.message && res.message.length > 0) {
                    if (res.message.toLowerCase().includes("captcha") && !requireReCaptchaV2) {
                        //handleExpiredStep1()
                        setRequireReCaptchaV2(true);
                    } else if (
                        res.message.toLowerCase().includes("captcha") &&
                        requireReCaptchaV2
                    ) {
                        setRegistrationError("Invalid CAPTCHA, please try again.");
                        document.getElementById("recaptcha")?.focus();
                    } else {
                        if (res.accountExists) {
                            setShowLoginButton(true);
                        }
                        setRegistrationError(res.message);
                    }
                } else {
                    setRegistrationError(
                        "We are unable to provide your policy information online at this time. Please call us at 800-231-0801."
                    );
                }
            } else {
                setRegistrationError(
                    "We are unable to provide your policy information online at this time. Please call us at 800-231-0801."
                );
            }
        }
        document.body.scrollTop = 0; //safari
        document.documentElement.scrollTop = 0; //chrome, firefox, ie
    };

    const onSubmit = async (_token: string) => {
        let validationError = "";
        setRegistrationError(validationError);
        setStepNumber(2);

        if (emailAddress !== emailAddressConfirm) {
            validationError = "Email and Confirm Email do not match.";
        }
        if (!emailAddress && validationError == "") {
            validationError = "Please enter your Email Address.";
        }
        if (password !== passwordConfirm && validationError == "") {
            validationError = "Password and Confirm Password do not match.";
        }
        if (!password && validationError == "") {
            validationError = "Please enter your Password.";
        }
        // if (!mobilePhone && validationError == '') {
        //     validationError = 'Please enter your Mobile Phone Number'
        // }

        if (validationError && validationError.length > 0) {
            setStepNumber(2);
            setRegistrationError(validationError);
            return;
        } else if (!_token) {
            validationError =
                "There was a problem validating your request, please try again or contact customer service.";
            setStepNumber(1);
            setRegistrationError(validationError);
            return;
        } else {
            //Register user
            const res = await onRegisterCustomer(
                emailAddress,
                password,
                firstName,
                lastName,
                mobilePhone,
                dateOfBirth,
                policyNumber,
                lastPremium,
                _token
            );

            //Show second step of Registration
            if (res && res.result) {
                setActivationLink(res.activationLink);
                setActivationMessage(res.message);
            } else if (res) {
                if (res.message && res.message.length > 0) {
                    if (res.message.toLowerCase().includes("captcha")) {
                        //setRequireReCaptchaV2(true) //Not doing V2 on second step for now
                        setRegistrationError(
                            "There was a problem validating your request, please try again."
                        );
                    } else {
                        setStepNumber(2);
                        setRegistrationError(res.message);
                    }
                } else {
                    setRegistrationError(
                        "We are unable to provide your policy information online at this time. Please call us at 800-231-0801."
                    );
                }
            } else {
                setRegistrationError(
                    "We are unable to provide your policy information online at this time. Please call us at 800-231-0801."
                );
            }
        }
    };

    const onLoginClick = async () => {
        if (activationLink && activationLink.length > 0) {
            navigate.push(activationLink);
        } else {
            oktaAuth.signInWithRedirect({ originalUri: "/" });
        }
    };

    const onContinueClick = async () => {
        oktaAuth.signInWithRedirect({ originalUri: "/" });
    };

    const getZoomRatio = (): void => {
        const zoom = Math.round(window.devicePixelRatio * 100);
        //check zoom against 400% (hard-coded from accessibility report) - 07/19/23
        //if zoom is 400% or greater, push Recaptcha below all page content
        if (zoom >= 400) {
            const element = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
            !!element ? (element.style.position = "initial") : "";
        }
    };

    useEffect(() => {
        getZoomRatio();
    }, []);

    if (stepNumber === 1) {
        return (
            <>
                <main
                    id="register-form"
                    className="d-flex flex-column min-vh-100 justify-content-center align-items-center"
                >
                    <section aria-labelledby="register-form-section">
                        <Card
                            body
                            style={{
                                backgroundColor: "#EEEEEE",
                                borderStyle: "none",
                                boxShadow: "0 0 10px 0 rgba(100, 100, 100, 0.26)"
                            }}
                        >
                            <form className="add-form" style={{ backgroundColor: "#EEEEEE" }}>
                                <div>
                                    <input id="hdnInputFocus" type="hidden" autoFocus />
                                    <h1 className="custom-h1">Registration</h1>
                                    {registrationError && registrationError.length > 0 ? (
                                        <>
                                            {showLoginButton ? (
                                                <>
                                                    <label aria-live="assertive" role="alert">
                                                        {registrationError}
                                                    </label>
                                                    &nbsp;
                                                    <a href="#" onClick={onLoginClick}>
                                                        Log-in Here.
                                                    </a>
                                                    <br />
                                                    <br />
                                                </>
                                            ) : (
                                                <>
                                                    <label
                                                        className="text-danger"
                                                        aria-live="assertive"
                                                        role="alert"
                                                    >
                                                        {registrationError}
                                                        <br />
                                                        <br />
                                                    </label>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <label>
                                            Please enter the following to help us verify that
                                            it&apos;s you:
                                            <br />
                                            <br />
                                        </label>
                                    )}
                                </div>
                                <div>
                                    <label>
                                        Owner First Name as it appears on your policy (Required *)
                                    </label>
                                    <input
                                        id="firstName"
                                        type="text"
                                        className="form-control skip-to"
                                        placeholder=""
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        aria-required={true}
                                        aria-label="Owner First Name"
                                    ></input>
                                </div>
                                <div>
                                    <label>
                                        Owner Last Name as it appears on your policy (Required *)
                                    </label>
                                    <input
                                        id="lastName"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        aria-required={true}
                                        aria-label="Owner Last Name"
                                    ></input>
                                </div>
                                <div>
                                    <label>Insured Date of Birth (Required *)</label>
                                    <input
                                        id="dateOfBirth"
                                        type="date"
                                        className="form-control"
                                        placeholder="mm/dd/yyyy"
                                        min="1922-01-01"
                                        max="2022-12-31"
                                        value={dateOfBirth}
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                        aria-required={true}
                                        aria-label="Insured Date of Birth"
                                        autoFocus
                                    ></input>
                                </div>
                                <div>
                                    <span>&nbsp;</span>
                                </div>
                                <div>
                                    <label>Policy Number</label>
                                    <input
                                        id="policyNumber"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={policyNumber}
                                        onChange={(e) => setPolicyNumber(e.target.value.trim())}
                                        aria-label="Policy Number"
                                    ></input>
                                </div>
                                <div style={{ paddingBottom: "10px", paddingLeft: "5px" }}>
                                    <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                                        OR
                                    </span>
                                </div>
                                <div>
                                    <label>Last Payment Amount</label>
                                </div>
                                <div>
                                    $&nbsp;
                                    <input
                                        id="lastPremium"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={lastPremium}
                                        onChange={handleLastPremium}
                                        style={{ display: "inline", width: "95%" }}
                                        aria-label="Last Premium Amount"
                                    ></input>
                                </div>
                                {isProcessing ? (
                                    <label>Processing...</label>
                                ) : (
                                    <div style={{ display: "block" }}>
                                        {/* TODO: Need to replace button with spinner so user can't do multiple submits */}
                                        <input
                                            type="button"
                                            value="Next"
                                            className="btnRounded btn-block"
                                            onClick={handleCaptchaStep1}
                                        ></input>
                                        {requireReCaptchaV2 ? (
                                            <ReCaptchaV2
                                                id="recaptcha"
                                                sitekey={config.app.recaptchav2Key}
                                                ref={reCaptchaRefStep1}
                                                aria-required={true}
                                                aria-label="Recaptcha"
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </form>
                        </Card>
                    </section>
                </main>
            </>
        );
    } else if (stepNumber === 2) {
        return (
            <main>
                <section aria-labelledby="register-form-section">
                    <h1 style={{ display: "none" }} aria-labelledby="register-form-section">
                        Registration Form Section
                    </h1>
                    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                        <Card
                            body
                            style={{
                                backgroundColor: "#EEEEEE",
                                borderStyle: "none",
                                boxShadow: "0 0 10px 0 rgba(100, 100, 100, 0.26)"
                            }}
                        >
                            <form className="add-form" style={{ backgroundColor: "#EEEEEE" }}>
                                <div>
                                    <input id="hdnInputFocus" type="hidden" autoFocus />
                                    <h2>Registration</h2>
                                    {registrationError && registrationError.length > 0 ? (
                                        <label
                                            className="text-danger"
                                            aria-live="assertive"
                                            role="alert"
                                        >
                                            {registrationError}
                                            <br />
                                            <br />
                                        </label>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {isProcessing ||
                                (activationMessage && activationMessage.length > 0) ? (
                                    <>
                                        {activationMessage && activationMessage.length > 0 ? (
                                            <label>
                                                {activationMessage}&nbsp;
                                                <a href="#" onClick={onLoginClick}>
                                                    Log-in Here.
                                                </a>
                                                <br />
                                                <br />
                                            </label>
                                        ) : (
                                            <>
                                                {activationMessage &&
                                                activationMessage.length > 0 ? (
                                                    <label>
                                                        {activationMessage}&nbsp;
                                                        <a href="#" onClick={onContinueClick}>
                                                            Continue.
                                                        </a>
                                                        <br />
                                                        <br />
                                                    </label>
                                                ) : (
                                                    <label>Processing...</label>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <label>
                                            We found your policy, enter the details below to create
                                            your account:
                                            <br />
                                            <br />
                                        </label>
                                        <div>
                                            <label>Email&nbsp;*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={emailAddress}
                                                onChange={(e) =>
                                                    setEmailAddress(e.target.value.trim())
                                                }
                                                aria-label="Email Address"
                                            ></input>
                                        </div>
                                        <div>
                                            <label>Re-enter Email&nbsp;*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={emailAddressConfirm}
                                                onChange={(e) =>
                                                    setEmailAddressConfirm(e.target.value.trim())
                                                }
                                                aria-label="Email Address confirm"
                                            ></input>
                                        </div>
                                        <div>
                                            <label>Password&nbsp;*</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder=""
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value.trim())}
                                                aria-label="Password"
                                            ></input>
                                        </div>
                                        <div>
                                            <label>Re-enter Password&nbsp;*</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder=""
                                                value={passwordConfirm}
                                                onChange={(e) =>
                                                    setPasswordConfirm(e.target.value.trim())
                                                }
                                                aria-label="Re-enter Password"
                                            ></input>
                                        </div>
                                        <div>
                                            <label>Mobile Phone</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={mobilePhone}
                                                onChange={handleMobilePhone}
                                                onKeyUp={handleMobilePhoneKeyUp}
                                                aria-label="Mobile Phone Number"
                                            ></input>
                                        </div>
                                        {isProcessing ? (
                                            <label>Processing...</label>
                                        ) : (
                                            <>
                                                <input
                                                    type="button"
                                                    value="Back"
                                                    className="btnRounded"
                                                    onClick={onBack}
                                                    aria-label="Back button"
                                                ></input>
                                                {/* TODO: Need to replace button with spinner so user can't do multiple submits */}
                                                <input
                                                    type="button"
                                                    value="Submit"
                                                    className="btnRounded"
                                                    onClick={handleCaptchaStep2}
                                                    aria-label="Submit button"
                                                ></input>
                                            </>
                                        )}
                                    </>
                                )}
                            </form>
                        </Card>
                    </div>
                </section>
            </main>
        );
    } else {
        return (
            <main>
                <section aria-labelledby="register-form-section">
                    <h1 style={{ display: "none" }} aria-labelledby="register-form-section">
                        Registration Form Section
                    </h1>

                    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                        <Card
                            body
                            style={{
                                backgroundColor: "#EEEEEE",
                                borderStyle: "none",
                                boxShadow: "0 0 10px 0 rgba(100, 100, 100, 0.26)"
                            }}
                        >
                            <form className="add-form" style={{ backgroundColor: "#EEEEEE" }}>
                                <input id="hdnInputFocus" type="hidden" autoFocus />
                            </form>
                        </Card>
                    </div>
                </section>
            </main>
        );
    }
};

export default Register;

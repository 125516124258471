import React, { useState, useContext, useEffect, FormEvent } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import Header from "../shared/Header";
import useUtilities from "_helpers/useUtilities";
import useFetch from "_helpers/useFetch";
import useLogger from "_helpers/useLogger";
import { CarrierAdminSystem } from "enums/CarrierAdminSystem";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import { BankAccountTypes } from "../../enums/BankAccountTypes";
import { Spinner } from "../../_components/shared/Common";
import { BankCustomerTypes } from "enums/BankCustomerType";
import FormDisclaimer from "../service/FormDisclaimer";
import { config } from "../../config";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { PolicyStatus } from "enums/PolicyStatus";

const AddNewBankDraft: React.FC<{ policyData?: Policy }> = (parameters) => {
    const params = useParams<propType>();
    let idParsed = 0;
    if (params.id && params.id.trim()) {
        idParsed = +params.id;
    }

    const initialState = {
        bankCustomerType: BankCustomerTypes.Personal,
        nameMatchesBankStatement: true,
        bankAccountType: BankAccountTypes.Checking,
        payorFirstName: "",
        payorMiddleInitial: "",
        payorLastName: "",
        payorPhoneNumber: "",
        payorEmailAddress: "",
        bankRoutingNumber: "",
        bankAccountNumber: "",
        specialInstructions: "",
        draftDate: "",
        payorSSN: ""
    };
    const { authState, oktaAuth } = useOktaAuth();
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string[]>([]);
    const [dateErrorMessage, setDateErrorMessage] = useState("");
    const logger = useLogger();
    const utilities = useUtilities();
    const { get, post } = useFetch();
    const [formData, setFormData] = useState<PolicyForm>();
    const [formValidated, setFormValidated] = useState(false);
    const [draftDateValidated, setDraftDateFormValidated] = useState(false);
    const [state, setLocalState] = useState(initialState);
    const [showModal, setShowModal] = useState(false);
    const [showFCRAModal, setShowFCRAModal] = useState(false);
    const [giactFailureCount, setGiactFailureCount] = useState(0);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseFCRAModal = () => setShowFCRAModal(false);
    const handleShowFCRAModal = () => setShowFCRAModal(true);
    const { isMaintenanceError } = useUtilities();

    const localRef = React.useRef<HTMLInputElement>(null);
    const value = "";
    const minDate = dayjs("01-01-1900");
    const maxDate = dayjs("12-31-2099");
    const defaultErrorMessage = "Please enter a valid date.";
    const defaultMaxDateErrorMessage = `Date entered must be on or before ${maxDate}.`;
    const defaultMinDateErrorMessage = `Date entered must be on or after ${minDate}.`;
    const defaultMaxDraftDateErrorMessage =
        "Selected date is outside the 10 days from your original due date, please choose a different date or contact customer service at 800-231-0801.";
    const defaultCoreTwentyEightDaysOnlyErrorMessage =
        "Date selected cannot be the 29th, 30th, or 31st.";

    const payorFirstNameErrorMessage =
        "Payor First Name is required.  Please enter the First Name.";
    const payorLastNameErrorMessage = "Payor Last Name is required.  Please enter the Last Name.";

    const validate = (value: string) => {
        const maxDraftDate = moment(policyData?.summary.currentDraftDate).add(10, "days");
        const date: Date = new Date(value);
        const formattedDate = moment(date).format("MM/DD/YYYY").toString();
        const newState = { ...state };
        newState.draftDate = formattedDate;
        setLocalState(newState);
        const invalidDates: number[] = [29, 30, 31];
        const selectedDate = moment(value.toString());
        if (invalidDates.includes(selectedDate.date())) {
            if (!errorMessage.includes(defaultCoreTwentyEightDaysOnlyErrorMessage)) {
                setErrorMessage([...errorMessage, defaultCoreTwentyEightDaysOnlyErrorMessage]);
            }
            setDraftDateFormValidated(false);
        } else if (selectedDate === null || selectedDate === undefined || !selectedDate.isValid()) {
            if (!errorMessage.includes(defaultErrorMessage)) {
                setErrorMessage([...errorMessage, defaultErrorMessage]);
            }
            setDraftDateFormValidated(false);
        } else if (selectedDate < minDate) {
            if (!errorMessage.includes(defaultMinDateErrorMessage)) {
                setErrorMessage([...errorMessage, defaultMinDateErrorMessage]);
            }
            setDraftDateFormValidated(false);
        } else if (selectedDate > maxDate) {
            if (!errorMessage.includes(defaultMaxDateErrorMessage)) {
                setErrorMessage([...errorMessage, defaultMaxDateErrorMessage]);
            }
            setDraftDateFormValidated(false);
        } else if (selectedDate > maxDraftDate) {
            if (!errorMessage.includes(defaultMaxDraftDateErrorMessage)) {
                setErrorMessage([...errorMessage, defaultMaxDraftDateErrorMessage]);
            }
            setDraftDateFormValidated(false);
        } else {
            setErrorMessage([
                ...errorMessage.filter(
                    (err: string) =>
                        err !== defaultCoreTwentyEightDaysOnlyErrorMessage &&
                        err !== defaultErrorMessage &&
                        err !== defaultMinDateErrorMessage &&
                        err !== defaultMaxDateErrorMessage &&
                        err !== defaultMaxDraftDateErrorMessage
                )
            ]);
            setDraftDateFormValidated(true);
        }
    };

    const navigate = useHistory();
    const cpContext = useContext(CPContext);

    let _policyData = {} as Policy;

    const getPolicy = async () => {
        try {
            if (!isProcessing) {
                setIsProcessing(true);
                if (policyData && policyData.summary) {
                    //do nothing, we have data already
                } else {
                    _policyData = await get<Policy>("/policy/" + idParsed, "");
                    if (_policyData) {
                        //Determine if this policy can be shown the one-time payment link
                        _policyData.onlinePaymentAllowed = await utilities.validatePolicyForPayment(
                            idParsed
                        );
                    }
                    setPolicyData(_policyData);
                }
            }
        } catch (e: unknown) {
            setErrorMessage([...errorMessage, "An unexpected error has occurred."]);
        }
    };

    const getForm = async () => {
        try {
            const formFromServer = await get<PolicyForm>(
                "/policyform/getformdata?policyDWKey=" + idParsed + "&formNumber=" + "AF55019",
                ""
            );
            if (formFromServer) {
                setFormData(formFromServer);
            } else {
                setErrorMessage([
                    ...errorMessage,
                    "There was a problem loading the selected form, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                ]);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage([
                    ...errorMessage,
                    "There was a problem loading the selected form, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                ]);
            }
        }
    };

    useEffect(() => {
        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            setIsProcessing(true);
            //Policy Data is needed for just about everything so that will be the catalyst to load
            getPolicy();
            getForm();
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext]);

    useEffect(() => {
        if (policyData) {
            setIsProcessing(false);
        }
    }, [policyData]);

    const downloadPolicyForm = async (_id: number, _name: string) => {
        try {
            const body: fileDownloadRequest = {
                id: _id,
                policyDWKey: idParsed,
                ignorePreviousDisclosure: false
            };

            let formattedName = _name;
            if (formattedName && formattedName.length > 0) {
                formattedName = formattedName.replaceAll(" ", "");
            } else {
                formattedName = "file";
            }

            const requestOptionsBodyAuth = {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
                }
            };

            await fetch(
                config.resourceServer.apiUrl + "/policyform/download",
                requestOptionsBodyAuth
            )
                .then((response) => response.blob())
                .then((response) => {
                    if (response.type == "application/problem+json") {
                        setErrorMessage([
                            ...errorMessage,
                            "There was a problem loading the selected document."
                        ]);
                    } else {
                        setErrorMessage([...errorMessage, ""]);
                        const url = window.URL.createObjectURL(new Blob([response]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.target = "_blank";
                        link.setAttribute("download", formattedName + ".pdf"); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch((error) => {
                    setErrorMessage([
                        ...errorMessage,
                        "There was a problem loading the selected document."
                    ]);
                });
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage([...errorMessage, "An unexpected error has occured."]);
            }
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (formData?.resourceVersionFileItemId && formData?.formName) {
            await downloadPolicyForm(formData?.resourceVersionFileItemId, formData?.formName);
        }
    };

    const returnToPayments = () => {
        logger
            .loggedRequestNavigate(
                `/policy-detail/${idParsed}?tab=payments`,
                "ManageMyPayments",
                "",
                "",
                false,
                policyData
            )
            .then((uri) => navigate.push(uri));
    };

    const handleCancel = () => {
        if (JSON.stringify(initialState) !== JSON.stringify(state)) {
            handleShowModal();
        } else {
            returnToPayments();
        }
    };

    const handleModalAffirmative = () => {
        handleCloseModal();
        returnToPayments();
    };
    const handleModalNegative = () => {
        handleCloseModal();
    };
    const handleFCRAModalAffirmative = () => {
        handleCloseFCRAModal();
    };
    const validateBankInfo = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (giactFailureCount >= 3) {
                //Will come in future story
                alert("GIACT Failure limit reached");
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            try {
                const name = policyData?.client.ownerName.split(",");
                const body: FraudDetectionRequest = {
                    policyDWKey: idParsed,
                    firstName: utilities.isNullOrWhitespace(state.payorFirstName)
                        ? name?.at(1)
                        : state.payorFirstName,
                    middleInitial: state.payorMiddleInitial,
                    lastName: utilities.isNullOrWhitespace(state.payorLastName)
                        ? name?.at(0)
                        : state.payorLastName,
                    routingNumber: state.bankRoutingNumber,
                    accountNumber: state.bankAccountNumber,
                    accountType: state.bankAccountType,
                    phoneNumber: state.payorPhoneNumber,
                    emailAddress: state.payorEmailAddress
                };
                const response = await post<FraudDetectionRequest, FraudDetectionResponse>(
                    "/policy/account-verification",
                    "",
                    body
                );
                setGiactFailureCount(response.attemptCount);
                if (response.verificationPassed) {
                    // if giact succeeds... populate bank draft form
                    const _requestId = await populateBankDraftAuthForm();
                    if (_requestId && _requestId > 0) {
                        logger
                            .loggedRequestNavigate(
                                `/payments/bank-draft-review/${idParsed}/${_requestId}`,
                                "AddNewBankDraft",
                                "",
                                "",
                                false,
                                policyData
                            )
                            .then((uri) => navigate.push(uri));
                    } else {
                        //preview generation failed
                        setErrorMessage([
                            ...errorMessage,
                            "Could not load bank draft authorization forms."
                        ]);
                    }
                } else if (response.fcraRequired) {
                    handleShowFCRAModal();
                } else {
                    //Will come in future story
                    alert("GIACT failed");
                    return;
                }
            } catch (e) {
                setGiactFailureCount(giactFailureCount + 1);
            }
        }
    };

    const fieldRequiredCheck = (error: string, event?: any) => {
        if (event?.target.value === "" || event?.target.value === "MM/DD/YYYY") {
            if (!errorMessage.includes(error)) {
                setErrorMessage([...errorMessage, error]);
            }
        } else {
            if (errorMessage.length >= 1) {
                setErrorMessage([...errorMessage.filter((x) => x !== error)]);
            } else {
                setErrorMessage([]);
            }
        }
    };

    const populateBankDraftAuthForm = async () => {
        try {
            const body: BankDraftAuthFormData = {
                policyDWKey: idParsed,
                specificStartDate: state.draftDate,
                accountType: state.bankAccountType,
                insuredName: policyData?.client.ownerName.replace(",", ", ") ?? "",
                policyNumber: policyData?.policyNumber ?? "",
                payorName: getPayorName(),
                payorPhoneNumber:
                    state.payorPhoneNumber !== ""
                        ? state.payorPhoneNumber
                        : policyData?.client.ownerPhone ?? "",
                ownerDOB: policyData?.client.insuredDOB ?? "",
                ownerAddress:
                    `${policyData?.client.ownerAddress} ${policyData?.client.ownerAddress2}` ?? "",
                routingNumber: state.bankRoutingNumber,
                accountNumber: state.bankAccountNumber
            };

            const result = await post<BankDraftAuthFormData, number>(
                "/policy/generate-bank-draft-authorization-preview/",
                "",
                body
            );
            return result;
        } catch (e: unknown) {
            if (isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const getPayorName = () => {
        if (state.nameMatchesBankStatement) {
            const name = policyData?.client.ownerName.split(",");
            return `${name?.at(1)} ${name?.at(0)}`;
        } else {
            return `${state.payorFirstName}${
                !!state.payorMiddleInitial ? ` ${state.payorMiddleInitial}` : ""
            } ${state.payorLastName}`;
        }
    };

    if (!isProcessing) {
        return (
            <>
                <div className="row" style={{ backgroundColor: "#eee" }}>
                    <Header
                        title="Payments"
                        description="Add New Bank Draft"
                        policyData={policyData}
                    />
                    {policyData?.summary.carrierAdminSystem.toLocaleLowerCase() !==
                        CarrierAdminSystem.L70 &&
                        policyData?.summary.status !== PolicyStatus.Active && (
                            <p className="text-danger">
                                By proceeding, you give Americo authorization to immediately deduct
                                the full grace amount from your account as your policy is currently
                                in grace. If you prefer to only pay the past due charges, please
                                contact Customer Service at 800-231-0801 for an exact quote.
                                Alternatively, you can pay the full grace amount using your Credit
                                or Debit card by clicking here:{" "}
                                <a
                                    className="text-decoration-none"
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        logger.loggedRequestNavigate(
                                            `/payments/one-time-payment`,
                                            "AddNewBankDraft",
                                            "",
                                            "",
                                            false,
                                            policyData,
                                            idParsed
                                        );
                                    }}
                                >
                                    One Time Payment
                                </a>
                            </p>
                        )}
                    {policyData?.summary.carrierAdminSystem.toLocaleLowerCase() ===
                        CarrierAdminSystem.L70 &&
                        (policyData.summary.status === PolicyStatus.Lapsed ||
                            policyData.summary.status === PolicyStatus.LapsePending) && (
                            <p className="text-danger">
                                Your policy is currently in Grace, payment is required before you
                                are able to set up a draft. Please contact Customer Service at
                                800-366-6100.
                            </p>
                        )}
                    {errorMessage && errorMessage.length > 0 ? (
                        <div className="mt-3">
                            {errorMessage.map((err, i) => {
                                return (
                                    <div
                                        className="text-danger"
                                        aria-live="assertive"
                                        role="alert"
                                        key={i}
                                    >
                                        {err}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                {/* do not display Form if policy is L70 and in "grace" (lapse pending status in Customer Portal) */}
                {policyData?.summary.carrierAdminSystem.toLocaleLowerCase() ===
                    CarrierAdminSystem.L70 &&
                (policyData?.summary.status === PolicyStatus.LapsePending ||
                    policyData.summary.status === PolicyStatus.Lapsed) ? (
                    <></>
                ) : (
                    <Form noValidate validated={formValidated}>
                        <Row className="mt-3">
                            <Form.Label className="fst-italic">
                                <span>required </span>
                                <span className="bi bi-asterisk text-danger"></span>
                            </Form.Label>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}></Col>
                            <Col>
                                <Form.Label>
                                    <div title="Personal or Business Payor">
                                        Personal or Business
                                    </div>
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}></Col>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    id="personal-account-radio"
                                    label={BankCustomerTypes.Personal}
                                    title="Select Personal Payor"
                                    inline
                                    defaultChecked
                                    onChange={(e) => {
                                        setLocalState({
                                            ...state,
                                            bankCustomerType: BankCustomerTypes.Personal
                                        });
                                    }}
                                    checked={state.bankCustomerType === BankCustomerTypes.Personal}
                                />
                                <Form.Check
                                    type="radio"
                                    id="business-account-radio"
                                    label={BankCustomerTypes.Business}
                                    title="Select Business Payor"
                                    inline
                                    onChange={(e) => {
                                        setLocalState({
                                            ...state,
                                            bankCustomerType: BankCustomerTypes.Business
                                        });
                                        setErrorMessage([...errorMessage, ""]);
                                    }}
                                    checked={state.bankCustomerType === BankCustomerTypes.Business}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>
                                <Form.Label>Owner Name: </Form.Label>
                            </Col>
                            <Col>
                                <Form.Label>
                                    <div title="Owner Full Name">
                                        {policyData?.client.ownerName.replace(",", ", ")}
                                    </div>
                                </Form.Label>
                            </Col>
                        </Row>
                        {state.bankCustomerType === BankCustomerTypes.Business && (
                            <>
                                <Row className="mt-3">
                                    <div className="col-12 manage-my-payments-border">
                                        <h1
                                            className="custom-h1 skip-to"
                                            style={{ paddingTop: 10 }}
                                        >
                                            Bank Draft Authorization Form Download
                                        </h1>
                                    </div>
                                    <FormDisclaimer formName="Bank Draft Authorization Form" />
                                </Row>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <>
                                        <div className="col-4 col-xl-2 col-lg-2 col-md-3 col-sm-4">
                                            <input
                                                type="button"
                                                value="Download"
                                                className="btn btn-Primary"
                                                onClick={onSubmit}
                                                aria-label="Download button"
                                            ></input>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                type="button"
                                                className="btn btn-Primary"
                                                onClick={() => {
                                                    logger
                                                        .loggedRequestNavigate(
                                                            `/policy-detail/${idParsed}?tab=payments`,
                                                            "AddNewBankDraft",
                                                            "",
                                                            "",
                                                            false,
                                                            policyData!
                                                        )
                                                        .then((uri: any) => navigate.push(uri));
                                                }}
                                                value="Cancel"
                                                aria-label="Cancel button"
                                            ></input>
                                        </div>
                                    </>
                                </div>
                            </>
                        )}
                        {state.bankCustomerType === BankCustomerTypes.Personal && (
                            <>
                                <Row className="mt-3">
                                    <Col xs={4}></Col>
                                    <Col>
                                        <Form.Label>
                                            <div
                                                title="Is the above name exactly how it appears on your bank
                                            statement?"
                                            >
                                                Is the above name exactly how it appears on your
                                                bank statement?
                                            </div>
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4}></Col>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            id="name-matches-bank"
                                            label="Yes"
                                            title="Name Matches Bank Statement"
                                            inline
                                            defaultChecked
                                            onChange={(e) => {
                                                setLocalState({
                                                    ...state,
                                                    nameMatchesBankStatement: true,
                                                    payorFirstName: "",
                                                    payorLastName: ""
                                                });
                                                setErrorMessage([
                                                    ...errorMessage.filter(
                                                        (x) =>
                                                            x !== payorFirstNameErrorMessage &&
                                                            x !== payorLastNameErrorMessage
                                                    )
                                                ]);
                                                setFormValidated(
                                                    e.currentTarget.form?.checkValidity() || false
                                                );
                                            }}
                                            checked={state.nameMatchesBankStatement}
                                        />
                                        <Form.Check
                                            type="radio"
                                            id="name-does-not-match"
                                            label="No"
                                            title="Name Doesn't Match Bank Statement"
                                            inline
                                            onChange={(e) => {
                                                setLocalState({
                                                    ...state,
                                                    nameMatchesBankStatement: false
                                                });
                                                setFormValidated(false);
                                            }}
                                            checked={!state.nameMatchesBankStatement}
                                        />
                                    </Col>
                                </Row>
                                {!state.nameMatchesBankStatement && (
                                    <>
                                        <Row className="mt-3">
                                            <Col xs={4}>
                                                <Form.Label>
                                                    Payor First Name:{" "}
                                                    <i className="bi bi-asterisk text-danger"></i>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Control
                                                    id="payor-first-name"
                                                    required
                                                    type="text"
                                                    title="Payor First Name"
                                                    placeholder="First Name"
                                                    maxLength={50}
                                                    defaultValue={state.payorFirstName}
                                                    onChange={(e: any) => {
                                                        setLocalState({
                                                            ...state,
                                                            payorFirstName: e.target.value
                                                        });
                                                        setFormValidated(
                                                            e.currentTarget.form?.checkValidity() ||
                                                                false
                                                        );
                                                    }}
                                                    onBlur={(e: any) => {
                                                        fieldRequiredCheck(
                                                            payorFirstNameErrorMessage,
                                                            e
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={4}>
                                                <Form.Label>Payor Middle Initial:</Form.Label>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Control
                                                    id="payor-middle-initial"
                                                    type="text"
                                                    placeholder="M.I."
                                                    title="Payor Middle Initial"
                                                    maxLength={1}
                                                    defaultValue={state.payorMiddleInitial}
                                                    onChange={(e: any) => {
                                                        setLocalState({
                                                            ...state,
                                                            payorMiddleInitial: e.target.value
                                                        });
                                                        setFormValidated(
                                                            e.currentTarget.form?.checkValidity() ||
                                                                false
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={4}>
                                                <Form.Label>
                                                    Payor Last Name:{" "}
                                                    <i className="bi bi-asterisk text-danger"></i>
                                                </Form.Label>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Control
                                                    id="payor-last-name"
                                                    required
                                                    type="text"
                                                    title="Payor Last Name"
                                                    placeholder="Last Name"
                                                    maxLength={50}
                                                    defaultValue={state.payorLastName}
                                                    onChange={(e: any) => {
                                                        setLocalState({
                                                            ...state,
                                                            payorLastName: e.target.value
                                                        });
                                                        setFormValidated(
                                                            e.currentTarget.form?.checkValidity() ||
                                                                false
                                                        );
                                                    }}
                                                    onBlur={(e: any) => {
                                                        fieldRequiredCheck(
                                                            payorLastNameErrorMessage,
                                                            e
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                <Row className="mt-3">
                                    <Col xs={4}>
                                        <Form.Label>
                                            Payor Phone Number:{" "}
                                            <i className="bi bi-asterisk text-danger"></i>
                                        </Form.Label>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Control
                                            id="payor-phone-number"
                                            required
                                            type="tel"
                                            title="Payor Phone Number"
                                            defaultValue={policyData?.client.ownerPhone}
                                            maxLength={12}
                                            onChange={(e: any) => {
                                                e.target.value = e.target.value.replace(
                                                    /(\d{3})(\d{3})(\d{4})/,
                                                    "$1-$2-$3"
                                                );
                                                setLocalState({
                                                    ...state,
                                                    payorPhoneNumber: e.target.value
                                                });

                                                setFormValidated(
                                                    e.currentTarget.form?.checkValidity() || false
                                                );
                                            }}
                                            onBlur={(e: any) => {
                                                fieldRequiredCheck(
                                                    "Payor Phone Number is required.  Please enter a valid Phone Number. ",
                                                    e
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={4}>
                                        <Form.Label>
                                            Payor Email Address:{" "}
                                            <i className="bi bi-asterisk text-danger"></i>
                                        </Form.Label>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Control
                                            id="payor-email-address"
                                            required
                                            type="text"
                                            title="Payor Email Address"
                                            maxLength={50}
                                            defaultValue={policyData?.client.ownerEmail}
                                            onChange={(e: any) => {
                                                setLocalState({
                                                    ...state,
                                                    payorEmailAddress: e.target.value
                                                });
                                                setFormValidated(
                                                    e.currentTarget.form?.checkValidity() || false
                                                );
                                            }}
                                            onBlur={(e: any) => {
                                                fieldRequiredCheck(
                                                    "Payor Email Address is required.  Please enter a valid Email.",
                                                    e
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {utilities.isNullOrWhitespace(
                                    policyData?.summary.ssBillingOption
                                ) && (
                                    <Row className="mt-3">
                                        <Col xs={4}>
                                            <Form.Label>
                                                Draft Date:{" "}
                                                <i className="bi bi-asterisk text-danger"></i>
                                            </Form.Label>
                                        </Col>
                                        <Col xs={4}>
                                            <div
                                                id="dateSelectorFieldContainer"
                                                className="row"
                                                title="Draft Date To Be Used"
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DesktopDatePicker
                                                        inputRef={localRef}
                                                        value={value ? dayjs(value) : dayjs("")}
                                                        minDate={minDate}
                                                        maxDate={maxDate}
                                                        disabled={false}
                                                        className="dateSelectorField"
                                                        format={"MM/DD/YYYY"}
                                                        views={["year", "month", "day"]}
                                                        onChange={(e: any) => {
                                                            validate(e);
                                                        }}
                                                        slotProps={{
                                                            textField: {
                                                                onChange: (e: any) => {
                                                                    validate(e);
                                                                },
                                                                onBlur: (e: any) => {
                                                                    fieldRequiredCheck(
                                                                        "Draft Date is required.  Please enter a valid Draft Date.",
                                                                        e
                                                                    );
                                                                },
                                                                error: false,
                                                                sx: {
                                                                    ".MuiInputBase-root": {
                                                                        fontSize: "1rem"
                                                                    }
                                                                },
                                                                id: "draft-date"
                                                            }
                                                        }}
                                                    ></DesktopDatePicker>
                                                </LocalizationProvider>
                                                <div className="mt-3">
                                                    <label
                                                        className="text-danger"
                                                        aria-live="assertive"
                                                        role="alert"
                                                    >
                                                        {dateErrorMessage}
                                                        <br />
                                                        <br />
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Row className="mt-3">
                                    <Col xs={4}>
                                        <Form.Label>
                                            Routing Number:{" "}
                                            <i className="bi bi-asterisk text-danger"></i>
                                        </Form.Label>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Control
                                            id="routing-number"
                                            required
                                            type="text"
                                            title="Routing Number - Must be 9 digits and valid"
                                            minLength={9}
                                            maxLength={9}
                                            onChange={(e: any) => {
                                                setLocalState({
                                                    ...state,
                                                    bankRoutingNumber: e.target.value
                                                });
                                                setFormValidated(
                                                    e.currentTarget.form?.checkValidity() || false
                                                );
                                            }}
                                            onBlur={(e: any) => {
                                                fieldRequiredCheck(
                                                    "Bank Routing is required.  Please enter a valid 9 digit Routing number.",
                                                    e
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={4}></Col>
                                    <Col xs={4}>
                                        <Form.Label>
                                            [TODO: BANK NAME after routing number entered]
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={4}>
                                        <Form.Label>Checking or Savings Account:</Form.Label>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Check
                                            type="radio"
                                            id="checking-account-radio"
                                            title="Checking Account"
                                            label="Checking"
                                            inline
                                            defaultChecked
                                            onChange={(e) => {
                                                setLocalState({
                                                    ...state,
                                                    bankAccountType: BankAccountTypes.Checking
                                                });
                                                setFormValidated(
                                                    e.currentTarget.form?.checkValidity() || false
                                                );
                                            }}
                                            checked={
                                                state.bankAccountType === BankAccountTypes.Checking
                                            }
                                        />
                                        <Form.Check
                                            type="radio"
                                            id="savings-account-radio"
                                            label="Savings"
                                            title="Savings Account"
                                            inline
                                            onChange={(e) => {
                                                setLocalState({
                                                    ...state,
                                                    bankAccountType: BankAccountTypes.Savings
                                                });
                                                setFormValidated(
                                                    e.currentTarget.form?.checkValidity() || false
                                                );
                                            }}
                                            checked={
                                                state.bankAccountType === BankAccountTypes.Savings
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={4}>
                                        <Form.Label>
                                            Account Number:{" "}
                                            <i className="bi bi-asterisk text-danger"></i>
                                        </Form.Label>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Control
                                            id="account-number"
                                            required
                                            type="text"
                                            title="Full Banking Account Number"
                                            minLength={4}
                                            maxLength={17}
                                            defaultValue=""
                                            onChange={(e: any) => {
                                                setLocalState({
                                                    ...state,
                                                    bankAccountNumber: e.target.value
                                                });
                                                setFormValidated(
                                                    e.currentTarget.form?.checkValidity() || false
                                                );
                                            }}
                                            onBlur={(e: any) => {
                                                fieldRequiredCheck(
                                                    "Bank Account number is required. Please enter valid 4-17 digit Bank Account number.",
                                                    e
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        {state.bankCustomerType !== BankCustomerTypes.Business && (
                            <div className="row pt-4 pb-3">
                                <div className="col-4">
                                    <input
                                        type="button"
                                        className="btn btn-Primary float-end"
                                        value="Cancel"
                                        onClick={handleCancel}
                                        aria-label="Cancel button"
                                    ></input>
                                </div>
                                <div className="col-8">
                                    <input
                                        type="button"
                                        className="btn btn-Primary"
                                        disabled={
                                            !(
                                                formValidated &&
                                                (policyData?.summary.ssBillingOption ||
                                                    draftDateValidated)
                                            )
                                        }
                                        value="Validate Bank Info"
                                        aria-label="Validate Bank Info"
                                        onClick={validateBankInfo}
                                    ></input>
                                </div>
                            </div>
                        )}
                    </Form>
                )}
                <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        You have entered New Bank Draft information. Are you sure you want to
                        cancel?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleModalAffirmative}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={handleModalNegative}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showFCRAModal}
                    onHide={handleCloseFCRAModal}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    contentClassName="d-flex"
                >
                    <Modal.Header className="justify-content-center">
                        <div className="fw-bold">Please read the following disclosure </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            We are unable to use the provided banking information due to information
                            we requested and received from GIACT, a consumer reporting agency. You
                            have the right to dispute the accuracy and/or completeness of any
                            information in a consumer report furnished by GIACT. To obtain a free
                            copy of your consumer report, you may write GIACT within 60 days at:
                        </div>
                        <div className="pt-3">
                            <p className="mb-0">GIACT Systems, LLC.</p>
                            <p className="mb-0">Attention: Consumer Resolutions</p>
                            <p className="mb-0">P.O. Box 1116</p>
                            <p className="mb-0">Allen, Texas 75013</p>
                        </div>
                        <div className="pt-3">
                            Or call them at 1-833-802-8092. GIACT will send you a letter outlining
                            your rights and explain how to dispute the information provided by them.
                            GIACT only provided us information and did not make the decision to
                            reject your banking information. They will not be able to provide you
                            with specific reasons for our action.
                            <p className="pt-3">
                                Click agree to indicate you have read the above information.
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <Button
                            variant="primary"
                            onClick={handleFCRAModalAffirmative}
                            className="text-center"
                        >
                            Agree
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default AddNewBankDraft;

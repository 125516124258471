import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

type propType = {
    id: string;
};

const AdminMarketingMessageDetail: React.FC = () => {
    const { id } = useParams<propType>();
    let idParsed = 0;
    if (id && id.trim()) {
        idParsed = +id;
    }

    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { get, post } = useFetch();
    const [marketingMessage, setMarketingMessage] = useState<MarketingMessage>();
    const [embeddedURL, setEmbeddedURL] = useState<string>("");
    const [clickURL, setClickURL] = useState<string>("");
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [isActive, setIsActive] = useState<boolean>(false);
    const { authState, oktaAuth } = useOktaAuth();

    const onSaveMM = async (
        _id: number,
        _embeddedURL: string,
        _clickURL: string,
        _effectiveDate: string,
        _isActive: boolean
    ) => {
        const body: MarketingMessage = {
            id: _id,
            embeddedURL: _embeddedURL,
            clickURL: _clickURL,
            effectiveDate: _effectiveDate,
            formattedEffectiveDate: _effectiveDate,
            isActive: _isActive,
            states: []
        };
        const result = await post<MarketingMessage, boolean>("/marketingmessage/update/", "", body);
        return result;
    };

    useEffect(() => {
        const getMarketingMessage = async () => {
            const mmFromServer = await get<MarketingMessage>("/marketingmessage/" + idParsed, "");
            setMarketingMessage(mmFromServer);
            setEmbeddedURL(mmFromServer.embeddedURL);
            setClickURL(mmFromServer.clickURL);
            setEffectiveDate(mmFromServer.formattedEffectiveDate);
            setIsActive(mmFromServer.isActive);
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getMarketingMessage();
        }
    }, [idParsed, authState, oktaAuth, cpContext, navigate]);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!embeddedURL) {
            alert("Please enter Banner Url");
            return;
        }

        const result = await onSaveMM(idParsed, embeddedURL, clickURL, effectiveDate, isActive);

        //navigate.push('/admin/marketing-messages')
        //TODO: [LOW] Need to build a refresh of the State data instead of hard refreshing the page
        if (result) {
            window.location.href = "/admin/marketing-messages";
        }
    };

    if (marketingMessage && marketingMessage.id && cpContext.isAdmin) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-md-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2px",
                            borderBottomColor: "#33CCCC"
                        }}
                    >
                        <div className="col-md-8">
                            <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                Marketing Message - # {marketingMessage.id}
                            </h1>
                        </div>
                        <div
                            className="col-md-1"
                            style={{ paddingTop: 15, textAlign: "right", color: "#5D94CB" }}
                        >
                            <h5>
                                <Link
                                    to={"/admin/marketing-messages"}
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        textDecoration: "none"
                                    }}
                                >
                                    List
                                </Link>
                            </h5>
                        </div>
                        <div
                            className="col-md-1"
                            style={{
                                paddingTop: 15,
                                textAlign: "left",
                                color: "black",
                                fontWeight: "bold",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "4px",
                                borderBottomColor: "black"
                            }}
                        >
                            <span
                                style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    marginLeft: "10px"
                                }}
                            >
                                Details
                            </span>
                        </div>
                        <div
                            className="col-md-2"
                            style={{ paddingTop: 15, textAlign: "left", color: "#5D94CB" }}
                        >
                            <h5>
                                <Link
                                    to={"/admin/marketing-message-states/" + idParsed}
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        textDecoration: "none"
                                    }}
                                >
                                    States
                                </Link>
                            </h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10" style={{ backgroundColor: "#eee" }}>
                            <form
                                className="form-control border-0"
                                onSubmit={onSubmit}
                                style={{ backgroundColor: "#eee" }}
                            >
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Embedded URL:</label>
                                        <input
                                            className="form-control skip-to"
                                            type="text"
                                            name="EmbeddedUrl"
                                            value={embeddedURL}
                                            onChange={(e) => setEmbeddedURL(e.target.value)}
                                            aria-label="Embedded URL"
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Effective Date:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="EffectiveDate"
                                            value={effectiveDate}
                                            onChange={(e) => setEffectiveDate(e.target.value)}
                                            aria-label="Effective Date"
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-md-3">
                                        <input
                                            type="submit"
                                            value="Save"
                                            className="btn btn-Primary"
                                            aria-label="Save button"
                                        ></input>
                                    </div>
                                    <div className="col-md-3">
                                        <input
                                            type="button"
                                            className="btn btn-Primary"
                                            onClick={() =>
                                                navigate.push("/admin/marketing-messages")
                                            }
                                            value="Cancel"
                                            aria-label="Cancel button"
                                        ></input>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default AdminMarketingMessageDetail;

import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

const AdminMarketingMessageAdd: React.FC = () => {
    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { post } = useFetch();
    const [embeddedBannerUrl, setEmbeddedBannerUrl] = useState<string>("");
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const { authState, oktaAuth } = useOktaAuth();

    const onSaveMM = async (
        _embeddedURL: string,
        _clickURL: string,
        _effectiveDate: string,
        _isActive: boolean
    ) => {
        const body: NewMarketingMessage = {
            embeddedURL: _embeddedURL,
            clickURL: _clickURL,
            effectiveDate: _effectiveDate,
            isActive: _isActive
        };
        const result = await post<NewMarketingMessage, boolean>(
            "/marketingmessage/add/",
            "",
            body
        );
        return result;
    };

    

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!embeddedBannerUrl) {
            alert("Please enter Embedded Banner Url");
            return;
        }

        const result = await onSaveMM(embeddedBannerUrl, "", effectiveDate, true);

        //navigate.push('/admin/marketing-messages')
        //TODO: [LOW] Need to build a refresh of the State data instead of hard refreshing the page
        if (result) {
            window.location.href = "/admin/marketing-messages";
        }
    };

    if (cpContext.isAdmin) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-md-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1px",
                            borderBottomColor: "#33CCCC"
                        }}
                    >
                        <div className="col-md-8">
                            <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                Marketing Message - Add
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10" style={{ backgroundColor: "#eee" }}>
                            <form
                                className="form-control border-0"
                                onSubmit={onSubmit}
                                style={{ backgroundColor: "#eee" }}
                            >
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Embedded URL:</label>
                                        <input
                                            className="form-control skip-to"
                                            type="text"
                                            name="EmbeddedBannerUrl"
                                            value={embeddedBannerUrl}
                                            onChange={(e) => setEmbeddedBannerUrl(e.target.value)}
                                            aria-label="Embedded URL"
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Effective Date:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="EffectiveDate"
                                            value={effectiveDate}
                                            onChange={(e) => setEffectiveDate(e.target.value)}
                                            aria-label="Effective Date"
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-md-3">
                                        <input
                                            type="submit"
                                            value="Save"
                                            className="btn btn-Primary"
                                            aria-label="Save button"
                                        ></input>
                                    </div>
                                    <div className="col-md-3">
                                        <input
                                            type="button"
                                            className="btn btn-Primary"
                                            onClick={() =>
                                                navigate.push("/admin/marketing-messages")
                                            }
                                            value="Cancel"
                                            aria-label="Cancel button"
                                        ></input>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default AdminMarketingMessageAdd;

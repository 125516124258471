import { LinkContainer } from "react-router-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import useFetch from "../../_helpers/useFetch";
import { config } from "../../config";
import useLogger from "../../_helpers/useLogger";
import { Spinner } from "./Common";
import { CPContext } from "../../_helpers/CPContext";
import SkipLink from "../../_helpers/SkipLink";

const TopNavbar: React.FC = () => {
    const cpContext = useContext(CPContext);
    const { authState, oktaAuth } = useOktaAuth();
    const navigate = useHistory();
    const location = useLocation();
    const { get } = useFetch();
    const logger = useLogger();
    const [errorMessage, setErrorMessage] = useState("");
    const [currentProcessing, setCurrentProcessing] = useState(false);
    const [apiStatusProcessing, setAPIStatusProcessing] = useState(false);
    const [currentResult, setCurrentResult] = useState<ImpersonateResult>();
    const [apiStatus, setAPIStatus] = useState<apiStatus>();

    useEffect(() => {
        const fetchImpersonate = async () => {
            if (!cpContext.isCustomer && !currentProcessing && currentResult == undefined) {
                try {
                    setCurrentProcessing(true);
                    const result = await get<ImpersonateResult>("/impersonate/current", "");
                    setCurrentResult(result);
                    if (result && result.name) {
                        cpContext.changeImpersonateName(result.name);
                        cpContext.changeImpersonateEmail(result.email);
                        setErrorMessage("");
                    }
                    setCurrentProcessing(false);
                } catch (error) {
                    setErrorMessage("An unexpected error has occured, please try again.");
                    setCurrentProcessing(false);
                }
            }
        };

        const fetchAPIStatus = async () => {
            if (!apiStatusProcessing && apiStatus == undefined) {
                try {
                    setAPIStatusProcessing(true);
                    const result = await get<apiStatus>("/home/apistatus", "");
                    setAPIStatus(result);
                    if (result) {
                        cpContext.changeIsMaint(result.isMaintenance);
                        if (result.isMaintenance && cpContext.isCustomer) {
                            navigate.push("/maint");
                        }
                        setErrorMessage("");
                    }
                    setAPIStatusProcessing(false);
                } catch (error) {
                    setAPIStatusProcessing(false);
                }
            }
        };

        if (authState && authState.isAuthenticated) {
            fetchImpersonate();
        } else if (authState && !authState.isAuthenticated) {
            //Not sure if we really need to do this since we have the customAuthHandler in App.tsx which should do the same thing
            navigate.push("/");
        }

        //Used to find API status but also to pull the CRSF cookie
        fetchAPIStatus();

        //Used to hide functionality from staging/production/test users
        cpContext.changeHideForNonDev(config.app.hideForNonDev);

        // eslint-disable-next-line
    }, [authState, oktaAuth, navigate, cpContext]);

    const onActingAsClick = () => {
        navigate.push("/policies");
    };

    const onLoginClick = async () => {
        await logger.logDetailedActivity(
            "Home",
            "login",
            cpContext,

            "anonymous",
            cpContext.userInfo?.jti
        );
        oktaAuth.signInWithRedirect({ originalUri: "/" });
    };

    const onRegisterClick = async () => {
        await logger.logDetailedActivity(
            "Home",
            "register",
            cpContext,

            "anonymous",
            cpContext.userInfo?.jti
        );
        navigate.push("/register");
    };

    const onClaimsClick = async () => {
        window.open("https://www.americo.com/claims/");
    };

    const onLogoutClick = async () => {
        await logger.logDetailedActivity(
            "Home",
            "logout",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.userInfo?.jti
        );

        navigate.replace("/logout");
    };

    if (!authState) {
        return null;
    }

    const isCurrentPage = (href: string): boolean => {
        if (href === "/") {
            //home, index
            return location.pathname === href ? true : false;
        } else {
            //allow for partial URLs being passed in with .includes()
            return location.pathname.includes(href) ? true : false;
        }
    };

    return (
        <div className="container-fluid header">
            <div className="row">
                <div className="col-12 col-md-3 logoCell">
                    <SkipLink className="primary skip-link" skipTo=".skip-to">
                        <button type="button">Skip Navigation Links</button>
                    </SkipLink>
                    <a href="/" className="navbar-brand">
                        <img
                            src="https://www.americocustomer.com/assets/images/americo-logo.png"
                            title="Americo"
                            className="logo"
                        ></img>
                    </a>
                </div>
                <div className="col-12 col-md-5 header-customer-name">
                    {authState && authState.isAuthenticated && cpContext.userInfo ? (
                        <div className="pt-3">
                            {"Customer Portal  |  " + cpContext.userInfo.name}
                            {cpContext.isHO || cpContext.isAdmin ? (
                                <>
                                    {currentProcessing || apiStatusProcessing ? (
                                        <>
                                            <span style={{ paddingLeft: "20px" }}>
                                                <Spinner imageWidth={20} aria-label="Loading" />
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            {errorMessage && errorMessage.length > 0 ? (
                                                <>
                                                    <span
                                                        className="ps-4 text-danger"
                                                        aria-live="assertive"
                                                        role="alert"
                                                    >
                                                        Could not load impersonation
                                                    </span>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                    {authState &&
                                    authState.isAuthenticated &&
                                    cpContext.userInfo &&
                                    cpContext.impName != "" ? (
                                        <a
                                            href="#"
                                            onClick={onActingAsClick}
                                            className="ps-4 text-light"
                                        >
                                            Acting as {cpContext.impName}
                                        </a>
                                    ) : (
                                        <></>
                                    )}
                                    {/* {((authState && authState.isAuthenticated && cpContext.userInfo && cpContext.isMaint)) ? (
                                        <span className='bi bi-tools text-danger' style={{ paddingLeft: "5px" }}></span>
                                    ) :
                                        (
                                            <></>
                                        )} */}
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="col-12 col-md-4 menuCell">
                    {authState && authState.isAuthenticated ? (
                        <Nav
                            className="navMenu flex-grow-1 pe-3"
                            variant="pills"
                            role="navigation"
                            aria-label="Navigation Menu"
                        >
                            <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                                <li style={{ display: "inline-block" }}>
                                    <Nav.Link href="/" as="a" aria-current={isCurrentPage("/")}>
                                        Home
                                    </Nav.Link>
                                </li>
                                {cpContext.isAdmin ? (
                                    <li style={{ display: "inline-block" }}>
                                        <NavDropdown title="Admin" id="offcanvasNavbarDropdown">
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            >
                                                <li>
                                                    <LinkContainer to="/admin/faqs">
                                                        <NavDropdown.Item
                                                            href="/admin/faqs"
                                                            as="a"
                                                            aria-current={isCurrentPage(
                                                                "/admin/faq"
                                                            )}
                                                        >
                                                            FAQs
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                </li>
                                                <li>
                                                    <LinkContainer to="/admin/marketing-messages">
                                                        <NavDropdown.Item
                                                            href="/admin/marketing-messages"
                                                            as="a"
                                                            aria-current={isCurrentPage(
                                                                "/admin/marketing-message"
                                                            )}
                                                        >
                                                            Marketing Messages
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                </li>
                                                <li>
                                                    <LinkContainer to="/admin/settings">
                                                        <NavDropdown.Item
                                                            href="/admin/settings"
                                                            as="a"
                                                            aria-current={isCurrentPage(
                                                                "/admin/setting"
                                                            )}
                                                        >
                                                            Settings
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                </li>
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                ) : (
                                    <></>
                                )}
                                {cpContext.isCustomer ? (
                                    <li style={{ display: "inline-block" }}>
                                        <LinkContainer to="/policies">
                                            <Nav.Link
                                                as="a"
                                                aria-current={isCurrentPage("/policies")}
                                            >
                                                My Policies
                                            </Nav.Link>
                                        </LinkContainer>
                                    </li>
                                ) : (
                                    <></>
                                )}
                                {cpContext.isHO || cpContext.isAdmin ? (
                                    <li style={{ display: "inline-block" }}>
                                        <LinkContainer to="/impersonate/0">
                                            <Nav.Link
                                                as="a"
                                                aria-current={isCurrentPage("/impersonate")}
                                            >
                                                Act As Policy Holder
                                            </Nav.Link>
                                        </LinkContainer>
                                    </li>
                                ) : (
                                    <></>
                                )}
                                {cpContext.isCustomer && cpContext.customer.showAccountSettings ? (
                                    <li style={{ display: "inline-block" }}>
                                        <NavDropdown
                                            title="Account Settings"
                                            id="offcanvasNavbarDropdown"
                                        >
                                            <ul
                                                style={{
                                                    listStyleType: "none",
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            >
                                                <li>
                                                    <LinkContainer to="/document-delivery">
                                                        <NavDropdown.Item
                                                            href="/document-delivery"
                                                            as="a"
                                                            aria-current={isCurrentPage(
                                                                "/document-delivery"
                                                            )}
                                                        >
                                                            Document Delivery Preference
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                </li>
                                                <li>
                                                    <LinkContainer to="/messages">
                                                        <NavDropdown.Item
                                                            href="/messages"
                                                            as="a"
                                                            aria-current={isCurrentPage(
                                                                "/messages"
                                                            )}
                                                        >
                                                            Messages
                                                            {cpContext.messageCount > 0 && (
                                                                <span className="dot float-end">
                                                                    <p className="dotNumber text-center">
                                                                        {cpContext.messageCount}
                                                                    </p>
                                                                </span>
                                                            )}
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                </li>
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                ) : (
                                    <></>
                                )}
                                <li style={{ display: "inline-block" }}>
                                    <Nav.Link
                                        onClick={() => {
                                            onLogoutClick();
                                        }}
                                        as="a"
                                    >
                                        Logout
                                    </Nav.Link>
                                </li>
                            </ul>
                        </Nav>
                    ) : (
                        <nav
                            className="justify-content-end flex-grow-1 pe-3"
                            style={{ paddingTop: "20px" }}
                        >
                            <ul style={{ listStyleType: "none", float: "right" }}>
                                <li style={{ display: "inline-block" }}>
                                    {/* TODO: Need to replace button with spinner so user can't do multiple submits */}
                                    <a
                                        href="#"
                                        id="claims-button"
                                        onClick={onClaimsClick}
                                        aria-current={isCurrentPage("/claims")}
                                        className="mb-1 btn btn-primary"
                                        type="button"
                                    >
                                        Claims
                                    </a>
                                </li>
                                &nbsp;
                                <li style={{ display: "inline-block" }}>
                                    {/* TODO: Need to replace button with spinner so user can't do multiple submits */}
                                    <a
                                        href="#"
                                        id="login-button"
                                        onClick={onLoginClick}
                                        aria-current={isCurrentPage("/login")}
                                        className="mb-1 btn btn-primary"
                                        type="button"
                                    >
                                        Login
                                    </a>
                                </li>
                                &nbsp;
                                <li style={{ display: "inline-block" }}>
                                    {/* TODO: Need to replace button with spinner so user can't do multiple submits */}
                                    <a
                                        href="#"
                                        id="register-button"
                                        onClick={onRegisterClick}
                                        aria-current={isCurrentPage("/register")}
                                        className="mb-1 btn btn-primary"
                                        type="button"
                                    >
                                        Register
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopNavbar;

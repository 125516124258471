/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import useFetch from "../../_helpers/useFetch";
import useLogger from "../../_helpers/useLogger";
import { Spinner } from "../shared/Common";
import { CPContext } from "../../_helpers/CPContext";
import useUtilities from "../../_helpers/useUtilities";
import { config } from "config";

type propType = {
    id: string;
    formNumber: string;
};

const Freelook: React.FC = () => {
    const params = useParams<propType>();
    let idParsed = 0;
    let formNumberParsed = "";
    let policyNumber = "";

    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { isMaintenanceError } = useUtilities();
    const logger = useLogger();
    const { get, post } = useFetch();
    const search = navigate.location.search;
    const paramsFromURL = new URLSearchParams(search);

    const { authState, oktaAuth } = useOktaAuth();
    const [stepNumber, setStepNumber] = useState(1);
    const [fundsOption, setFundsOption] = useState("regularmail");
    const [accountOption, setAccountOption] = useState("onfile");
    const [onFileAccountValidationFail, setOnFileAccountValidationFail] = useState<boolean>(false);
    const [newAccountValidationFail, setNewAccountValidationFail] = useState<boolean>(false);
    const [maxGIACTAttemptsReached, setMaxGIACTAttemptsReached] = useState<boolean>(false);
    const [showFCRA, setShowFCRA] = useState<boolean>(false);
    const [surrenderChecked, setSurrenderChecked] = useState<boolean>(false);
    const [liabilityChecked, setLiabilityChecked] = useState<boolean>(false);
    const [noInterestChecked, setNoInterestChecked] = useState<boolean>(false);
    const [esignChecked, setEsignChecked] = useState<boolean>(false);
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [authorizeChecked, setAuthorizeChecked] = useState<boolean>(false);
    const [surrenderReason, setSurrenderReason] = useState("");
    const [surrenderReasonOther, setSurrenderReasonOther] = useState("");
    const [errorText, setErrorText] = useState("");
    const [newRoutingNumber, setNewRoutingNumber] = useState("");
    const [newAccountNumber, setNewAccountNumber] = useState("");
    const [newAccountNumberVerify, setNewAccountNumberVerify] = useState("");
    const [newAccountFirstName, setNewAccountFirstName] = useState("");
    const [newAccountLastName, setNewAccountLastName] = useState("");
    const [giactRoutingNumber, setGiactRoutingNumber] = useState("");
    const [giactAccountNumber, setGiactAccountNumber] = useState("");
    const [giactFirstName, setGiactAccountFirstName] = useState("");
    const [giactLastName, setGiactAccountLastName] = useState("");
    const [giactRunCount, setGiactRunCount] = useState(0);
    const [newAccountNumberError, setNewAccountNumberError] = useState(false);
    const [newAccountNumberVerifyError, setNewAccountNumberVerifyError] = useState(false);
    const [newRoutingNumberError, setNewRoutingNumberError] = useState(false);
    const [newAccountFirstNameError, setNewAccountFirstNameError] = useState(false);
    const [newAccountLastNameError, setNewAccountLastNameError] = useState(false);
    const [newAuthorizeError, setNewAuthorizeError] = useState(false);
    const [newGiactError, setNewGiactError] = useState(false);

    const [policyData, setPolicyData] = useState<Policy>();
    const [policyLapsed, setPolicyLapsed] = useState(false);
    const [electronicNotifyDecline, setElectronicNotifyDecline] = useState(false);
    const [notifyEmailAddress, setNotifyEmailAddress] = useState("");
    const [policyPreviewURI, setPolicyPreviewURI] = useState("");
    const [collectOnlyReason, setCollectOnlyReason] = useState<boolean>(false);
    const [hoCollectOnlyReason, setHOCollectOnlyReason] = useState<boolean>(false);
    const [requestId, setRequestId] = useState<number>(0);
    const [lastRequestId, setLastRequestId] = useState<number>(0);

    const [isProcessing, setIsProcessing] = useState(false);
    const [isNextProcessing, setIsNextProcessing] = useState(false);
    const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageGIACT, setErrorMessageGIACT] = useState("");
    const [freelookPDFBlob, setFreelookPDFBlob] = useState<string>();
    const [iFrameLoaded, setIFrameLoaded] = useState(false);
    const reasonOtherMin = 5;
    const reasonOtherMax = 250;

    const errorStyle = {
        color: "#dc3545",
        borderColor: "#dc3545"
    };

    //Load values of parameters or runtime variables
    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
    }
    formNumberParsed = paramsFromURL.get("formNumber") ?? "details";

    const handleFundsRadioClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setFundsOption(e.target.value);
        setErrorText("");
    };

    const handleAccountRadioClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccountOption(e.target.value);
    };

    const handleSurrenderReason = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSurrenderReason(e.target.value);
        setSurrenderReasonOther("");
        setErrorText("");
    };

    const handleSurrenderReasonOther = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setErrorText("");
        setSurrenderReasonOther(e.target.value.substring(0, reasonOtherMax));
    };

    const handleNewRoutingNumber = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let cleanup = e.target.value;
        cleanup = cleanup.replace(/\D/g, "");
        if (cleanup.toString().length > 9) {
            cleanup = cleanup.substring(0, 9);
        }
        setNewRoutingNumber(cleanup);
    };

    const validateRoutingNumber = (num: string) => {
        if (num.length != 9) {
            return false;
        }

        let n = 0;
        for (let i = 0; i < num.length; i += 3) {
            n +=
                parseInt(num.charAt(i), 10) * 3 +
                parseInt(num.charAt(i + 1), 10) * 7 +
                parseInt(num.charAt(i + 2), 10);
        }

        // If the resulting sum is an even multiple of ten (but not zero),
        // the aba routing number is good.
        if (n != 0 && n % 10 == 0) {
            return true;
        } else {
            return false;
        }
    };

    const onNext = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        let validationError = "";
        setErrorText(validationError);
        setIsNextProcessing(true);

        if (stepNumber === 1) {
            if (surrenderReason === "") {
                validationError = "Please select a cancellation reason to continue.";
            } else if (
                (surrenderReasonOther === "" || surrenderReasonOther.length < reasonOtherMin) &&
                surrenderReason === "Other"
            ) {
                validationError =
                    "Please enter your cancellation reason in the textbox to continue.";
            }

            const logObject = {
                Reason: surrenderReason,
                ReasonOther: surrenderReasonOther,
                PolicyNumber: policyNumber
            };
            if (
                collectOnlyReason &&
                ((validationError && validationError.length <= 0) || !validationError)
            ) {
                await logger.logDetailedActivity(
                    "Freelook",
                    "submit reason",
                    cpContext,
                    cpContext.userInfo?.email,
                    cpContext.sessionId,
                    JSON.stringify(logObject),
                    idParsed
                );

                //Redirect to download page
                navigate.push("/formdownload/" + idParsed + "?formNumber=" + formNumberParsed);
            } else if ((validationError && validationError.length <= 0) || !validationError) {
                await logger.logDetailedActivity(
                    "Freelook",
                    "submit reason",
                    cpContext,
                    cpContext.userInfo?.email,
                    cpContext.sessionId,
                    JSON.stringify(logObject),
                    idParsed
                );
            }
        }
        if (stepNumber === 2) {
            const logObject = { FundsOption: fundsOption, PolicyNumber: policyNumber };
            if (fundsOption === "") {
                validationError = "Please select a distribution method to continue.";
            } else {
                await logger.logDetailedActivity(
                    "Freelook",
                    "submit distribution",
                    cpContext,
                    cpContext.userInfo?.email,
                    cpContext.sessionId,
                    JSON.stringify(logObject),
                    idParsed
                );
                if (fundsOption === "electroniconfile" || fundsOption === "electronicnew") {
                    if (fundsOption === "electronicnew") {
                        //clear flags for field highlighting
                        setNewRoutingNumberError(false);
                        setNewAccountNumberVerifyError(false);
                        setNewAccountNumberError(false);
                        setNewAccountFirstNameError(false);
                        setNewAccountLastNameError(false);
                        setNewAuthorizeError(false);
                        setNewGiactError(false);

                        if (!newRoutingNumber || !validateRoutingNumber(newRoutingNumber)) {
                            if (!newRoutingNumber) {
                                validationError = "Please enter a Routing Number to continue.";
                                setNewRoutingNumberError(true);
                            } else {
                                validationError =
                                    "Please enter a valid 9 digit Routing Number to continue.";
                                setNewRoutingNumberError(true);
                            }
                        } else if (!newAccountNumber) {
                            validationError = "Please enter an Account Number to continue.";
                            setNewAccountNumberError(true);
                        } else if (!newAccountNumberVerify) {
                            validationError = "Please reenter Account Number to continue.";
                            setNewAccountNumberVerifyError(true);
                        } else if (!newAccountFirstName) {
                            validationError = "Please enter Account First Name to continue.";
                            setNewAccountFirstNameError(true);
                        } else if (!newAccountLastName) {
                            validationError = "Please enter Account Last Name to continue.";
                            setNewAccountLastNameError(true);
                        } else if (!authorizeChecked) {
                            validationError = "Please check the Authorization box to continue.";
                            setNewAuthorizeError(true);
                        } else if (
                            giactRunCount > 0 &&
                            giactAccountNumber == newAccountNumber &&
                            giactFirstName == newAccountFirstName &&
                            giactLastName == newAccountLastName &&
                            giactRoutingNumber == newRoutingNumber
                        ) {
                            validationError =
                                "You have not changed your information. Please verify your routing number, bank account and name as it appears on your bank account. Please try again after verifying your information.";
                        } else {
                            //GIACT Process
                            const giactResult = await runGiactVerify();
                            if (giactResult) {
                                //Set request id for later user by preview
                                setRequestId(giactResult.requestHistoryId);
                                if (
                                    giactResult.allowedCustomerAttemptsExceeded &&
                                    cpContext.isCustomer
                                ) {
                                    validationError =
                                        "Multiple attempts to input your bank account, routing number and name have been unsuccessful.  You may continue by selecting an option below or you can select cancel and your coverage will continue.  Should you need assistance please call us at 1-800-231-0801.";
                                    setNewGiactError(true);
                                    setFundsOption("");
                                } else if (
                                    giactResult.allowedOtherAttemptsExceeded &&
                                    !cpContext.isCustomer
                                ) {
                                    validationError =
                                        "Multiple attempts to input bank account, routing number and name have been unsuccessful. You may continue by selecting an option below or you can select cancel and the coverage will continue.";
                                    setNewGiactError(true);
                                    setFundsOption("");
                                } else {
                                    if (giactResult.fcraRequired) {
                                        setShowFCRA(giactResult.fcraRequired);
                                        validationError = " ";
                                        if (giactResult.accountNumberRelatedFailure) {
                                            setNewAccountNumberError(true);
                                            setNewAccountNumberVerifyError(true);
                                        }
                                        if (giactResult.nameRelatedFailure) {
                                            setNewAccountFirstNameError(true);
                                            setNewAccountLastNameError(true);
                                        }
                                        if (giactResult.routingNumberRelateFailure) {
                                            setNewRoutingNumberError(true);
                                        }
                                    } else {
                                        if (giactResult.timedOut) {
                                            validationError =
                                                "We're experiencing technical difficulties at this time. Please try again in ten minutes.";
                                        } else {
                                            if (!giactResult.isVerified) {
                                                validationError =
                                                    "Unable to validate Payment Information. Please verify your routing number, bank account and name as it appears on your bank account. Please try again after verifying your information.";
                                                if (giactResult.accountNumberRelatedFailure) {
                                                    setNewAccountNumberError(true);
                                                    setNewAccountNumberVerifyError(true);
                                                }
                                                if (giactResult.nameRelatedFailure) {
                                                    setNewAccountFirstNameError(true);
                                                    setNewAccountLastNameError(true);
                                                }
                                                if (giactResult.routingNumberRelateFailure) {
                                                    setNewRoutingNumberError(true);
                                                }
                                            } else {
                                                //giact verified, continue to next step
                                                validationError = "";
                                                setNewGiactError(false);
                                            }
                                        }
                                    }
                                }
                            } else {
                                validationError = "Could not process request, please try again.";
                            }
                        }
                    }
                }
            }
        }
        if (stepNumber === 3) {
            await logger.logDetailedActivity(
                "Freelook",
                "submit conditions",
                cpContext,
                cpContext.userInfo?.email,
                cpContext.sessionId,
                "",
                idParsed
            );
            if (!surrenderChecked || !liabilityChecked || !noInterestChecked) {
                validationError = "Please check the boxes below to continue.";
            }
        }
        if (stepNumber === 4) {
            const logObject = {
                isCustomer: cpContext.isCustomer,
                notifyEmailAddress: notifyEmailAddress,
                electronicNotifyDecline: electronicNotifyDecline
            };
            await logger.logDetailedActivity(
                "Freelook",
                "submit esign",
                cpContext,
                cpContext.userInfo?.email,
                cpContext.sessionId,
                JSON.stringify(logObject),
                idParsed
            );
            if (!esignChecked) {
                if (!cpContext.isCustomer && !notifyEmailAddress && !electronicNotifyDecline) {
                    validationError =
                        "Please ensure electronic signature box is checked and either an email is entered or the decline box is checked.";
                } else {
                    validationError =
                        "Please ensure electronic signature box is checked to continue.";
                }
            } else {
                if (!cpContext.isCustomer && !notifyEmailAddress && !electronicNotifyDecline) {
                    validationError =
                        "Please ensure either an email is entered or the decline box is checked.";
                }
            }

            if (validationError.length <= 0) {
                setLastRequestId(requestId);
                const _requestId = await generateFreelookPreview();
                if (_requestId && _requestId <= 0) {
                    //preview generation failed
                    validationError = "Could not load Freelook forms, please try again.";
                } else if (_requestId) {
                    setRequestId(_requestId);
                    const previewResult = await getFreelookPreview(_requestId);
                    if (!previewResult) {
                        validationError = "Could not load Freelook forms, please try again.";
                    }
                } else {
                    validationError = "Could not load Freelook forms, please try again.";
                }
            }
        }
        if (stepNumber === 5) {
            //No validation, review step
        }

        if (validationError && validationError.length > 0) {
            setErrorText(validationError);
            setIsNextProcessing(false);
            return;
        } else {
            setErrorText(validationError);
            setIsNextProcessing(false);
            if (
                stepNumber === 1 &&
                policyData &&
                policyData.summary.totalPremiumReceived <= 0 &&
                cpContext.isCustomer
            ) {
                setStepNumber(stepNumber + 2); //Skip distribution step
            } else {
                setStepNumber(stepNumber + 1);
            }
        }

        document.body.scrollTop = 0; //safari
        document.documentElement.scrollTop = 0; //chrome, firefox, ie
    };

    const onBack = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (
            stepNumber === 3 &&
            policyData &&
            policyData.summary.totalPremiumReceived <= 0 &&
            cpContext.isCustomer
        ) {
            setStepNumber(stepNumber - 2); //Skip distribution step
        } else {
            setStepNumber(stepNumber - 1);
        }
        document.body.scrollTop = 0; //safari
        document.documentElement.scrollTop = 0; //chrome, firefox, ie
    };

    const onSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        setIsSubmitProcessing(true);
        const freelookResult = await processFreelook();

        if (freelookResult) {
            setStepNumber(0);
            setRequestSubmitted(true);
            setErrorText("");
        } else {
            setStepNumber(5);
            setErrorText("There was a problem submitting your request, please try again.");
        }
        setIsSubmitProcessing(false);
        document.body.scrollTop = 0; //safari
        document.documentElement.scrollTop = 0; //chrome, firefox, ie
    };

    const onCancel = async (e: React.FormEvent<HTMLInputElement>) => {
        await logger.logDetailedActivity(
            "Freelook",
            "cancel",
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId
        );
        navigate.push("/policy-detail/" + idParsed + "?tab=service");
        document.body.scrollTop = 0; //safari
        document.documentElement.scrollTop = 0; //chrome, firefox, ie
    };

    const onFCRAAgree = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        await logger.logDetailedActivity(
            "Freelook",
            "FCRA Agree",
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId
        );
        setShowFCRA(false);
    };

    const renderPolicyDocumentsLinkPath = () => {
        return "<a href='/policy-detail/" + idParsed + "?tab=docs' target='_blank'>here</a>";
    };

    const renderSurrenderEmpathy = (reason: string) => {
        switch (reason) {
            case "NotAfford":
                return "Americo understands the importance of financial stability. Ensuring your premium fits your financial scope is important to Americo. You may have the option of reducing your death benefit to reduce your premium payment. Please reach us at 1-800-231-0801 to speak with one of our representatives today.";
            case "NotAnticipated":
                return "Understanding your policy is extremely important. Americo wants to ensure you have the best possible coverage for you and your loved ones. You may call us at 1-800-231-0801 to learn detailed information regarding your current coverage and any options you may have to better accommodate your needs.";
            case "NoPolicyPages":
                return (
                    "Receiving and reviewing your contract after purchasing coverage is an expectation for all of our valued customers. We have made viewing your policy easy and accessible on your client portal. You may view, download, and even print your policy right from " +
                    renderPolicyDocumentsLinkPath() +
                    ". If you would like us to send a copy of your policy, please call 1-800-231-0801."
                );
            case "MedicareGov":
                return "We understand the importance of meeting expected benefit requirements and continuing your coverage. You may want to explore other options beyond canceling your policy such as a change in ownership or beneficiary change. Please call us at 1-800-231-0801 for more information.";
            case "EndTerm":
                return "We appreciate the opportunity to serve as your life insurance company throughout the term of your policy. You may have the ability to convert your policy to a whole life (if applicable). Please call us at 1-800-231-0801 to discuss if this is an option.";
            default:
                return "";
        }
    };

    const processFreelook = async () => {
        try {
            let surrenderReasonTemp = "";
            if (surrenderReason !== "" && surrenderReason !== "Other") {
                surrenderReasonTemp = surrenderReason;
            } else {
                surrenderReasonTemp = "Other: " + surrenderReasonOther;
            }

            const body: PolicyCancelFreelook = {
                policyDWKey: idParsed,
                cancelReason: surrenderReasonTemp,
                fundsDistribution: fundsOption,
                newEFTABA: newRoutingNumber,
                newEFTAccount: newAccountNumber,
                newEFTFirstName: newAccountFirstName,
                newEFTLastName: newAccountLastName,
                newEFTGIACTAuthorization: true,
                surrenderConditionsAcknowledged: true,
                electronicSignatureAgreement: true,
                electronicSignatureName: "",
                notifyEmailAddress: notifyEmailAddress,
                declineElectronicNotification: electronicNotifyDecline,
                requestId: requestId
            };
            const result = await post<PolicyCancelFreelook, boolean>(
                "/policy/policycancelfreelook/",
                "",
                body
            );
            return result;
        } catch (e: unknown) {
            if (isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const generateFreelookPreview = async () => {
        try {
            let surrenderReasonTemp = "";
            if (surrenderReason !== "" && surrenderReason !== "Other") {
                surrenderReasonTemp = surrenderReason;
            } else {
                surrenderReasonTemp = "Other: " + surrenderReasonOther;
            }

            const body: PolicyCancelFreelook = {
                policyDWKey: idParsed,
                cancelReason: surrenderReasonTemp,
                fundsDistribution: fundsOption,
                newEFTABA: newRoutingNumber,
                newEFTAccount: newAccountNumber,
                newEFTFirstName: newAccountFirstName,
                newEFTLastName: newAccountLastName,
                newEFTGIACTAuthorization: true,
                surrenderConditionsAcknowledged: true,
                electronicSignatureAgreement: true,
                electronicSignatureName: "",
                notifyEmailAddress: notifyEmailAddress,
                declineElectronicNotification: electronicNotifyDecline,
                requestId: requestId
            };
            const result = await post<PolicyCancelFreelook, number>(
                "/policy/generatefreelookpreview/",
                "",
                body
            );
            return result;
        } catch (e: unknown) {
            if (isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const getFreelookPreview = async (_id: number): Promise<boolean> => {
        let result = false;
        try {
            let surrenderReasonTemp = "";
            if (surrenderReason !== "" && surrenderReason !== "Other") {
                surrenderReasonTemp = surrenderReason;
            } else {
                surrenderReasonTemp = "Other: " + surrenderReasonOther;
            }

            const body: PolicyCancelFreelook = {
                policyDWKey: idParsed,
                cancelReason: surrenderReasonTemp,
                fundsDistribution: fundsOption,
                newEFTABA: newRoutingNumber,
                newEFTAccount: newAccountNumber,
                newEFTFirstName: newAccountFirstName,
                newEFTLastName: newAccountLastName,
                newEFTGIACTAuthorization: true,
                surrenderConditionsAcknowledged: true,
                electronicSignatureAgreement: true,
                electronicSignatureName: "",
                notifyEmailAddress: notifyEmailAddress,
                declineElectronicNotification: electronicNotifyDecline,
                requestId: _id
            };

            const requestOptionsBodyAuth = {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
                }
            };

            await fetch(
                config.resourceServer.apiUrl + "/policy/freelookpreviews",
                requestOptionsBodyAuth
            )
                .then((response) => response.blob())
                .then((response) => {
                    if (response.type == "application/problem+json" || response.size == 0) {
                        result = false;
                    } else {
                        setErrorText("");
                        const pdfBlob = window.URL.createObjectURL(response);
                        setFreelookPDFBlob(pdfBlob);
                        result = true;
                    }
                })
                .catch((e: unknown) => {
                    if (isMaintenanceError(e)) {
                        navigate.push("/maint");
                    } else {
                        result = false;
                    }
                });
        } catch (e: unknown) {
            if (isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }

        return result;
    };

    const runGiactVerify = async () => {
        try {
            const body: GiactVerifyRequest = {
                requestId: 0,
                policyDWKey: idParsed,
                firstName: newAccountFirstName,
                lastName: newAccountLastName,
                routingNumber: newRoutingNumber,
                accountNumber: newAccountNumber,
                accountType: ""
            };

            setGiactAccountFirstName(newAccountFirstName);
            setGiactAccountLastName(newAccountLastName);
            setGiactRoutingNumber(newRoutingNumber);
            setGiactAccountNumber(newAccountNumber);
            const result = await post<GiactVerifyRequest, GiactVerifyResponse>(
                "/policy/giactverify/",
                "",
                body
            );
            setGiactRunCount(giactRunCount + 1);
            return result;
        } catch (e: unknown) {
            if (isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const LogggedNavigate = async (_uri: string, _linkName: string) => {
        await logger.logDetailedActivity(
            "Freelook",
            _linkName,
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );

        navigate.push(_uri);
    };

    useEffect(() => {
        const getPolicy = async () => {
            if (!isProcessing) {
                try {
                    setIsProcessing(true);
                    const policyFromServer = await get<Policy>(
                        "/policy/full?policyDWKey=" + idParsed,
                        ""
                    );
                    setPolicyData(policyFromServer);

                    //Check if policy has lapsed or is pending lapse
                    if (policyFromServer) {
                        policyNumber = policyFromServer.policyNumber;
                        if (
                            policyFromServer.summary.status == "Lapse Pending" ||
                            policyFromServer.summary.status == "Lapsed"
                        ) {
                            setPolicyLapsed(true);
                        } else {
                            setPolicyLapsed(false);
                        }

                        if (cpContext.isHO) {
                            setNotifyEmailAddress("");
                        } else if (!cpContext.isCustomer) {
                            setNotifyEmailAddress(cpContext.impEmail);
                        } else {
                            setNotifyEmailAddress(cpContext.userInfo?.email);
                        }

                        //TODO: [LOW] probably a better way set CollectOnlyReason logic than put it in the TSX, maybe flag in C# instead
                        if (
                            policyFromServer.hasRecentADDR &&
                            policyFromServer.client.ownerName !==
                                policyFromServer.client.payorName &&
                            cpContext.isCustomer
                        ) {
                            setCollectOnlyReason(true);
                        }
                        if (
                            policyFromServer.hasRecentADDR &&
                            policyFromServer.client.ownerName !==
                                policyFromServer.client.payorName &&
                            !cpContext.isCustomer
                        ) {
                            setHOCollectOnlyReason(true);
                        }
                        if (policyFromServer.summary.daysSinceIssue >= 45 && cpContext.isCustomer) {
                            setCollectOnlyReason(true);
                        }
                        if (
                            policyFromServer.summary.daysSinceIssue <= 45 &&
                            policyFromServer.productType.toLowerCase() == "annuity" &&
                            cpContext.isCustomer
                        ) {
                            setCollectOnlyReason(true);
                        }
                    }

                    setIsProcessing(false);
                } catch (e: unknown) {
                    if (isMaintenanceError(e)) {
                        navigate.push("/maint");
                    } else {
                        setErrorMessage(
                            "There was a problem loading your policy information, please call us at 800-231-0801 for assistance."
                        );
                        setIsProcessing(false);
                    }
                }
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getPolicy();
        }
    }, [authState, oktaAuth, cpContext]);

    const handleIFrameLoad = () => setIFrameLoaded(true);

    if (policyData && policyData.summary) {
        return (
            <section aria-labelledby="freelook-section">
                <h2 style={{ display: "none" }} aria-labelledby="freelook-section">
                    Freelook Section
                </h2>
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-md-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-6">
                                <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                    Policy Cancellation
                                </h1>
                            </div>
                            <div className="col-6 text-left">
                                <div style={{ paddingTop: 10 }}>
                                    {policyData?.policyNumber} - {policyData.product}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <Card className="policyCard">
                                    <Card.Body className="shadow">
                                        <div>
                                            {errorText && errorText.length > 0 ? (
                                                <>
                                                    <label
                                                        className="text-danger"
                                                        aria-live="assertive"
                                                        role="alert"
                                                    >
                                                        {errorText}
                                                        <br />
                                                        <br />
                                                    </label>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        {stepNumber === 1 ? (
                                            <>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <div className="col-9">
                                                        {policyData.hasRecentADDR &&
                                                        !cpContext.isCustomer ? (
                                                            <>
                                                                <span className="text-danger">
                                                                    Addr Change within 30 days
                                                                </span>
                                                                <br></br>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {collectOnlyReason ||
                                                        policyData.summary.daysSinceIssue >= 45 ? (
                                                            <></>
                                                        ) : (
                                                            <>
                                                                This policy may be entitled to a
                                                                full refund.
                                                            </>
                                                        )}
                                                        {policyData.isDraftDay ? (
                                                            <div>
                                                                <span className="text-danger">
                                                                    Cancellations within 2 business
                                                                    days of draft date may not
                                                                    suspend the scheduled draft. If
                                                                    draft occurs, refund will be
                                                                    processed within 14 business
                                                                    days.
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 5 }}>
                                                    <div
                                                        className="col-9"
                                                        style={{
                                                            fontFamily: "PT Sans Bold",
                                                            fontSize: "20px",
                                                            paddingBottom: 15
                                                        }}
                                                    >
                                                        <b>
                                                            Why do you want to cancel this policy?
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingBottom: 15 }}>
                                                    <div className="col-9">
                                                        <select
                                                            name="SurrenderReason"
                                                            style={{ width: "400px" }}
                                                            onChange={handleSurrenderReason}
                                                            defaultValue={surrenderReason}
                                                            className="skip-to"
                                                        >
                                                            <option value="">
                                                                --Select a reason--
                                                            </option>
                                                            <option value="NotAfford">
                                                                I can no longer afford my insurance
                                                                premium.
                                                            </option>
                                                            <option value="NotAnticipated">
                                                                This policy is not what I
                                                                anticipated.
                                                            </option>
                                                            <option value="NoPolicyPages">
                                                                I have not received my policy pages.
                                                            </option>
                                                            <option value="MedicareGov">
                                                                I cannot keep the coverage due to
                                                                Medicare/government guidelines.
                                                            </option>
                                                            <option value="Other">
                                                                Other (please describe)
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 5 }}>
                                                    <div className="col-9">
                                                        {surrenderReason &&
                                                        surrenderReason.length > 0 ? (
                                                            <>
                                                                {surrenderReason !== "Other" ? (
                                                                    <>
                                                                        <span
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: renderSurrenderEmpathy(
                                                                                    surrenderReason
                                                                                )
                                                                            }}
                                                                        ></span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span
                                                                            style={
                                                                                surrenderReasonOther.length ===
                                                                                    reasonOtherMax ||
                                                                                surrenderReasonOther.length <
                                                                                    reasonOtherMin
                                                                                    ? errorStyle
                                                                                    : {
                                                                                          color: "green"
                                                                                      }
                                                                            }
                                                                        >
                                                                            {
                                                                                surrenderReasonOther.length
                                                                            }{" "}
                                                                            / {reasonOtherMax}
                                                                        </span>
                                                                        <span className="text-danger">
                                                                            &nbsp;*Required
                                                                        </span>
                                                                        <br></br>
                                                                        <textarea
                                                                            style={{
                                                                                width: "400px",
                                                                                height: "160px"
                                                                            }}
                                                                            className="form-control"
                                                                            minLength={5}
                                                                            maxLength={
                                                                                reasonOtherMax
                                                                            }
                                                                            placeholder=""
                                                                            onChange={
                                                                                handleSurrenderReasonOther
                                                                            }
                                                                            aria-required={true}
                                                                        >
                                                                            {surrenderReasonOther.substring(
                                                                                0,
                                                                                reasonOtherMax
                                                                            )}
                                                                        </textarea>
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 2 ? (
                                            <>
                                                <div>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 5, paddingBottom: 20 }}
                                                    >
                                                        <div className="col-9">
                                                            <b>
                                                                How should these funds be
                                                                distributed?
                                                            </b>
                                                        </div>
                                                    </div>
                                                    {errorMessageGIACT.length > 0 ? (
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 20
                                                            }}
                                                        >
                                                            <div
                                                                className="col-9"
                                                                aria-live="assertive"
                                                                role="alert"
                                                            >
                                                                <b>{errorMessageGIACT}</b>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {policyData.hasRecentADDR &&
                                                    cpContext.isCustomer ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className="row"
                                                                style={{ paddingBottom: 5 }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 30 }}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        value="regularmail"
                                                                        name="FundsDistribution"
                                                                        checked={
                                                                            fundsOption ===
                                                                            "regularmail"
                                                                        }
                                                                        onChange={
                                                                            handleFundsRadioClick
                                                                        }
                                                                        aria-label="Regular Mail Funds Distribution"
                                                                    />{" "}
                                                                    Regular Mail
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{ paddingTop: 15 }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 70 }}
                                                                >
                                                                    {fundsOption ===
                                                                    "regularmail" ? (
                                                                        <>
                                                                            Funds will be mailed to:
                                                                            <br></br>
                                                                            <p
                                                                                style={{
                                                                                    paddingLeft: 10,
                                                                                    paddingTop: 5
                                                                                }}
                                                                            >
                                                                                {
                                                                                    policyData
                                                                                        .client
                                                                                        .insuredAddress
                                                                                }
                                                                                <br></br>
                                                                                {
                                                                                    policyData
                                                                                        .client
                                                                                        .insuredAddress2
                                                                                }
                                                                            </p>
                                                                            <br></br>
                                                                            If the address above is
                                                                            incorrect, please select
                                                                            the EFT option to have
                                                                            your funds deposited
                                                                            into the bank account on
                                                                            file. If you would like
                                                                            a check mailed to a
                                                                            different address,
                                                                            please email or mail
                                                                            your signed request with
                                                                            the new address to:
                                                                            <br></br>
                                                                            <p
                                                                                style={{
                                                                                    paddingLeft: 10,
                                                                                    paddingTop: 5
                                                                                }}
                                                                            >
                                                                                PO Box 410288
                                                                                <br></br>
                                                                                Kansas City, MO
                                                                                64141 <br></br>
                                                                                <a href="mailto:Forms@americo.com?subject=Change address for distribution check&body=I would like to change the address where my distribution check will be sent to: ">
                                                                                    Forms@americo.com
                                                                                </a>
                                                                            </p>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {policyData.hasRecentADDR &&
                                                    cpContext.isCustomer ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingBottom: 5,
                                                                    paddingTop: 15
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 30 }}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        value="overnight"
                                                                        name="FundsDistribution"
                                                                        checked={
                                                                            fundsOption ===
                                                                            "overnight"
                                                                        }
                                                                        onChange={
                                                                            handleFundsRadioClick
                                                                        }
                                                                        aria-label="Overnight Funds Distribution"
                                                                    />{" "}
                                                                    Overnight $20.00 (physical
                                                                    address required)
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{ paddingTop: 15 }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 70 }}
                                                                >
                                                                    {fundsOption === "overnight" ? (
                                                                        <>
                                                                            Note: Proceeds will be
                                                                            overnight mailed after
                                                                            your transaction status
                                                                            indicates complete.
                                                                            Please check the
                                                                            transaction history
                                                                            section for status
                                                                            updates.
                                                                            <br></br>
                                                                            Proceeds will be mailed
                                                                            to:
                                                                            <br></br>
                                                                            <p
                                                                                style={{
                                                                                    paddingLeft: 10,
                                                                                    paddingTop: 5
                                                                                }}
                                                                            >
                                                                                {
                                                                                    policyData
                                                                                        .client
                                                                                        .insuredAddress
                                                                                }
                                                                                <br></br>
                                                                                {
                                                                                    policyData
                                                                                        .client
                                                                                        .insuredAddress2
                                                                                }
                                                                            </p>
                                                                            <br></br>
                                                                            If the address above is
                                                                            incorrect, please select
                                                                            the EFT option to have
                                                                            your funds deposited
                                                                            into the bank account on
                                                                            file. If you would like
                                                                            a check mailed to a
                                                                            different address,
                                                                            please email or mail
                                                                            your signed request with
                                                                            the new address to:
                                                                            <br></br>
                                                                            <p
                                                                                style={{
                                                                                    paddingLeft: 10,
                                                                                    paddingTop: 5
                                                                                }}
                                                                            >
                                                                                PO Box 410288
                                                                                <br></br>
                                                                                Kansas City, MO
                                                                                64141 <br></br>
                                                                                <a href="mailto:Forms@americo.com?subject=Change address for distribution check&body=I would like to change the address where my distribution check will be sent to: ">
                                                                                    Forms@americo.com
                                                                                </a>
                                                                            </p>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {(policyData.client.ownerName !==
                                                        policyData.client.payorName ||
                                                        policyData.summary.billingMethod !=
                                                            "Bank Draft") &&
                                                    cpContext.isCustomer ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingBottom: 5,
                                                                    paddingTop: 15
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 30 }}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        value="electroniconfile"
                                                                        name="FundsDistribution"
                                                                        checked={
                                                                            fundsOption ===
                                                                            "electroniconfile"
                                                                        }
                                                                        disabled={
                                                                            onFileAccountValidationFail
                                                                        }
                                                                        onChange={
                                                                            handleFundsRadioClick
                                                                        }
                                                                        aria-label="Electronic Funds Transfer Funds Distribution"
                                                                    />{" "}
                                                                    Electronic Funds Transfer -
                                                                    Account on file
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{ paddingTop: 15 }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 40 }}
                                                                >
                                                                    {fundsOption ===
                                                                        "electroniconfile" &&
                                                                    !onFileAccountValidationFail ? (
                                                                        <>
                                                                            <p
                                                                                style={{
                                                                                    paddingLeft: 30
                                                                                }}
                                                                            >
                                                                                {
                                                                                    policyData
                                                                                        .summary
                                                                                        .bankName
                                                                                }
                                                                                <br></br>
                                                                                Account ending in{" "}
                                                                                {
                                                                                    policyData
                                                                                        .summary
                                                                                        .last4DigitAcctNum
                                                                                }{" "}
                                                                                for{" "}
                                                                                {
                                                                                    policyData
                                                                                        .summary
                                                                                        .billingMethod
                                                                                }
                                                                                <br></br>
                                                                            </p>
                                                                            {/* <p style={{ paddingLeft: 30 }}>
                                                                            Proceeds will be sent to the bank account currently used to draft premium payments.<br></br>
                                                                        </p> */}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {onFileAccountValidationFail ? (
                                                                                <>
                                                                                    <p
                                                                                        className="text-danger"
                                                                                        style={{
                                                                                            paddingLeft: 30
                                                                                        }}
                                                                                    >
                                                                                        We were
                                                                                        unable to
                                                                                        verify the
                                                                                        account on
                                                                                        file as as
                                                                                        destination
                                                                                        for the
                                                                                        funds:
                                                                                        <br></br>
                                                                                        Please
                                                                                        select a
                                                                                        different
                                                                                        delivery
                                                                                        option to
                                                                                        receive your
                                                                                        payment or
                                                                                        click Cancel
                                                                                        to cancel
                                                                                        the
                                                                                        transaction.
                                                                                    </p>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {((policyData.hasRecentADDR ||
                                                        policyData.allowedCustomerGiactAttemptsExceeded ||
                                                        newGiactError) &&
                                                        cpContext.isCustomer) ||
                                                    ((newGiactError ||
                                                        policyData.allowedOtherGiactAttemptsExceeded) &&
                                                        !cpContext.isCustomer) ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingBottom: 5,
                                                                    paddingTop: 15
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 30 }}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        value="electronicnew"
                                                                        name="FundsDistribution"
                                                                        checked={
                                                                            fundsOption ===
                                                                            "electronicnew"
                                                                        }
                                                                        disabled={
                                                                            newAccountValidationFail
                                                                        }
                                                                        onChange={
                                                                            handleFundsRadioClick
                                                                        }
                                                                        aria-label="New Account Electronic Funds Transfer Distribution Account"
                                                                    />{" "}
                                                                    Electronic Funds Transfer - New
                                                                    Account (Must belong to the
                                                                    policy owner)
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{ paddingTop: 5 }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 70 }}
                                                                >
                                                                    {showFCRA ? (
                                                                        <>
                                                                            <p
                                                                                className="text-danger"
                                                                                style={{
                                                                                    paddingLeft: 30
                                                                                }}
                                                                            >
                                                                                We are unable to use
                                                                                the provide banking
                                                                                information due to
                                                                                information we
                                                                                requested and
                                                                                received from GIACT,
                                                                                a consumer reporting
                                                                                agency, You have the
                                                                                right to dispute the
                                                                                accuracy and/or
                                                                                completeness of any
                                                                                information in a
                                                                                consumer report
                                                                                furnished by GIACT.
                                                                                To obtain a free
                                                                                copy of your
                                                                                consumer report, you
                                                                                may write GIACT
                                                                                within 60 days at:
                                                                                <br></br>
                                                                                <br></br>
                                                                                GIACT Sytems, LLC.
                                                                                <br></br>
                                                                                Attension Consumer
                                                                                Resolutions<br></br>
                                                                                P.O. Box 1116
                                                                                <br></br>
                                                                                Allen, TX 75013
                                                                                <br></br>
                                                                                <br></br>
                                                                                <br></br>
                                                                                Or call them at
                                                                                1-833-802-8092.
                                                                                GIACT will send yo a
                                                                                letter outlining
                                                                                your rights and
                                                                                explain how to
                                                                                dispute the
                                                                                information provided
                                                                                by them. GIACT only
                                                                                provided us
                                                                                information and did
                                                                                not make the
                                                                                decision to reject
                                                                                your banking
                                                                                information. They
                                                                                will not be able to
                                                                                provide you with
                                                                                specific reasons for
                                                                                our action.
                                                                                <br></br>
                                                                                <br></br>
                                                                                Click Agree to
                                                                                indicate you have
                                                                                read the above
                                                                                information.
                                                                            </p>
                                                                            <div className="col">
                                                                                <input
                                                                                    type="button"
                                                                                    value="I Agree"
                                                                                    className="btn btn-Primary"
                                                                                    onClick={
                                                                                        onFCRAAgree
                                                                                    }
                                                                                    style={{
                                                                                        width: "90px"
                                                                                    }}
                                                                                    aria-label="Agree button"
                                                                                ></input>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {fundsOption ===
                                                                                "electronicnew" &&
                                                                            !newAccountValidationFail ? (
                                                                                <>
                                                                                    <div>
                                                                                        <label>
                                                                                            Routing
                                                                                            Number
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            placeholder=""
                                                                                            onChange={
                                                                                                handleNewRoutingNumber
                                                                                            }
                                                                                            value={
                                                                                                newRoutingNumber
                                                                                            }
                                                                                            style={
                                                                                                newRoutingNumberError
                                                                                                    ? errorStyle
                                                                                                    : {}
                                                                                            }
                                                                                            aria-label="Routing Number"
                                                                                            aria-live={
                                                                                                newRoutingNumberError
                                                                                                    ? "assertive"
                                                                                                    : "off"
                                                                                            }
                                                                                            role={
                                                                                                newRoutingNumberError
                                                                                                    ? "alert"
                                                                                                    : ""
                                                                                            }
                                                                                        ></input>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>
                                                                                            Account
                                                                                            Number
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder=""
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setNewAccountNumber(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            }
                                                                                            value={
                                                                                                newAccountNumber
                                                                                            }
                                                                                            style={
                                                                                                newAccountNumberError
                                                                                                    ? errorStyle
                                                                                                    : {}
                                                                                            }
                                                                                            aria-label="Account Number"
                                                                                            aria-live={
                                                                                                newAccountNumberError
                                                                                                    ? "assertive"
                                                                                                    : "off"
                                                                                            }
                                                                                            role={
                                                                                                newAccountNumberError
                                                                                                    ? "alert"
                                                                                                    : ""
                                                                                            }
                                                                                        ></input>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>
                                                                                            Reenter
                                                                                            Account
                                                                                            Number
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder=""
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setNewAccountNumberVerify(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            }
                                                                                            value={
                                                                                                newAccountNumberVerify
                                                                                            }
                                                                                            style={
                                                                                                newAccountNumberVerifyError
                                                                                                    ? errorStyle
                                                                                                    : {}
                                                                                            }
                                                                                            aria-label="Re-enter Account Number"
                                                                                            aria-live={
                                                                                                newAccountNumberVerifyError
                                                                                                    ? "assertive"
                                                                                                    : "off"
                                                                                            }
                                                                                            role={
                                                                                                newAccountNumberVerifyError
                                                                                                    ? "alert"
                                                                                                    : ""
                                                                                            }
                                                                                        ></input>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>
                                                                                            First
                                                                                            Name as
                                                                                            it
                                                                                            appears
                                                                                            on bank
                                                                                            account
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder=""
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setNewAccountFirstName(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            }
                                                                                            value={
                                                                                                newAccountFirstName
                                                                                            }
                                                                                            style={
                                                                                                newAccountFirstNameError
                                                                                                    ? errorStyle
                                                                                                    : {}
                                                                                            }
                                                                                            aria-label="First Name as it appears on bank account"
                                                                                            aria-live={
                                                                                                newAccountFirstNameError
                                                                                                    ? "assertive"
                                                                                                    : "off"
                                                                                            }
                                                                                            role={
                                                                                                newAccountFirstNameError
                                                                                                    ? "alert"
                                                                                                    : ""
                                                                                            }
                                                                                        ></input>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>
                                                                                            Last
                                                                                            Name as
                                                                                            it
                                                                                            appears
                                                                                            on bank
                                                                                            account
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder=""
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setNewAccountLastName(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            }
                                                                                            value={
                                                                                                newAccountLastName
                                                                                            }
                                                                                            style={
                                                                                                newAccountLastNameError
                                                                                                    ? errorStyle
                                                                                                    : {}
                                                                                            }
                                                                                            aria-label="Last Name as it appears on bank account"
                                                                                            aria-live={
                                                                                                newAccountLastNameError
                                                                                                    ? "assertive"
                                                                                                    : "off"
                                                                                            }
                                                                                            role={
                                                                                                newAccountLastNameError
                                                                                                    ? "alert"
                                                                                                    : ""
                                                                                            }
                                                                                        ></input>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {newAccountValidationFail ? (
                                                                                        <>
                                                                                            <p
                                                                                                className="text-danger"
                                                                                                style={{
                                                                                                    paddingLeft: 30
                                                                                                }}
                                                                                            >
                                                                                                Multiple
                                                                                                attempts
                                                                                                to
                                                                                                input
                                                                                                your
                                                                                                bank
                                                                                                account,
                                                                                                routing
                                                                                                number
                                                                                                and
                                                                                                name
                                                                                                have
                                                                                                been
                                                                                                unsuccessful.
                                                                                                You
                                                                                                may
                                                                                                continue
                                                                                                by
                                                                                                selecting
                                                                                                an
                                                                                                different
                                                                                                option
                                                                                                or
                                                                                                you
                                                                                                can
                                                                                                select
                                                                                                cancel
                                                                                                and
                                                                                                your
                                                                                                coverage
                                                                                                will
                                                                                                continue.
                                                                                                Should
                                                                                                you
                                                                                                need
                                                                                                assistance
                                                                                                please
                                                                                                call
                                                                                                us
                                                                                                at
                                                                                                1-800-231-0801.
                                                                                            </p>
                                                                                        </>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div>
                                                    {fundsOption === "electronicnew" &&
                                                    !newAccountValidationFail &&
                                                    !showFCRA &&
                                                    !newGiactError ? (
                                                        <>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 25,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div className="col-9">
                                                                    <b>Authorization:</b>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{ paddingTop: 5 }}
                                                            >
                                                                <div
                                                                    className="col-9"
                                                                    style={{ paddingLeft: 30 }}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={authorizeChecked}
                                                                        value={authorizeChecked?.toString()}
                                                                        onChange={(e) =>
                                                                            setAuthorizeChecked(
                                                                                e.currentTarget
                                                                                    .checked
                                                                            )
                                                                        }
                                                                        className="inactiveCheckbox"
                                                                        aria-label="Inactive checkbox"
                                                                    ></input>{" "}
                                                                    <span
                                                                        style={
                                                                            newAuthorizeError
                                                                                ? errorStyle
                                                                                : {}
                                                                        }
                                                                        aria-live={
                                                                            newAuthorizeError
                                                                                ? "assertive"
                                                                                : "off"
                                                                        }
                                                                        role={
                                                                            newAuthorizeError
                                                                                ? "alert"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        I authorize Americo to
                                                                        verify my information with a
                                                                        third party
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 3 ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 20 }}
                                                >
                                                    <div className="col-9">
                                                        <b>
                                                            Please acknowledge the conditions of
                                                            your cancellation:
                                                        </b>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingBottom: 15, paddingTop: 15 }}
                                                >
                                                    <div
                                                        className="col-1"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={surrenderChecked}
                                                            value={surrenderChecked?.toString()}
                                                            onChange={(e) =>
                                                                setSurrenderChecked(
                                                                    e.currentTarget.checked
                                                                )
                                                            }
                                                            className="SurrenderCheck"
                                                            aria-label="Surrender checkbox"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        In consideration of and in exchange for the
                                                        cash value of the above policy, the
                                                        undersigned hereby surrenders said policy
                                                        for cancellation. In accordance with the
                                                        terms of the policy, it is hereby agreed
                                                        that any indebtedness thereon to the Company
                                                        will be deducted from the cash value.
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingBottom: 15 }}>
                                                    <div
                                                        className="col-1"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={liabilityChecked}
                                                            value={liabilityChecked?.toString()}
                                                            onChange={(e) =>
                                                                setLiabilityChecked(
                                                                    e.currentTarget.checked
                                                                )
                                                            }
                                                            className="LiabilityCheck"
                                                            aria-label="Liability checkbox"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        It is understood and agreed that upon
                                                        execution and mailing of this request to the
                                                        Company, the entire liability of the Company
                                                        under this policy is hereby discharged and
                                                        terminated, except for payment of the net
                                                        cash surrender value.
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingBottom: 5 }}>
                                                    <div
                                                        className="col-1"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={noInterestChecked}
                                                            value={noInterestChecked?.toString()}
                                                            onChange={(e) =>
                                                                setNoInterestChecked(
                                                                    e.currentTarget.checked
                                                                )
                                                            }
                                                            className="LiabilityCheck"
                                                            aria-label="No Interest checkbox"
                                                        ></input>
                                                    </div>
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        It is expressly represented and warranted
                                                        that no other person, firm or corporation
                                                        has any interest in said policy except the
                                                        undersigned and that there are no tax liens
                                                        or proceedings in insolvency or bankruptcy
                                                        instituted or pending against the
                                                        undersigned owner.
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 4 ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div className="col-9">
                                                        <b>Electronic Signature:</b>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={esignChecked}
                                                            value={esignChecked?.toString()}
                                                            onChange={(e) =>
                                                                setEsignChecked(
                                                                    e.currentTarget.checked
                                                                )
                                                            }
                                                            className="EsignCheck"
                                                            aria-label="E-sign checkbox"
                                                        ></input>
                                                        &nbsp;
                                                        {cpContext.isCustomer ? (
                                                            <>
                                                                By checking this box you agree and
                                                                affirm with full knowledge and
                                                                consent that all electronic
                                                                signatures that you provide or adopt
                                                                are the legal equivalent of your
                                                                handwritten signature and your
                                                                signature on this document is as
                                                                valid as if you signed the document
                                                                in writing. This acknowledgement may
                                                                be used in conjunction with the use
                                                                of electronic signatures on all
                                                                forms regarding any and all future
                                                                documentation with a signature
                                                                requirement, should you elect to
                                                                have signed electronically. You have
                                                                the right to withdraw your consent
                                                                at any time and receive a paper copy
                                                                at no charge by reaching out to
                                                                Americo at 800.231.0801.
                                                            </>
                                                        ) : (
                                                            <>
                                                                By checking this box, I authorize
                                                                Americo to terminate my coverage and
                                                                waive the requirement of sending my
                                                                request in writing.
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                {!cpContext.isCustomer ? (
                                                    <>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 15
                                                            }}
                                                        >
                                                            <div
                                                                className="col-9"
                                                                style={{ paddingLeft: 30 }}
                                                            >
                                                                <label>Notify Email</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    onChange={(e) =>
                                                                        setNotifyEmailAddress(
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    value={notifyEmailAddress}
                                                                    aria-label="Notify Email"
                                                                ></input>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 15
                                                            }}
                                                        >
                                                            <div
                                                                className="col-9"
                                                                style={{ paddingLeft: 30 }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        electronicNotifyDecline
                                                                    }
                                                                    value={electronicNotifyDecline?.toString()}
                                                                    onChange={(e) =>
                                                                        setElectronicNotifyDecline(
                                                                            e.currentTarget.checked
                                                                        )
                                                                    }
                                                                    className="EsignCheck"
                                                                    aria-label="E-sign checkbox"
                                                                ></input>
                                                                &nbsp;By checking this box, I
                                                                decline to receive confirmation of
                                                                this cancellation via email.
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-9"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <p>
                                                            Click Next to review forms and to submit
                                                            your final request
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {stepNumber === 5 ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div className="col-12">
                                                        <b>Review and Submit:</b>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-12"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <p>
                                                            Please review the form and click
                                                            &apos;Submit&apos; to submit your
                                                            request. If you would like to make
                                                            changes, please hit the
                                                            &apos;previous&apos; button.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-12"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        {requestId > 0 &&
                                                        freelookPDFBlob &&
                                                        freelookPDFBlob.length > 0 ? (
                                                            <>
                                                                <iframe
                                                                    src={freelookPDFBlob}
                                                                    onLoad={handleIFrameLoad}
                                                                    title={"FreelookPreview"}
                                                                    id={"FreelookPreview"}
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "550px"
                                                                    }}
                                                                ></iframe>
                                                            </>
                                                        ) : (
                                                            <>
                                                                Count not load preview, please click
                                                                OK to try again.{requestId}:
                                                                {lastRequestId}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div
                                                        className="col-12"
                                                        style={{ paddingLeft: 30 }}
                                                    >
                                                        <p>
                                                            Click submit to finalize your request.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {requestSubmitted ? (
                                            <div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 15 }}
                                                >
                                                    <div className="col-12">
                                                        <b>Your request has been submitted</b>
                                                    </div>
                                                </div>
                                                {cpContext.isCustomer ? (
                                                    <>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 15
                                                            }}
                                                        >
                                                            <div
                                                                className="col-12"
                                                                style={{ paddingLeft: 30 }}
                                                            >
                                                                <p>
                                                                    You will receive an email at the
                                                                    email you used to log in
                                                                    confirming receipt of your
                                                                    request.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                            <div className="col">
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-12">
                                        <div className="row">
                                            {showFCRA ? (
                                                <></>
                                            ) : (
                                                <>
                                                    {requestSubmitted ? (
                                                        <>
                                                            <div
                                                                style={{
                                                                    paddingLeft: "0px",
                                                                    paddingRight: "0px",
                                                                    float: "left",
                                                                    width: "100px"
                                                                }}
                                                            >
                                                                <input
                                                                    type="button"
                                                                    value="OK"
                                                                    className="btn btn-Primary"
                                                                    onClick={() =>
                                                                        navigate.push(
                                                                            "/policy-detail/" +
                                                                                idParsed +
                                                                                "?tab=service"
                                                                        )
                                                                    }
                                                                    style={{ width: "90px" }}
                                                                    aria-label="OK button"
                                                                ></input>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {stepNumber > 1 ? (
                                                                <div
                                                                    style={{
                                                                        paddingLeft: "0px",
                                                                        paddingRight: "0px",
                                                                        float: "left",
                                                                        width: "100px"
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="button"
                                                                        value="Previous"
                                                                        className="btn btn-Primary"
                                                                        onClick={onBack}
                                                                        style={{ width: "90px" }}
                                                                        aria-label="Previous button"
                                                                    ></input>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {stepNumber == 5 ? (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            paddingLeft: "0px",
                                                                            paddingRight: "0px",
                                                                            float: "left",
                                                                            width: "100px"
                                                                        }}
                                                                    >
                                                                        {isSubmitProcessing ? (
                                                                            <Spinner
                                                                                imageWidth={20}
                                                                                aria-label="Loading"
                                                                            />
                                                                        ) : (
                                                                            <input
                                                                                type="button"
                                                                                value="Submit"
                                                                                className="btn btn-Primary"
                                                                                onClick={onSubmit}
                                                                                style={{
                                                                                    width: "90px"
                                                                                }}
                                                                                aria-label="Submit button"
                                                                            ></input>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            paddingLeft: "0px",
                                                                            paddingRight: "0px",
                                                                            float: "left",
                                                                            width: "100px"
                                                                        }}
                                                                    >
                                                                        {isNextProcessing ? (
                                                                            <Spinner
                                                                                imageWidth={20}
                                                                                aria-label="Loading"
                                                                            />
                                                                        ) : (
                                                                            <input
                                                                                type="button"
                                                                                value="Next"
                                                                                className="btn btn-Primary"
                                                                                onClick={onNext}
                                                                                style={{
                                                                                    width: "90px"
                                                                                }}
                                                                                aria-label="Next button"
                                                                            ></input>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            )}

                                                            <div
                                                                style={{
                                                                    paddingLeft: "0px",
                                                                    paddingRight: "0px",
                                                                    float: "left",
                                                                    width: "100px"
                                                                }}
                                                            >
                                                                <input
                                                                    type="button"
                                                                    className="btn btn-Primary"
                                                                    onClick={() =>
                                                                        LogggedNavigate(
                                                                            "/policy-detail/" +
                                                                                idParsed +
                                                                                "?tab=service",
                                                                            "Cancel"
                                                                        )
                                                                    }
                                                                    value="Cancel"
                                                                    style={{ width: "90px" }}
                                                                    aria-label="Cancel button"
                                                                ></input>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <section aria-labelledby="freelook-section">
                <h1 style={{ display: "none" }}>Freelook Policy Cancellation Error Message</h1>
                <h2 style={{ display: "none" }} aria-labelledby="freelook-section">
                    Freelook Section
                </h2>
                <div
                    className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-danger"
                    aria-live="assertive"
                    role="alert"
                >
                    {errorMessage}
                </div>
            </section>
        );
    } else if (isProcessing) {
        return (
            <section aria-labelledby="freelook-section">
                <h1 style={{ display: "none" }}>Freelook Policy Cancellation Spinner</h1>
                <h2 style={{ display: "none" }} aria-labelledby="freelook-section">
                    Freelook Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    } else {
        return (
            <section aria-labelledby="freelook-section">
                <h1 style={{ display: "none" }}>
                    Freelook Policy Cancellation Contact Customer Service
                </h1>
                <h2 style={{ display: "none" }} aria-labelledby="freelook-section">
                    Freelook Section
                </h2>
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                    There was a problem loading your policy information, please call us at
                    800-231-0801 for assistance.
                </div>
            </section>
        );
    }
};

export default Freelook;
/*eslint-enable*/

import React from "react";

// eslint-disable-next-line
const Spinner = ({ imageWidth }: { imageWidth: number }): any => (
    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <img
            src="https://www.americocustomer.com/assets/images/spinner.gif"
            width={imageWidth}
            aria-label="Loading"
            aria-live="assertive"
            role="alert"
        />
    </div>
);

export { Spinner };

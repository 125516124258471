import useUtilities from "./useUtilities";
import useFetch from "./useFetch";
import { CPContext } from "../_helpers/CPContext";
import { useContext } from "react";
import { useHistory } from "react-router";

const useLogger = () => {
    const { post } = useFetch();
    const utilities = useUtilities();
    const cpContext = useContext(CPContext);
    const navigate = useHistory();

    async function logActivity(
        _area: string,
        _activity: string,
        _user?: string,
        _refString?: string
    ): Promise<boolean> {
        try {
            const body: ActivityLog = {
                User: _user ?? "anonymous",
                Area: _area,
                Action: _activity,
                ReferenceString: _refString ?? ""
            };
            const result = await post<ActivityLog, boolean>("/home/logactivity", "", body);
            return result;
        } catch (error) {
            return false;
        }
    }

    async function logDetailedActivity(
        _view: string,
        _activity: string,
        _cpContext: ICPHeaderContext,
        _user?: string,
        _sessionId?: string,
        _reference?: string,
        _referenceId?: number
    ): Promise<boolean> {
        try {
            const body: DetailedActivityLog = {
                View: _view,
                Referrer: document.referrer ?? "",
                Action: _activity,
                ReferenceString: _reference ?? "",
                ReferenceId: _referenceId ?? 0,
                Device: "",
                UserAgent: navigator.userAgent,
                IP: "",
                User: _user ?? "anonymous",
                SessionId: _sessionId ?? ""
            };

            let result = false;
            result = await post<DetailedActivityLog, boolean>(
                "/home/logsecure",
                await utilities.getCSRFToken(),
                body
            );

            return result;
        } catch (error) {
            return false;
        }
    }

    const loggedRequestNavigate = async (
        _uri: string,
        _currentView: string,
        _linkName: string,
        _formNumber: string,
        _isExternalURI: boolean,
        _policyData?: Policy,
        _id?: number
    ) => {
        await logDetailedActivity(
            _currentView,
            _linkName,
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId
        );

        if (_isExternalURI) {
            window.open(_uri);
        } else {
            if (
                (_uri === "" ||
                    _uri.length <= 0 ||
                    (_policyData?.customerPortalAccessLevel !== undefined &&
                        _policyData.customerPortalAccessLevel !== 2)) &&
                _formNumber.length > 0
            ) {
                _uri = "/formdownload/" + _id + "?formNumber=" + _formNumber;
            } else {
                if (_policyData?.electronicDisclosure || !cpContext.isCustomer) {
                    if (_formNumber && _formNumber.length > 0) {
                        _uri = _uri + "/" + _id + "?formNumber=" + _formNumber;
                    } else {
                        if (_id != undefined || _id != null) {
                            _uri = _uri + "/" + _id;
                        }
                    }
                } else if (_uri.length > 0) {
                    if (_uri.indexOf("request=/payments/") === -1) {
                        if (_uri.indexOf("freelook") > 0) {
                            _uri =
                                "/electronicauth/" +
                                _id +
                                "?request=freelook&formNumber=" +
                                _formNumber;
                        } else if (_uri.indexOf("beneficiary") > 0) {
                            _uri += `/${_id}`;
                        } else if (_uri.indexOf("document-upload") > 0) {
                            _uri += `/${_id}`;
                        } else {
                            const _request = _uri.replace("/", "");
                            _uri =
                                "/electronicauth/" +
                                _id +
                                "?request=" +
                                _request +
                                "&formNumber=" +
                                _formNumber;
                        }
                    }
                }
            }

            navigate.push(_uri);
        }
    };

    return { logActivity, logDetailedActivity, loggedRequestNavigate };
};

export default useLogger;

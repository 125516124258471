import React, { useContext, useEffect, useState } from "react";
import { CPContext } from "../../_helpers/CPContext";
import { useHistory, useLocation } from "react-router";
import { Card } from "react-bootstrap";
import useUtilities from "../../_helpers/useUtilities";
import useLogger from "../../_helpers/useLogger";
import { Spinner } from "../shared/Common";

const DocumentDelivery: React.FC = () => {
    const utilities = useUtilities();
    const logger = useLogger();
    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const [deliveryOptionChanged, setDeliveryOptionChanged] = useState(false);
    const [defaultDeliveryOption, setDefaultDeliveryOption] = useState<boolean>();
    const [emailDocumentDelivery, setEmailDocumentDelivery] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setIsLoading] = useState<boolean>();

    useEffect(() => {
        getEDeliveryFlag();
    }, []);

    const location = useLocation();

    const changeDeliveryOption = (option: boolean) => {
        setDeliveryOptionChanged(true);
        setEmailDocumentDelivery(option);
        setErrorMessage("");
    };

    const getEDeliveryFlag = async () => {
        setIsLoading(true);
        const result = await utilities.getEDeliveryFlag();
        setDefaultDeliveryOption(result.eDeliveryFlag === "Y");
        setEmailDocumentDelivery(result.eDeliveryFlag === "Y");
        setIsLoading(false);
    };

    const documentDeliveryAuthorization = () => {
        if (!deliveryOptionChanged || defaultDeliveryOption === emailDocumentDelivery) {
            setErrorMessage(
                "No change has been made. Please make a change before continuing to the next screen."
            );
            return;
        }
        if (!emailDocumentDelivery) {
            utilities.createEDeliveryFlagWorkObject(false);
            logger.logDetailedActivity(
                "DocumentDelivery",
                "Regular Mail",
                cpContext,
                cpContext.userInfo?.email,
                cpContext.sessionId,
                "Paper Submit",
                0
            );
            navigate.push({ pathname: "/document-delivery", state: { fromEAuth: true } });
            return;
        }
        LoggedNavigate("/document-delivery-authorization", "DocumentDeliveryAuthorization");
    };

    const LoggedNavigate = async (_uri: string, _linkName: string) => {
        await logger.logDetailedActivity(
            _uri,
            _linkName,
            cpContext,
            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            0
        );

        navigate.push(_uri);
    };

    if (loading) {
        return (
            <section aria-labelledby="eauth-section">
                <h1 style={{ display: "none" }}>
                    Electronic Signature and Delivery Authorization Spinner
                </h1>
                <h2 style={{ display: "none" }} aria-labelledby="eauth-section">
                    Electronic Auth Section
                </h2>
                <Spinner imageWidth={20} aria-label="Loading" />
            </section>
        );
    } else {
        return (
            <div className="row my-5" style={{ padding: 10 }}>
                <div className="col-12">
                    <Card className="policyCard">
                        <Card.Body className="shadow">
                            <div className="row p-4">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                    {errorMessage && (
                                        <div className="text-danger mx-auto mb-3">
                                            {errorMessage}
                                        </div>
                                    )}
                                    <div className="pb-3">
                                        I wish to receive my Americo policy correspondence and
                                        statements using:
                                    </div>
                                    <div className="row pt-4">
                                        <div className="col-1">
                                            <input
                                                type="checkbox"
                                                value={String(emailDocumentDelivery)}
                                                onClick={() => changeDeliveryOption(true)}
                                                className="NotAgreeCheck"
                                                aria-label="An email will be sent when a document is available"
                                                checked={emailDocumentDelivery}
                                            ></input>
                                        </div>
                                        <div className="col-9">
                                            Email: {cpContext.userInfo?.email}
                                            {emailDocumentDelivery && (
                                                <small className="ms-3 enrolled">Enrolled</small>
                                            )}
                                            <p className="ms-5">
                                                <small>
                                                    An email will be sent when a document is
                                                    available
                                                </small>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row pt-4">
                                        <div className="col-1">
                                            <input
                                                type="checkbox"
                                                value={String(!emailDocumentDelivery)}
                                                onClick={() => changeDeliveryOption(false)}
                                                className="NotAgreeCheck"
                                                aria-label="Documents will be sent via mail"
                                                checked={!emailDocumentDelivery}
                                            ></input>
                                        </div>
                                        <div className="col-9">
                                            Paper (regular mail)
                                            {!emailDocumentDelivery && (
                                                <small className="ms-3 enrolled">Enrolled</small>
                                            )}
                                        </div>
                                    </div>
                                    {location?.state?.fromEAuth ? (
                                        <div>
                                            <div className="row pt-4">
                                                <div className="col-2"></div>
                                                <div className="col-8">
                                                    <p style={{ color: "red" }}>
                                                        *A Confirmation email will be sent to{" "}
                                                        {cpContext.userInfo?.email} confirming your
                                                        delivery preference change request. Please
                                                        allow 3-5 business days for this change to
                                                        be reflected on your delivery preference
                                                        page.
                                                    </p>
                                                </div>
                                                <div className="col-2"></div>
                                            </div>
                                            <div className="row pt-4">
                                                <div tabIndex={0} className="text-center">
                                                    <input
                                                        type="button"
                                                        className="btn btn-Primary me-2"
                                                        value="Back to Home"
                                                        onClick={() =>
                                                            LoggedNavigate(
                                                                "/landingpage/",
                                                                "Back to Home"
                                                            )
                                                        }
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row pt-4">
                                            <div className="col-12 d-flex">
                                                <div className="mx-auto">
                                                    <input
                                                        type="button"
                                                        className="btn btn-Primary me-2"
                                                        onClick={() =>
                                                            documentDeliveryAuthorization()
                                                        }
                                                        value="Next"
                                                        aria-label="Next button"
                                                    ></input>
                                                    <input
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        value="Cancel"
                                                        aria-label="Cancel button"
                                                        onClick={() => {
                                                            LoggedNavigate("/policies/", "Cancel");
                                                            logger.logDetailedActivity(
                                                                "DocumentDelivery",
                                                                "Cancel",
                                                                cpContext,
                                                                cpContext.userInfo?.email,
                                                                cpContext.sessionId,
                                                                "Cancel Document Delivery",
                                                                0
                                                            );
                                                        }}
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
};

export default DocumentDelivery;

/*eslint-disable*/
import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import useFetch from "../../_helpers/useFetch";
import { config } from "../../config";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../shared/Common";
import useUtilities from "../../_helpers/useUtilities";

type propType = {
    id: string;
};

interface uploadRemoveRequest {
    id: number;
    policyDWKey: number;
}

interface uploadSubmitRequest {
    policyDWKey: number;
}

const DocumentUpload: React.FC = () => {
    const params = useParams<propType>();
    let idParsed = 0;
    if (typeof params.id != "undefined" && params.id && params.id.trim()) {
        idParsed = +params.id;
    }

    const cpContext = useContext(CPContext);
    const utilities = useUtilities();
    const { get, post } = useFetch();
    const { authState, oktaAuth } = useOktaAuth();
    const navigate = useHistory();
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [policyData, setPolicyData] = useState<Policy>();
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [uploads, setUploads] = useState<UploadedDocument[]>();
    const [uploadFile, setUploadFile] = useState<File>();
    const [documentType, setDocumentType] = useState("");
    const [currentFileName, setCurrentFileName] = useState("");
    const [fileUploaded, setFileUploaded] = useState(false);
    const [documentTypeError, setDocumentTypeError] = useState(false);
    const [fileTypeError, setFileTypeError] = useState(false);
    const [fileTypeErrorMessage, setFileTypeErrorMessage] = useState("");
    const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
    const [isUploadProcessing, setIsUploadProcessing] = useState(false);

    const errorStyle = {
        color: "#dc3545",
        borderColor: "#dc3545",
        paddingLeft: "5px"
    };

    const AllowedExtensions = ["png", "jpg", "jpeg", "tiff", "pdf", "tif"];

    function validateFileExt(fileName: string): boolean {
        console.log("validateFileExt=" + fileName);
        const fileExt = fileName.split(".").pop()!.toLowerCase();
        console.log("validateFileExt=" + fileExt);
        return AllowedExtensions.includes(fileExt);
    }

    const handleDocumentType = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value != "--Select Type--") {
            setErrorMessage("");
            setSuccessMessage("");
            setDocumentTypeError(false);
        }
        setDocumentType(e.target.value);
    };

    const handleUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files) return;

        setUploadFile(files[0]);
        setCurrentFileName(files[0]?.name);
        setFileTypeErrorMessage("");
        setFileTypeError(false);
    };

    const doUploadFile = async function () {
        if (uploadFile) {
            try {
                setIsUploadProcessing(true);
                setErrorMessage("");
                setSuccessMessage("");
                const formData = new FormData();
                formData.append("RequestFile", uploadFile, uploadFile.name);
                formData.append("RequestType", documentType.toString());
                formData.append("PolicyDWKey", idParsed.toString());
                const requestOptionsBodyAuth = {
                    method: "POST",
                    body: formData,
                    headers: {
                        //'Content-Type': 'multipart/form-data',
                        Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
                    }
                };

                const response = await fetch(
                    config.resourceServer.apiUrl +
                        "/policy/uploadfile?requestType=" +
                        documentType.toString() +
                        "&policyDWKey=" +
                        idParsed.toString(),
                    requestOptionsBodyAuth
                );
                if (!response.ok) {
                    console.log(
                        `fetch failed with ${response.status} code and text ${response.statusText}`
                    );
                    setErrorMessage("Unable to upload selected file type. Please try again.");
                } else {
                    setFileUploaded(true);
                    setSuccessMessage("File was uploaded successfully.");
                    const jsonResponse = response.json().catch(() => ({}));
                }
                setIsUploadProcessing(false);
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    setIsUploadProcessing(false);
                    setErrorMessage("Unable to upload selected file type. Please try again.");
                    setSuccessMessage("");
                }
            }
        }
    };

    const fetchPolicy = async (_id: number) => {
        try {
            const result = await get<Policy>("/policy/full?policyDWKey=" + _id, "");
            return result;
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                throw new Error("Could not load policy");
            }
        }
    };

    const getUploads = async () => {
        try {
            const dataFromServer = await get<UploadedDocument[]>(
                "/policy/pendinguploads?policyDWKey=" + idParsed,
                ""
            );
            setUploads(dataFromServer);
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const removeUpload = async (_id: number) => {
        try {
            const body: uploadRemoveRequest = {
                id: _id,
                policyDWKey: idParsed
            };
            const result = await post<uploadRemoveRequest, boolean>(
                "/policy/removeupload/",
                "",
                body
            );
            if (result) {
                await getUploads();
                setSuccessMessage("File was removed successfully.");
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
            setSuccessMessage("");
        }
    };

    const removeUploads = async () => {
        try {
            const body: uploadRemoveRequest = {
                id: idParsed,
                policyDWKey: idParsed
            };
            const result = await post<uploadRemoveRequest, boolean>(
                "/policy/removeuploads/",
                "",
                body
            );

            if (result) {
                await getUploads();
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage("An unexpected error has occured.");
                setSuccessMessage("");
                setIsSubmitProcessing(false);
            }
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();

        try {
            setIsSubmitProcessing(true);
            const body: uploadSubmitRequest = {
                policyDWKey: idParsed
            };
            const result = await post<uploadSubmitRequest, boolean>(
                "/policy/submituploads/",
                "",
                body
            );

            if (result) {
                setRequestSubmitted(true);
            } else {
                //show error message
                if (!uploads || uploads.length <= 0) {
                    setErrorMessage("No documents were provided, could not submit your request");
                    setSuccessMessage("");
                } else {
                    setErrorMessage("Could not submit your request.");
                    setSuccessMessage("");
                }
            }
            setIsSubmitProcessing(false);
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage("An unexpected error has occured.");
                setSuccessMessage("");
                setIsSubmitProcessing(false);
            }
        }
    };

    const uploadClick = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();

        setDocumentTypeError(false);
        setFileTypeError(false);
        if (documentType == "--Select Type--" || documentType == "" || documentType.length == 0) {
            setDocumentTypeError(true);
            setErrorMessage("Please select a document type to continue.");
            setSuccessMessage("");
        } else {
            if (uploadFile && !validateFileExt(uploadFile.name)) {
                setFileTypeError(true);
                setFileTypeErrorMessage("Unable to upload selected file type. Please try again.");
                setSuccessMessage("");
            } else {
                await doUploadFile();
                await getUploads();
            }
        }
    };

    const uploadAnotherClick = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        setDocumentType("");
        setSuccessMessage("");
        setFileUploaded(false);
        setUploadFile(undefined);
        setCurrentFileName("");
    };

    const onCancel = async (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        try {
            await removeUploads();
        } catch (e: unknown) {
            //do nothing here
        }
        navigate.push("/policy-detail/" + idParsed + "?tab=service");
    };

    useEffect(() => {
        const getPolicy = async () => {
            try {
                setIsProcessing(true);
                const policyFromServer = await fetchPolicy(idParsed);

                if (policyFromServer) {
                    setPolicyData(policyFromServer);
                }

                setIsProcessing(false);
            } catch (error) {
                setErrorMessage(
                    "There was a problem loading your policy information, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                );
                setIsProcessing(false);
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getPolicy();
            getUploads();
        }
    }, [authState, oktaAuth, cpContext, navigate]); // Update if authState changes

    const removeUploadTooltip = (
        <Tooltip id="removeUploadTooltip">Click the trash can icon to remove this upload.</Tooltip>
    );

    if (policyData && policyData.summary) {
        return (
            <section aria-labelledby="document-upload-section">
                <h2 style={{ display: "none" }} aria-labelledby="document-upload-section">
                    Document Upload Section
                </h2>
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-md-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-6">
                                <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                    Document Upload
                                </h1>
                            </div>
                            <div className="col-6">
                                <div style={{ paddingTop: 10 }}>
                                    {policyData?.policyNumber} - {policyData.product}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <Card className="policyCard">
                                    <Card.Body className="shadow">
                                        <div
                                            className="row"
                                            style={{
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {successMessage && successMessage.length > 0 ? (
                                                <div
                                                    className="col-12"
                                                    aria-live="assertive"
                                                    role="alert"
                                                >
                                                    {successMessage}
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        {requestSubmitted ? (
                                            <>
                                                <div>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 5, paddingBottom: 15 }}
                                                    >
                                                        <div
                                                            className="col-9"
                                                            aria-live="assertive"
                                                            role="alert"
                                                        >
                                                            <b>Your request has been submitted</b>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 5, paddingBottom: 15 }}
                                                    >
                                                        <div
                                                            className="col-9"
                                                            style={{ paddingLeft: 30 }}
                                                        >
                                                            <p>
                                                                You will receive an email shortly
                                                                confirming your request at the
                                                                account you used to log in.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    {errorMessage && errorMessage.length > 0 ? (
                                                        <div
                                                            className="col-12 text-danger"
                                                            aria-live="assertive"
                                                            role="alert"
                                                        >
                                                            {errorMessage}
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <div className="col-12">
                                                        If you are uploading multiple documents, we
                                                        recommend submitting a separate file for
                                                        each to ensure the fastest processing.
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingTop: 5 }}>
                                                    <form
                                                        className="add-form"
                                                        style={{ backgroundColor: "#FFFFFF" }}
                                                    >
                                                        <div className="col-12">
                                                            {fileUploaded ? (
                                                                <input
                                                                    type="button"
                                                                    value="Upload Another Document"
                                                                    onClick={uploadAnotherClick}
                                                                    className="btn btn-Primary"
                                                                    aria-label="Upload another document button"
                                                                ></input>
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        className="row"
                                                                        style={{ paddingTop: 5 }}
                                                                    >
                                                                        <div className="col-6">
                                                                            Select the type of
                                                                            document you are
                                                                            uploading.
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{ paddingBottom: 5 }}
                                                                    >
                                                                        <div className="col-6">
                                                                            <select
                                                                                name="DocumentType"
                                                                                className="form-select skip-to"
                                                                                onChange={
                                                                                    handleDocumentType
                                                                                }
                                                                                defaultValue={
                                                                                    documentType
                                                                                }
                                                                                style={
                                                                                    documentTypeError
                                                                                        ? errorStyle
                                                                                        : {}
                                                                                }
                                                                            >
                                                                                <option value="--Select Type--">
                                                                                    -- Select Type
                                                                                    --
                                                                                </option>
                                                                                <option value="Surrender">
                                                                                    Surrender Policy
                                                                                </option>
                                                                                <option value="PartialSurrender">
                                                                                    Partial
                                                                                    Surrender
                                                                                    Request
                                                                                </option>
                                                                                <option value="Transfer">
                                                                                    Transfer Request
                                                                                </option>
                                                                                <option value="Beneficiary">
                                                                                    Beneficiary
                                                                                    Change Request
                                                                                </option>
                                                                                <option value="Owner">
                                                                                    Ownership Change
                                                                                    Request
                                                                                </option>
                                                                                <option value="Address">
                                                                                    Address Change
                                                                                    Request
                                                                                </option>
                                                                                <option value="MBPACK">
                                                                                    Bank Draft
                                                                                    Authorization
                                                                                </option>
                                                                                <option value="CollateralAssignment">
                                                                                    Collateral
                                                                                    Assignment
                                                                                </option>
                                                                                <option value="Name">
                                                                                    Name Change
                                                                                    Request
                                                                                </option>
                                                                                <option value="Loan">
                                                                                    Qualified Loan
                                                                                    Application
                                                                                </option>
                                                                                {policyData.policyType ===
                                                                                "annuity" ? (
                                                                                    <option value="RMD">
                                                                                        Required
                                                                                        Minimum
                                                                                        Distribution
                                                                                        Withdrawal
                                                                                        Request
                                                                                    </option>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                <option value="W9">
                                                                                    W-9 Request
                                                                                </option>
                                                                                <option value="PayrollDeduction">
                                                                                    Payroll
                                                                                    Deduction
                                                                                    Employee
                                                                                    Allocation
                                                                                    Agreement
                                                                                </option>
                                                                                <option value="Other">
                                                                                    Other/Supporting
                                                                                    Documents
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{ paddingTop: 5 }}
                                                                    >
                                                                        <div className="col-12">
                                                                            Select the document to
                                                                            be
                                                                            uploaded.&nbsp;&nbsp;(Accepted
                                                                            file types .PNG, .JPG,
                                                                            .JPEG, .TIFF, .TIF,
                                                                            .PDF)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{ paddingBottom: 5 }}
                                                                    >
                                                                        <div className="col-6">
                                                                            <label
                                                                                htmlFor="upload"
                                                                                className="btn btn-Primary"
                                                                            >
                                                                                Browse
                                                                            </label>
                                                                            <input
                                                                                type="file"
                                                                                onChange={
                                                                                    handleUploadChange
                                                                                }
                                                                                id="upload"
                                                                                style={{
                                                                                    opacity: 0,
                                                                                    position:
                                                                                        "absolute",
                                                                                    zIndex: -1
                                                                                }}
                                                                                accept=".png,.jpg,.jpeg,.tiff,.pdf,.tif"
                                                                                aria-label="Upload file"
                                                                            ></input>
                                                                            <label
                                                                                style={
                                                                                    fileTypeError
                                                                                        ? errorStyle
                                                                                        : {
                                                                                              paddingLeft:
                                                                                                  "5px"
                                                                                          }
                                                                                }
                                                                            >
                                                                                {currentFileName}
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            {isUploadProcessing ? (
                                                                                <Spinner
                                                                                    imageWidth={15}
                                                                                    aria-label="Loading"
                                                                                />
                                                                            ) : (
                                                                                <input
                                                                                    type="button"
                                                                                    name="Upload"
                                                                                    value="Upload"
                                                                                    onClick={
                                                                                        uploadClick
                                                                                    }
                                                                                    className="btn btn-Primary"
                                                                                    aria-label="Upload button"
                                                                                ></input>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {fileTypeErrorMessage &&
                                                                    fileTypeErrorMessage.length >
                                                                        0 ? (
                                                                        <>
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-12 text-danger"
                                                                                    aria-live="assertive"
                                                                                    role="alert"
                                                                                >
                                                                                    {
                                                                                        fileTypeErrorMessage
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </form>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 20, paddingBottom: 5 }}
                                                    role="table"
                                                    aria-label="Files"
                                                    aria-describedby="Uploaded Documents"
                                                >
                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div
                                                                className="col-4"
                                                                role="columnheader"
                                                            >
                                                                <b>Type</b>
                                                            </div>
                                                            <div
                                                                className="col-6"
                                                                role="columnheader"
                                                            >
                                                                <b>File</b>
                                                            </div>
                                                            <div
                                                                className="col-2"
                                                                role="columnheader"
                                                            >
                                                                <b className="d-none">
                                                                    Remove Upload Icon
                                                                </b>
                                                            </div>
                                                        </div>
                                                        <>
                                                            {uploads && uploads.length > 0 ? (
                                                                <>
                                                                    {uploads.map((u, idx) => (
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                            key={idx}
                                                                        >
                                                                            <div
                                                                                tabIndex={0}
                                                                                className="col-4 fieldValue"
                                                                                title="Type"
                                                                                role="cell"
                                                                            >
                                                                                {u.documentType}
                                                                            </div>
                                                                            <div
                                                                                tabIndex={0}
                                                                                className="col-6 fieldValue"
                                                                                title="Name"
                                                                                role="cell"
                                                                            >
                                                                                {u.documentName}
                                                                            </div>
                                                                            <div
                                                                                className="col-2 fieldValue"
                                                                                title="Remove Upload Icon"
                                                                                role="cell"
                                                                            >
                                                                                <OverlayTrigger
                                                                                    placement="right"
                                                                                    overlay={
                                                                                        removeUploadTooltip
                                                                                    }
                                                                                >
                                                                                    <a
                                                                                        href={"#"}
                                                                                        aria-label="remove upload"
                                                                                        onClick={() =>
                                                                                            removeUpload(
                                                                                                u.requestId
                                                                                            )
                                                                                        }
                                                                                        className="bi bi-trash"
                                                                                    ></a>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <label>No Uploads Found</label>
                                                                </>
                                                            )}
                                                        </>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                            <div className="col-md-6">
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    {requestSubmitted ? (
                                        <>
                                            <div className="col">
                                                <input
                                                    type="button"
                                                    value="OK"
                                                    className="btn btn-Primary"
                                                    onClick={() =>
                                                        navigate.push(
                                                            "/policy-detail/" +
                                                                idParsed +
                                                                "?tab=service"
                                                        )
                                                    }
                                                    aria-label="OK button"
                                                ></input>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="col-md-3">
                                                {isSubmitProcessing ? (
                                                    <Spinner imageWidth={20} aria-label="Loading" />
                                                ) : (
                                                    <>
                                                        <input
                                                            type="button"
                                                            value="Submit"
                                                            className="btn btn-Primary"
                                                            onClick={onSubmit}
                                                            aria-label="Submit button"
                                                        ></input>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    type="button"
                                                    className="btn btn-Primary"
                                                    onClick={onCancel}
                                                    value="Cancel"
                                                    aria-label="Cancel button"
                                                ></input>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <section aria-labelledby="document-upload-section">
                <h1 style={{ display: "none" }}>Document Upload Error Message</h1>
                <h2 style={{ display: "none" }} aria-labelledby="document-upload-section">
                    Document Upload Section
                </h2>
                <div
                    className="d-flex flex-column min-vh-100 justify-content-center align-items-center"
                    style={{ color: "red" }}
                >
                    <div style={{ backgroundColor: "#E8E7E7" }}>
                        <div className="row">
                            <div className="col-12 text-danger" aria-live="assertive" role="alert">
                                {errorMessage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="button"
                                    value="OK"
                                    className="btn btn-Primary"
                                    onClick={() => setErrorMessage("")}
                                    aria-label="OK button"
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else if (isProcessing) {
        return (
            <section aria-labelledby="document-upload-section">
                <h1 style={{ display: "none" }}>Document Upload Spinner</h1>
                <h2 style={{ display: "none" }} aria-labelledby="document-upload-section">
                    Document Upload Section
                </h2>
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                    <Spinner imageWidth={20} aria-label="Loading" />
                </div>
            </section>
        );
    } else {
        return (
            <section aria-labelledby="document-upload-section">
                <h1 style={{ display: "none" }}>Document Upload Spinner</h1>
                <h2 style={{ display: "none" }} aria-labelledby="document-upload-section">
                    Document Upload Section
                </h2>
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                    <Spinner imageWidth={20} aria-label="Loading" />
                </div>
            </section>
        );
    }
};

export default DocumentUpload;
/*eslint-enable*/

import React, { useState, useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { Spinner } from "../shared/Common";
import { CPContext } from "../../_helpers/CPContext";
import useUtilities from "../../_helpers/useUtilities";

type propType = {
    id: string;
};

const IVRResult: React.FC = () => {
    const cpContext = useContext(CPContext);
    const params = useParams<propType>();
    let idParsed = 0;
    if (typeof params.id != "undefined" && params.id && params.id.trim() && params.id != "NONE") {
        idParsed = +params.id;
    } else {
        idParsed = -1;
    }

    const navigate = useHistory();
    const { get, post } = useFetch();
    const { parseAndFormatDate } = useUtilities();
    const { authState, oktaAuth } = useOktaAuth();
    const [isProcessing, setIsProcessing] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [policies, setPolicies] = useState<PolicySimple[]>([]);
    const [policyData, setPolicyData] = useState<Policy>();
    const [failedLookupData, setFailedLookupData] = useState<FailedLookupResult>();
    const [isPolicyListProcessing, setIsPolicyListProcessing] = useState<boolean>(false);
    const [isPolicyDetailProcessing, setIsPolicyDetailProcessing] = useState<boolean>(false);

    const setImpersonate = async (_policyDWKey: number) => {
        setIsProcessing(true);
        const body: ImpersonateRequest = {
            policyDWKey: _policyDWKey.toString()
        };
        const result = await post<ImpersonateRequest, PolicyOwner>(
            "/impersonate",
            "",
            body
        );

        if (result) {
            if (result.ownerName) {
                cpContext.changeImpersonateName(result.ownerName);
                navigate.push("/policy-detail/" + _policyDWKey + "?tab=homeoffice");
            }
        } else {
            setErrorMessage("An unexpected error has occured, please try again.");
        }
        setIsProcessing(false);
        return result;
    };

    const fetchFailedLookup = async () => {
        setIsProcessing(true);
        try {
            const result = await get<FailedLookupResult>(
                "/impersonate/findfailedlookup?logId=" + idParsed,
                ""
            );
            if (result && result.requestId > 0) {
                setFailedLookupData(result);
            } else {
                const failedDefault: FailedLookupResult = {
                    insuredDOB: "",
                    isDOBValid: false,
                    isPostalCodeValid: false,
                    isTaxIdValid: false,
                    postalCode: "",
                    requestId: 999999999,
                    taxId: ""
                };
                setFailedLookupData(failedDefault);
            }
        } catch (error) {
            const failedDefault: FailedLookupResult = {
                insuredDOB: "",
                isDOBValid: false,
                isPostalCodeValid: false,
                isTaxIdValid: false,
                postalCode: "",
                requestId: 999999999,
                taxId: ""
            };
            setFailedLookupData(failedDefault);
        }
        setIsProcessing(false);
    };

    useEffect(() => {
        const getPolicy = async (_id: number) => {
            if (!isPolicyDetailProcessing) {
                try {
                    setIsPolicyDetailProcessing(true);
                    setIsProcessing(true);
                    const policyFromServer = await get<Policy>(
                        "/policy/getpolicyforivr?policyDWKey=" + _id,
                        ""
                    );
                    setPolicyData(policyFromServer);

                    setIsPolicyDetailProcessing(false);
                    setIsProcessing(false);
                } catch (error) {
                    setErrorMessage("There was a problem loading the policy information.");
                    setIsPolicyDetailProcessing(false);
                    setIsProcessing(false);
                }
            }
        };

        const getPoliciesForIVR = async (
            _taxId: string,
            _postalCode: string,
            _insuredDOB: string
        ) => {
            if (!isPolicyListProcessing) {
                try {
                    setIsPolicyListProcessing(true);
                    setIsProcessing(true);
                    const body: IVRLookupPoliciesRequest = {
                        RequestId: idParsed,
                        TaxId: _taxId,
                        PostalCode: _postalCode,
                        InsuredDOB: _insuredDOB
                    };
                    const policiesFromServer = await post<IVRLookupPoliciesRequest, PolicySimple[]>(
                        "/policy/getpoliciesforivr",
                        "",
                        body
                    );
                    if (policiesFromServer.length > 0) {
                        setPolicies(policiesFromServer);
                        await getPolicy(policiesFromServer[0].policyDWKey);
                    } else {
                        //hmm
                    }
                    setIsPolicyListProcessing(false);
                    setIsProcessing(false);
                } catch (error) {
                    setIsPolicyListProcessing(false);
                    setIsProcessing(false);
                    setErrorMessage(
                        "An unexpected error has occured loading policies from the IVR data."
                    );
                }
            }
        };

        if (authState && authState.isAuthenticated) {
            if (cpContext.isAdmin || cpContext.isHO) {
                if (failedLookupData && failedLookupData.requestId > 0) {
                    if (failedLookupData.isTaxIdValid && failedLookupData.isPostalCodeValid) {
                        getPoliciesForIVR(failedLookupData.taxId, failedLookupData.postalCode, "");
                    } else if (failedLookupData.isTaxIdValid) {
                        getPoliciesForIVR(failedLookupData.taxId, "", "");
                    }
                } else if (idParsed > 0 && !isProcessing && !failedLookupData) {
                    fetchFailedLookup();
                } else {
                    setIsProcessing(false);
                }
            }
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext, failedLookupData]);

    if (failedLookupData && failedLookupData.requestId > 0) {
        if (!failedLookupData.isTaxIdValid) {
            return (
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <h1 style={{ display: "none" }}>IVR Result</h1>
                    <div className="col-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-12">
                                <h4 style={{ paddingTop: 10 }}>Invalid Social Security Number</h4>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-9">
                                <Card border="secondary">
                                    <Card.Body>
                                        <div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    <p>Invalid SSN entered by the customer:</p>
                                                </div>
                                            </div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    <p>
                                                        Last SSN Entered in IVR:{" "}
                                                        {failedLookupData.taxId}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    <input
                                                        type="button"
                                                        value="Act As Policyholder"
                                                        className="btn btn-Primary"
                                                        onClick={() =>
                                                            navigate.push("/impersonate/0")
                                                        }
                                                        aria-label="Act as a policyholder button"
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (!failedLookupData.isPostalCodeValid) {
            return (
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-12">
                                <h4 style={{ paddingTop: 10 }}>
                                    ZIP entered in IVR did not match Owner
                                </h4>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <Card border="secondary">
                                    <Card.Body>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 5 }}
                                        >
                                            <div className="col-12 fieldLabel">
                                                &nbsp;Information for the SSN the customer entered:
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 5 }}
                                        >
                                            {policyData && policyData.client ? (
                                                <div className="col-12 col-md-12 col-lg-10 col-xl-10">
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 5, paddingBottom: 5 }}
                                                    >
                                                        <div className="col-12 col-md-12 col-lg-12 col-xl-7">
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    Name:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    {policyData.client.ownerName}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    Phone:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    {policyData.client.ownerPhone}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    Email:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    {policyData.client.ownerEmail}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                    title="Gender of the insured person (male/female)."
                                                                >
                                                                    Gender:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                    title="Gender of the insured person (male/female)."
                                                                >
                                                                    {
                                                                        policyData.client
                                                                            .insuredGender
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-12 col-xl-5">
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-4 col-lg-4 col-xl-5 col-xxl-5 fieldLabel"
                                                                    title="The mailing address of the insured person."
                                                                >
                                                                    Address:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-8 col-lg-8 col-xl-7 col-xxl-7 fieldValue"
                                                                    title="The mailing address of the insured person."
                                                                >
                                                                    {policyData.client.ownerAddress}
                                                                    <br />
                                                                    {
                                                                        policyData.client
                                                                            .ownerAddress2
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-4 col-lg-4 col-xl-5 col-xxl-5 fieldLabel"
                                                                    title="The date of birth for the insured person."
                                                                >
                                                                    Zip Entered in IVR:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-8 col-lg-8 col-xl-7 col-xxl-7 fieldValue"
                                                                    title="The date of birth for the insured person."
                                                                >
                                                                    <span className="text-danger">
                                                                        {
                                                                            failedLookupData.postalCode
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {isProcessing ? (
                                                        <Spinner
                                                            imageWidth={20}
                                                            aria-label="Loading"
                                                        />
                                                    ) : (
                                                        <>
                                                            Could not load policy data for owner
                                                            SSN.
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <h4 style={{ paddingTop: 10 }}>Policies for this Owner SSN</h4>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <Card border="secondary">
                                    <Card.Body>
                                        {policies && policies.length > 0 ? (
                                            <>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <div className="col-1 col-md-1 fieldLabel">
                                                        &nbsp;
                                                    </div>
                                                    <div className="col-12 col-md-3 fieldLabel">
                                                        Policy Number
                                                    </div>
                                                    <div className="col-12 col-md-4 fieldLabel">
                                                        Insured Name
                                                    </div>
                                                    <div className="col-12 col-md-2 fieldLabel">
                                                        Insured DOB
                                                    </div>
                                                    <div className="col-1 col-md-1 fieldLabel">
                                                        &nbsp;
                                                    </div>
                                                </div>
                                                {policies.map((p, idx) => (
                                                    <>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 5
                                                            }}
                                                            key={idx}
                                                        >
                                                            <div className="col-1 col-md-1 fieldLabel"></div>
                                                            <div className="col-12 col-md-3">
                                                                <a
                                                                    href="#"
                                                                    onClick={() =>
                                                                        setImpersonate(
                                                                            p.policyDWKey
                                                                        )
                                                                    }
                                                                >
                                                                    {p.policyNumber}
                                                                </a>
                                                            </div>
                                                            <div className="col-12 col-md-4 fieldValue">
                                                                {p.insuredName}
                                                            </div>
                                                            <div className="col-12 col-md-2 fieldValue">
                                                                {parseAndFormatDate(
                                                                    p.insuredDateofBirth
                                                                )}
                                                            </div>
                                                            <div className="col-1 col-md-1 fieldLabel"></div>
                                                        </div>
                                                    </>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {isProcessing ? (
                                                    <Spinner imageWidth={20} aria-label="Loading" />
                                                ) : (
                                                    <>Could not load policy data for owner SSN.</>
                                                )}
                                            </>
                                        )}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (!failedLookupData.isDOBValid && policyData) {
            return (
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-12">
                                <h4 style={{ paddingTop: 10 }}>
                                    Insured Date of Birth entered in IVR did not match Owner SSN
                                </h4>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <Card border="secondary">
                                    <Card.Body>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 5 }}
                                        >
                                            <div className="col-12 fieldLabel">
                                                &nbsp;Information for the SSN the customer entered:
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 5 }}
                                        >
                                            {policyData && policyData.client ? (
                                                <div className="col-12 col-md-12 col-lg-10 col-xl-10">
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 5, paddingBottom: 5 }}
                                                    >
                                                        <div className="col-12 col-md-12 col-lg-12 col-xl-7">
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    Name:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    {policyData.client.ownerName}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    Phone:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    {policyData.client.ownerPhone}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                    title="Gender of the insured person (male/female)."
                                                                >
                                                                    Gender:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                    title="Gender of the insured person (male/female)."
                                                                >
                                                                    {
                                                                        policyData.client
                                                                            .insuredGender
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-12 col-xl-5">
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    Email:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                    title="The email address for the insured person."
                                                                >
                                                                    {policyData.client.ownerEmail}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5
                                                                }}
                                                            >
                                                                <div
                                                                    className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                    title="The mailing address of the insured person."
                                                                >
                                                                    Address:
                                                                </div>
                                                                <div
                                                                    className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                    title="The mailing address of the insured person."
                                                                >
                                                                    {policyData.client.ownerAddress}
                                                                    <br />
                                                                    {
                                                                        policyData.client
                                                                            .ownerAddress2
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {isProcessing ? (
                                                        <Spinner
                                                            imageWidth={20}
                                                            aria-label="Loading"
                                                        />
                                                    ) : (
                                                        <>
                                                            Could not load policy data for owner
                                                            SSN.
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <h4 style={{ paddingTop: 10 }}>Policies for this Owner SSN</h4>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-12">
                                <Card border="secondary">
                                    <Card.Body>
                                        {policies && policies.length > 0 ? (
                                            <>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <div className="col-12">
                                                        <b>Insured DOB Entered in IVR:</b>{" "}
                                                        <span className="text-danger">
                                                            {parseAndFormatDate(
                                                                failedLookupData.insuredDOB
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <div className="col-1 col-md-1 fieldLabel">
                                                        &nbsp;
                                                    </div>
                                                    <div className="col-12 col-md-3 fieldLabel">
                                                        Policy Number
                                                    </div>
                                                    <div className="col-12 col-md-4 fieldLabel">
                                                        Insured Name
                                                    </div>
                                                    <div className="col-12 col-md-2 fieldLabel">
                                                        Insured DOB
                                                    </div>
                                                    <div className="col-1 col-md-1 fieldLabel">
                                                        &nbsp;
                                                    </div>
                                                </div>
                                                {policies.map((p, idx) => (
                                                    <>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 5
                                                            }}
                                                            key={idx}
                                                        >
                                                            <div className="col-1 col-md-1 fieldLabel"></div>
                                                            <div className="col-12 col-md-3">
                                                                <a
                                                                    href="#"
                                                                    onClick={() =>
                                                                        setImpersonate(
                                                                            p.policyDWKey
                                                                        )
                                                                    }
                                                                >
                                                                    {p.policyNumber}
                                                                </a>
                                                            </div>
                                                            <div className="col-12 col-md-4 fieldValue">
                                                                {p.insuredName}
                                                            </div>
                                                            <div className="col-12 col-md-2 fieldValue">
                                                                {parseAndFormatDate(
                                                                    p.insuredDateofBirth
                                                                )}
                                                            </div>
                                                            <div className="col-1 col-md-1 fieldLabel"></div>
                                                        </div>
                                                    </>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {isProcessing ? (
                                                    <Spinner imageWidth={20} aria-label="Loading" />
                                                ) : (
                                                    <>Could not load policy data for owner SSN.</>
                                                )}
                                            </>
                                        )}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (isProcessing) {
            return (
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-12">
                                <h4 style={{ paddingTop: 10 }}>Customer IVR Selection</h4>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-9">
                                <Card border="secondary">
                                    <Card.Body>
                                        <div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    {isProcessing ? (
                                                        <Spinner
                                                            imageWidth={20}
                                                            aria-label="Loading"
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                    <div className="col-12">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 2,
                                borderStyle: "solid",
                                borderColor: "#33CCCC"
                            }}
                        >
                            <div className="col-12">
                                <h4 style={{ paddingTop: 10 }}>Customer IVR Selection</h4>
                            </div>
                        </div>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-9">
                                <Card border="secondary">
                                    <Card.Body>
                                        <div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-9" style={{ paddingLeft: 30 }}>
                                                    <p>
                                                        Error loading IVR Result Data, values
                                                        entered into IVR are below:
                                                    </p>
                                                </div>
                                                <div className="col-3">&nbsp;</div>
                                            </div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-3" style={{ paddingLeft: 30 }}>
                                                    Tax Id: {failedLookupData.taxId}
                                                </div>
                                                <div className="col-3">
                                                    {failedLookupData.isTaxIdValid ? (
                                                        <>
                                                            <span
                                                                className="bi bi-check2-circle"
                                                                style={{
                                                                    color: "green",
                                                                    paddingLeft: "5px"
                                                                }}
                                                            ></span>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-3" style={{ paddingLeft: 30 }}>
                                                    Postal Code: {failedLookupData.postalCode}
                                                </div>
                                                <div className="col-3">
                                                    {failedLookupData.isPostalCodeValid ? (
                                                        <>
                                                            <span
                                                                className="bi bi-check2-circle"
                                                                style={{
                                                                    color: "green",
                                                                    paddingLeft: "5px"
                                                                }}
                                                            ></span>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-3" style={{ paddingLeft: 30 }}>
                                                    DOB:{" "}
                                                    {parseAndFormatDate(
                                                        failedLookupData.insuredDOB
                                                    )}
                                                </div>
                                                <div className="col-3">
                                                    {failedLookupData.isDOBValid ? (
                                                        <>
                                                            <span
                                                                className="bi bi-check2-circle"
                                                                style={{
                                                                    color: "green",
                                                                    paddingLeft: "5px"
                                                                }}
                                                            ></span>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="row"
                                                style={{ paddingTop: 5, paddingBottom: 15 }}
                                            >
                                                <div className="col-4" style={{ paddingLeft: 30 }}>
                                                    <input
                                                        type="button"
                                                        value="Act As Policyholder"
                                                        className="btn btn-Primary"
                                                        onClick={() =>
                                                            navigate.push("/impersonate/0")
                                                        }
                                                        aria-label="Act as a policyholder button"
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else if (idParsed === -1) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: 2,
                            borderStyle: "solid",
                            borderColor: "#33CCCC"
                        }}
                    >
                        <div className="col-12">
                            <h4 style={{ paddingTop: 10 }}>Customer IVR Selection</h4>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-9">
                            <Card border="secondary">
                                <Card.Body>
                                    <div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <p>No information entered by customer in IVR</p>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <input
                                                    type="button"
                                                    value="Act As Policyholder"
                                                    className="btn btn-Primary"
                                                    onClick={() => navigate.push("/impersonate/0")}
                                                    aria-label="Act as a policyholder button"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (idParsed === -2) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: 2,
                            borderStyle: "solid",
                            borderColor: "#33CCCC"
                        }}
                    >
                        <div className="col-12">
                            <h4 style={{ paddingTop: 10 }}>Customer IVR Selection</h4>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-9">
                            <Card border="secondary">
                                <Card.Body>
                                    <div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <p>
                                                    Customer Selects 8 for Trust/Estate/Company in
                                                    IVR
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <input
                                                    type="button"
                                                    value="Act As Policyholder"
                                                    className="btn btn-Primary"
                                                    onClick={() => navigate.push("/impersonate/0")}
                                                    aria-label="Act as a policyholder button"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (idParsed === -3) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: 2,
                            borderStyle: "solid",
                            borderColor: "#33CCCC"
                        }}
                    >
                        <div className="col-12">
                            <h4 style={{ paddingTop: 10 }}>Customer IVR Selection</h4>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-9">
                            <Card border="secondary">
                                <Card.Body>
                                    <div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <p>
                                                    Customer did not enter in correctly formatted
                                                    SSN in IVR
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <input
                                                    type="button"
                                                    value="Act As Policyholder"
                                                    className="btn btn-Primary"
                                                    onClick={() => navigate.push("/impersonate/0")}
                                                    aria-label="Act as a policyholder button"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: 2,
                            borderStyle: "solid",
                            borderColor: "#33CCCC"
                        }}
                    >
                        <div className="col-12">
                            <h4 style={{ paddingTop: 10 }}>Customer IVR Selection</h4>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-9">
                            <Card border="secondary">
                                <Card.Body>
                                    <div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <p
                                                    className="text-danger"
                                                    aria-live="assertive"
                                                    role="alert"
                                                >
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                <input
                                                    type="button"
                                                    value="Act As Policyholder"
                                                    className="btn btn-Primary"
                                                    onClick={() => navigate.push("/impersonate/0")}
                                                    aria-label="Act as a policyholder button"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: 2,
                            borderStyle: "solid",
                            borderColor: "#33CCCC"
                        }}
                    >
                        <div className="col-12">
                            <h4 style={{ paddingTop: 10 }}>Customer IVR Selection</h4>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-9">
                            <Card border="secondary">
                                <Card.Body>
                                    <div>
                                        <div
                                            className="row"
                                            style={{ paddingTop: 5, paddingBottom: 15 }}
                                        >
                                            <div className="col-9" style={{ paddingLeft: 30 }}>
                                                {isProcessing ? (
                                                    <Spinner imageWidth={20} aria-label="Loading" />
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default IVRResult;

import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

type propType = {
    id: string;
};

const SettingDetail: React.FC = () => {
    const { id } = useParams<propType>();
    let idParsed = "";
    if (typeof id != "undefined" && id && id.trim()) {
        idParsed = id.trim();
    }

    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { get, post } = useFetch();
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [settingData, setSettingData] = useState<Setting>();
    const [errorMessage, setErrorMessage] = useState("");
    const { authState, oktaAuth } = useOktaAuth();

    const onSaveSetting = async (_id: string, _name: string, _value: string) => {
        const body: Setting = {
            id: _id,
            name: _name,
            value: _value
        };
        const result = await post<Setting, boolean>(
            "/settings/update/",
            "",
            body
        );
        return result;
    };

    useEffect(() => {
        const getSetting = async () => {
            const fromServer = await get<Setting>(
                "/settings/setting?id=" + idParsed,
                ""
            );
            setName(fromServer.name);
            setValue(fromServer.value);

            setSettingData(fromServer);
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getSetting();
        }
    }, [idParsed, authState, oktaAuth, cpContext, navigate]);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!name) {
            alert("Please enter a setting name");
            return;
        }
        if (!value) {
            alert("Please enter a setting value");
            return;
        }

        const result = await onSaveSetting(idParsed, name, value);

        //TODO: [LOW] Need to build a refresh of the State data instead of hard refreshing the page
        if (result) {
            window.location.href = "/admin/settings";
        } else {
            setErrorMessage("There was a problem updating the Setting.");
        }
    };

    if (typeof settingData != "undefined" && settingData && settingData.id && cpContext.isAdmin) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-md-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2px",
                            borderBottomColor: "#33CCCC"
                        }}
                    >
                        <div className="col-md-8">
                            <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                                Settings Admin - Detail
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7" style={{ backgroundColor: "#eee" }}>
                            <form
                                className="form-control border-0"
                                onSubmit={onSubmit}
                                style={{ backgroundColor: "#eee" }}
                            >
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        {errorMessage && errorMessage.length > 0 ? (
                                            <>
                                                <span
                                                    style={{ color: "red" }}
                                                    aria-live="assertive"
                                                    role="alert"
                                                >
                                                    {errorMessage}
                                                </span>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Name:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="Question"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            readOnly
                                            aria-label="Name"
                                        ></input>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 20, paddingBottom: 0 }}>
                                    <div className="col-md-12" style={{ backgroundColor: "#eee" }}>
                                        <label>Value:</label>
                                        <textarea
                                            className="form-control skip-to"
                                            name="Value"
                                            rows={8}
                                            cols={78}
                                            onChange={(e) => setValue(e.target.value)}
                                            value={value}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-md-3">
                                        <input
                                            type="submit"
                                            value="Save"
                                            className="btn btn-Primary"
                                            aria-label="Save button"
                                        ></input>
                                    </div>
                                    <div className="col-md-3">
                                        <input
                                            type="button"
                                            className="btn btn-Primary"
                                            onClick={() => navigate.push("/admin/settings")}
                                            value="Cancel"
                                            aria-label="Cancel button"
                                        ></input>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default SettingDetail;

import React, { useEffect, useContext, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, Collapse, Tabs, Tab, Accordion, Row, Col, Container } from "react-bootstrap";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import useLogger from "../../_helpers/useLogger";
import { Spinner } from "../shared/Common";
import { CPContext } from "../../_helpers/CPContext";
import useUtilities from "../../_helpers/useUtilities";
import { PolicyType } from "../../enums/PolicyType";
import { CarrierAdminSystem } from "../../enums/CarrierAdminSystem";
import { QualifiedPlanType } from "../../enums/QualifiedPlanType";
import { ProductType } from "../../enums/ProductType";
import { TransactionType } from "enums/TransactionType";
import PolicyDocuments from "./PolicyDocuments";
import PolicyPayments from "./PolicyPayments";
import { PolicyStatus } from "enums/PolicyStatus";
import PolicyFinancialActivity from "./PolicyFinancialActivity";

type propType = {
    id: string;
    tab: string;
    faqTag: string;
};

const PolicyContainer: React.FC = () => {
    const params = useParams<propType>();
    let idParsed = 0;
    if (typeof params.id != "undefined" && params.id && params.id.trim()) idParsed = +params.id;

    let tabParsed = "details";
    let faqTagParsed = "";
    const navigate = useHistory();
    const utilities = useUtilities();
    const logger = useLogger();
    const { get } = useFetch();
    const search = navigate.location.search;
    const paramsFromURL = new URLSearchParams(search);
    tabParsed = paramsFromURL.get("tab") ?? "details";
    faqTagParsed = paramsFromURL.get("faqTag") ?? "";

    const currentDateTime = new Date();

    const cpContext = useContext(CPContext);

    let _policyData = {} as Policy;
    const [policyData, setPolicyData] = useState<Policy>();
    const [faqs, setFAQs] = useState<FAQ[]>([]);
    const [faqsFiltered, setFAQsFiltered] = useState<FAQ[]>([]);

    // #region toggles
    const [toggleSummary, setToggleSummary] = useState(true);
    const [togglePolicyValues, setTogglePolicyValues] = useState(true);
    const [toggleClient, setToggleClient] = useState(true);
    const [toggleFinancial, setToggleFinancial] = useState(true);
    const [toggleCoverage, setToggleCoverage] = useState(true);
    const [toggleFund, setToggleFund] = useState(true);
    const [toggleHOSecurityCheck, setToggleHOSecurityCheck] = useState(true);
    const [toggleHOInsured, setToggleHOInsured] = useState(true);
    const [toggleHOAlertAWD, setToggleHOAlertAWD] = useState(true);
    const [toggleHOAgentIDs, setToggleHOAgentIDs] = useState(true);
    const [toggleHOOpenAWD, setToggleHOOpenAWD] = useState(true);
    const [toggleHOOpenADDR, setToggleHOOpenADDR] = useState(true);

    const toggleSummaryFunc = React.useCallback(
        () => setToggleSummary(!toggleSummary),
        [toggleSummary]
    );
    const togglePolicyValuesFunc = React.useCallback(
        () => setTogglePolicyValues(!togglePolicyValues),
        [togglePolicyValues]
    );
    const toggleClientFunc = React.useCallback(
        () => setToggleClient(!toggleClient),
        [toggleClient]
    );
    const toggleCoverageFunc = React.useCallback(
        () => setToggleCoverage(!toggleCoverage),
        [toggleCoverage]
    );
    const toggleFinancialFunc = React.useCallback(
        () => setToggleFinancial(!toggleFinancial),
        [toggleFinancial]
    );
    const toggleFundFunc = React.useCallback(() => setToggleFund(!toggleFund), [toggleFund]);

    const toggleHOSecurityCheckFunc = React.useCallback(
        () => setToggleHOSecurityCheck(!toggleHOSecurityCheck),
        [toggleHOSecurityCheck]
    );
    const toggleHOInsuredFunc = React.useCallback(
        () => setToggleHOInsured(!toggleHOInsured),
        [toggleHOInsured]
    );
    const toggleHOAgentIDsFunc = React.useCallback(
        () => setToggleHOAgentIDs(!toggleHOAgentIDs),
        [toggleHOAgentIDs]
    );
    const toggleHOAlertAWDFunc = React.useCallback(
        () => setToggleHOAlertAWD(!toggleHOAlertAWD),
        [toggleHOAlertAWD]
    );
    const toggleHOOpenAWDFunc = React.useCallback(
        () => setToggleHOOpenAWD(!toggleHOOpenAWD),
        [toggleHOOpenAWD]
    );
    const toggleHOOpenADDRFunc = React.useCallback(
        () => setToggleHOOpenADDR(!toggleHOOpenADDR),
        [toggleHOOpenADDR]
    );
    // #endregion

    // #region processing flags
    const [isProcessingDetails, setIsProcessingDetails] = useState(false);
    const [isProcessingFAQs, setIsProcessingFAQs] = useState(false);
    const [isProcessingRequests, setIsProcessingRequests] = useState(false);
    const [isProcessingHO, setIsProcessingHO] = useState(false);
    // #endregion

    const { authState, oktaAuth } = useOktaAuth();
    const [faqSearch, setFAQSearch] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [requestError, setRequestError] = useState("");
    const [hoPolicyData, setHOPolicyData] = useState<PolicyHO>();
    const [hoAgents, setHOAgents] = useState<PolicyAdditionalAgentID[]>();
    const [hoAlertWorkobjects, setAlertWorkobjects] = useState<PolicyAlertAWDWorkobject[]>();
    const [hoOpenAWDWorkobjects, setOpenAWDWorkobjects] = useState<PolicyOpenAWDWorkobject[]>();
    const [showAllOpenAWDWorkobjects, setShowAllOpenAWDWorkobjects] = useState(false);
    const [hoOpenADDRWorkobjects, setOpenADDRWorkobjects] = useState<PolicyOpenADDRWorkobject[]>();
    const [showAllOpenADDRWorkobjects, setShowAllOpenADDRWorkobjects] = useState(false);
    const [requestTypes, setRequestTypes] = useState<RequestType[]>();
    const [requestHistory, setRequestHistory] = useState<RequestHistory[]>();
    const [tabKey, setTabKey] = useState("");
    const [trashIconClicked, setTrashIconClicked] = useState(false);

    const getHOAgents = async (_id: number) => {
        if ((hoAgents && hoAgents.length == 0) || !hoAgents) {
            try {
                const res = await get<PolicyAdditionalAgentID[]>(
                    "/policy/additionalagentids?policyDWKey=" + _id,
                    ""
                );
                setHOAgents(res);
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                }
            }
        }
    };

    const getHOAlertWorkobjects = async (_id: number, _showAll: boolean) => {
        if ((hoAlertWorkobjects && hoAlertWorkobjects.length == 0) || !hoAlertWorkobjects) {
            try {
                const res = await get<PolicyAlertAWDWorkobject[]>(
                    "/policy/alertawdworkobjects?policyDWKey=" + _id + "&showAll=" + _showAll,
                    ""
                );
                setAlertWorkobjects(res);
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                }
            }
        }
    };

    const getHOOpenAWDWorkobjects = async (_id: number, _showAll: boolean) => {
        try {
            const res = await get<PolicyOpenAWDWorkobject[]>(
                "/policy/openawdworkobjects?policyDWKey=" + _id + "&showAll=" + _showAll,
                ""
            );
            setShowAllOpenAWDWorkobjects(_showAll);
            setOpenAWDWorkobjects(res);
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const getHOOpenADDRWorkobjects = async (_id: number, _showAll: boolean) => {
        try {
            const res = await get<PolicyOpenADDRWorkobject[]>(
                "/policy/openaddrworkobjects?policyDWKey=" + _id + "&showAll=" + _showAll,
                ""
            );
            setShowAllOpenADDRWorkobjects(_showAll);
            setOpenADDRWorkobjects(res);
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const setupRequestsTab = async (
        _productType: string,
        _daysSinceIssue: number,
        _isCustomer: boolean,
        _companyCode: number
    ) => {
        try {
            if (!isProcessingRequests) {
                setIsProcessingRequests(true);
                await getRequestHistory(_productType);
                await getRequestTypes(_productType, _daysSinceIssue, _isCustomer);
                setIsProcessingRequests(false);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading this page, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(_companyCode) +
                        " for assistance."
                );
                setIsProcessingRequests(false);
            }
        }
    };

    const getRequestHistory = async (_productType: string) => {
        if ((requestHistory && requestHistory.length == 0) || !requestHistory) {
            try {
                const res = await get<RequestHistory[]>(
                    "/policy/requesthistory?policyDWKey=" +
                        idParsed +
                        "&productType=" +
                        _productType,
                    ""
                );
                setRequestHistory(res);
            } catch (e: unknown) {
                if (utilities.isMaintenanceError(e)) {
                    navigate.push("/maint");
                }
            }
        }
    };

    const getRequestTypes = async (
        _productType: string,
        _daysSinceIssue: number,
        _isCustomer: boolean
    ) => {
        try {
            const dataFromServer = await get<RequestType[]>(
                "/policy/requesttypes?policyDWKey=" + idParsed + "&productType=" + _productType,
                ""
            );
            let filteredRequests = dataFromServer;

            if (_isCustomer) {
                if (dataFromServer) {
                    filteredRequests = filteredRequests?.filter(
                        (req) =>
                            _daysSinceIssue >= req.minDaysAfterIssue &&
                            _daysSinceIssue <= req.maxDaysAfterIssue
                    );
                }
            }
            setRequestTypes(filteredRequests);
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
    };

    const getFAQs = async (_policyType: string, _companyCode: number) => {
        try {
            if (!isProcessingFAQs) {
                setIsProcessingFAQs(true);

                let result: FAQ[] = [];
                if (!faqs || faqs.length <= 0) {
                    if (_policyType && _policyType.length > 0) {
                        result = await get<FAQ[]>("/faq/listbyproduct?product=" + _policyType, "");
                    } else {
                        result = await get<FAQ[]>("/faq/", "");
                    }
                    setFAQs(result);
                } else {
                    result = faqs;
                }

                //filter
                result =
                    faqSearch !== "" && faqSearch !== "undefined"
                        ? result.filter(
                              (faq: FAQ) =>
                                  faq.question.toLowerCase().indexOf(faqSearch.toLowerCase()) !==
                                      -1 ||
                                  faq.response.toLowerCase().indexOf(faqSearch.toLowerCase()) !== -1
                          )
                        : result;

                //replace phone #
                for (let i = 0; i < result.length; i++) {
                    result[i].response = result[i].response.replace(
                        "##supportPhone##",
                        utilities.getSupportPhoneNumberByCompanyCode(_companyCode)
                    );
                }

                setFAQsFiltered(result);

                setIsProcessingFAQs(false);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading this page, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(_companyCode) +
                        " for assistance."
                );
                setIsProcessingFAQs(false);
            }
        }
    };

    const getHODetail = async () => {
        try {
            if (!isProcessingHO) {
                setIsProcessingHO(true);

                if (cpContext.isAdmin || cpContext.isHO) {
                    const hoPolicyData = await get<PolicyHO>(
                        "/policy/hodetail?policyDWKey=" + idParsed,
                        ""
                    );
                    if (hoPolicyData) {
                        await getHOAgents(idParsed);
                        await getHOAlertWorkobjects(idParsed, false);
                        await getHOOpenAWDWorkobjects(idParsed, false);
                        await getHOOpenADDRWorkobjects(idParsed, false);

                        setHOPolicyData(hoPolicyData);
                    }
                }

                setIsProcessingHO(false);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading this page, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        "for assistance."
                );
                setIsProcessingHO(false);
            }
        }
    };

    const getPolicy = async () => {
        try {
            if (!isProcessingDetails) {
                setIsProcessingDetails(true);
                if (policyData && policyData.summary) {
                    //do nothing, we have data already
                } else {
                    _policyData = await get<Policy>("/policy/" + idParsed, "");
                    if (_policyData) {
                        //Determine if this policy can be shown the one-time payment link
                        _policyData.onlinePaymentAllowed = await utilities.validatePolicyForPayment(
                            idParsed
                        );
                    }
                    setPolicyData(_policyData);
                }

                setIsProcessingDetails(false);
            }
        } catch (e: unknown) {
            if (utilities.isMaintenanceError(e)) {
                navigate.push("/maint");
            } else {
                setErrorMessage(
                    "There was a problem loading your policy information, please call us at " +
                        utilities.getSupportPhoneNumberByCompanyCode(policyData?.companyCode ?? 0) +
                        " for assistance."
                );
                setIsProcessingDetails(false);
            }
        }
    };

    const getLapsedElement = (_policyData: Policy) => {
        let result = <></>;
        const path = "/policy-detail/" + idParsed + "?tab=faqs&faqTag=grace";
        //TODO: need to change this to use the tagName once the FAQ db table is updated
        if (_policyData?.summary.status == "Lapse Pending") {
            result = (
                <span>
                    Urgent - The policy is currently in Grace. To learn more about Grace, please{" "}
                    <a href="#" onClick={() => faqNavigate(path)}>
                        click here
                    </a>{" "}
                    or call Customer Service at{" "}
                    {utilities.getSupportPhoneNumberByCompanyCode(_policyData?.companyCode)}.
                </span>
            );
        } else if (_policyData?.summary.status == "Lapsed") {
            result = (
                <span>
                    Attention! – Grace Period has Expired – Policy has Lapsed. Please call Customer
                    Service at{" "}
                    {utilities.getSupportPhoneNumberByCompanyCode(_policyData?.companyCode)}.
                </span>
            );
        }

        return result;
    };

    const faqNavigate = (_uri: string) => {
        navigate.push(_uri);
        setTabKey("faqs");
        loadTab("faqs");
    };

    const cancelRequest = async (requestId: number, requestType: string) => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "cancel " + requestType + " request",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );
        setRequestError(
            "To cancel your request, please call Customer Service at " +
                utilities.getSupportPhoneNumberByCompanyCode(_policyData?.companyCode) +
                "."
        );
    };

    const ShowBankDraftDateWithin24hrMessage = async () => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "bank draft date link",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );
        setRequestError(
            "You have already submitted a request to change your draft day. Please click <a href='#request-history'>here</a> for a status update."
        );
    };
    const ShowBankDraftDateWithin6moMessage = async () => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "bank draft date link",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );
        setRequestError(
            "Our records indicate you have exceeded the allowable number of date change requests in a 6 month period or less.  Further changes are not allowed at this time.  For further assistance please contact our Customer Service Department at 800-231-0801."
        );
    };
    const ShowBankDraftDateChangeMessage = async () => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "bank draft date link",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );
        setRequestError(
            "To change your current draft date, please contact our Policy Support Center at 1-800-231-0801."
        );
    };
    const ShowBankDraftDateCallMessage = async () => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "bank draft date link",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );
        setRequestError(
            "To be placed on bank draft, please contact our Policy Support Center at 1-800-231-0801."
        );
    };
    const ShowActiveFIBLMessage = async () => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "bank draft date link",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );
        setRequestError(
            "To change your draft date for your Financial Income Builder Annuity, please access your related Financial Income Builder Life policy and select ‘Bank Draft Date Change’ on the Request Tab."
        );
    };
    const ShowInActiveFIBLMessage = async () => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "bank draft date link",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );
        setRequestError(
            "We are unable to change your draft date at this time, please contact Customer Service at 800-231-0801."
        );
    };
    const ShowExistingFreelookMessage = async () => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "freelook link",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );
        setRequestError(
            "You have already submitted a request to cancel your policy. Please click <a href='#request-history'>here</a> for a status update."
        );
    };
    const ShowExistingBeneficiaryMessage = async () => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "beneficiary change link",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );
        setRequestError("Only one beneficiary change submission is allowed per calendar day.");
    };
    const ShowFreelookASGNMessage = async () => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "freelook link",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            "",
            idParsed
        );
        setRequestError(
            "We are unable to process this transaction online. Please contact our Policy Support Center at 1-800-231-0801."
        );
    };

    const handleKeywordKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key == "Enter") {
        }
    };

    const loadTab = async (_tab: string) => {
        if (_tab && _tab.length > 0 && policyData?.summary) {
            if (_tab === "details") {
                setToggleSummary(true);
            } else if (_tab === "faqs") {
                await getFAQs(policyData.customerMarket, policyData.companyCode);
                if (document) {
                    document.querySelector("#faqTag")?.scrollIntoView();
                }
            } else if (_tab === "service" && !cpContext.hideForNonDev) {
                await setupRequestsTab(
                    policyData.productType,
                    policyData.daysSinceIssue,
                    cpContext.isCustomer,
                    policyData.companyCode
                );
            } else if (_tab === "homeoffice") {
                await getHODetail();
            } else if (!policyData) {
            }
            await logger.logDetailedActivity(
                "PolicyDetail",
                "tab click",
                cpContext,

                cpContext.userInfo?.email,
                cpContext.sessionId,
                _tab,
                idParsed
            );
        }
    };

    const logFAQClick = async (_id: number, _title: string) => {
        await logger.logDetailedActivity(
            "PolicyDetail",
            "FAQ click",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId,
            _title,
            _id
        );
    };

    useEffect(() => {
        if (policyData && policyData.customerMarket) {
            getFAQs(policyData.customerMarket, policyData.companyCode);
        }

        // eslint-disable-next-line
    }, [faqSearch]);

    useEffect(() => {
        if (tabKey && tabKey.length > 0 && tabKey != tabParsed && policyData?.summary) {
            loadTab(tabKey);
        }
        // eslint-disable-next-line
    }, [tabKey]);

    useEffect(() => {
        if (policyData && policyData.summary) {
            loadTab(tabKey);
        }
        // eslint-disable-next-line
    }, [policyData]);

    useEffect(() => {
        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            //Policy Data is needed for just about everything so that will be the catalyst to load
            getPolicy();
            //Show specific tab on load
            setTabKey(tabParsed);
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth, cpContext]);

    const requestTypeDisplay = (req: RequestType) => {
        let result = (
            <a
                href="#"
                onClick={() =>
                    logger.loggedRequestNavigate(
                        "",
                        "",
                        req.transactionType,
                        req.formNumber,
                        req.isExternalURI,
                        policyData,
                        idParsed
                    )
                }
            >
                {cpContext.isCustomer ? req.transactionLongName : req.transactionNameInternal}
            </a>
        );

        const minNextDraftDate = new Date();
        const maxNextDraftDate = new Date();
        minNextDraftDate.setDate(minNextDraftDate.getDate() - 90); //Used for rule that doesn't allow if next date is older than 3mo, or newer than ~13mo from now
        maxNextDraftDate.setDate(maxNextDraftDate.getDate() + 395);

        switch (req.transactionType) {
            case TransactionType.OneTimePayment:
                if (
                    policyData &&
                    policyData?.customerPortalAccessLevel < 2 &&
                    cpContext.isCustomer
                ) {
                    //No workflows allowed if 0 or 1 for CustomerPortalAccessLevel
                    result = <a href="#" style={{ display: "none" }}></a>;
                } else if (policyData && policyData.onlinePaymentAllowed && cpContext.isCustomer) {
                    result = (
                        <a
                            href="#"
                            onClick={() =>
                                logger.loggedRequestNavigate(
                                    req.transactionURI,
                                    "",
                                    req.transactionType,
                                    req.formNumber,
                                    req.isExternalURI,
                                    policyData,
                                    idParsed
                                )
                            }
                        >
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else {
                    result = <a href="#" style={{ display: "none" }}></a>;
                }
                break;
            case TransactionType.Freelook:
                if (
                    policyData &&
                    policyData?.customerPortalAccessLevel < 2 &&
                    cpContext.isCustomer &&
                    policyData.daysSinceIssue <= req.maxDaysAfterIssue
                ) {
                    //No workflows allowed if 0 or 1 for CustomerPortalAccessLevel
                    result = (
                        <a
                            href="#"
                            onClick={() =>
                                logger.loggedRequestNavigate(
                                    req.transactionURI,
                                    "",
                                    req.transactionType,
                                    req.formNumber,
                                    req.isExternalURI,
                                    policyData,
                                    idParsed
                                )
                            }
                        >
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else if (req.previousRequests > 0) {
                    result = (
                        <a href="#" onClick={() => ShowExistingFreelookMessage()}>
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else {
                    if (
                        cpContext.isCustomer &&
                        policyData!.daysSinceIssue <= req.maxDaysAfterIssue
                    ) {
                        result = (
                            <a
                                href="#"
                                onClick={() =>
                                    logger.loggedRequestNavigate(
                                        req.transactionURI,
                                        "",
                                        req.transactionType,
                                        req.formNumber,
                                        req.isExternalURI,
                                        policyData,
                                        idParsed
                                    )
                                }
                            >
                                {cpContext.isCustomer
                                    ? req.transactionLongName
                                    : req.transactionNameInternal}
                            </a>
                        );
                    } else {
                        result = (
                            <a
                                href="#"
                                onClick={() =>
                                    logger.loggedRequestNavigate(
                                        req.transactionURI,
                                        "",
                                        req.transactionType,
                                        req.formNumber,
                                        req.isExternalURI,
                                        policyData,
                                        idParsed
                                    )
                                }
                            >
                                {cpContext.isCustomer
                                    ? req.transactionLongName
                                    : req.transactionNameInternal}
                            </a>
                        );
                    }
                }
                break;
            case TransactionType.Surrender:
                if (policyData?.productType.toLowerCase() === ProductType.ISWL) {
                    result = (
                        <a
                            href="#"
                            onClick={() =>
                                logger.loggedRequestNavigate(
                                    "/download-forms",
                                    "",
                                    "FormDownload",
                                    "",
                                    false,
                                    undefined,
                                    idParsed
                                )
                            }
                        >
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else {
                    if (
                        (policyData!.daysSinceIssue > req.minDaysAfterIssue &&
                            cpContext.isCustomer) ||
                        cpContext.isHO
                    ) {
                        result = (
                            <a
                                href="#"
                                onClick={() =>
                                    logger.loggedRequestNavigate(
                                        req.transactionURI,
                                        "",
                                        req.transactionType,
                                        req.formNumber,
                                        req.isExternalURI,
                                        policyData,
                                        idParsed
                                    )
                                }
                            >
                                {cpContext.isCustomer
                                    ? req.transactionLongName
                                    : req.transactionNameInternal}
                            </a>
                        );
                    }
                }
                break;
            case TransactionType.Beneficiary:
                if (
                    policyData &&
                    policyData?.customerPortalAccessLevel < 2 &&
                    cpContext.isCustomer
                ) {
                    //No workflows allowed if 0 or 1 for CustomerPortalAccessLevel
                    if (policyData.qualifiedPlanType.includes("403(b)")) {
                        result = (
                            <a
                                href="#"
                                onClick={() =>
                                    logger.loggedRequestNavigate(
                                        "/download-forms",
                                        "",
                                        "FormDownload",
                                        "",
                                        false,
                                        policyData,
                                        idParsed
                                    )
                                }
                            >
                                {cpContext.isCustomer
                                    ? req.transactionLongName
                                    : req.transactionNameInternal}
                            </a>
                        );
                    } else {
                        result = (
                            <a
                                href="#"
                                onClick={() =>
                                    logger.loggedRequestNavigate(
                                        "",
                                        "",
                                        req.transactionType,
                                        req.formNumber,
                                        req.isExternalURI,
                                        policyData,
                                        idParsed
                                    )
                                }
                            >
                                {cpContext.isCustomer
                                    ? req.transactionLongName
                                    : req.transactionNameInternal}
                            </a>
                        );
                    }
                } else if (req.previousRequests24Hours > 0 && cpContext.isCustomer) {
                    result = (
                        <a href="#" onClick={() => ShowExistingBeneficiaryMessage()}>
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else {
                    result = (
                        <a
                            href="#"
                            onClick={() =>
                                logger.loggedRequestNavigate(
                                    req.transactionURI,
                                    "",
                                    req.transactionType,
                                    req.formNumber,
                                    req.isExternalURI,
                                    policyData,
                                    idParsed
                                )
                            }
                        >
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                }
                break;
            case TransactionType.BankDraftForm:
                if (policyData?.summary?.status != "Active") {
                    //Rule, direct to call if not active
                    console.log("MBPACK status=" + policyData?.summary?.status);
                    result = (
                        <a href="#" onClick={() => ShowBankDraftDateChangeMessage()}>
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else if (
                    policyData?.policyNumber.toLowerCase().startsWith("am") &&
                    !policyData?.summary?.billingMethod.toLowerCase().includes("draft")
                ) {
                    //Rule, direct to call if policy numbers start with AM and not currently on bank draft
                    console.log(
                        "MBPACK starts with AM and not on DRAFT; BillingMethod=" +
                            policyData?.summary?.billingMethod
                    );
                    result = (
                        <a href="#" onClick={() => ShowBankDraftDateCallMessage()}>
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else if (
                    (policyData?.companyCode.toString().length == 1 ||
                        policyData?.companyCode.toString().length == 2) &&
                    policyData?.productType.toLowerCase() == "universal" &&
                    cpContext.isCustomer
                ) {
                    //Rule, company code starts with 1 or 2 AND is UL AND is Customer user, direct to call
                    console.log(
                        "MBPACK companyCode is 1 or 2 digit and UL; productType=" +
                            policyData?.productType
                    );
                    result = (
                        <a href="#" onClick={() => ShowBankDraftDateCallMessage()}>
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else {
                    result = (
                        <a
                            href="#"
                            onClick={() =>
                                logger.loggedRequestNavigate(
                                    "",
                                    "",
                                    req.transactionType,
                                    req.formNumber,
                                    req.isExternalURI,
                                    policyData,
                                    idParsed
                                )
                            }
                        >
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                }
                break;
            case TransactionType.BankDraftDateChange:
                //Rule once per 24 hours
                if (req.previousRequests24Hours > 0) {
                    result = (
                        <a href="#" onClick={() => ShowBankDraftDateWithin24hrMessage()}>
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else if (req.previousRequestsPastSixMonths >= 3) {
                    //Rule 3 every 6 months
                    result = (
                        <a href="#" onClick={() => ShowBankDraftDateWithin6moMessage()}>
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else if (policyData?.summary?.status != "Active") {
                    //Rule, direct to call if not active
                    console.log("BankDraftDateChange status=" + policyData?.summary?.status);
                    result = (
                        <a href="#" onClick={() => ShowBankDraftDateChangeMessage()}>
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else if (
                    policyData?.policyNumber.toLowerCase().startsWith("am") &&
                    !policyData?.summary?.billingMethod.toLowerCase().includes("draft")
                ) {
                    //Rule, direct to call if policy numbers start with AM and not currently on bank draft
                    console.log(
                        "BankDraftDateChange starts with AM and not on DRAFT; BillingMethod=" +
                            policyData?.summary?.billingMethod
                    );
                    result = (
                        <a href="#" onClick={() => ShowBankDraftDateCallMessage()}>
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else if (
                    policyData?.summary?.ssBillingOption &&
                    policyData?.summary?.ssBillingOption.length > 0
                ) {
                    //Rule, SSBillingOption direct to form download
                    result = (
                        <a
                            href="#"
                            onClick={() =>
                                logger.loggedRequestNavigate(
                                    "",
                                    "",
                                    req.transactionType,
                                    req.formNumber,
                                    req.isExternalURI,
                                    policyData,
                                    idParsed
                                )
                            }
                        >
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else if (policyData?.summary?.billingMethod.toLowerCase().includes("draft")) {
                    //Rule, if not on bank transfer already direct to download (by fall through to else), also have to check for valid paid to date
                    if (policyData?.product.toUpperCase() == "FINANCIAL INCOME BUILDER ANNUITY") {
                        if (policyData?.hasActiveFIBL) {
                            console.log("BankDraftDateChange past date hasActiveFIBL; ");
                            result = (
                                <a href="#" onClick={() => ShowActiveFIBLMessage()}>
                                    {cpContext.isCustomer
                                        ? req.transactionLongName
                                        : req.transactionNameInternal}
                                </a>
                            );
                        } else {
                            console.log("BankDraftDateChange past date !hasActiveFIBL; ");
                            result = (
                                <a href="#" onClick={() => ShowInActiveFIBLMessage()}>
                                    {cpContext.isCustomer
                                        ? req.transactionLongName
                                        : req.transactionNameInternal}
                                </a>
                            );
                        }
                    } else if (
                        utilities.parseDate(policyData?.summary.nextDraftDate) > maxNextDraftDate ||
                        utilities.parseDate(policyData?.summary.nextDraftDate) < minNextDraftDate
                    ) {
                        console.log(
                            "BankDraftDateChange past date " +
                                utilities
                                    .parseDate(policyData?.summary.nextDraftDate)
                                    .toISOString() +
                                " > " +
                                maxNextDraftDate.toLocaleDateString() +
                                " < " +
                                minNextDraftDate.toLocaleDateString()
                        );
                        result = (
                            <a
                                href="#"
                                onClick={() =>
                                    logger.loggedRequestNavigate(
                                        "",
                                        "",
                                        req.transactionType,
                                        req.formNumber,
                                        req.isExternalURI,
                                        policyData,
                                        idParsed
                                    )
                                }
                            >
                                {cpContext.isCustomer
                                    ? req.transactionLongName
                                    : req.transactionNameInternal}
                            </a>
                        );
                    } else {
                        console.log(
                            "BankDraftDateChange past date else; " +
                                policyData?.policyType +
                                "; " +
                                policyData?.product +
                                "; " +
                                +policyData?.productSeries
                        );
                        result = (
                            <a
                                href="#"
                                onClick={() =>
                                    logger.loggedRequestNavigate(
                                        req.transactionURI,
                                        "",
                                        req.transactionType,
                                        req.formNumber,
                                        req.isExternalURI,
                                        policyData,
                                        idParsed
                                    )
                                }
                            >
                                {cpContext.isCustomer
                                    ? req.transactionLongName
                                    : req.transactionNameInternal}
                            </a>
                        );
                    }
                } else {
                    result = (
                        <a
                            href="#"
                            onClick={() =>
                                logger.loggedRequestNavigate(
                                    "",
                                    "",
                                    req.transactionType,
                                    req.formNumber,
                                    req.isExternalURI,
                                    policyData,
                                    idParsed
                                )
                            }
                        >
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                }
                break;
            case TransactionType.FormDownload:
                if (
                    policyData &&
                    policyData?.customerPortalAccessLevel < 2 &&
                    cpContext.isCustomer
                ) {
                    //No workflows allowed if 0 or 1 for CustomerPortalAccessLevel
                    result = <a href="#" style={{ display: "none" }}></a>;
                } else if (policyData) {
                    result = (
                        <a
                            href="#"
                            onClick={() =>
                                logger.loggedRequestNavigate(
                                    req.transactionURI,
                                    "",
                                    req.transactionType,
                                    req.formNumber,
                                    req.isExternalURI,
                                    policyData,
                                    idParsed
                                )
                            }
                        >
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else {
                    result = <a href="#" style={{ display: "none" }}></a>;
                }
                break;
            default:
                if (
                    policyData &&
                    policyData?.customerPortalAccessLevel < 2 &&
                    cpContext.isCustomer
                ) {
                    //No workflows allowed if 0 or 1 for CustomerPortalAccessLevel
                    result = (
                        <a
                            href="#"
                            onClick={() =>
                                logger.loggedRequestNavigate(
                                    "",
                                    "",
                                    req.transactionType,
                                    req.formNumber,
                                    req.isExternalURI,
                                    policyData,
                                    idParsed
                                )
                            }
                        >
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else if (!req.completeWithASGN && policyData?.hasASGN) {
                    result = (
                        <a href="#" onClick={() => ShowFreelookASGNMessage()}>
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                } else if (req.transactionURI && req.transactionURI.length > 0) {
                    if (req.transactionType === TransactionType.Upload) {
                        result = (
                            <a
                                href="#"
                                onClick={() =>
                                    logger.loggedRequestNavigate(
                                        req.transactionURI,
                                        "",
                                        req.transactionType,
                                        req.formNumber,
                                        req.isExternalURI,
                                        policyData,
                                        idParsed
                                    )
                                }
                                style={{ fontWeight: 900 }}
                            >
                                {cpContext.isCustomer
                                    ? req.transactionLongName
                                    : req.transactionNameInternal}
                            </a>
                        );
                    } else {
                        result = (
                            <a
                                href="#"
                                onClick={() =>
                                    logger.loggedRequestNavigate(
                                        req.transactionURI,
                                        "",
                                        req.transactionType,
                                        req.formNumber,
                                        req.isExternalURI,
                                        policyData,
                                        idParsed
                                    )
                                }
                            >
                                {cpContext.isCustomer
                                    ? req.transactionLongName
                                    : req.transactionNameInternal}
                            </a>
                        );
                    }
                } else {
                    result = (
                        <a
                            href="#"
                            onClick={() =>
                                logger.loggedRequestNavigate(
                                    "",
                                    "",
                                    req.transactionType,
                                    req.formNumber,
                                    req.isExternalURI,
                                    policyData,
                                    idParsed
                                )
                            }
                        >
                            {cpContext.isCustomer
                                ? req.transactionLongName
                                : req.transactionNameInternal}
                        </a>
                    );
                }
                break;
        }

        return result;
    };

    const showFundBalances = () => {
        return (
            policyData &&
            policyData.fundBalances &&
            policyData.fundBalances.length > 0 &&
            (policyData.policyType.includes(PolicyType.Annuity) ||
                policyData.policyType.includes(PolicyType.IUL))
        );
    };

    if (policyData && policyData.summary) {
        const isCashBackOption: boolean =
            policyData.policyType.toLowerCase().includes(PolicyType.Universal) &&
            policyData.summary.carrierAdminSystem.toLowerCase() === CarrierAdminSystem.PAS;

        const showMaxLoanAmount = () => {
            return (
                (policyData.policyType.includes(PolicyType.Universal) &&
                    !policyData.policyNumber.toLowerCase().endsWith("c")) ||
                policyData.policyType.includes(PolicyType.Annuity) ||
                policyData.policyType.includes(PolicyType.WholeLife) ||
                policyData.policyType.includes(PolicyType.Term) ||
                (policyData.productType.toLowerCase() === ProductType.ISWL &&
                    policyData.qualifiedPlanType.toLowerCase() === QualifiedPlanType.NonQualified)
            );
        };

        const showLoanInterestRate = () => {
            return (
                policyData.policyType.includes(PolicyType.Universal) ||
                policyData.policyType.includes(PolicyType.Annuity) ||
                policyData.policyType.includes(PolicyType.WholeLife) ||
                policyData.policyType.includes(PolicyType.Term) ||
                (policyData.productType.toLowerCase() === ProductType.ISWL &&
                    policyData.qualifiedPlanType.toLowerCase() === QualifiedPlanType.NonQualified)
            );
        };

        const showMaxPartialSurrenderAmount = () => {
            return (
                (policyData.policyType.includes(PolicyType.Universal) &&
                    !policyData.policyNumber.toLowerCase().endsWith("c")) ||
                policyData.policyType.includes(PolicyType.Annuity)
            );
        };

        const showNextAnniversaryCOIAmount = () => {
            return (
                policyData.policyType.includes(PolicyType.Universal) ||
                (policyData.productType.toLowerCase() === ProductType.ISWL &&
                    policyData.qualifiedPlanType.toLowerCase() === QualifiedPlanType.NonQualified)
            );
        };

        const showLastMonthlyCOIAmount = () => {
            return (
                policyData.policyType.includes(PolicyType.Universal) ||
                (policyData.productType.toLowerCase() === ProductType.ISWL &&
                    policyData.qualifiedPlanType.toLowerCase() === QualifiedPlanType.NonQualified)
            );
        };

        const showPaymentsTab = () => {
            const isContinuationOfCoverage =
                policyData.policyNumber?.trim()?.toLowerCase()?.endsWith("c") === true;
            return (
                cpContext.featureFlags.policyPaymentsEnabled &&
                !isContinuationOfCoverage &&
                policyData.onlinePaymentAllowed &&
                (policyData.summary.status == PolicyStatus.Active ||
                    policyData.summary.status == PolicyStatus.LapsePending ||
                    policyData.summary.status == PolicyStatus.Grace)
            );
        };

        if (policyData.customerPortalAccessLevel == 0 && !(cpContext.isAdmin || cpContext.isHO)) {
            return (
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                    We are unable to provide your policy information online at this time. Please
                    call us at 800-231-0801.
                </div>
            );
        } else {
            return (
                <div className="row" style={{ backgroundColor: "#eee" }}>
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div
                            className="row"
                            style={{
                                paddingTop: 10,
                                paddingBottom: 0,
                                borderTop: 0,
                                borderLeft: 0,
                                borderRight: 0,
                                borderBottom: 1,
                                borderStyle: "solid"
                            }}
                        >
                            <h1
                                className="custom-h1 skip-to"
                                style={{ paddingTop: 10 }}
                                title="The number that identifies an individual policy contract"
                            >
                                Policy # {policyData.policyNumber} - {policyData.product}
                            </h1>
                            <Tabs
                                id="policyDetailTabs"
                                className="mb-3 justify-content-end"
                                activeKey={tabKey}
                                onSelect={(k) => setTabKey(k ? k : "details")}
                                style={{
                                    paddingTop: 10,
                                    paddingBottom: 0,
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "2px",
                                    borderBottomColor: "#33CCCC"
                                }}
                            >
                                {showPaymentsTab() && (
                                    <Tab eventKey="payments" title="Payments">
                                        {tabKey === "payments" && (
                                            <PolicyPayments
                                                idParsed={idParsed}
                                                policyData={policyData}
                                                setErrorMessage={setErrorMessage}
                                            />
                                        )}
                                    </Tab>
                                )}
                                <Tab eventKey="details" title="Policy Details">
                                    <section aria-labelledby="details-section">
                                        <h2
                                            style={{ display: "none" }}
                                            aria-labelledby="details-section"
                                        >
                                            Details Section
                                        </h2>
                                        {isProcessingDetails ? (
                                            <Spinner imageWidth={20} aria-label="Loading" />
                                        ) : (
                                            <>
                                                {policyData.summary.status == "Lapse Pending" ||
                                                policyData.summary.status == "Lapsed" ? (
                                                    <div className="row" style={{ padding: 10 }}>
                                                        <div className="col-12">
                                                            <Card
                                                                style={{
                                                                    borderColor: "red",
                                                                    borderStyle: "solid",
                                                                    borderWidth: "1px"
                                                                }}
                                                            >
                                                                <Card.Body className="shadow">
                                                                    <div className="row">
                                                                        <div className="col-12 text-danger">
                                                                            {getLapsedElement(
                                                                                policyData
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                <div className="row" style={{ paddingTop: 20 }}>
                                                    <h2 className="homeOfficeHeadingToggle">
                                                        <span
                                                            onClick={toggleSummaryFunc}
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "#1C2F67",
                                                                fontWeight: "bold"
                                                            }}
                                                            tabIndex={0}
                                                        >
                                                            Summary&nbsp;&nbsp;
                                                        </span>
                                                        <span
                                                            onClick={toggleSummaryFunc}
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "#1C2F67",
                                                                fontWeight: "bold"
                                                            }}
                                                            className={
                                                                toggleSummary
                                                                    ? "bi bi-chevron-up"
                                                                    : "bi bi-chevron-down"
                                                            }
                                                            aria-hidden={true}
                                                            tabIndex={0}
                                                        >
                                                            &nbsp;
                                                        </span>
                                                    </h2>
                                                </div>
                                                <Collapse in={toggleSummary}>
                                                    <div className="row" style={{ padding: 10 }}>
                                                        <div className="col-12">
                                                            <Card className="policyCard">
                                                                <Card.Body className="shadow">
                                                                    <>
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                        >
                                                                            <div className="col-12 col-md-6 col-lg-4">
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 40,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="col-6 fieldLabel"
                                                                                        title="Age of the insured on the date the policy was issued."
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        Issue Age:
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-6 fieldValue"
                                                                                        title="Age of the insured on the date the policy was issued."
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        {
                                                                                            policyData
                                                                                                .summary
                                                                                                ?.issueAge
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                    tabIndex={0}
                                                                                >
                                                                                    <div
                                                                                        className="col-6 fieldLabel"
                                                                                        title="Date we issued the policy"
                                                                                    >
                                                                                        Issue Date:
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-6 fieldValue"
                                                                                        title="Date we issued the policy"
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        {utilities.parseAndFormatDate(
                                                                                            policyData
                                                                                                .summary
                                                                                                .issueDate
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="col-6 fieldLabel"
                                                                                        title="Date the policy started billing for coverage."
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        Effective
                                                                                        Date:
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-6 fieldValue"
                                                                                        title="Date the policy started billing for coverage."
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        {utilities.parseAndFormatDate(
                                                                                            policyData
                                                                                                .summary
                                                                                                .effectiveDate
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                {policyData.policyType.toLowerCase() ==
                                                                                    "universal" &&
                                                                                utilities.parseDate(
                                                                                    policyData
                                                                                        .summary
                                                                                        .nextRiskPurchaseDate
                                                                                ) >
                                                                                    utilities.parseDate(
                                                                                        "1900-01-01T00:00:00"
                                                                                    ) &&
                                                                                utilities
                                                                                    .parseDate(
                                                                                        policyData
                                                                                            .summary
                                                                                            .nextRiskPurchaseDate
                                                                                    )
                                                                                    .toISOString() !==
                                                                                    utilities
                                                                                        .parseDate(
                                                                                            "2199-01-01T00:00:00"
                                                                                        )
                                                                                        .toISOString() ? (
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-6 fieldLabel"
                                                                                            title="Paid to Date."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Paid to
                                                                                            Date:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-6 fieldValue"
                                                                                            title="Paid to Date."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {utilities.parseAndFormatDate(
                                                                                                policyData
                                                                                                    .summary
                                                                                                    .nextRiskPurchaseDate
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                {utilities.parseDate(
                                                                                    policyData.annuityDate
                                                                                ) >
                                                                                    utilities.parseDate(
                                                                                        "1900-01-01T00:00:00"
                                                                                    ) &&
                                                                                utilities
                                                                                    .parseDate(
                                                                                        policyData.annuityDate
                                                                                    )
                                                                                    .toISOString() !==
                                                                                    utilities
                                                                                        .parseDate(
                                                                                            "2199-01-01T00:00:00"
                                                                                        )
                                                                                        .toISOString() ? (
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-6 fieldLabel"
                                                                                            title="The date annuity payments are scheduled to begin."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Annuity
                                                                                            Date:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-6 fieldValue"
                                                                                            title="The date annuity payments are scheduled to begin."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {utilities.parseAndFormatDate(
                                                                                                policyData.annuityDate
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="col-6 fieldLabel"
                                                                                        title="The current status of the policy."
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        Status:
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-6 fieldValue"
                                                                                        title="The current status of the policy."
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        {
                                                                                            policyData
                                                                                                .summary
                                                                                                .status
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                {policyData.policyType.toLowerCase() ===
                                                                                PolicyType.Annuity ? (
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fieldLabel"
                                                                                            title=""
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Qualified?
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fieldValue"
                                                                                            title=""
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {policyData.qualifiedIndicator ==
                                                                                            true
                                                                                                ? "Yes"
                                                                                                : "No"}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fieldLabel"
                                                                                        title=""
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        &nbsp;
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fieldValue"
                                                                                        title=""
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        {
                                                                                            policyData.qualifiedPlanType
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-md-6 col-lg-4">
                                                                                {!cpContext.isHO && (
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12"
                                                                                            style={{
                                                                                                textDecoration:
                                                                                                    "underline",
                                                                                                color: "#1C2F67",
                                                                                                fontWeight:
                                                                                                    "bold",
                                                                                                fontSize: 18
                                                                                            }}
                                                                                        >
                                                                                            POLICY
                                                                                            VALUES
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {!cpContext.isHO && (
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-6 col-lg-7 fieldLabel"
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Values
                                                                                            are as
                                                                                            of:
                                                                                        </div>
                                                                                        <div className="col-4 col-lg-5 fieldValue">
                                                                                            {utilities.parseDate(
                                                                                                policyData
                                                                                                    .summary
                                                                                                    .policyAsOfDate
                                                                                            ) >
                                                                                            utilities.parseDate(
                                                                                                "1900-01-01T00:00:00"
                                                                                            ) ? (
                                                                                                <>
                                                                                                    {utilities.parseAndFormatDate(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .policyAsOfDate
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {utilities.parseAndFormatDate(
                                                                                                        currentDateTime.toISOString()
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {!cpContext.isHO && (
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-6 col-lg-7 fieldLabel"
                                                                                            title="Funds that have accured from the payment of your premiums."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Cash
                                                                                            Value:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-6 col-lg-5 fieldValue"
                                                                                            title="Funds that have accured from the payment of your premiums."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {utilities.formatCurrency(
                                                                                                policyData
                                                                                                    .summary
                                                                                                    .cashValue
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {!cpContext.isHO &&
                                                                                policyData.summary
                                                                                    .surrenderCharge >
                                                                                    0 ? (
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-6 col-lg-7 fieldLabel"
                                                                                            title="Fee assessed upon the cancellation of the policy less any indebtedness."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            *
                                                                                            Surrender
                                                                                            Penalty:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-6 col-lg-5 fieldValue"
                                                                                            title="Fee assessed upon the cancellation of the policy less any indebtedness."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {policyData
                                                                                                .summary
                                                                                                .surrenderCharge <
                                                                                            0 ? (
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "black"
                                                                                                    }}
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .surrenderCharge
                                                                                                    )}
                                                                                                </span>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .surrenderCharge
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <></>
                                                                                )}

                                                                                {!cpContext.isHO && (
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-6 col-lg-7 fieldLabel"
                                                                                            title="The amount you will received less any indebtedness or fee upon cancellation of your policy."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {policyData.product
                                                                                                .toLocaleLowerCase()
                                                                                                .indexOf(
                                                                                                    "platinum assure"
                                                                                                ) >=
                                                                                            0 ? (
                                                                                                <>
                                                                                                    **
                                                                                                    Cash
                                                                                                    Surrender
                                                                                                    Value:
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    *
                                                                                                    Cash
                                                                                                    Surrender
                                                                                                    Value:
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-6 col-lg-5 fieldValue"
                                                                                            title="The amount you will received less any indebtedness or fee upon cancellation of your policy."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {policyData
                                                                                                .summary
                                                                                                .surrenderValue <
                                                                                            0 ? (
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "black"
                                                                                                    }}
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .surrenderValue
                                                                                                    )}
                                                                                                </span>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .surrenderValue
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {/* Base Death Benefit is displayed only for Universal and Whole Life policies - no Annuity policies */}
                                                                                {!policyData.policyType
                                                                                    .toLowerCase()
                                                                                    .includes(
                                                                                        PolicyType.Annuity
                                                                                    ) ? (
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop:
                                                                                                cpContext.isHO
                                                                                                    ? 40
                                                                                                    : 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-6 col-lg-7 fieldLabel"
                                                                                            title="Base death benefit amount"
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Death
                                                                                            Benefit
                                                                                            Amount:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-6 col-lg-5 fieldValue"
                                                                                            title="Base death benefit amount"
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {policyData
                                                                                                .coverages[0]
                                                                                                ?.faceAmount ===
                                                                                            undefined
                                                                                                ? utilities.formatCurrency(
                                                                                                      0.0
                                                                                                  )
                                                                                                : utilities.formatCurrency(
                                                                                                      policyData
                                                                                                          .coverages[0]
                                                                                                          ?.faceAmount
                                                                                                  )}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                {!cpContext.isHO &&
                                                                                policyData.summary
                                                                                    .loanPayoff >
                                                                                    0 ? (
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-6 col-lg-7 fieldLabel"
                                                                                            title="Total amount due plus interest to payoff loan."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            * Loan
                                                                                            Payoff
                                                                                            Amount:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-6 col-lg-5 fieldValue"
                                                                                            title="Total amount due plus interest to payoff loan."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {policyData.summary.loanPayoff.toLocaleString(
                                                                                                "en-US",
                                                                                                {
                                                                                                    style: "currency",
                                                                                                    currency:
                                                                                                        "USD",
                                                                                                    minimumFractionDigits: 2,
                                                                                                    maximumFractionDigits: 2
                                                                                                }
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-12 col-md-12 col-lg-4">
                                                                                {policyData.summary
                                                                                    .billingMethod
                                                                                    .length > 0 ||
                                                                                policyData.summary
                                                                                    .billingMode
                                                                                    .length > 0 ||
                                                                                policyData.summary
                                                                                    .premiumAmount >
                                                                                    0 ||
                                                                                utilities.parseDate(
                                                                                    policyData
                                                                                        .summary
                                                                                        .premiumDate
                                                                                ) >
                                                                                    utilities.parseDate(
                                                                                        "1900-01-01T00:00:00"
                                                                                    ) ? (
                                                                                    <>
                                                                                        {!cpContext.isHO && (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                    style={{
                                                                                                        textDecoration:
                                                                                                            "underline",
                                                                                                        color: "#1C2F67",
                                                                                                        fontWeight:
                                                                                                            "bold",
                                                                                                        fontSize: 18
                                                                                                    }}
                                                                                                >
                                                                                                    BILLING
                                                                                                    INFORMATION
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                        {!policyData.policyType.includes(
                                                                                            PolicyType.Term
                                                                                        ) ||
                                                                                        !isCashBackOption ? (
                                                                                            <>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop:
                                                                                                            cpContext.isHO
                                                                                                                ? 40
                                                                                                                : 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-6 fieldLabel"
                                                                                                        title="Approved billing process for premium i.e Direct Bill, Automatic Bank Draft."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Billing
                                                                                                        Method:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-6 fieldValue"
                                                                                                        title="Approved billing process for premium i.e Direct Bill, Automatic Bank Draft."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            policyData
                                                                                                                .summary
                                                                                                                .billingMethod
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-6 fieldLabel"
                                                                                                        title="Approved frequency of premium payments i.e.: monthly, quarterly, annually etc."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Billing
                                                                                                        Mode:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-6 fieldValue"
                                                                                                        title="Approved frequency of premium payments i.e.: monthly, quarterly, annually etc."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            policyData
                                                                                                                .summary
                                                                                                                .billingMode
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-6 fieldLabel"
                                                                                                        title="Amount that is to be paid to keep the policy active."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Premium
                                                                                                        Amount:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-6 fieldValue"
                                                                                                        title="Amount that is to be paid to keep the policy active."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {policyData.summary.premiumAmount.toLocaleString(
                                                                                                            "en-US",
                                                                                                            {
                                                                                                                style: "currency",
                                                                                                                currency:
                                                                                                                    "USD",
                                                                                                                minimumFractionDigits: 2,
                                                                                                                maximumFractionDigits: 2
                                                                                                            }
                                                                                                        )}
                                                                                                        <br></br>
                                                                                                        {cpContext.isCustomer &&
                                                                                                            policyData.onlinePaymentAllowed && (
                                                                                                                <a
                                                                                                                    href="#"
                                                                                                                    onClick={() =>
                                                                                                                        logger.loggedRequestNavigate(
                                                                                                                            "/payment",
                                                                                                                            "",
                                                                                                                            "Payment",
                                                                                                                            "",
                                                                                                                            false,
                                                                                                                            policyData,
                                                                                                                            idParsed
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    One-Time
                                                                                                                    Payment
                                                                                                                </a>
                                                                                                            )}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {utilities.parseDate(
                                                                                                    policyData
                                                                                                        .summary
                                                                                                        .premiumDate
                                                                                                ) >
                                                                                                    utilities.parseDate(
                                                                                                        "1900-01-01T00:00:00"
                                                                                                    ) &&
                                                                                                policyData
                                                                                                    .summary
                                                                                                    .status !=
                                                                                                    "Lapse Pending" ? (
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-6 fieldLabel"
                                                                                                            title="Date the next policy premium is due."
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            Next
                                                                                                            Payment
                                                                                                            Due
                                                                                                            Date:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-6 fieldValue"
                                                                                                            title="Date the next policy premium is due."
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {utilities.parseAndFormatDate(
                                                                                                                policyData
                                                                                                                    .summary
                                                                                                                    .premiumDate
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <>

                                                                                                    </>
                                                                                                )}
                                                                                                {utilities.parseDate(
                                                                                                    policyData
                                                                                                        .summary
                                                                                                        .graceNoticeDate
                                                                                                ) >
                                                                                                    utilities.parseDate(
                                                                                                        "1900-01-01T00:00:00"
                                                                                                    ) ||
                                                                                                policyData
                                                                                                    .summary
                                                                                                    .graceNoticeAmount >
                                                                                                    0 ? (
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className="col-12">
                                                                                                            <hr
                                                                                                                style={{
                                                                                                                    width: "95%"
                                                                                                                }}
                                                                                                            ></hr>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <>

                                                                                                    </>
                                                                                                )}

                                                                                                <>
                                                                                                    {policyData
                                                                                                        .summary
                                                                                                        .status !=
                                                                                                        PolicyStatus.Active && (
                                                                                                        <div
                                                                                                            className="row"
                                                                                                            style={{
                                                                                                                paddingTop: 5,
                                                                                                                paddingBottom: 5
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className="col-6 fieldLabel"
                                                                                                                title="The date the deficient amount for premium must be received in our office to avoid the loss of valuable coverage."
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {!policyData.summary.graceNoticeDate.includes(
                                                                                                                    "0001-01-01"
                                                                                                                ) ? (
                                                                                                                    <span>
                                                                                                                        Policy
                                                                                                                        Lapse
                                                                                                                        Date:
                                                                                                                    </span>
                                                                                                                ) : (
                                                                                                                    <>

                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-6 fieldValue"
                                                                                                                title="The date the deficient amount for premium must be received in our office to avoid the loss of valuable coverage."
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {!policyData.summary.graceNoticeDate.includes(
                                                                                                                    "0001-01-01"
                                                                                                                ) ? (
                                                                                                                    utilities.parseAndFormatDate(
                                                                                                                        policyData
                                                                                                                            .summary
                                                                                                                            .graceNoticeDate
                                                                                                                    )
                                                                                                                ) : (
                                                                                                                    <>

                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {policyData
                                                                                                        .summary
                                                                                                        .graceNoticeAmount >
                                                                                                    0 ? (
                                                                                                        <div
                                                                                                            className="row"
                                                                                                            style={{
                                                                                                                paddingTop: 5,
                                                                                                                paddingBottom: 5
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className="col-6 fieldLabel"
                                                                                                                title="Amount due less any Cash value applied plus the monthly cost of insurance and two future payments."
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                Grace
                                                                                                                Notice
                                                                                                                Amount:
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-6 fieldValue"
                                                                                                                title="Amount due less any Cash value applied plus the monthly cost of insurance and two future payments."
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {policyData.summary.graceNoticeAmount.toLocaleString(
                                                                                                                    "en-US",
                                                                                                                    {
                                                                                                                        style: "currency",
                                                                                                                        currency:
                                                                                                                            "USD",
                                                                                                                        minimumFractionDigits: 2,
                                                                                                                        maximumFractionDigits: 2
                                                                                                                    }
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <>

                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            </>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <>
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-12 fieldValue"
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "90%"
                                                                                    }}
                                                                                    tabIndex={0}
                                                                                >
                                                                                    {utilities.parseDate(
                                                                                        policyData
                                                                                            .summary
                                                                                            .policyAsOfDate
                                                                                    ) >
                                                                                    utilities.parseDate(
                                                                                        "1900-01-01T00:00:00"
                                                                                    ) ? (
                                                                                        <>
                                                                                            * Values
                                                                                            are
                                                                                            approximate
                                                                                            as of{" "}
                                                                                            {utilities.parseAndFormatDate(
                                                                                                policyData
                                                                                                    .summary
                                                                                                    .policyAsOfDate
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            * Values
                                                                                            are
                                                                                            approximate
                                                                                            as of{" "}
                                                                                            {utilities.parseAndFormatDate(
                                                                                                currentDateTime.toISOString()
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        {policyData.product
                                                                            .toLocaleLowerCase()
                                                                            .indexOf(
                                                                                "platinum assure"
                                                                            ) >= 0 &&
                                                                        policyData.summary
                                                                            .surrenderValue > 0 ? (
                                                                            <>
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-12 fieldValue"
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "90%"
                                                                                        }}
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        ** Cash
                                                                                        Surrender
                                                                                        Value may be
                                                                                        effected by
                                                                                        Market Value
                                                                                        Adjustment.
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                                {cpContext.isHO ? (
                                                    <>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 20,
                                                                color: "#1C2F67"
                                                            }}
                                                        >
                                                            <h2 className="homeOfficeHeadingToggle">
                                                                <span
                                                                    onClick={togglePolicyValuesFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    tabIndex={0}
                                                                >
                                                                    Policy Values&nbsp;&nbsp;
                                                                </span>
                                                                <span
                                                                    onClick={togglePolicyValuesFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    className={
                                                                        togglePolicyValues
                                                                            ? "bi bi-chevron-up"
                                                                            : "bi bi-chevron-down"
                                                                    }
                                                                    aria-hidden={true}
                                                                    tabIndex={0}
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </h2>
                                                        </div>
                                                        <Collapse in={togglePolicyValues}>
                                                            <div
                                                                className="row"
                                                                style={{ padding: 10 }}
                                                            >
                                                                <div className="col-12">
                                                                    <Card className="policyCard">
                                                                        <Card.Body className="shadow">
                                                                            <>
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="col-12 text-center"
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        <b>
                                                                                            Values
                                                                                            are as
                                                                                            of:
                                                                                        </b>
                                                                                        &nbsp;&nbsp;
                                                                                        {utilities.parseDate(
                                                                                            policyData
                                                                                                .summary
                                                                                                .policyAsOfDate
                                                                                        ) >
                                                                                        utilities.parseDate(
                                                                                            "1900-01-01T00:00:00"
                                                                                        ) ? (
                                                                                            <>
                                                                                                {utilities.parseAndFormatDate(
                                                                                                    policyData
                                                                                                        .summary
                                                                                                        .policyAsOfDate
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {utilities.parseAndFormatDate(
                                                                                                    currentDateTime.toISOString()
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div className="col-12 col-md-6">
                                                                                        {policyData.summary.carrierAdminSystem.toLowerCase() ===
                                                                                            CarrierAdminSystem.PAS ||
                                                                                        policyData.summary.carrierAdminSystem.toLowerCase() ===
                                                                                            CarrierAdminSystem.L70 ||
                                                                                        policyData.summary.carrierAdminSystem
                                                                                            .toLowerCase()
                                                                                            .includes(
                                                                                                CarrierAdminSystem.Vantage
                                                                                            ) ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="Funds that have accured from the payment of your premiums."
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    *
                                                                                                    Cash
                                                                                                    Value:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="Funds that have accured from the payment of your premiums."
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .cashValue
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {policyData.summary.carrierAdminSystem.toLowerCase() ===
                                                                                            CarrierAdminSystem.PAS ||
                                                                                        policyData.summary.carrierAdminSystem.toLowerCase() ===
                                                                                            CarrierAdminSystem.L70 ||
                                                                                        policyData.summary.carrierAdminSystem
                                                                                            .toLowerCase()
                                                                                            .includes(
                                                                                                CarrierAdminSystem.Vantage
                                                                                            ) ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="Fee assessed upon the cancellation of the policy less any indebtedness."
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    *
                                                                                                    Surrender
                                                                                                    Penalty:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="Fee assessed upon the cancellation of the policy less any indebtedness."
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {policyData
                                                                                                        .summary
                                                                                                        .surrenderCharge <
                                                                                                    0 ? (
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "black"
                                                                                                            }}
                                                                                                        >
                                                                                                            {utilities.formatCurrency(
                                                                                                                policyData
                                                                                                                    .summary
                                                                                                                    .surrenderCharge
                                                                                                            )}
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {utilities.formatCurrency(
                                                                                                                policyData
                                                                                                                    .summary
                                                                                                                    .surrenderCharge
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {policyData.summary.carrierAdminSystem.toLowerCase() ===
                                                                                            CarrierAdminSystem.PAS ||
                                                                                        policyData.summary.carrierAdminSystem
                                                                                            .toLowerCase()
                                                                                            .includes(
                                                                                                CarrierAdminSystem.Vantage
                                                                                            ) ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="Total amount due plus interest to payoff loan."
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    *
                                                                                                    Loan
                                                                                                    Payoff
                                                                                                    Amount:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="Total amount due plus interest to payoff loan."
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {policyData.summary.loanPayoff.toLocaleString(
                                                                                                        "en-US",
                                                                                                        {
                                                                                                            style: "currency",
                                                                                                            currency:
                                                                                                                "USD",
                                                                                                            minimumFractionDigits: 2,
                                                                                                            maximumFractionDigits: 2
                                                                                                        }
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {policyData.summary.carrierAdminSystem
                                                                                            .toLowerCase()
                                                                                            .includes(
                                                                                                CarrierAdminSystem.Vantage
                                                                                            ) &&
                                                                                        policyData.policyType.includes(
                                                                                            PolicyType.Annuity
                                                                                        ) ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="Market value adjustment for Home Office users only"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    *
                                                                                                    Market
                                                                                                    Value
                                                                                                    Adjustment:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="Market value adjustment for Home Office users only"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .mvaAmount
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {policyData.summary.carrierAdminSystem
                                                                                            .toLowerCase()
                                                                                            .includes(
                                                                                                CarrierAdminSystem.Vantage
                                                                                            ) &&
                                                                                        policyData.policyType.includes(
                                                                                            PolicyType.Annuity
                                                                                        ) ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="Minimum surrender benefit for Home Office users only"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    *
                                                                                                    Minimum
                                                                                                    Surrender
                                                                                                    Benefit:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="Minimum surrender benefit for Home Office users only"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .minSurrBenefitAmount
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {policyData.summary.carrierAdminSystem.toLowerCase() ===
                                                                                            CarrierAdminSystem.PAS ||
                                                                                        policyData.summary.carrierAdminSystem.toLowerCase() ===
                                                                                            CarrierAdminSystem.L70 ||
                                                                                        policyData.summary.carrierAdminSystem
                                                                                            .toLowerCase()
                                                                                            .includes(
                                                                                                CarrierAdminSystem.Vantage
                                                                                            ) ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="The amount you will received less any indebtedness or fee upon cancellation of your policy."
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {policyData.product
                                                                                                        .toLocaleLowerCase()
                                                                                                        .indexOf(
                                                                                                            "platinum assure"
                                                                                                        ) >=
                                                                                                    0 ? (
                                                                                                        <>
                                                                                                            **
                                                                                                            Cash
                                                                                                            Surrender
                                                                                                            Value:{" "}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            *
                                                                                                            Cash
                                                                                                            Surrender
                                                                                                            Value:{" "}
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="The amount you will received less any indebtedness or fee upon cancellation of your policy."
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {policyData
                                                                                                        .summary
                                                                                                        .surrenderValue <
                                                                                                    0 ? (
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "black"
                                                                                                            }}
                                                                                                        >
                                                                                                            {utilities.formatCurrency(
                                                                                                                policyData
                                                                                                                    .summary
                                                                                                                    .surrenderValue
                                                                                                            )}
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {utilities.formatCurrency(
                                                                                                                policyData
                                                                                                                    .summary
                                                                                                                    .surrenderValue
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {policyData.summary.carrierAdminSystem
                                                                                            .toLowerCase()
                                                                                            .includes(
                                                                                                CarrierAdminSystem.Vantage
                                                                                            ) &&
                                                                                        policyData.policyType.includes(
                                                                                            PolicyType.Annuity
                                                                                        ) ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="Guaranteed cash value for Home Office users only"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    *
                                                                                                    Guaranteed
                                                                                                    Minimum
                                                                                                    Value:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="Guaranteed cash value for Home Office users only"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .gmvAmount
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="col-12 col-md-6">
                                                                                        {(policyData.summary.carrierAdminSystem.toLowerCase() ===
                                                                                            CarrierAdminSystem.PAS ||
                                                                                            policyData.summary.carrierAdminSystem
                                                                                                .toLowerCase()
                                                                                                .includes(
                                                                                                    CarrierAdminSystem.Vantage
                                                                                                )) &&
                                                                                        showMaxLoanAmount() ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="The maximum loanable amount based on policy details"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    Maximum
                                                                                                    Loanable
                                                                                                    Amount:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="The maximum loanable amount based on policy details"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .maxLoanableAmount
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {showLoanInterestRate() ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="The existing loan interest rate"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    Loan
                                                                                                    Interest
                                                                                                    Rate:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="The existing loan interest rate"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {utilities.formatPercentage(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .loanInterestRate /
                                                                                                            100
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {/* !!free out amount is Penalty Free Amount */}
                                                                                        {policyData.summary.carrierAdminSystem
                                                                                            .toLowerCase()
                                                                                            .includes(
                                                                                                CarrierAdminSystem.Vantage
                                                                                            ) &&
                                                                                        policyData.policyType.includes(
                                                                                            PolicyType.Annuity
                                                                                        ) ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="Penalty free amount"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    Penalty
                                                                                                    Free
                                                                                                    Amount:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="Penalty free amount"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .freeOutAmount
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {(policyData.summary.carrierAdminSystem.toLowerCase() ===
                                                                                            CarrierAdminSystem.PAS ||
                                                                                            policyData.summary.carrierAdminSystem
                                                                                                .toLowerCase()
                                                                                                .includes(
                                                                                                    CarrierAdminSystem.Vantage
                                                                                                )) &&
                                                                                        showMaxPartialSurrenderAmount() ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="Maximum partial surrender amount"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    Maximum
                                                                                                    Partial
                                                                                                    Surrender
                                                                                                    Amount:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="Maximum partial surrender amount"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .maxPartialSurrAmount
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {(policyData.summary.carrierAdminSystem.toLowerCase() ===
                                                                                            CarrierAdminSystem.PAS ||
                                                                                            policyData.summary.carrierAdminSystem
                                                                                                .toLowerCase()
                                                                                                .includes(
                                                                                                    CarrierAdminSystem.Vantage
                                                                                                )) &&
                                                                                        showNextAnniversaryCOIAmount() ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="Next anniversary cost of insurance amount"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    Next
                                                                                                    Anniversary
                                                                                                    Cost
                                                                                                    of
                                                                                                    Insurance
                                                                                                    Amount:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="Next anniversary cost of insurance amount"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .nextAnnivCOIAmount
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {policyData.summary.carrierAdminSystem
                                                                                            .toLowerCase()
                                                                                            .includes(
                                                                                                CarrierAdminSystem.Vantage
                                                                                            ) &&
                                                                                        showLastMonthlyCOIAmount() ? (
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-9 col-md-8 col-lg-7 fieldLabel"
                                                                                                    title="Last monthly cost of insurance amount"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    Last
                                                                                                    Monthly
                                                                                                    Cost
                                                                                                    of
                                                                                                    Insurance
                                                                                                    Amount:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-3 col-md-4 col-lg-5 fieldValue"
                                                                                                    title="Last monthly cost of insurance amount"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {utilities.formatCurrency(
                                                                                                        policyData
                                                                                                            .summary
                                                                                                            .lastMonthlyCOICharge
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        </Collapse>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 20, color: "#1C2F67" }}
                                                >
                                                    <h2 className="homeOfficeHeadingToggle">
                                                        <span
                                                            onClick={toggleClientFunc}
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "#1C2F67",
                                                                fontWeight: "bold"
                                                            }}
                                                            tabIndex={0}
                                                        >
                                                            Client&nbsp;&nbsp;
                                                        </span>
                                                        <span
                                                            onClick={toggleClientFunc}
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "#1C2F67",
                                                                fontWeight: "bold"
                                                            }}
                                                            className={
                                                                toggleClient
                                                                    ? "bi bi-chevron-up"
                                                                    : "bi bi-chevron-down"
                                                            }
                                                            aria-hidden={true}
                                                            tabIndex={0}
                                                        >
                                                            &nbsp;
                                                        </span>
                                                    </h2>
                                                </div>
                                                <Collapse in={toggleClient}>
                                                    <div className="row" style={{ padding: 10 }}>
                                                        <div className="col-12">
                                                            <div className="card policyCard">
                                                                <div className="card-body shadow">
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5
                                                                        }}
                                                                    >
                                                                        <div className="col-12 text-center text-md-start fieldLabel">
                                                                            {policyData.productType.toLowerCase() ==
                                                                            "annuity" ? (
                                                                                <>Annuitant:</>
                                                                            ) : (
                                                                                <>Insured:</>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5
                                                                        }}
                                                                    >
                                                                        <div className="col-12 offset-md-2 col-md-10 col-lg-10 col-xl-10">
                                                                            <div
                                                                                className="row"
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5
                                                                                }}
                                                                            >
                                                                                <div className="col-12 col-md-12 col-lg-12 col-xl-7">
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                            title="The name of the insured person."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Name:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .insuredName
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                            title="The email address for the insured person."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Email:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                            title="The email address for the insured person."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .insuredEmail
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                            title="The mailing address of the insured person."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Address:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                            title="The mailing address of the insured person."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .insuredAddress
                                                                                            }
                                                                                            <br />
                                                                                            {policyData
                                                                                                .client
                                                                                                .insuredAttentionLine &&
                                                                                                policyData
                                                                                                    .client
                                                                                                    .insuredAttentionLine !==
                                                                                                    "" && (
                                                                                                    <>
                                                                                                        {
                                                                                                            policyData
                                                                                                                .client
                                                                                                                .insuredAttentionLine
                                                                                                        }
                                                                                                        <br />
                                                                                                    </>
                                                                                                )}
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .insuredAddress2
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-md-12 col-lg-12 col-xl-5">
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                            title="Gender of the insured person (male/female)."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Gender:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                            title="Gender of the insured person (male/female)."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .insuredGender
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                            title="The age of the insured at policy issue."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Age:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                            title="The age of the insured at policy issue."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .insuredAge
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                            title="The date of birth for the insured person."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            DOB:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                            title="The date of birth for the insured person."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .insuredDOB
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5
                                                                        }}
                                                                    >
                                                                        <div className="col-12 offset-md-2 col-md-10">
                                                                            <hr></hr>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="col-12 text-center text-md-start fieldLabel"
                                                                            tabIndex={0}
                                                                        >
                                                                            Owner:
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5
                                                                        }}
                                                                    >
                                                                        <div className="col-12 offset-md-2 col-md-10 col-lg-10 col-xl-10">
                                                                            <div
                                                                                className="row"
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5
                                                                                }}
                                                                            >
                                                                                <div className="col-12 col-md-12 col-lg-12 col-xl-7">
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                            title="The name of the insured person."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Name:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .ownerName
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                            title="The email address for the policy owner."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Email:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                            title="The email address for the policy owner."
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .ownerEmail
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            Address:
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .ownerAddress
                                                                                            }
                                                                                            <br />
                                                                                            {policyData
                                                                                                .client
                                                                                                .ownerAttentionLine &&
                                                                                                policyData
                                                                                                    .client
                                                                                                    .ownerAttentionLine !==
                                                                                                    "" && (
                                                                                                    <>
                                                                                                        {
                                                                                                            policyData
                                                                                                                .client
                                                                                                                .ownerAttentionLine
                                                                                                        }
                                                                                                        <br />
                                                                                                    </>
                                                                                                )}
                                                                                            {
                                                                                                policyData
                                                                                                    .client
                                                                                                    .ownerAddress2
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5
                                                                        }}
                                                                    >
                                                                        <div className="col-12 offset-md-2 col-md-10">
                                                                            <hr></hr>
                                                                        </div>
                                                                    </div>
                                                                    {policyData &&
                                                                    policyData.client &&
                                                                    policyData.client.payorName &&
                                                                    policyData.client.payorName
                                                                        .length > 3 &&
                                                                    policyData.productType.toLowerCase() !==
                                                                        "annuity" ? (
                                                                        <>
                                                                            <div
                                                                                className="row"
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="col-12 text-center text-md-start fieldLabel"
                                                                                    tabIndex={0}
                                                                                >
                                                                                    Payor:
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="row"
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5
                                                                                }}
                                                                            >
                                                                                <div className="col-12 offset-md-2 col-md-10 col-lg-10 col-xl-10">
                                                                                    <div
                                                                                        className="row"
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            paddingBottom: 5
                                                                                        }}
                                                                                    >
                                                                                        <div className="col-12 col-md-12 col-lg-12 col-xl-7">
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                                    title="The name of the listed payor."
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    Name:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        policyData
                                                                                                            .client
                                                                                                            .payorName
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {policyData
                                                                                                .client
                                                                                                .payorEmail &&
                                                                                            policyData
                                                                                                .client
                                                                                                .payorEmail
                                                                                                .length >
                                                                                                0 ? (
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                                        title="The email address for the listed payor."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Email:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                                        title="The email address for the listed payor."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            policyData
                                                                                                                .client
                                                                                                                .payorEmail
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <>

                                                                                                </>
                                                                                            )}
                                                                                            {policyData
                                                                                                .client
                                                                                                .payorAddress &&
                                                                                            policyData
                                                                                                .client
                                                                                                .payorAddress
                                                                                                .length >
                                                                                                0 ? (
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-3 col-lg-3 col-xl-4 col-xxl-4 fieldLabel"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Address:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-8 fieldValue"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            policyData
                                                                                                                .client
                                                                                                                .payorAddress
                                                                                                        }
                                                                                                        <br />
                                                                                                        {policyData
                                                                                                            .client
                                                                                                            .ownerAttentionLine &&
                                                                                                            policyData
                                                                                                                .client
                                                                                                                .payerAttentionLine !==
                                                                                                                "" && (
                                                                                                                <>
                                                                                                                    {
                                                                                                                        policyData
                                                                                                                            .client
                                                                                                                            .payerAttentionLine
                                                                                                                    }
                                                                                                                    <br />
                                                                                                                </>
                                                                                                            )}
                                                                                                        {
                                                                                                            policyData
                                                                                                                .client
                                                                                                                .payorAddress2
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <>

                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="row"
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5
                                                                                }}
                                                                            >
                                                                                <div className="col-12 offset-md-2 col-md-10">
                                                                                    <hr></hr>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}

                                                                    {policyData &&
                                                                    policyData.client &&
                                                                    policyData.client.beneficiaries
                                                                        .length > 0 ? (
                                                                        <>
                                                                            <div
                                                                                className="row"
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="col-12 text-center text-md-start fieldLabel"
                                                                                    tabIndex={0}
                                                                                >
                                                                                    Beneficiaries:
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="row"
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5
                                                                                }}
                                                                            >
                                                                                <div className="d-none d-lg-block col-12 offset-md-2 col-md-10">
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-md-12 col-lg-5">
                                                                                            <div className="row">
                                                                                                <div
                                                                                                    className="col-12 fieldLabel"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    Beneficiary
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-md-12 col-lg-3">
                                                                                            <div className="row">
                                                                                                <div
                                                                                                    className="col-12 fieldLabel"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    Percentage
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-md-12 col-lg-4">
                                                                                            <div className="row">
                                                                                                <div
                                                                                                    className="col-12 fieldLabel"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                >
                                                                                                    Relation
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {policyData.client.beneficiaries.map(
                                                                                (b, idx) => (
                                                                                    <>
                                                                                        <div
                                                                                            className="row"
                                                                                            key={
                                                                                                idx
                                                                                            }
                                                                                        >
                                                                                            <div className="col-12 offset-md-2 col-md-10">
                                                                                                <div className="row">
                                                                                                    <div className="col-12 col-lg-5">
                                                                                                        <div
                                                                                                            className="row"
                                                                                                            style={{
                                                                                                                paddingTop: 5,
                                                                                                                paddingBottom: 5
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className="d-block d-lg-none col-12 fieldLabel"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                Beneficiary:
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-12 fieldValue"
                                                                                                                title="The name for the listed beneficiary."
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    b.beneficiaryName
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {b
                                                                                                            .beneficiaryAddress
                                                                                                            .length >
                                                                                                        0 ? (
                                                                                                            <div
                                                                                                                className="row"
                                                                                                                style={{
                                                                                                                    paddingTop: 5,
                                                                                                                    paddingBottom: 5
                                                                                                                }}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="col-12 fieldValue"
                                                                                                                    title="The address for the listed beneficiary."
                                                                                                                    tabIndex={
                                                                                                                        0
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        b.beneficiaryAddress
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <>

                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="d-block d-lg-none col-12 fieldLabel"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Percentage:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-lg-3 fieldValue"
                                                                                                        title="The percentage for the listed beneficiary."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            b.beneficiaryPercentage
                                                                                                        }

                                                                                                        %
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="d-block d-lg-none col-12 fieldLabel"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Relation:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-lg-4 fieldValue"
                                                                                                        title="The relation for the listed beneficiary."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            b.beneficiaryRelation
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {policyData
                                                                                            .client
                                                                                            .beneficiaries
                                                                                            .length >
                                                                                            1 &&
                                                                                            idx <
                                                                                                policyData
                                                                                                    .client
                                                                                                    .beneficiaries
                                                                                                    .length -
                                                                                                    1 && (
                                                                                                <div className="row d-block d-lg-none">
                                                                                                    <div className="col-12 offset-md-2 col-md-10">
                                                                                                        <hr></hr>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                    </>
                                                                                )
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                                {policyData &&
                                                policyData.coverages &&
                                                policyData.coverages.length > 0 ? (
                                                    <>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 20,
                                                                color: "#1C2F67"
                                                            }}
                                                        >
                                                            <h2 className="homeOfficeHeadingToggle">
                                                                <span
                                                                    onClick={toggleCoverageFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    tabIndex={0}
                                                                >
                                                                    Coverage&nbsp;&nbsp;
                                                                </span>
                                                                <span
                                                                    onClick={toggleCoverageFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    className={
                                                                        toggleCoverage
                                                                            ? "bi bi-chevron-up"
                                                                            : "bi bi-chevron-down"
                                                                    }
                                                                    aria-hidden={true}
                                                                    tabIndex={0}
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </h2>
                                                        </div>
                                                        <Collapse in={toggleCoverage}>
                                                            <div
                                                                className="row"
                                                                style={{ padding: 10 }}
                                                            >
                                                                <>
                                                                    {policyData &&
                                                                    policyData.coverages ? (
                                                                        <>
                                                                            {policyData.coverages.map(
                                                                                (c, idx) => (
                                                                                    <div
                                                                                        className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4"
                                                                                        key={idx}
                                                                                    >
                                                                                        <div className="card policyCard coverageCard">
                                                                                            <div className="card-body shadow">
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12"
                                                                                                        title="Type of coverage provided."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            c.coverageName
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-6 fieldLabel"
                                                                                                        title="The benefit payable upon death including any additional rider benefits."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Death
                                                                                                        Benefit:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-6 fieldValue"
                                                                                                        title="The benefit payable upon death including any additional rider benefits."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {c.faceAmount <
                                                                                                        0 ? (
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "black"
                                                                                                                }}
                                                                                                            >
                                                                                                                {utilities.formatCurrency(
                                                                                                                    c.faceAmount
                                                                                                                )}
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {utilities.formatCurrency(
                                                                                                                    c.faceAmount
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {utilities.parseDate(
                                                                                                    c.expiryDate
                                                                                                ) >
                                                                                                    utilities.parseDate(
                                                                                                        "1900-01-01T00:00:00"
                                                                                                    ) &&
                                                                                                utilities
                                                                                                    .parseDate(
                                                                                                        c.expiryDate
                                                                                                    )
                                                                                                    .toISOString() !==
                                                                                                    utilities
                                                                                                        .parseDate(
                                                                                                            "2199-01-01T00:00:00"
                                                                                                        )
                                                                                                        .toISOString() ? (
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-6 fieldLabel"
                                                                                                            title="The date the coverage expires."
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            Expiry
                                                                                                            Date:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-6 fieldValue"
                                                                                                            title="The date the coverage expires."
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {utilities.parseAndFormatDate(
                                                                                                                c.expiryDate
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <>

                                                                                                    </>
                                                                                                )}
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-6 fieldLabel"
                                                                                                        title="The current status of each coverage."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Coverage
                                                                                                        Status:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-6 fieldValue"
                                                                                                        title="The current status of each coverage."
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            c.coverageStatus
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <label tabIndex={0}>
                                                                                No Coverages
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </>
                                                            </div>
                                                        </Collapse>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 20, color: "#1C2F67" }}
                                                >
                                                    <h2 className="homeOfficeHeadingToggle">
                                                        <span
                                                            onClick={toggleFinancialFunc}
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "#1C2F67",
                                                                fontWeight: "bold"
                                                            }}
                                                            tabIndex={0}
                                                        >
                                                            Financial&nbsp;&nbsp;
                                                        </span>
                                                        <span
                                                            onClick={toggleFinancialFunc}
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "#1C2F67",
                                                                fontWeight: "bold"
                                                            }}
                                                            className={
                                                                toggleFinancial
                                                                    ? "bi bi-chevron-up"
                                                                    : "bi bi-chevron-down"
                                                            }
                                                            aria-hidden={true}
                                                            tabIndex={0}
                                                        >
                                                            &nbsp;
                                                        </span>
                                                    </h2>
                                                </div>
                                                <Collapse in={toggleFinancial}>
                                                    <div className="row" style={{ padding: 10 }}>
                                                        <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-5">
                                                            <div className="card policyCard">
                                                                <div
                                                                    className="card-body shadow"
                                                                    style={{ minHeight: 160 }}
                                                                >
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="col-12"
                                                                            style={{
                                                                                textDecoration:
                                                                                    "underline",
                                                                                color: "#1C2F67",
                                                                                textTransform:
                                                                                    "uppercase",
                                                                                fontWeight: "bold"
                                                                            }}
                                                                        >
                                                                            Financial Activity
                                                                        </div>
                                                                    </div>
                                                                    <PolicyFinancialActivity
                                                                        policyData={policyData}
                                                                        activityTypes={[]}
                                                                        showLastSixMonthsOrDefault={
                                                                            true
                                                                        }
                                                                    ></PolicyFinancialActivity>
                                                                </div>
                                                            </div>
                                                            {policyData &&
                                                            policyData.policyType ===
                                                                "universal" ? (
                                                                <span
                                                                    style={{ fontSize: "75%" }}
                                                                    tabIndex={0}
                                                                >
                                                                    * COI is the actual cost of the
                                                                    insurance monthly. This cost
                                                                    must be covered by either paid
                                                                    premiums, existing cash value,
                                                                    or a combination of both. The
                                                                    cost of insurance increases
                                                                    annually on the anniversary of
                                                                    the policy.
                                                                </span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7">
                                                            <div className="card policyCard">
                                                                <div
                                                                    className="card-body shadow"
                                                                    style={{ minHeight: 160 }}
                                                                >
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="col-12"
                                                                            style={{
                                                                                textDecoration:
                                                                                    "underline",
                                                                                color: "#1C2F67",
                                                                                textTransform:
                                                                                    "uppercase",
                                                                                fontWeight: "bold"
                                                                            }}
                                                                        >
                                                                            Disbursement Activity
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="col-5 fieldLabel px-1 px-sm-3"
                                                                            title="The date the transaction was completed."
                                                                            tabIndex={0}
                                                                        >
                                                                            Last Disbursement
                                                                            Activity
                                                                        </div>
                                                                        <div
                                                                            className="col-3 fieldLabel px-1 px-sm-3"
                                                                            title="Type of transaction requested."
                                                                            tabIndex={0}
                                                                        >
                                                                            Type
                                                                        </div>
                                                                        <div
                                                                            className="col-4 fieldLabel text-end px-1 px-sm-3"
                                                                            title="Amount of funds disbursed."
                                                                            tabIndex={0}
                                                                        >
                                                                            Amount
                                                                        </div>
                                                                    </div>
                                                                    <>
                                                                        {policyData &&
                                                                        policyData.financial &&
                                                                        policyData.financial
                                                                            .disbursementActivity ? (
                                                                            <>
                                                                                {policyData.financial.disbursementActivity.map(
                                                                                    (d, idx) => (
                                                                                        <div
                                                                                            className="row"
                                                                                            style={{
                                                                                                paddingTop: 5,
                                                                                                paddingBottom: 5
                                                                                            }}
                                                                                            key={
                                                                                                idx
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                className="col-5 fieldValue px-1 px-sm-3"
                                                                                                title="The date the transaction was completed."
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                {utilities.parseAndFormatDate(
                                                                                                    d.disbursementDate
                                                                                                )}
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-3 fieldValue px-1 px-sm-3"
                                                                                                title="Type of transaction requested."
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    d.activityType
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-4 fieldValue text-end px-1 px-sm-3"
                                                                                                title="Amount of funds disbursed."
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                {d.activityAmount <
                                                                                                0 ? (
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "black"
                                                                                                        }}
                                                                                                    >
                                                                                                        {utilities.formatCurrency(
                                                                                                            d.activityAmount
                                                                                                        )}
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {utilities.formatCurrency(
                                                                                                            d.activityAmount
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <label tabIndex={0}>
                                                                                    No Activity
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapse>
                                                {showFundBalances() ? (
                                                    <>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 20,
                                                                color: "#1C2F67"
                                                            }}
                                                        >
                                                            <h2 className="homeOfficeHeadingToggle">
                                                                <span
                                                                    onClick={toggleFundFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    tabIndex={0}
                                                                >
                                                                    Fund Balances&nbsp;&nbsp;
                                                                </span>
                                                                <span
                                                                    onClick={toggleFundFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    className={
                                                                        toggleFund
                                                                            ? "bi bi-chevron-up"
                                                                            : "bi bi-chevron-down"
                                                                    }
                                                                    aria-hidden={true}
                                                                    tabIndex={0}
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </h2>
                                                        </div>
                                                        <Collapse in={toggleFund}>
                                                            <div
                                                                className="row"
                                                                style={{ padding: 10 }}
                                                            >
                                                                <div className="col-12">
                                                                    <div className="card policyCard">
                                                                        <div
                                                                            className="card-body shadow"
                                                                            style={{
                                                                                minHeight: 160
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="row"
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="col-3 fieldLabel"
                                                                                    tabIndex={0}
                                                                                >
                                                                                    Fund Number
                                                                                </div>
                                                                                <div
                                                                                    className="col-6 fieldLabel"
                                                                                    title="Equity Indexed Annuity fund option."
                                                                                    tabIndex={0}
                                                                                >
                                                                                    Fund Name
                                                                                </div>
                                                                                <div
                                                                                    className="col-3 fieldLabel text-end"
                                                                                    title="Amount of contract value allocated to a specific index fund option."
                                                                                    tabIndex={0}
                                                                                >
                                                                                    Fund Balance
                                                                                </div>
                                                                            </div>
                                                                            <>
                                                                                {policyData &&
                                                                                policyData.fundBalances ? (
                                                                                    <>
                                                                                        {policyData.fundBalances.map(
                                                                                            (
                                                                                                b,
                                                                                                idx
                                                                                            ) => (
                                                                                                <>
                                                                                                    {b.fundBalance ==
                                                                                                    0 ? (
                                                                                                        <>

                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <div
                                                                                                            className="row"
                                                                                                            style={{
                                                                                                                paddingTop: 5,
                                                                                                                paddingBottom: 5
                                                                                                            }}
                                                                                                            key={
                                                                                                                idx
                                                                                                            }
                                                                                                        >
                                                                                                            <div
                                                                                                                className="col-3 fieldValue"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    b.fundNumber
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-6 fieldValue"
                                                                                                                title="Equity Indexed Annuity fund option."
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    b.fundName
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-3 fieldValue text-end"
                                                                                                                title="Amount of contract value allocated to a specific index fund option."
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {b.fundBalance <
                                                                                                                0 ? (
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color: "black"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {utilities.formatCurrency(
                                                                                                                            b.fundBalance
                                                                                                                        )}
                                                                                                                    </span>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {utilities.formatCurrency(
                                                                                                                            b.fundBalance
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            )
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <label
                                                                                            tabIndex={
                                                                                                0
                                                                                            }
                                                                                        >
                                                                                            No
                                                                                            Activity
                                                                                        </label>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Collapse>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                            </>
                                        )}
                                    </section>
                                </Tab>
                                <Tab eventKey="docs" title="Documents">
                                    {tabKey === "docs" && (
                                        <PolicyDocuments
                                            idParsed={idParsed}
                                            policyData={policyData}
                                            setErrorMessage={setErrorMessage}
                                        />
                                    )}
                                </Tab>
                                <Tab eventKey="faqs" title="FAQs">
                                    <section aria-labelledby="faqs-section">
                                        <h2
                                            style={{ display: "none" }}
                                            aria-labelledby="faqs-section"
                                        >
                                            FAQs Section
                                        </h2>
                                        {isProcessingFAQs ? (
                                            <Spinner imageWidth={20} aria-label="Loading" />
                                        ) : (
                                            <>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <div className="col-8" tabIndex={0}>
                                                        <h3>Frequently Asked Questions</h3>
                                                    </div>
                                                    <div className="col-4" tabIndex={0}>
                                                        <div className="inner-addon right-addon">
                                                            <i className="biCustom bi-search"></i>
                                                            <input
                                                                type="text"
                                                                name="faqSearch"
                                                                value={faqSearch}
                                                                placeholder="Search..."
                                                                className="form-control"
                                                                onChange={(e) =>
                                                                    setFAQSearch(e.target.value)
                                                                }
                                                                onKeyPress={handleKeywordKeyPress}
                                                                aria-label="Search input"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <div className="col-12" tabIndex={0}>
                                                        {faqs && faqs.length > 0 ? (
                                                            <Accordion
                                                                style={{ backgroundColor: "#eee" }}
                                                                id="faqAccordion"
                                                                defaultActiveKey={
                                                                    faqs.filter(
                                                                        (x) =>
                                                                            x.tagName ==
                                                                            faqTagParsed
                                                                    ).length > 0
                                                                        ? faqs
                                                                              .filter(
                                                                                  (x) =>
                                                                                      x.tagName ==
                                                                                      faqTagParsed
                                                                              )[0]
                                                                              .id.toString()
                                                                        : ""
                                                                }
                                                            >
                                                                {faqsFiltered.map((faq) => (
                                                                    <Accordion.Item
                                                                        key={faq.id}
                                                                        eventKey={faq.id.toString()}
                                                                        style={{
                                                                            backgroundColor: "#eee",
                                                                            borderStyle: "none"
                                                                        }}
                                                                        onClick={() =>
                                                                            logFAQClick(
                                                                                faq.id,
                                                                                faq.question
                                                                            )
                                                                        }
                                                                    >
                                                                        <Accordion.Header
                                                                            style={{
                                                                                backgroundColor:
                                                                                    "#eee",
                                                                                color: "#000",
                                                                                boxShadow: "none"
                                                                            }}
                                                                            tabIndex={0}
                                                                        >
                                                                            {faq.question}
                                                                        </Accordion.Header>
                                                                        <Accordion.Body
                                                                            tabIndex={0}
                                                                        >
                                                                            <Card>
                                                                                <Card.Body>
                                                                                    <div
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: faq.response
                                                                                        }}
                                                                                    ></div>
                                                                                    {faq.tagName ==
                                                                                    faqTagParsed ? (
                                                                                        <div id="faqTag">
                                                                                            &nbsp;
                                                                                        </div>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                    <a href="#faqTag">
                                                                                        &nbsp;
                                                                                    </a>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                ))}
                                                            </Accordion>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </section>
                                </Tab>
                                <Tab eventKey="service" title="Requests">
                                    <section aria-labelledby="requests-section">
                                        <h2
                                            style={{ display: "none" }}
                                            aria-labelledby="requests-section"
                                        >
                                            Requests Section
                                        </h2>
                                        {isProcessingRequests ? (
                                            <Spinner imageWidth={20} aria-label="Loading" />
                                        ) : (
                                            <>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <div className="col-12" tabIndex={0}>
                                                        <h3
                                                            style={{ color: "#003366" }}
                                                            tabIndex={0}
                                                        >
                                                            <b>What would you like to do?</b>
                                                        </h3>
                                                        {requestError &&
                                                        requestError.length > 0 &&
                                                        !trashIconClicked ? (
                                                            <>
                                                                <label
                                                                    className="text-danger"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: requestError
                                                                    }}
                                                                ></label>
                                                                <br />
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <div className="col-12">
                                                        {requestTypes && requestTypes.length > 0 ? (
                                                            <Container>
                                                                <Row xs={1} md={2} className="g-4">
                                                                    {requestTypes.map(
                                                                        (req, idx) => (
                                                                            <Col key={idx}>
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div className="col-12">
                                                                                        {/* <u><b>{req.transactionLongName}</b></u><br></br>
                                                                                        TransactionURI: {req.transactionURI}<br></br>
                                                                                        TransactionURI: {req.transactionURI?.length}<br></br>
                                                                                        TransactionType: {req.transactionType}<br></br>
                                                                                        FormNumber:{req.formNumber}<br></br>
                                                                                        Max days since issue:{req.maxDaysAfterIssue}<br></br>
                                                                                        Min days since issue:{req.minDaysAfterIssue}<br></br>
                                                                                        Previous Requests:{req.previousRequests}<br></br>
                                                                                        Previous Requests 24hr:{req.previousRequests24Hours}<br></br>
                                                                                        hasASGN:{policyData.hasASGN.toString()}<br></br>
                                                                                        daysSinceIssue:{policyData.daysSinceIssue.toString()}<br></br> */}
                                                                                        {requestTypeDisplay(
                                                                                            req
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    )}
                                                                </Row>
                                                            </Container>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ paddingTop: 30, paddingBottom: 5 }}
                                                >
                                                    <div className="col-12" tabIndex={0}>
                                                        <h3
                                                            style={{ color: "#003366" }}
                                                            tabIndex={0}
                                                        >
                                                            <b id="request-history">
                                                                Request History
                                                            </b>
                                                        </h3>
                                                        {requestError &&
                                                            requestError.length > 0 &&
                                                            trashIconClicked && (
                                                                <p
                                                                    className="text-danger"
                                                                    style={{ marginBottom: "0" }}
                                                                >
                                                                    {requestError}
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="row" style={{ padding: 10 }}>
                                                    <div className="col-12">
                                                        <div className="card">
                                                            <div className="card-body shadow">
                                                                <div
                                                                    className="row"
                                                                    style={{
                                                                        paddingTop: 5,
                                                                        paddingBottom: 5
                                                                    }}
                                                                >
                                                                    <div className="col-12 col-md-12 col-lg-10 col-xl-10">
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                        >
                                                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldLabel"
                                                                                        title="Type"
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        Type:
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldLabel"
                                                                                        title="Status"
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        Status:
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 fieldLabel"
                                                                                        title="Receipt Date"
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        Receipt
                                                                                        Date:
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 fieldLabel"
                                                                                        title="Complete Date"
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        Complete
                                                                                        Date:
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1 fieldLabel"
                                                                                        title=""
                                                                                        tabIndex={0}
                                                                                    >
                                                                                        &nbsp;
                                                                                    </div>
                                                                                </div>
                                                                                <>
                                                                                    {requestHistory &&
                                                                                    requestHistory.length >
                                                                                        0 ? (
                                                                                        <>
                                                                                            {requestHistory.map(
                                                                                                (
                                                                                                    h,
                                                                                                    idx
                                                                                                ) => (
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={
                                                                                                            h.requestStatus ==
                                                                                                            "Incomplete"
                                                                                                                ? {
                                                                                                                      color: "#dc3545"
                                                                                                                  }
                                                                                                                : {
                                                                                                                      paddingTop: 5,
                                                                                                                      paddingBottom: 5
                                                                                                                  }
                                                                                                        }
                                                                                                        key={
                                                                                                            idx
                                                                                                        }
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-3 fieldValue"
                                                                                                            title="Type"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                h.requestType
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-3 fieldValue"
                                                                                                            title="Status"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                h.requestStatus
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-3 fieldValue"
                                                                                                            title="Receipt Date"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {utilities.parseAndFormatDate(
                                                                                                                h.receiptDate
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-2 fieldValue"
                                                                                                            title="Complete Date"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {utilities.parseDate(
                                                                                                                h.completeDate
                                                                                                            ) >
                                                                                                            utilities.parseDate(
                                                                                                                "1900-01-01T00:00:00"
                                                                                                            ) ? (
                                                                                                                utilities.parseAndFormatDate(
                                                                                                                    h.completeDate
                                                                                                                )
                                                                                                            ) : (
                                                                                                                <>

                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-1 fieldValue"
                                                                                                            title="Cancel"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {(h.requestType ==
                                                                                                                "Cancellation" &&
                                                                                                                utilities.parseDate(
                                                                                                                    h.completeDate
                                                                                                                ) <=
                                                                                                                    utilities.parseDate(
                                                                                                                        "1900-01-01T00:00:00"
                                                                                                                    )) ||
                                                                                                            h.requestType ==
                                                                                                                "Bank Draft Date Change" ? (
                                                                                                                <>
                                                                                                                    <a
                                                                                                                        href={
                                                                                                                            "#"
                                                                                                                        }
                                                                                                                        onClick={() => {
                                                                                                                            cancelRequest(
                                                                                                                                h.requestId,
                                                                                                                                h.requestType
                                                                                                                            );
                                                                                                                            setTrashIconClicked(
                                                                                                                                true
                                                                                                                            );
                                                                                                                        }}
                                                                                                                        className="bi bi-trash"
                                                                                                                        title="Trash"
                                                                                                                    ></a>
                                                                                                                    &nbsp;
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>

                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <label
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                No
                                                                                                History
                                                                                            </label>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </section>
                                </Tab>
                                {cpContext.isHO || cpContext.isAdmin ? (
                                    <Tab eventKey="homeoffice" title="Home Office" tabIndex={0}>
                                        <section aria-labelledby="home-office-section">
                                            <h2
                                                style={{ display: "none" }}
                                                aria-labelledby="home-office-section"
                                            >
                                                Home Office Section
                                            </h2>
                                            {isProcessingHO ? (
                                                <Spinner imageWidth={20} aria-label="Loading" />
                                            ) : (
                                                <>
                                                    {hoPolicyData &&
                                                    hoPolicyData.hasPastFailedGiact ? (
                                                        <div>
                                                            <span className="text-danger">
                                                                **Prior GIACT failed attempt**
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 20, color: "#1C2F67" }}
                                                    >
                                                        <button
                                                            id="securityCheckCollapse"
                                                            className="homeOfficeCollapse"
                                                            aria-expanded={toggleHOSecurityCheck}
                                                            onClick={() =>
                                                                utilities.setAriaExpanded(
                                                                    "securityCheckCollapse",
                                                                    !toggleHOSecurityCheck
                                                                )
                                                            }
                                                        >
                                                            <h2 className="homeOfficeHeadingToggle">
                                                                <span
                                                                    onClick={
                                                                        toggleHOSecurityCheckFunc
                                                                    }
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    tabIndex={0}
                                                                >
                                                                    Security Check
                                                                    Information&nbsp;&nbsp;
                                                                </span>
                                                                <span
                                                                    onClick={
                                                                        toggleHOSecurityCheckFunc
                                                                    }
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    tabIndex={0}
                                                                    className={
                                                                        toggleHOSecurityCheck
                                                                            ? "bi bi-chevron-up"
                                                                            : "bi bi-chevron-down"
                                                                    }
                                                                    aria-hidden={true}
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </h2>
                                                        </button>
                                                    </div>
                                                    <Collapse
                                                        in={toggleHOSecurityCheck}
                                                        role="region"
                                                        aria-labelledby="securityCheckCollapse"
                                                    >
                                                        <div
                                                            className="row"
                                                            style={{ padding: 10 }}
                                                        >
                                                            <div className="col-12">
                                                                <div className="card securityCard">
                                                                    <div className="card-body shadow">
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                        >
                                                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                                                {hoPolicyData &&
                                                                                hoPolicyData.policyDWKey ? (
                                                                                    <>
                                                                                        <div
                                                                                            className="row"
                                                                                            style={{
                                                                                                paddingTop: 5,
                                                                                                paddingBottom: 5
                                                                                            }}
                                                                                        >
                                                                                            <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Owner Name"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Owner
                                                                                                        Name:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        <span title="Owner Name">
                                                                                                            {
                                                                                                                hoPolicyData.ownerName
                                                                                                            }
                                                                                                        </span>
                                                                                                        {hoPolicyData.isCustomerPortalRegistered ? (
                                                                                                            <>
                                                                                                                <span
                                                                                                                    className="bi bi-check2-circle"
                                                                                                                    title="Customer Registered"
                                                                                                                    style={{
                                                                                                                        color: "green",
                                                                                                                        paddingLeft:
                                                                                                                            "5px"
                                                                                                                    }}
                                                                                                                ></span>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>

                                                                                                            </>
                                                                                                        )}
                                                                                                        {hoPolicyData.electronicDocumentDelivery ? (
                                                                                                            <>
                                                                                                                <i
                                                                                                                    className="ms-2 bi bi-envelope-at-fill eDelivery-flag"
                                                                                                                    title="Document eDelivery Flag"
                                                                                                                ></i>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>

                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Owner Social Security Number"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Owner
                                                                                                        SSN:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Owner Social Security Number"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            hoPolicyData.ownerGovtID
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Date of Birth"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {hoPolicyData.productType.toLowerCase() ==
                                                                                                        "annuity" ? (
                                                                                                            <>
                                                                                                                Annuitant
                                                                                                                DOB:
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                Insured
                                                                                                                DOB:
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Insured Date of Birth"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {utilities.parseAndFormatDate(
                                                                                                            hoPolicyData.insuredDateOfBirth
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Policy Status"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Policy
                                                                                                        Status:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Policy Status"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            hoPolicyData.status
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Product Type"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Product
                                                                                                        Type:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Product Type"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            hoPolicyData.productType
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Company Code"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Company
                                                                                                        Code:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Company Code"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            hoPolicyData.companyCode
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Qualified"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Qualified?
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Qualified"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {hoPolicyData.qualifiedIndicator ==
                                                                                                        true
                                                                                                            ? "Yes"
                                                                                                            : "No"}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title=""
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        &nbsp;
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Qualified Plan Type"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            hoPolicyData.qualifiedPlanType
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Owner Address"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Owner
                                                                                                        Address:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Owner Address"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            hoPolicyData.ownerAddress
                                                                                                        }
                                                                                                        <br></br>
                                                                                                        {
                                                                                                            hoPolicyData.ownerCityStateZip
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Agent Number"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Agent
                                                                                                        Number:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Agent Number"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            hoPolicyData.agentNumber
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Agent Name"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Agent
                                                                                                        Name:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Agent Name"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            hoPolicyData.agentName
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Agent Status"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Agent
                                                                                                        Status:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Agent Status"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            hoPolicyData.agentStatus
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Agent Social Security Number"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Agent
                                                                                                        SSN:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Agent Social Security Number"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            hoPolicyData.agentGovtID
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Agent Email Address"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Agent
                                                                                                        Email
                                                                                                        Address:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Agent Email Address"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {hoPolicyData.agentEmail ??
                                                                                                            ""}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Agent Phone Number"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Agent
                                                                                                        Phone
                                                                                                        Number:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Agent Phone Number"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {hoPolicyData.agentPhone ??
                                                                                                            ""}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                        title="Agent Address"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        Agent
                                                                                                        Address:
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                        title="Agent Address"
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        {hoPolicyData.agentAddress ??
                                                                                                            ""}
                                                                                                        <br></br>
                                                                                                        {hoPolicyData.agentCityStateZip ??
                                                                                                            ""}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 20, color: "#1C2F67" }}
                                                    >
                                                        <button
                                                            id="alertAWDCollapse"
                                                            className="homeOfficeCollapse"
                                                            aria-expanded={toggleHOAlertAWD}
                                                            onClick={() =>
                                                                utilities.setAriaExpanded(
                                                                    "alertAWDCollapse",
                                                                    !toggleHOAlertAWD
                                                                )
                                                            }
                                                        >
                                                            <h2 className="homeOfficeHeadingToggle">
                                                                <span
                                                                    onClick={toggleHOAlertAWDFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    tabIndex={0}
                                                                >
                                                                    **ALERT AWD
                                                                    WorkObjects:&nbsp;&nbsp;
                                                                </span>
                                                                <span
                                                                    onClick={toggleHOAlertAWDFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    className={
                                                                        toggleHOAlertAWD
                                                                            ? "bi bi-chevron-up"
                                                                            : "bi bi-chevron-down"
                                                                    }
                                                                    aria-hidden={true}
                                                                    tabIndex={0}
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </h2>
                                                        </button>
                                                    </div>
                                                    <Collapse
                                                        in={toggleHOAlertAWD}
                                                        role="region"
                                                        aria-labelledby="alertAWDCollapse"
                                                    >
                                                        <div
                                                            className="row"
                                                            style={{ padding: 10 }}
                                                        >
                                                            <div className="col-12">
                                                                <div className="card securityCard">
                                                                    <div className="card-body shadow">
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                        >
                                                                            <div className="col-12 col-md-12 col-lg-10 col-xl-10">
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="col-12 col-md-12 col-lg-12 col-xl-12"
                                                                                        aria-label="Alert AWD Work Objects"
                                                                                        aria-describedby="alertAWDWorkObjectsDesc"
                                                                                        role="table"
                                                                                    >
                                                                                        <div id="alertAWDWorkObjectsDesc">
                                                                                            Alert
                                                                                            AWD Work
                                                                                            Objects
                                                                                        </div>
                                                                                        <div role="rowgroup">
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                                role="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 fieldLabel"
                                                                                                    title="Create Date and Time"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Create
                                                                                                    Date
                                                                                                    Time:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 fieldLabel"
                                                                                                    title="Work Type Description"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Work
                                                                                                    Type
                                                                                                    Desc:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 fieldLabel"
                                                                                                    title="Status Description"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Status
                                                                                                    Desc:
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {hoAlertWorkobjects &&
                                                                                        hoAlertWorkobjects.length >
                                                                                            0 ? (
                                                                                            <div role="rowgroup">
                                                                                                {hoAlertWorkobjects.map(
                                                                                                    (
                                                                                                        wo,
                                                                                                        idx
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            className="row"
                                                                                                            style={{
                                                                                                                paddingTop: 5,
                                                                                                                paddingBottom: 5
                                                                                                            }}
                                                                                                            key={
                                                                                                                idx
                                                                                                            }
                                                                                                        >
                                                                                                            <div
                                                                                                                className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 fieldValue"
                                                                                                                title="Work Object Create Date and Time"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {utilities.parseAndFormatDate(
                                                                                                                    wo.createDateTime
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 fieldValue"
                                                                                                                title="Work Object Work Type Description"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.workTypeDesc
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 fieldValue"
                                                                                                                title="Work Object Status Description"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.statusDesc
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                                {/* <div className="row" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                                                        <button disabled={showAllAlertWorkobjects} style={{ width: "70px" }} onClick={() => getHOAlertWorkobjects(idParsed, true)}>More</button>
                                                                                    </div> */}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                    <>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 20,
                                                                color: "#1C2F67"
                                                            }}
                                                        >
                                                            <button
                                                                id="insuredInformationCollapse"
                                                                className="homeOfficeCollapse"
                                                                aria-expanded={toggleHOInsured}
                                                                onClick={() =>
                                                                    utilities.setAriaExpanded(
                                                                        "insuredInformationCollapse",
                                                                        !toggleHOInsured
                                                                    )
                                                                }
                                                            >
                                                                <h2 className="homeOfficeHeadingToggle">
                                                                    <span
                                                                        onClick={
                                                                            toggleHOInsuredFunc
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "#1C2F67",
                                                                            fontWeight: "bold"
                                                                        }}
                                                                        tabIndex={0}
                                                                    >
                                                                        {hoPolicyData &&
                                                                        hoPolicyData.productType.toLowerCase() ==
                                                                            "annuity" ? (
                                                                            <>
                                                                                Annuitant
                                                                                Information&nbsp;&nbsp;
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                Insured
                                                                                Information&nbsp;&nbsp;
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                    <span
                                                                        onClick={
                                                                            toggleHOInsuredFunc
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "#1C2F67",
                                                                            fontWeight: "bold"
                                                                        }}
                                                                        className={
                                                                            toggleHOInsured
                                                                                ? "bi bi-chevron-up"
                                                                                : "bi bi-chevron-down"
                                                                        }
                                                                        aria-hidden={true}
                                                                        tabIndex={0}
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </h2>
                                                            </button>
                                                        </div>
                                                        <Collapse
                                                            in={toggleHOInsured}
                                                            role="region"
                                                            aria-labelledby="insuredInformationCollapse"
                                                        >
                                                            <div
                                                                className="row"
                                                                style={{ padding: 10 }}
                                                            >
                                                                <div className="col-12">
                                                                    <div className="card insuredCard">
                                                                        <div className="card-body shadow">
                                                                            <div
                                                                                className="row"
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingBottom: 5
                                                                                }}
                                                                            >
                                                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                                                    {hoPolicyData &&
                                                                                    hoPolicyData.policyDWKey ? (
                                                                                        <>
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                            >
                                                                                                <div className="col-12 col-md-12 col-lg-12 col-xl-7">
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                            title="Name"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            Name:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                            title="Insured Name"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                hoPolicyData.insuredName
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                            title="Address"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            Address:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                            title="Insured Address"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                hoPolicyData.insuredAddress
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                            title=""
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        ></div>
                                                                                                        <div
                                                                                                            className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                            title="Insured City, State and Zip"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                hoPolicyData.insuredCityStateZip
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                            title="Phone Number"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            Phone:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                            title="Insured Phone Number"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                hoPolicyData.insuredPhoneNumber
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                            title="Email Address"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            Email:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                            title="Insured Email Address"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                hoPolicyData.insuredEmailAddress
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-12 col-lg-12 col-xl-5">
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                            title="Gender"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            Gender:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                            title="Insured Gender"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                hoPolicyData.insuredGender
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                            title="Social Security Number"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            SSN:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                            title="Social Security Number"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                hoPolicyData.insuredGovtID
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                            title="Date of Birth"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            DOB:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                            title="Insured Date of Birth"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {utilities.parseAndFormatDate(
                                                                                                                hoPolicyData.insuredDateOfBirth
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="row"
                                                                                                        style={{
                                                                                                            paddingTop: 5,
                                                                                                            paddingBottom: 5
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 fieldLabel"
                                                                                                            title="Age"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            Age:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 fieldValue"
                                                                                                            title="Insured Calculated Age"
                                                                                                            tabIndex={
                                                                                                                0
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                hoPolicyData.insuredCalculatedAge
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Collapse>
                                                    </>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 20, color: "#1C2F67" }}
                                                    >
                                                        <button
                                                            id="agentIDsCollapse"
                                                            className="homeOfficeCollapse"
                                                            aria-expanded={toggleHOAgentIDs}
                                                            onClick={() =>
                                                                utilities.setAriaExpanded(
                                                                    "agentIDsCollapse",
                                                                    !toggleHOAgentIDs
                                                                )
                                                            }
                                                        >
                                                            <h2 className="homeOfficeHeadingToggle">
                                                                <span
                                                                    onClick={toggleHOAgentIDsFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    tabIndex={0}
                                                                >
                                                                    Additional Agent
                                                                    IDs:&nbsp;&nbsp;
                                                                </span>
                                                                <span
                                                                    onClick={toggleHOAgentIDsFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    className={
                                                                        toggleHOAgentIDs
                                                                            ? "bi bi-chevron-up"
                                                                            : "bi bi-chevron-down"
                                                                    }
                                                                    aria-hidden={true}
                                                                    tabIndex={0}
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </h2>
                                                        </button>
                                                    </div>
                                                    <Collapse
                                                        in={toggleHOAgentIDs}
                                                        role="region"
                                                        aria-labelledby="agentIDsCollapse"
                                                    >
                                                        <div
                                                            className="row"
                                                            style={{ padding: 10 }}
                                                        >
                                                            <div className="col-12">
                                                                <div className="card securityCard">
                                                                    <div className="card-body shadow">
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                        >
                                                                            <div className="col-12 col-md-12 col-lg-10 col-xl-10">
                                                                                {hoAgents &&
                                                                                hoAgents.length >
                                                                                    0 ? (
                                                                                    <>
                                                                                        {hoAgents.map(
                                                                                            (
                                                                                                a,
                                                                                                idx
                                                                                            ) => (
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                    key={
                                                                                                        idx
                                                                                                    }
                                                                                                >
                                                                                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                                                                                        <div
                                                                                                            className="row"
                                                                                                            style={{
                                                                                                                paddingTop: 5,
                                                                                                                paddingBottom: 5
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fieldLabel"
                                                                                                                title="Producer ID"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                Producer
                                                                                                                ID:
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fieldValue"
                                                                                                                title="Producer ID"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    a.producerID
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                                                                                        <div
                                                                                                            className="row"
                                                                                                            style={{
                                                                                                                paddingTop: 5,
                                                                                                                paddingBottom: 5
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fieldLabel"
                                                                                                                title="Status"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                Status:
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 fieldValue"
                                                                                                                title="Producer Status"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    a.producerStatus
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 20, color: "#1C2F67" }}
                                                    >
                                                        <button
                                                            id="openAWDCollapse"
                                                            className="homeOfficeCollapse"
                                                            aria-expanded={toggleHOOpenAWD}
                                                            onClick={() =>
                                                                utilities.setAriaExpanded(
                                                                    "openAWDCollapse",
                                                                    !toggleHOOpenAWD
                                                                )
                                                            }
                                                        >
                                                            <h2 className="homeOfficeHeadingToggle">
                                                                <span
                                                                    onClick={toggleHOOpenAWDFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    tabIndex={0}
                                                                >
                                                                    **Open AWD
                                                                    WorkObjects:&nbsp;&nbsp;
                                                                </span>
                                                                <span
                                                                    onClick={toggleHOOpenAWDFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    className={
                                                                        toggleHOOpenAWD
                                                                            ? "bi bi-chevron-up"
                                                                            : "bi bi-chevron-down"
                                                                    }
                                                                    aria-hidden={true}
                                                                    tabIndex={0}
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </h2>
                                                        </button>
                                                    </div>
                                                    <Collapse
                                                        in={toggleHOOpenAWD}
                                                        role="region"
                                                        aria-labelledby="openAWDCollapse"
                                                    >
                                                        <div
                                                            className="row"
                                                            style={{ padding: 10 }}
                                                        >
                                                            <div className="col-12">
                                                                <div className="card securityCard">
                                                                    <div className="card-body shadow">
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                        >
                                                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="col-12 col-md-12 col-lg-12 col-xl-12"
                                                                                        aria-label="Open AWD WorkObjects"
                                                                                        aria-describedby="openAWDWorkObjectsDesc"
                                                                                        role="table"
                                                                                    >
                                                                                        <div id="openAWDWorkObjectsDesc">
                                                                                            Open AWD
                                                                                            Work
                                                                                            Objects
                                                                                        </div>
                                                                                        <div role="rowgroup">
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    paddingTop: 5,
                                                                                                    paddingBottom: 5
                                                                                                }}
                                                                                                role="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col fieldLabel"
                                                                                                    title="Create Date and Time AWD"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Create
                                                                                                    Date
                                                                                                    Time
                                                                                                    AWD:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col fieldLabel"
                                                                                                    title="Create Date and Time"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Create
                                                                                                    Date
                                                                                                    Time:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col fieldLabel"
                                                                                                    title="Unit Description"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Unit
                                                                                                    Desc:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col fieldLabel"
                                                                                                    title="Work Type Description"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Work
                                                                                                    Type
                                                                                                    Desc:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col fieldLabel"
                                                                                                    title="Queue Name"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Queue
                                                                                                    Name:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col fieldLabel"
                                                                                                    title="Status Description"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Status
                                                                                                    Desc:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col fieldLabel"
                                                                                                    title="Status Change Date and Time"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Status
                                                                                                    Change
                                                                                                    Date
                                                                                                    Time:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col fieldLabel"
                                                                                                    title="Last Non-Batch User"
                                                                                                    tabIndex={
                                                                                                        0
                                                                                                    }
                                                                                                    role="columnheader"
                                                                                                >
                                                                                                    Last
                                                                                                    Non
                                                                                                    Batch
                                                                                                    User:
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {hoOpenAWDWorkobjects &&
                                                                                        hoOpenAWDWorkobjects.length >
                                                                                            0 ? (
                                                                                            <div role="rowgroup">
                                                                                                {hoOpenAWDWorkobjects.map(
                                                                                                    (
                                                                                                        wo,
                                                                                                        idx
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            className="row"
                                                                                                            style={{
                                                                                                                paddingTop: 5,
                                                                                                                paddingBottom: 5
                                                                                                            }}
                                                                                                            key={
                                                                                                                idx
                                                                                                            }
                                                                                                            role="row"
                                                                                                        >
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Work Object Create Date and Time AWD"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.createDateTimeAWD
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Work Object Create Date and Time"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {utilities.parseAndFormatDate(
                                                                                                                    wo.createDateTime
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Work Object Unit Description"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.unitDesc
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Work Object Work Type Description"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.workTypeDesc
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Work Object Queue Name"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.queueName
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Work Object Status Description"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.statusDesc
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Work Object Status Change Date and Time"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {utilities.parseAndFormatDate(
                                                                                                                    wo.statusChangeDateTime
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Work Object Last Non-Batch User"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                                role="cell"
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.lastNonBatchUser
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <button
                                                                                                        style={{
                                                                                                            width: "70px"
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            getHOOpenAWDWorkobjects(
                                                                                                                idParsed,
                                                                                                                true
                                                                                                            )
                                                                                                        }
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        More
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                    <div
                                                        className="row"
                                                        style={{ paddingTop: 20, color: "#1C2F67" }}
                                                    >
                                                        <button
                                                            id="openADDRCollapse"
                                                            className="homeOfficeCollapse"
                                                            aria-expanded={toggleHOOpenADDR}
                                                            onClick={() =>
                                                                utilities.setAriaExpanded(
                                                                    "openADDRCollapse",
                                                                    !toggleHOOpenADDR
                                                                )
                                                            }
                                                        >
                                                            <h2 className="homeOfficeHeadingToggle">
                                                                <span
                                                                    onClick={toggleHOOpenADDRFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    tabIndex={0}
                                                                >
                                                                    **Open ADDR
                                                                    WorkObjects:&nbsp;&nbsp;
                                                                </span>
                                                                <span
                                                                    onClick={toggleHOOpenADDRFunc}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#1C2F67",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    className={
                                                                        toggleHOOpenADDR
                                                                            ? "bi bi-chevron-up"
                                                                            : "bi bi-chevron-down"
                                                                    }
                                                                    aria-hidden={true}
                                                                    tabIndex={0}
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </h2>
                                                        </button>
                                                    </div>
                                                    <Collapse
                                                        in={toggleHOOpenADDR}
                                                        role="region"
                                                        aria-labelledby="openADDRCollapse"
                                                    >
                                                        <div
                                                            className="row"
                                                            style={{ padding: 10 }}
                                                        >
                                                            <div className="col-12">
                                                                <div className="card securityCard">
                                                                    <div className="card-body shadow">
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                paddingBottom: 5
                                                                            }}
                                                                        >
                                                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                                                <div
                                                                                    className="row"
                                                                                    style={{
                                                                                        paddingTop: 5,
                                                                                        paddingBottom: 5
                                                                                    }}
                                                                                >
                                                                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                                                        <div
                                                                                            className="row"
                                                                                            style={{
                                                                                                paddingTop: 5,
                                                                                                paddingBottom: 5
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="col fieldLabel"
                                                                                                title="Create Date and Time AWD"
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                Create
                                                                                                Date
                                                                                                Time
                                                                                                AWD:
                                                                                            </div>
                                                                                            <div
                                                                                                className="col fieldLabel"
                                                                                                title="Create Date and Time"
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                Create
                                                                                                Date
                                                                                                Time:
                                                                                            </div>
                                                                                            <div
                                                                                                className="col fieldLabel"
                                                                                                title="Unit Description"
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                Unit
                                                                                                Desc:
                                                                                            </div>
                                                                                            <div
                                                                                                className="col fieldLabel"
                                                                                                title="Work Type Description"
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                Work
                                                                                                Type
                                                                                                Desc:
                                                                                            </div>
                                                                                            <div
                                                                                                className="col fieldLabel"
                                                                                                title="Queue Name"
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                Queue
                                                                                                Name:
                                                                                            </div>
                                                                                            <div
                                                                                                className="col fieldLabel"
                                                                                                title="Status Description"
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                Status
                                                                                                Desc:
                                                                                            </div>
                                                                                            <div
                                                                                                className="col fieldLabel"
                                                                                                title="Status Change Date and Time"
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                Status
                                                                                                Change
                                                                                                Date
                                                                                                Time:
                                                                                            </div>
                                                                                            <div
                                                                                                className="col fieldLabel"
                                                                                                title="Last Non-Batch User"
                                                                                                tabIndex={
                                                                                                    0
                                                                                                }
                                                                                            >
                                                                                                Last
                                                                                                Non
                                                                                                Batch
                                                                                                User:
                                                                                            </div>
                                                                                        </div>
                                                                                        {hoOpenADDRWorkobjects &&
                                                                                        hoOpenADDRWorkobjects.length >
                                                                                            0 ? (
                                                                                            <>
                                                                                                {hoOpenADDRWorkobjects.map(
                                                                                                    (
                                                                                                        wo,
                                                                                                        idx
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            className="row"
                                                                                                            style={{
                                                                                                                paddingTop: 5,
                                                                                                                paddingBottom: 5
                                                                                                            }}
                                                                                                            key={
                                                                                                                idx
                                                                                                            }
                                                                                                        >
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Create Date and Time AWD"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.createDateTimeAWD
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Create Date and Time"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {utilities.parseAndFormatDate(
                                                                                                                    wo.createDateTime
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Unit Description"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.unitDesc
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Work Type Description"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.workTypeDesc
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Queue Name"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.queueName
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Status Description"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.statusDesc
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Status Change Date and Time"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {utilities.parseAndFormatDate(
                                                                                                                    wo.statusChangeDateTime
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col fieldValue"
                                                                                                                title="Last Non-Batch User"
                                                                                                                tabIndex={
                                                                                                                    0
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    wo.lastNonBatchUser
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                                <div
                                                                                                    className="row"
                                                                                                    style={{
                                                                                                        paddingTop: 5,
                                                                                                        paddingBottom: 5
                                                                                                    }}
                                                                                                >
                                                                                                    <button
                                                                                                        disabled={
                                                                                                            showAllOpenADDRWorkobjects
                                                                                                        }
                                                                                                        style={{
                                                                                                            width: "70px"
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            getHOOpenADDRWorkobjects(
                                                                                                                idParsed,
                                                                                                                true
                                                                                                            )
                                                                                                        }
                                                                                                        tabIndex={
                                                                                                            0
                                                                                                        }
                                                                                                    >
                                                                                                        More
                                                                                                    </button>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </>
                                            )}
                                        </section>
                                    </Tab>
                                ) : (
                                    <></>
                                )}
                            </Tabs>
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
            );
        }
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <>
                <h1 style={{ display: "none" }}>Policy Details Error Message</h1>
                <div
                    className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-warning"
                    aria-live="assertive"
                    role="alert"
                >
                    {errorMessage}
                </div>
            </>
        );
    } else {
        return (
            <>
                <h1 style={{ display: "none" }}>Policy Details Spinner</h1>
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                    <Spinner imageWidth={20} aria-label="Loading" />
                </div>
            </>
        );
    }
};

export default PolicyContainer;

import { config as appConfig } from "../config";

async function http<T>(
    path: string,
    config: RequestInit,
    method: string,
    csrfToken: string,
    bearerToken: string
): Promise<T> {
    const creds: RequestCredentials = "include";
    let requestOptions = {
        method,
        credentials: creds,
        headers: {
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": csrfToken
        }
    };

    const requestOptionsBody = {
        body: config.body,
        ...requestOptions
    };
    const requestOptionsBodyAuth = {
        ...requestOptions,
        body: config.body,
        headers: {
            Authorization: "Bearer " + bearerToken,
            ...requestOptions.headers
        }
    };
    const requestOptionsAuth = {
        ...requestOptions,
        headers: {
            Authorization: "Bearer " + bearerToken,
            ...requestOptions.headers
        }
    };

    if (config?.body) {
        if (path.includes("login") || path.includes("logdetailed")) {
            requestOptions = requestOptionsBody;
        } else {
            requestOptions = requestOptionsBodyAuth;
        }
    } else {
        if (path.includes("login")) {
            requestOptions = requestOptions;
        } else {
            requestOptions = requestOptionsAuth;
        }
    }

    const response = await fetch(appConfig.resourceServer.apiUrl + path, requestOptions);
    if (!response.ok) {
        console.log(`fetch failed with ${response.status} code and text ${response.statusText}`);
        throw new Error(`Status: ${response.status}, Message: ${response.statusText}`);
    }

    // may error if there is no body, return empty array
    return response.json().catch(() => ({}));
}

export { http };

export enum PolicyStatus {
    Active = "Active",
    ContinuationOfInsurance = "Continuation Of Insurance",
    DeathClaimPaid = "Death Claim Paid",
    DeathClaimPending = "Death Claim Pending",
    NotActive = "Not Active",
    ActivePaidUp = "Active - Paid Up",
    ExtendedTerm = "Extended Term",
    LapsePending = "Lapse Pending",
    Lapse = "Lapse",
    Lapsed = "Lapsed",
    ReducedPaidUp = "Reduced Paid Up",
    Surrendered = "Surrendered",
    Grace = "Grace"
}

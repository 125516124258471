import { parse, isValid, format } from "date-fns";
import { enUS } from "date-fns/locale";
import useFetch from "./useFetch";
import { useHistory } from "react-router";

const useUtilities = () => {
    const { post, get } = useFetch();
    const navigate = useHistory();

    const isMaintenanceError = (_error: unknown): boolean => {
        if (typeof _error === "string") {
            return _error.toString().indexOf("503") > 0;
        } else if (_error instanceof Error) {
            return _error.message.toString().indexOf("503") > 0;
        } else {
            return false;
        }
    };

    const getSupportPhoneNumberByCompanyCode = (_companyCode: number): string => {
        if (_companyCode?.toString().length <= 2 && _companyCode > 0) {
            return "800-366-6100";
        } else {
            return "800-231-0801";
        }
    };

    const formatCurrency = (_valueToFormat: number): string => {
        const absValue = Math.abs(_valueToFormat);
        const returnString = Number(absValue).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return _valueToFormat < 0 ? "(" + returnString + ")" : returnString;
    };

    const formatPercentage = (_valueToFormat: number): string => {
        const absValue = Math.abs(_valueToFormat);
        const returnString = Number(absValue).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return _valueToFormat < 0 ? "(" + returnString + ")" : returnString;
    };

    const parseAndFormatDate = (dateString: string): string => {
        if (dateString === null) return "";

        let tempDate = dateString;
        if (tempDate.indexOf(".") > 0) {
            tempDate = tempDate.substring(0, tempDate.indexOf("."));
        }
        const parsed: Date = parse(tempDate, "yyyy-MM-dd'T'HH:mm:ss", new Date(), { locale: enUS });
        if (isValid(parsed)) {
            const result = format(parsed, "MM-dd-yyyy");
            return result;
        }
        return "NA";
    };

    const parseAndFormatDateCustom = (dateString: string, inputFormatString: string): string => {
        let tempDate = dateString;
        if (!inputFormatString) {
            inputFormatString = "yyyy-MM-dd'T'HH:mm:ss";
        }
        if (tempDate.indexOf(".") > 0) {
            tempDate = tempDate.substring(0, tempDate.indexOf("."));
        }
        const parsed: Date = parse(tempDate, inputFormatString, new Date(), { locale: enUS });
        if (isValid(parsed)) {
            const result = format(parsed, "MM/dd/yyyy");
            return result;
        }
        return "NA";
    };

    const parseDate = (dateString: string): Date => {
        const parsed: Date = parse(dateString, "yyyy-MM-dd'T'HH:mm:ss", new Date(), {
            locale: enUS
        });
        if (isValid(parsed)) {
            return parsed;
        } else {
            return new Date();
        }
    };

    const parseDateCustom = (dateString: string, inputFormatString: string): Date => {
        if (!inputFormatString) {
            inputFormatString = "yyyy-MM-dd'T'HH:mm:ss";
        }
        const parsed: Date = parse(dateString, inputFormatString, new Date(), { locale: enUS });
        if (isValid(parsed)) {
            return parsed;
        } else {
            return new Date();
        }
    };

    const isValidDate = (dateString: string, formatString: string): boolean => {
        if (!formatString) {
            formatString = "yyyy-MM-dd'T'HH:mm:ss";
        }
        const parsed: Date = parse(dateString, formatString, new Date(), { locale: enUS });
        if (isValid(parsed)) {
            return true;
        } else {
            return false;
        }
    };

    const roundDown = (_number: number, _decimals: number): number => {
        _decimals = _decimals || 0;
        return Math.floor(_number * Math.pow(10, _decimals)) / Math.pow(10, _decimals);
    };

    const getCookie = (name: string): string => {
        let cookieValue = "";

        if (document.cookie && document.cookie !== "") {
            const cookies = document.cookie.split(";");

            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();

                // Does this cookie string begin with the name we want?

                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }

        return cookieValue;
    };

    const setCookie = (
        name: string,
        value: string,
        days = 1,
        path = "/",
        encode: boolean
    ): void => {
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        if (encode) {
            document.cookie =
                name + "=" + encodeURIComponent(value) + "; expires=" + expires + "; path=" + path;
        } else {
            document.cookie = name + "=" + value + "; expires=" + expires + "; path=" + path;
        }
    };

    const getCSRFToken = async () => {
        let token = "";
        const serverCookie = getCookie("XSRF-TOKEN");
        const uiCookie = getCookie("UI-XSRF-TOKEN");

        if (serverCookie?.length > 0) {
            token = serverCookie;
        } else if (uiCookie?.length > 0) {
            token = uiCookie;
        } else {
            const tokenResult = await get<string>("/csrf/token", "");

            setCookie("UI-XSRF-TOKEN", tokenResult, 1, "/", false);
            token = tokenResult;
        }
        return token;
    };

    const setAriaExpanded = (id: string, isExpanded: boolean): void => {
        const element = document.getElementById(id);
        if (element) {
            element.setAttribute("aria-expanded", isExpanded.toString());
        }
    };

    const getCurrentGeolocation = async () => {
        const options = { timeout: 5000 };
        return new Promise((response, error) => {
            navigator.geolocation.getCurrentPosition(response, error, options);
        });
    };

    const createEDeliveryFlagWorkObject = async (isEDelivery: boolean) => {
        let position: any;
        try {
            position = await getCurrentGeolocation();
        } catch (e: unknown) {
            position = {
                coords: {
                    latitude: 0,
                    longitude: 0
                }
            };
        }

        const result = await post<unknown, number>(
            "/customer/edelivery-preference?isEDelivery=" + isEDelivery,
            "",
            { latitude: position.coords.latitude, longitude: position.coords.longitude }
        );
        return result;
    };

    const getEDeliveryFlag = async () => {
        const eDeliveryFlag = await get<EDelivery>("/customer/edelivery", "");
        return eDeliveryFlag;
    };

    const isNullOrWhitespace = (str: string | undefined) => {
        return str === null || str?.match(/^ *$/) !== null || str === undefined;
    };

    const validatePolicyForPayment = async (_id: number): Promise<boolean> => {
        let result = false;

        try {
            result = await get<boolean>("/payment/validatepolicy?policyDWKey=" + _id, "");
        } catch (e: unknown) {
            if (isMaintenanceError(e)) {
                navigate.push("/maint");
            }
        }
        return result;
    };

    const convertToLocalDate = (utcDate: Date) => {
        const utc = new Date(utcDate);
        const offset = utc.getTimezoneOffset();
        const local = new Date(utc.getTime() - offset * 60000);
        return local.toLocaleDateString("en-US");
    };

    return {
        formatCurrency,
        formatPercentage,
        parseAndFormatDate,
        parseDate,
        isValidDate,
        parseDateCustom,
        isMaintenanceError,
        parseAndFormatDateCustom,
        roundDown,
        getSupportPhoneNumberByCompanyCode,
        setCookie,
        setAriaExpanded,
        getCSRFToken,
        getEDeliveryFlag,
        createEDeliveryFlagWorkObject,
        validatePolicyForPayment,
        isNullOrWhitespace,
        convertToLocalDate
    };
};
export default useUtilities;

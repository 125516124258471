import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import useFetch from "../../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import { CPContext } from "../../_helpers/CPContext";
import { Spinner } from "../../_components/shared/Common";

const AdminMarketingMessageList: React.FC = () => {
    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const { get } = useFetch();
    const [marketingMessages, setMarketingMessages] = useState<MarketingMessage[]>();
    const [mmSearch, setMMSearch] = useState<string>("");
    const { authState, oktaAuth } = useOktaAuth();
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const getMarketingMessages = async () => {
            try {
                const mmsFromServer = await get<MarketingMessage[]>("/marketingmessage/", "");

                let mmsTemp = mmsFromServer;
                //mmsTemp = inActiveMMDisplay === true ? mmsTemp : mmsTemp.filter((mm:any) => mm.isActive === true)

                //filter
                mmsTemp =
                    mmSearch !== "" && mmSearch !== "undefined"
                        ? mmsTemp.filter(
                              (mm: MarketingMessage) =>
                                  (mm.embeddedURL && mm.embeddedURL.indexOf(mmSearch) !== -1) ||
                                  (mm.clickURL && mm.clickURL.indexOf(mmSearch) !== -1)
                          )
                        : mmsTemp;

                setMarketingMessages(mmsTemp);
            } catch (error) {
                setErrorMessage("An unexpected error has occured, please try again.");
            }
        };

        if (
            authState &&
            authState.isAuthenticated &&
            cpContext.sessionId &&
            cpContext.sessionId.length > 0
        ) {
            getMarketingMessages();
        }
    }, [mmSearch, authState, oktaAuth, cpContext, navigate]);

    if (cpContext.isAdmin) {
        return (
            <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                <div className="col-md-12">
                    <div
                        className="row"
                        style={{
                            paddingTop: 10,
                            paddingBottom: 0,
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderBottom: 2,
                            borderStyle: "solid",
                            borderColor: "#33CCCC"
                        }}
                    >
                        <h1 className="custom-h1" style={{ paddingTop: 10 }}>
                            Marketing Message Admin
                        </h1>
                    </div>
                    <div className="row" style={{ paddingTop: 50 }}>
                        <div className="col-md-3">
                            <div className="inner-addon right-addon">
                                <i className="biCustom bi-search"></i>
                                <input
                                    type="text"
                                    name="mmSearch"
                                    placeholder="Search..."
                                    className="form-control skip-to"
                                    onChange={(e) => setMMSearch(e.target.value)}
                                    aria-label="Search marketing messages"
                                ></input>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div
                                className="form-control form-control-check border-0"
                                style={{ backgroundColor: "#eee" }}
                            >
                                <Link
                                    to={"/admin/marketing-message-add"}
                                    style={{ float: "right" }}
                                >
                                    <button className="btn btn-Primary">Add</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Table striped bordered hover responsive="lg">
                                <thead
                                    style={{
                                        backgroundColor: "#003366",
                                        color: "white",
                                        height: "40px",
                                        textAlign: "center"
                                    }}
                                >
                                    <tr>
                                        <th style={{ width: "5%" }}></th>
                                        <th className="text-start" style={{ width: "70%" }}>
                                            Embed URL
                                        </th>
                                        <th>Effective Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marketingMessages && marketingMessages.length > 0 ? (
                                        <>
                                            {marketingMessages.map((marketingMessage, idx) => (
                                                <tr key={idx}>
                                                    <td>
                                                        <Link
                                                            to={
                                                                "/admin/marketing-message/" +
                                                                marketingMessage.id
                                                            }
                                                        >
                                                            <span
                                                                className="bi bi-pencil"
                                                                style={{ color: "black" }}
                                                                title="Edit Marketing Message"
                                                            ></span>
                                                        </Link>
                                                    </td>
                                                    <td
                                                        tabIndex={0}
                                                        style={{
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                    >
                                                        {marketingMessage.embeddedURL}
                                                    </td>
                                                    <td
                                                        tabIndex={0}
                                                        style={{
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                    >
                                                        {marketingMessage.formattedEffectiveDate}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <td colSpan={3}>
                                                    {mmSearch !== "" && mmSearch !== "undefined" ? (
                                                        <>No results found.</>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <div
                className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-danger"
                aria-live="assertive"
                role="alert"
            >
                {errorMessage}
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default AdminMarketingMessageList;

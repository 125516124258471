import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useState } from "react";
import useFetch from "../_helpers/useFetch";
import { useOktaAuth } from "@okta/okta-react";
import useLogger from "../_helpers/useLogger";
import { CPContext } from "../_helpers/CPContext";
import { Spinner } from "./shared/Common";
import useUtilities from "../_helpers/useUtilities";
import { config } from "config";

interface fileDownloadRequest {
    id: number;
    policyDWKey: number;
}

const LandingPage: React.FC = () => {
    const cpContext = useContext(CPContext);
    const navigate = useHistory();
    const logger = useLogger();
    const { get, post } = useFetch();
    const { isMaintenanceError } = useUtilities();
    const [marketingMessage, setMarketingMessage] = useState<MarketingMessage>();
    const { authState, oktaAuth } = useOktaAuth();
    const [errorMessage, setErrorMessage] = useState("");
    const [isProcessingMM, setIsProcessingMM] = useState<boolean>(false);
    const [isProcessingPolicy, setIsProcessingPolicy] = useState<boolean>(false);
    const [electronicDisclosure, setElectronicDisclosure] = useState<boolean>(true);
    const [agreeChecked, setAgreeChecked] = useState(false);
    const [eAuthStep, setEAuthStep] = useState(1);
    const [showEAuth, setShowEAuth] = useState(false);
    const [iFrameLoaded, setIFrameLoaded] = useState(false);
    const [isSubmitProcessing, setIsSubmitProcessing] = useState<boolean>(false);
    const [eAuthPDFBlob, setEAuthPDFBlob] = useState<string>();

    useEffect(() => {
        const getMarketingMessage = async () => {
            if (!isProcessingMM) {
                try {
                    setIsProcessingMM(true);
                    setMarketingMessage(
                        await get<MarketingMessage>(
                            "/marketingmessage/landingpagemessage",
                            "",
                            cpContext
                        )
                    );
                    setIsProcessingMM(false);
                } catch (e: unknown) {
                    if (isMaintenanceError(e)) {
                        navigate.push("/maint");
                    } else {
                        setErrorMessage("An unexpected error has occured.");
                        setIsProcessingMM(false);
                    }
                }
            }
        };

        getMarketingMessage();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const getPolicies = async () => {
            if (!isProcessingPolicy) {
                try {
                    setIsProcessingPolicy(true);
                    const policiesFromServer = await get<PolicySimple[]>("/policy", "", cpContext);
                    let eauthFound = false;
                    for (let i = 0; i < policiesFromServer.length; i++) {
                        if (
                            policiesFromServer[i].electronicDisclosure ||
                            policiesFromServer[i].electronicDisclosureDeclineCount > 1
                        ) {
                            eauthFound = true;
                            break;
                        }
                    }

                    if (policiesFromServer.length > 0 && !eauthFound && cpContext.isCustomer) {
                        setElectronicDisclosure(false);
                        setEAuthStep(1);
                    } else if (!cpContext.isCustomer) {
                        navigate.push("/");
                    }
                    setIsProcessingPolicy(false);
                } catch (e: unknown) {
                    if (isMaintenanceError(e)) {
                        navigate.push("/maint");
                    } else {
                        setErrorMessage("An unexpected error has occured.");
                        setIsProcessingPolicy(false);
                    }
                }
            }
        };

        if (!cpContext.hideForNonDev && authState) {
            getPolicies();
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        await logger.logDetailedActivity(
            "LandingPage",
            "Continue",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId
        );

        if (cpContext.isCustomer) {
            navigate.push("/policies");
        } else {
            navigate.push("/impersonate");
        }
    };

    const EAuthDecline = async () => {
        await logger.logDetailedActivity(
            "LandingPage",
            "EAuthDecline",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId
        );

        setElectronicDisclosure(true);
    };

    const onErrorOK = async () => {
        setErrorMessage("");
        navigate.push("/");
    };

    const showEAuthClick = async () => {
        await logger.logDetailedActivity(
            "ElectronicAuth",
            "ShowPreview",
            cpContext,

            cpContext.userInfo?.email,
            cpContext.sessionId
        );
        await getEAuthPreview();
        setShowEAuth(true);
    };

    const getEAuthPreview = async () => {
        const body: fileDownloadRequest = {
            id: 0,
            policyDWKey: 0
        };

        const requestOptionsBodyAuth = {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + (oktaAuth.getAccessToken() || "")
            }
        };

        await fetch(config.resourceServer.apiUrl + "/policy/eauthpreviews", requestOptionsBodyAuth)
            .then((response) => response.blob())
            .then((response) => {
                if (response.type == "application/problem+json") {
                    setErrorMessage(
                        "Could not load the Electronic Authorization forms, please try again."
                    );
                } else {
                    setErrorMessage("");
                    const pdfBlob = window.URL.createObjectURL(response);
                    setEAuthPDFBlob(pdfBlob);
                }
            })
            .catch(() => {
                setErrorMessage(
                    "Could not load the Electronic Authorization forms, please try again."
                );
            });
    };

    const onSubmitEAuth = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (agreeChecked) {
            try {
                setIsSubmitProcessing(true);
                await logger.logDetailedActivity(
                    "LandingPage",
                    "EAuthSubmit",
                    cpContext,

                    cpContext.userInfo?.email,
                    cpContext.sessionId
                );

                const body: PolicyElectronicAuthorization = {
                    policyDWKey: 0,
                    electronicSignatureAgreement: true,
                    electronicSignatureName: "",
                    requestId: 0
                };
                const result = await post<PolicyElectronicAuthorization, boolean>(
                    "/policy/completeelectronicauthorization/",
                    "",
                    body,
                    cpContext
                );

                if (result) {
                    setEAuthStep(eAuthStep + 2);
                } else {
                    setErrorMessage(
                        "There was a problem saving your Electronic Disclosure Authorization selection. Please try again."
                    );
                }
            } catch (e: unknown) {
                if (isMaintenanceError(e)) {
                    navigate.push("/maint");
                } else {
                    setErrorMessage("An unexpected error has occured.");
                    setIsSubmitProcessing(false);
                }
            }
        } else {
            setEAuthStep(eAuthStep + 1);
        }
        setIsSubmitProcessing(false);
    };

    const handleIFrameLoad = () => setIFrameLoaded(true);

    if (!isProcessingMM && !isProcessingPolicy && errorMessage.length <= 0) {
        if (!electronicDisclosure && !cpContext.hideForNonDev) {
            if (eAuthStep == 1) {
                return (
                    <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                        <div className="col-md-12">
                            <form
                                className="add-form"
                                onSubmit={onSubmitEAuth}
                                style={{ backgroundColor: "#eee" }}
                            >
                                <div
                                    className="row"
                                    style={{
                                        paddingTop: 10,
                                        paddingBottom: 0,
                                        borderTop: 0,
                                        borderLeft: 0,
                                        borderRight: 0,
                                        borderBottom: 2,
                                        borderStyle: "solid",
                                        borderColor: "#33CCCC"
                                    }}
                                >
                                    <div className="col-8">
                                        <h4 style={{ paddingTop: 10 }}>
                                            Electronic Signature and Delivery Authorization
                                        </h4>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: 10 }}>
                                    <div className="col-9">
                                        <Card className="policyCard">
                                            <Card.Body className="shadow">
                                                <>
                                                    <div>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 15
                                                            }}
                                                        >
                                                            <div className="col-9 skip-to">
                                                                <p>
                                                                    {!showEAuth ? (
                                                                        <>
                                                                            Please review and agree
                                                                            to the Electronic
                                                                            Signature and Delivery
                                                                            Authorization consumer
                                                                            disclosure (click OK to
                                                                            view):
                                                                            <br></br>
                                                                            <input
                                                                                type="button"
                                                                                value="OK"
                                                                                className="btnRounded btn-block"
                                                                                onClick={() =>
                                                                                    showEAuthClick()
                                                                                }
                                                                                aria-label="Show electronic authorization disclosure button"
                                                                            ></input>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            Please review and agree
                                                                            to the Electronic
                                                                            Signature and Delivery
                                                                            Authorization consumer
                                                                            disclosure:
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 15
                                                            }}
                                                        >
                                                            <div
                                                                className="col-9"
                                                                style={{ paddingLeft: 30 }}
                                                            >
                                                                {showEAuth &&
                                                                eAuthPDFBlob &&
                                                                eAuthPDFBlob?.length > 0 ? (
                                                                    <>
                                                                        <iframe
                                                                            src={eAuthPDFBlob}
                                                                            width={650}
                                                                            height={550}
                                                                            onLoad={
                                                                                handleIFrameLoad
                                                                            }
                                                                            title={
                                                                                "ElectronicAuthorizationPreview"
                                                                            }
                                                                            id={
                                                                                "ElectronicAuthorizationPreview"
                                                                            }
                                                                        ></iframe>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {showEAuth ? (
                                                            <>
                                                                <div
                                                                    className="row"
                                                                    style={{
                                                                        paddingBottom: 15,
                                                                        paddingTop: 15
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="col-1"
                                                                        style={{ paddingLeft: 30 }}
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={agreeChecked}
                                                                            value="true"
                                                                            onChange={() =>
                                                                                setAgreeChecked(
                                                                                    true
                                                                                )
                                                                            }
                                                                            className="AgreeCheck"
                                                                            aria-label="Agree to electronic authorization disclosure checkbox"
                                                                        ></input>
                                                                    </div>
                                                                    <div
                                                                        className="col-9"
                                                                        style={{ paddingLeft: 30 }}
                                                                    >
                                                                        I agree to use electronic
                                                                        records and signature&apos;s
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="row"
                                                                    style={{ paddingBottom: 15 }}
                                                                >
                                                                    <div
                                                                        className="col-1"
                                                                        style={{ paddingLeft: 30 }}
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={!agreeChecked}
                                                                            value="false"
                                                                            onChange={() =>
                                                                                setAgreeChecked(
                                                                                    false
                                                                                )
                                                                            }
                                                                            className="NotAgreeCheck"
                                                                            aria-label="Do not agree to electronic authorization disclosure checkbox"
                                                                        ></input>
                                                                    </div>
                                                                    <div
                                                                        className="col-9"
                                                                        style={{ paddingLeft: 30 }}
                                                                    >
                                                                        I do not agree to use
                                                                        electronic records and
                                                                        signature&apos;s
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                    <div className="col-md-6">
                                        <div
                                            className="row"
                                            style={{ paddingTop: 10, paddingBottom: 0 }}
                                        >
                                            <div className="col-3">
                                                {/* TODO: Need to replace button with spinner so user can't do multiple submits */}
                                                {showEAuth && iFrameLoaded ? (
                                                    <>
                                                        {isSubmitProcessing ? (
                                                            <Spinner
                                                                imageWidth={20}
                                                                aria-label="Loading"
                                                            />
                                                        ) : (
                                                            <input
                                                                type="submit"
                                                                value="Complete"
                                                                className="btnRounded btn-block"
                                                                aria-label="Complete electronic authorization disclosure button"
                                                            ></input>
                                                        )}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                );
            } else if (eAuthStep == 2) {
                return (
                    <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                        <div className="col-md-12">
                            <div
                                className="row"
                                style={{
                                    paddingTop: 10,
                                    paddingBottom: 0,
                                    borderTop: 0,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    borderBottom: 2,
                                    borderStyle: "solid",
                                    borderColor: "#33CCCC"
                                }}
                            >
                                <div className="col-8">
                                    <h4 style={{ paddingTop: 10 }}>
                                        Electronic Signature and Delivery Authorization
                                    </h4>
                                </div>
                            </div>
                            <div className="row" style={{ padding: 10 }}>
                                <div className="col-9">
                                    <Card className="policyCard">
                                        <Card.Body className="shadow">
                                            <>
                                                <form
                                                    className="add-form"
                                                    onSubmit={onSubmitEAuth}
                                                    style={{ backgroundColor: "#FFFFFF" }}
                                                >
                                                    <div
                                                        className="row"
                                                        style={{
                                                            paddingBottom: 15,
                                                            paddingTop: 15
                                                        }}
                                                    >
                                                        <>
                                                            In order to make any change to your
                                                            Policy electronically through the
                                                            Portal, we must have your consent to use
                                                            electronic records and signatures. In
                                                            the meantime, you can continue by
                                                            hitting &apos;OK&apos;.
                                                        </>
                                                    </div>
                                                </form>
                                            </>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                <div className="col-md-6">
                                    <div
                                        className="row"
                                        style={{ paddingTop: 10, paddingBottom: 0 }}
                                    >
                                        <div className="col-6">
                                            <input
                                                type="button"
                                                value="Back"
                                                className="btn btn-Primary"
                                                onClick={() => setEAuthStep(eAuthStep - 1)}
                                                style={{ width: "90px" }}
                                            ></input>
                                        </div>
                                        <div className="col-6">
                                            {/* TODO: Need to replace button with spinner so user can't do multiple submits */}
                                            <input
                                                type="button"
                                                value="OK"
                                                className="btn btn-Primary"
                                                onClick={() => EAuthDecline()}
                                                aria-label="Decline electronic authorization disclosure button"
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else if (eAuthStep == 3) {
                return (
                    <div className="row" style={{ backgroundColor: "#eee", padding: "20px" }}>
                        <div className="col-md-12">
                            <div
                                className="row"
                                style={{
                                    paddingTop: 10,
                                    paddingBottom: 0,
                                    borderTop: 0,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    borderBottom: 2,
                                    borderStyle: "solid",
                                    borderColor: "#33CCCC"
                                }}
                            >
                                <div className="col-8">
                                    <h4 style={{ paddingTop: 10 }}>
                                        Electronic Signature and Delivery Authorization
                                    </h4>
                                </div>
                            </div>
                            <div className="row" style={{ padding: 10 }}>
                                <div className="col-9">
                                    <Card className="policyCard">
                                        <Card.Body className="shadow">
                                            <>
                                                <form
                                                    className="add-form"
                                                    onSubmit={onSubmitEAuth}
                                                    style={{ backgroundColor: "#FFFFFF" }}
                                                >
                                                    <div
                                                        className="row"
                                                        style={{
                                                            paddingBottom: 15,
                                                            paddingTop: 15
                                                        }}
                                                    >
                                                        Thank you for signing. Your signature has
                                                        been applied. A confirmation email will be
                                                        sent to your email address with a link to
                                                        your completed Electronic Authorization
                                                        Form.
                                                    </div>
                                                </form>
                                            </>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="row" style={{ paddingTop: 10, paddingBottom: 0 }}>
                                <div className="col-md-6">
                                    <div
                                        className="row"
                                        style={{ paddingTop: 10, paddingBottom: 0 }}
                                    >
                                        <div className="col-6">
                                            <input
                                                type="button"
                                                value="OK"
                                                className="btn btn-Primary"
                                                onClick={() => setElectronicDisclosure(true)}
                                                aria-label="Accept electronic authorization disclosure button"
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                        &nbsp;
                    </div>
                );
            }
        } else if (marketingMessage && marketingMessage.id) {
            return (
                <div className="banner-container-parent d-flex flex-column justify-content-center align-items-center">
                    <Card body className="banner-container">
                        <form
                            className="add-form"
                            onSubmit={onSubmit}
                            style={{ backgroundColor: "#EEEEEE" }}
                        >
                            <div>
                                {marketingMessage &&
                                marketingMessage.embeddedURL &&
                                marketingMessage.embeddedURL.length > 0 ? (
                                    <iframe
                                        src={marketingMessage.embeddedURL}
                                        className="banner-iframe"
                                        allowFullScreen={false}
                                        frameBorder="0"
                                        title="Customer Portal"
                                        scrolling="no"
                                    ></iframe>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div style={{ backgroundColor: "#E8E7E7" }}>
                                <div className="row">
                                    <div className="col-6">
                                        {/* TODO: Need to replace button with spinner so user can't do multiple submits */}
                                        <input
                                            type="submit"
                                            value="Continue"
                                            className="btnRounded btn-block"
                                            aria-label="Continue with application form submit"
                                        ></input>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            );
        } else {
            return (
                <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                    &nbsp;
                </div>
            );
        }
    } else if (errorMessage && errorMessage.length > 0) {
        return (
            <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                <div style={{ backgroundColor: "#E8E7E7" }}>
                    <div className="row">
                        <div className="col-12 text-danger" aria-live="assertive" role="alert">
                            {errorMessage}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="button"
                                value="OK"
                                className="btn btn-Primary"
                                onClick={onErrorOK}
                                aria-label="Acknowledge and dismiss error message button"
                            ></input>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Spinner imageWidth={20} aria-label="Loading" />;
    }
};

export default LandingPage;

import React from "react";

const CompanyList: React.FC = () => {
    return (
        <div>
            <p className="mt-5 text-center">
                Americo is the brand name for insurance products issued by the subsidiary insurance
                companies controlled by Americo Life, Inc. Products are underwritten by Americo
                Financial Life and Annuity Insurance Company (AFL) or Great Southern Life Insurance
                Company (GSL), Kansas City, MO, and may vary in accordance with state laws. Not all
                policies and benefits are available in every state. AFL is authorized to conduct
                life insurance business in the District of Columbia and all states except NY, and
                health insurance business in the District of Columbia and all states except CT, ME,
                and NY. GSL is authorized to conduct health insurance business in the District of
                Columbia and all states except NJ, NY, and VT.
            </p>
            <p className="m-5 text-center">
                Americo Financial Life and Annuity Insurance Co. (formerly The College Life
                Insurance Company of America) • Great Southern Life Insurance Co. • United Fidelity
                Life Insurance Co. • National Farmers Union Life Insurance Co. • Investors Life
                Insurance Company of North America • Companies Administered by the Americo Group of
                Companies: Protective Life Ins. Co. (formerly Ohio Life Ins.) • Berkley Life and
                Health Ins. Co. (formerly Investors Guaranty Life) • First Health Life & Health
                Insurance Co. (formerly Loyalty Life Ins. Co.) • Renaissance Life & Health Insurance
                Company of America (formerly Central National Life of Omaha) • Pavonia Life
                Insurance Co. of NY (formerly First Central National Life of New York) • Wilcac Life
                Ins Co. (formerly Conseco Life Ins. Co./ Massachusetts General Life Ins. Co.) • Life
                Insurance Company of North America • Athene Annuity and Life Company (formerly
                American Investors Life) • The Ohio State Life Insurance Co. • Upstream Life
                Insurance Co. (formerly Financial Assurance Life Insurance Co.)
            </p>
        </div>
    );
};

export default CompanyList;
